.chat-container {
  display: flex;
  height: 92vh;
  width: 100%;
  margin: 0;
  background-color: #1a1b26;
  color: #a9b1d6;
  font-family: "Inter", sans-serif;
}

.chat-wrapper {
  display: flex;
  width: 100%;
}

.sidebar {
  width: 330px;
  background-color: #1f2335;
  border-right: 1px solid #2f3549;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
}

.sidebar-header {
  padding: 20px;
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid #2f3549;
}

.search-container {
  padding: 10px;
  position: relative;
}

.search-input {
  width: 100%;
  padding: 10px 30px 10px 10px;
  border: none;
  border-radius: 5px;
  background-color: #24283b;
  color: #a9b1d6;
  border-color: #2f3549;
}

.search-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.conversations-list {
  overflow-y: auto;
  flex-grow: 1;
}

.conversation-item {
  display: flex;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.conversation-item:hover,
.conversation-item.active {
  background-color: #292e42;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.conversation-details {
  flex-grow: 1;
  overflow: hidden;
}

.conversation-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sender-name {
  font-weight: bold;
}

.timestamp {
  font-size: 0.8em;
  color: #565f89;
}

.message-preview {
  font-size: 0.9em;
  color: #565f89;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedback-text {
  cursor: pointer;
}

.main-chat {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 54%;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1f2335;
  border-bottom: 1px solid #2f3549;
}

.chat-header-content {
  display: flex;
  align-items: center;
}

.current-chat-name {
  margin-left: 10px;
  font-weight: bold;
}

.sidebar-toggle {
  display: none;
  background: none;
  border: none;
  color: #a9b1d6;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

.image-toggle {
  background: none;
  border: none;
  color: #a9b1d6;
  cursor: pointer;
  font-size: 1.2em;
}

.messages-area {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.message {
  display: flex;
  margin-bottom: 20px;
}

.message.user {
  flex-direction: row-reverse;
}

.message-content {
  background-color: #1f2335;
  border-radius: 10px;
  padding: 10px 15px;
  max-width: 70%;
}

.message-content.user {
  background-color: #7aa2f7;
  color: #1a1b26;
}

.input-area {
  padding: 20px;
  background-color: #1f2335;
  border-top: 1px solid #2f3549;
}

.message-input {
  width: calc(100% - 50px);
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #24283b;
  color: #a9b1d6;
  border-color: #2f3549;
}

.send-button {
  width: 40px;
  height: 40px;
  background-color: #7aa2f7;
  border: none;
  border-radius: 50%;
  color: #1a1b26;
  cursor: pointer;
  margin-left: 10px;
}

.input-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 0.9em;
  color: #565f89;
}

.reset-button {
  background: none;
  border: none;
  color: #7aa2f7;
  cursor: pointer;
}

.image-display {
  width: 370px;
  background-color: #1f2335;
  border-left: 1px solid #2f3549;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
}

.image-avatar {
  width: 100%;
  height: 600px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  font-size: 24px;
  border-radius: 10px;
}

.image-name {
  font-size: 24px;
  font-weight: bold;
  padding: 15px;
  color: #ffffff;
}

.image-para {
  margin-top: -20px;
  font-size: 14px;
  line-height: 1.5;
  padding: 0 15px 15px;
  color: #a9b1d6;
}

.no-messages,
.no-chat-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #565f89;
}

.typing-indicator {
  display: flex;
}

.typing-indicator span {
  height: 10px;
  width: 10px;
  float: left;
  margin: 0 1px;
  background-color: #565f89;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
  animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
  animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
  animation: 1s blink infinite 0.9999s;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background: #171717;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.modal-header h2 {
  margin: 0;
  font-size: 18px;
}
.modal-close {
  background: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
.modal-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #444;
  border-radius: 5px;
  background: #1e1e1e;
  color: white;
  resize: none;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.btn {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.btn.cancel {
  background: #444;
  color: white;
}
.btn.submit {
  background: #4caf50;
  color: white;
}
.modal-trigger {
  padding: 10px 15px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .chat-container {
    height: 88vh;
  }

  .chat-wrapper {
    flex-direction: column;
  }

  .sidebar-toggle {
    display: block;
  }

  .sidebar {
    position: absolute;
    left: -100%;
    top: 0;
    bottom: 0;
    width: 80%;
    max-width: 300px;
    transition: left 0.3s ease;
    z-index: 1000;
    background-color: #1a1a1a;
  }

  .image-toggle {
    display: none;
  }

  .sidebar.expanded {
    display: block;
    height: auto;
    width: 300px;
    left: 0;
  }

  .image-toggle {
    display: none;
  }

  .sidebar-toggle {
    display: block;
  }

  .sidebar.expanded .search-container,
  .sidebar.expanded .conversations-list {
    display: block;
  }

  .image-display {
    display: none;
  }

  .main-chat {
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1f2335;
}

::-webkit-scrollbar-thumb {
  background: #292e42;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3b4261;
}
