#saasbox_page_content {
    @charset "UTF-8";
  }
  :root {
    --blue: #50a1ff;
    --indigo: #6610f2;
    --purple: #926dde;
    --pink: #e83e8c;
    --red: #ff4954;
    --orange: #ffbe00;
    --yellow: #ffba00;
    --green: #3cd458;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #868e96;
    --gray-dark: #343a40;
    --primary: #50a1ff;
    --secondary: #e9ecf0;
    --success: #3cd458;
    --info: #926dde;
    --warning: #ffba00;
    --danger: #ff4954;
    --light: #f8f9fa;
    --dark: #191919;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  #saasbox_page_content *,
  #saasbox_page_content *::before,
  #saasbox_page_content *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  #saasbox_page_content article,
  #saasbox_page_content aside,
  #saasbox_page_content figcaption,
  #saasbox_page_content figure,
  #saasbox_page_content footer,
  #saasbox_page_content header,
  #saasbox_page_content hgroup,
  #saasbox_page_content main,
  #saasbox_page_content nav,
  #saasbox_page_content section {
    display: block;
  }
  body {
    margin: 0;
    font-family: "Open Sans", "Microsoft YaHei", "Hiragino Sans GB", "PingFang SC", "Helvetica", "Helvetica Neue", "BlinkMacSystemFont", sans-serif;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.9;
    color: #757575;
    text-align: left;
    background-color: #fff;
  }
  #saasbox_page_content [tabindex="-1"]:focus {
    outline: 0 !important;
  }
  #saasbox_page_content hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  #saasbox_page_content h1,
  #saasbox_page_content h2,
  #saasbox_page_content h3,
  #saasbox_page_content h4,
  #saasbox_page_content .woocommerce h2,
  #saasbox_page_content h5,
  #saasbox_page_content h6 {
    margin-top: 0;
    margin-bottom: .5rem;
  }
  #saasbox_page_content p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  #saasbox_page_content abbr[title],
  #saasbox_page_content abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
  }
  #saasbox_page_content address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  #saasbox_page_content ol,
  #saasbox_page_content ul,
  #saasbox_page_content dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  #saasbox_page_content ol ol,
  #saasbox_page_content ul ul,
  #saasbox_page_content ol ul,
  #saasbox_page_content ul ol {
    margin-bottom: 0;
  }
  #saasbox_page_content dt {
    font-weight: 600;
  }
  #saasbox_page_content dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }
  #saasbox_page_content blockquote {
    margin: 0 0 1rem;
  }
  #saasbox_page_content b,
  #saasbox_page_content strong {
    font-weight: bolder;
  }
  #saasbox_page_content small {
    font-size: 80%;
  }
  #saasbox_page_content sub,
  #saasbox_page_content sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  #saasbox_page_content sub {
    bottom: -0.25em;
  }
  #saasbox_page_content sup {
    top: -0.5em;
  }
  #saasbox_page_content a {
    color: #50a1ff;
    text-decoration: none;
    background-color: transparent;
  }
  #saasbox_page_content a:hover {
    color: #0478ff;
    text-decoration: underline;
  }
  #saasbox_page_content a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  #saasbox_page_content a:not([href]):not([tabindex]):hover,
  #saasbox_page_content a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }
  #saasbox_page_content a:not([href]):not([tabindex]):focus {
    outline: 0;
  }
  #saasbox_page_content pre,
  #saasbox_page_content code,
  #saasbox_page_content kbd,
  #saasbox_page_content samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
  }
  #saasbox_page_content pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
  }
  #saasbox_page_content figure {
    margin: 0 0 1rem;
  }
  #saasbox_page_content img {
    vertical-align: middle;
    border-style: none;
  }
  #saasbox_page_content svg {
    overflow: hidden;
    vertical-align: middle;
  }
  #saasbox_page_content table {
    border-collapse: collapse;
  }
  #saasbox_page_content caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #868e96;
    text-align: left;
    caption-side: bottom;
  }
  #saasbox_page_content th {
    text-align: inherit;
  }
  #saasbox_page_content label {
    display: inline-block;
    margin-bottom: .5rem;
  }
  #saasbox_page_content button {
    border-radius: 0;
  }
  #saasbox_page_content button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  #saasbox_page_content input,
  #saasbox_page_content button,
  #saasbox_page_content select,
  #saasbox_page_content optgroup,
  #saasbox_page_content textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  #saasbox_page_content button,
  #saasbox_page_content input {
    overflow: visible;
  }
  #saasbox_page_content button,
  #saasbox_page_content select {
    text-transform: none;
  }
  #saasbox_page_content select {
    word-wrap: normal;
  }
  #saasbox_page_content button,
  #saasbox_page_content [type="button"],
  #saasbox_page_content [type="reset"],
  #saasbox_page_content [type="submit"] {
    -webkit-appearance: button;
  }
  #saasbox_page_content button:not(:disabled),
  #saasbox_page_content [type="button"]:not(:disabled),
  #saasbox_page_content [type="reset"]:not(:disabled),
  #saasbox_page_content [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  #saasbox_page_content button::-moz-focus-inner,
  #saasbox_page_content [type="button"]::-moz-focus-inner,
  #saasbox_page_content [type="reset"]::-moz-focus-inner,
  #saasbox_page_content [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  #saasbox_page_content input[type="radio"],
  #saasbox_page_content input[type="checkbox"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }
  #saasbox_page_content input[type="date"],
  #saasbox_page_content input[type="time"],
  #saasbox_page_content input[type="datetime-local"],
  #saasbox_page_content input[type="month"] {
    -webkit-appearance: listbox;
  }
  #saasbox_page_content textarea {
    overflow: auto;
    resize: vertical;
  }
  #saasbox_page_content fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  #saasbox_page_content legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  #saasbox_page_content progress {
    vertical-align: baseline;
  }
  #saasbox_page_content [type="number"]::-webkit-inner-spin-button,
  #saasbox_page_content [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  #saasbox_page_content [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  #saasbox_page_content [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  #saasbox_page_content ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  #saasbox_page_content output {
    display: inline-block;
  }
  #saasbox_page_content summary {
    display: list-item;
    cursor: pointer;
  }
  #saasbox_page_content template {
    display: none;
  }
  #saasbox_page_content [hidden] {
    display: none !important;
  }
  #saasbox_page_content h1,
  #saasbox_page_content h2,
  #saasbox_page_content h3,
  #saasbox_page_content h4,
  #saasbox_page_content .woocommerce h2,
  #saasbox_page_content h5,
  #saasbox_page_content h6,
  #saasbox_page_content .h1,
  #saasbox_page_content .h2,
  #saasbox_page_content .h3,
  #saasbox_page_content .h4,
  #saasbox_page_content .h5,
  #saasbox_page_content .h6 {
    margin-bottom: .5rem;
    font-family: Dosis, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #323d47;
  }
  #saasbox_page_content h1,
  #saasbox_page_content .h1 {
    font-size: 2.57813rem;
  }
  #saasbox_page_content h2,
  #saasbox_page_content .h2 {
    font-size: 2.10938rem;
  }
  #saasbox_page_content h3,
  #saasbox_page_content .h3 {
    font-size: 1.75781rem;
  }
  #saasbox_page_content h4,
  #saasbox_page_content .woocommerce h2,
  #saasbox_page_content .h4 {
    font-size: 1.52344rem;
  }
  #saasbox_page_content h5,
  #saasbox_page_content .h5 {
    font-size: 1.23047rem;
  }
  #saasbox_page_content h6,
  #saasbox_page_content .h6 {
    font-size: 1.05469rem;
  }
  #saasbox_page_content .lead,
  #saasbox_page_content .pricing-4 .plan-price p {
    font-size: 1.17188rem;
    font-weight: 300;
  }
  #saasbox_page_content .display-1 {
    font-size: 5rem;
    font-weight: 200;
    line-height: 1.5;
  }
  #saasbox_page_content .display-2 {
    font-size: 4rem;
    font-weight: 200;
    line-height: 1.5;
  }
  #saasbox_page_content .display-3 {
    font-size: 3.5rem;
    font-weight: 200;
    line-height: 1.5;
  }
  #saasbox_page_content .display-4 {
    font-size: 3rem;
    font-weight: 200;
    line-height: 1.5;
  }
  #saasbox_page_content hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  #saasbox_page_content small,
  #saasbox_page_content .small {
    font-size: 85%;
    font-weight: 400;
  }
  #saasbox_page_content mark,
  #saasbox_page_content .mark {
    padding: .2em;
    background-color: #fcf8e3;
  }
  #saasbox_page_content .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  #saasbox_page_content .list-inline {
    padding-left: 0;
    list-style: none;
  }
  #saasbox_page_content .list-inline-item {
    display: inline-block;
  }
  #saasbox_page_content .list-inline-item:not(:last-child) {
    margin-right: .5rem;
  }
  #saasbox_page_content .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  #saasbox_page_content .blockquote {
    margin-bottom: 1rem;
    font-size: 1.17188rem;
  }
  #saasbox_page_content .blockquote-footer {
    display: block;
    font-size: 85%;
    color: #868e96;
  }
  #saasbox_page_content .blockquote-footer::before {
    content: "\2014\00A0";
  }
  #saasbox_page_content .img-fluid {
    max-width: 100%;
    height: auto;
  }
  #saasbox_page_content .img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
  }
  #saasbox_page_content .figure {
    display: inline-block;
  }
  #saasbox_page_content .figure-img {
    margin-bottom: .5rem;
    line-height: 1;
  }
  #saasbox_page_content .figure-caption {
    font-size: 90%;
    color: #868e96;
  }
  #saasbox_page_content code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
  }
  #saasbox_page_content a>code {
    color: inherit;
  }
  #saasbox_page_content kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: .125rem;
  }
  #saasbox_page_content kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600;
  }
  #saasbox_page_content pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
  }
  #saasbox_page_content pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  #saasbox_page_content .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  #saasbox_page_content .container,
  #saasbox_page_content .woocommerce-page .woocommerce {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .container,
    #saasbox_page_content .woocommerce-page .woocommerce {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .container,
    #saasbox_page_content .woocommerce-page .woocommerce {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .container,
    #saasbox_page_content .woocommerce-page .woocommerce {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .container,
    #saasbox_page_content .woocommerce-page .woocommerce {
      max-width: 1140px;
    }
  }
  #saasbox_page_content .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  #saasbox_page_content .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  #saasbox_page_content .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  #saasbox_page_content .no-gutters>.col,
  #saasbox_page_content .no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  #saasbox_page_content .col-1,
  #saasbox_page_content .col-2,
  #saasbox_page_content .col-3,
  #saasbox_page_content .col-4,
  #saasbox_page_content .col-5,
  #saasbox_page_content .col-6,
  #saasbox_page_content .col-7,
  #saasbox_page_content .col-8,
  #saasbox_page_content .col-9,
  #saasbox_page_content .col-10,
  #saasbox_page_content .col-11,
  #saasbox_page_content .col-12,
  #saasbox_page_content .col,
  #saasbox_page_content .col-auto,
  #saasbox_page_content .col-sm-1,
  #saasbox_page_content .col-sm-2,
  #saasbox_page_content .col-sm-3,
  #saasbox_page_content .col-sm-4,
  #saasbox_page_content .col-sm-5,
  #saasbox_page_content .col-sm-6,
  #saasbox_page_content .col-sm-7,
  #saasbox_page_content .col-sm-8,
  #saasbox_page_content .col-sm-9,
  #saasbox_page_content .col-sm-10,
  #saasbox_page_content .col-sm-11,
  #saasbox_page_content .col-sm-12,
  #saasbox_page_content .col-sm,
  #saasbox_page_content .col-sm-auto,
  #saasbox_page_content .col-md-1,
  #saasbox_page_content .col-md-2,
  #saasbox_page_content .col-md-3,
  #saasbox_page_content .col-md-4,
  #saasbox_page_content .col-md-5,
  #saasbox_page_content .col-md-6,
  #saasbox_page_content .col-md-7,
  #saasbox_page_content .col-md-8,
  #saasbox_page_content .col-md-9,
  #saasbox_page_content .col-md-10,
  #saasbox_page_content .col-md-11,
  #saasbox_page_content .col-md-12,
  #saasbox_page_content .col-md,
  #saasbox_page_content .col-md-auto,
  #saasbox_page_content .col-lg-1,
  #saasbox_page_content .col-lg-2,
  #saasbox_page_content .col-lg-3,
  #saasbox_page_content .col-lg-4,
  #saasbox_page_content .col-lg-5,
  #saasbox_page_content .col-lg-6,
  #saasbox_page_content .col-lg-7,
  #saasbox_page_content .col-lg-8,
  #saasbox_page_content .col-lg-9,
  #saasbox_page_content .col-lg-10,
  #saasbox_page_content .col-lg-11,
  #saasbox_page_content .col-lg-12,
  #saasbox_page_content .col-lg,
  #saasbox_page_content .col-lg-auto,
  #saasbox_page_content .col-xl-1,
  #saasbox_page_content .col-xl-2,
  #saasbox_page_content .col-xl-3,
  #saasbox_page_content .col-xl-4,
  #saasbox_page_content .col-xl-5,
  #saasbox_page_content .col-xl-6,
  #saasbox_page_content .col-xl-7,
  #saasbox_page_content .col-xl-8,
  #saasbox_page_content .col-xl-9,
  #saasbox_page_content .col-xl-10,
  #saasbox_page_content .col-xl-11,
  #saasbox_page_content .col-xl-12,
  #saasbox_page_content .col-xl,
  #saasbox_page_content .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  #saasbox_page_content .col {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  #saasbox_page_content .col-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  #saasbox_page_content .col-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  #saasbox_page_content .col-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  #saasbox_page_content .col-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  #saasbox_page_content .col-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  #saasbox_page_content .col-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  #saasbox_page_content .col-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  #saasbox_page_content .col-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  #saasbox_page_content .col-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  #saasbox_page_content .col-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }
  #saasbox_page_content .col-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  #saasbox_page_content .col-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  #saasbox_page_content .col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  #saasbox_page_content .order-first {
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  #saasbox_page_content .order-last {
    -webkit-box-ordinal-group: 14;
    order: 13;
  }
  #saasbox_page_content .order-0 {
    -webkit-box-ordinal-group: 1;
    order: 0;
  }
  #saasbox_page_content .order-1 {
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
  #saasbox_page_content .order-2 {
    -webkit-box-ordinal-group: 3;
    order: 2;
  }
  #saasbox_page_content .order-3 {
    -webkit-box-ordinal-group: 4;
    order: 3;
  }
  #saasbox_page_content .order-4 {
    -webkit-box-ordinal-group: 5;
    order: 4;
  }
  #saasbox_page_content .order-5 {
    -webkit-box-ordinal-group: 6;
    order: 5;
  }
  #saasbox_page_content .order-6 {
    -webkit-box-ordinal-group: 7;
    order: 6;
  }
  #saasbox_page_content .order-7 {
    -webkit-box-ordinal-group: 8;
    order: 7;
  }
  #saasbox_page_content .order-8 {
    -webkit-box-ordinal-group: 9;
    order: 8;
  }
  #saasbox_page_content .order-9 {
    -webkit-box-ordinal-group: 10;
    order: 9;
  }
  #saasbox_page_content .order-10 {
    -webkit-box-ordinal-group: 11;
    order: 10;
  }
  #saasbox_page_content .order-11 {
    -webkit-box-ordinal-group: 12;
    order: 11;
  }
  #saasbox_page_content .order-12 {
    -webkit-box-ordinal-group: 13;
    order: 12;
  }
  #saasbox_page_content .offset-1 {
    margin-left: 8.33333%;
  }
  #saasbox_page_content .offset-2 {
    margin-left: 16.66667%;
  }
  #saasbox_page_content .offset-3 {
    margin-left: 25%;
  }
  #saasbox_page_content .offset-4 {
    margin-left: 33.33333%;
  }
  #saasbox_page_content .offset-5 {
    margin-left: 41.66667%;
  }
  #saasbox_page_content .offset-6 {
    margin-left: 50%;
  }
  #saasbox_page_content .offset-7 {
    margin-left: 58.33333%;
  }
  #saasbox_page_content .offset-8 {
    margin-left: 66.66667%;
  }
  #saasbox_page_content .offset-9 {
    margin-left: 75%;
  }
  #saasbox_page_content .offset-10 {
    margin-left: 83.33333%;
  }
  #saasbox_page_content .offset-11 {
    margin-left: 91.66667%;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .col-sm {
      flex-basis: 0;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    #saasbox_page_content .col-sm-auto {
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    #saasbox_page_content .col-sm-1 {
      -webkit-box-flex: 0;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    #saasbox_page_content .col-sm-2 {
      -webkit-box-flex: 0;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    #saasbox_page_content .col-sm-3 {
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }
    #saasbox_page_content .col-sm-4 {
      -webkit-box-flex: 0;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    #saasbox_page_content .col-sm-5 {
      -webkit-box-flex: 0;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    #saasbox_page_content .col-sm-6 {
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }
    #saasbox_page_content .col-sm-7 {
      -webkit-box-flex: 0;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    #saasbox_page_content .col-sm-8 {
      -webkit-box-flex: 0;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    #saasbox_page_content .col-sm-9 {
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%;
    }
    #saasbox_page_content .col-sm-10 {
      -webkit-box-flex: 0;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    #saasbox_page_content .col-sm-11 {
      -webkit-box-flex: 0;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    #saasbox_page_content .col-sm-12 {
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    #saasbox_page_content .order-sm-first {
      -webkit-box-ordinal-group: 0;
      order: -1;
    }
    #saasbox_page_content .order-sm-last {
      -webkit-box-ordinal-group: 14;
      order: 13;
    }
    #saasbox_page_content .order-sm-0 {
      -webkit-box-ordinal-group: 1;
      order: 0;
    }
    #saasbox_page_content .order-sm-1 {
      -webkit-box-ordinal-group: 2;
      order: 1;
    }
    #saasbox_page_content .order-sm-2 {
      -webkit-box-ordinal-group: 3;
      order: 2;
    }
    #saasbox_page_content .order-sm-3 {
      -webkit-box-ordinal-group: 4;
      order: 3;
    }
    #saasbox_page_content .order-sm-4 {
      -webkit-box-ordinal-group: 5;
      order: 4;
    }
    #saasbox_page_content .order-sm-5 {
      -webkit-box-ordinal-group: 6;
      order: 5;
    }
    #saasbox_page_content .order-sm-6 {
      -webkit-box-ordinal-group: 7;
      order: 6;
    }
    #saasbox_page_content .order-sm-7 {
      -webkit-box-ordinal-group: 8;
      order: 7;
    }
    #saasbox_page_content .order-sm-8 {
      -webkit-box-ordinal-group: 9;
      order: 8;
    }
    #saasbox_page_content .order-sm-9 {
      -webkit-box-ordinal-group: 10;
      order: 9;
    }
    #saasbox_page_content .order-sm-10 {
      -webkit-box-ordinal-group: 11;
      order: 10;
    }
    #saasbox_page_content .order-sm-11 {
      -webkit-box-ordinal-group: 12;
      order: 11;
    }
    #saasbox_page_content .order-sm-12 {
      -webkit-box-ordinal-group: 13;
      order: 12;
    }
    #saasbox_page_content .offset-sm-0 {
      margin-left: 0;
    }
    #saasbox_page_content .offset-sm-1 {
      margin-left: 8.33333%;
    }
    #saasbox_page_content .offset-sm-2 {
      margin-left: 16.66667%;
    }
    #saasbox_page_content .offset-sm-3 {
      margin-left: 25%;
    }
    #saasbox_page_content .offset-sm-4 {
      margin-left: 33.33333%;
    }
    #saasbox_page_content .offset-sm-5 {
      margin-left: 41.66667%;
    }
    #saasbox_page_content .offset-sm-6 {
      margin-left: 50%;
    }
    #saasbox_page_content .offset-sm-7 {
      margin-left: 58.33333%;
    }
    #saasbox_page_content .offset-sm-8 {
      margin-left: 66.66667%;
    }
    #saasbox_page_content .offset-sm-9 {
      margin-left: 75%;
    }
    #saasbox_page_content .offset-sm-10 {
      margin-left: 83.33333%;
    }
    #saasbox_page_content .offset-sm-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .col-md {
      flex-basis: 0;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    #saasbox_page_content .col-md-auto {
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    #saasbox_page_content .col-md-1 {
      -webkit-box-flex: 0;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    #saasbox_page_content .col-md-2 {
      -webkit-box-flex: 0;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    #saasbox_page_content .col-md-3 {
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }
    #saasbox_page_content .col-md-4 {
      -webkit-box-flex: 0;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    #saasbox_page_content .col-md-5 {
      -webkit-box-flex: 0;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    #saasbox_page_content .col-md-6 {
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }
    #saasbox_page_content .col-md-7 {
      -webkit-box-flex: 0;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    #saasbox_page_content .col-md-8 {
      -webkit-box-flex: 0;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    #saasbox_page_content .col-md-9 {
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%;
    }
    #saasbox_page_content .col-md-10 {
      -webkit-box-flex: 0;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    #saasbox_page_content .col-md-11 {
      -webkit-box-flex: 0;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    #saasbox_page_content .col-md-12 {
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    #saasbox_page_content .order-md-first {
      -webkit-box-ordinal-group: 0;
      order: -1;
    }
    #saasbox_page_content .order-md-last {
      -webkit-box-ordinal-group: 14;
      order: 13;
    }
    #saasbox_page_content .order-md-0 {
      -webkit-box-ordinal-group: 1;
      order: 0;
    }
    #saasbox_page_content .order-md-1 {
      -webkit-box-ordinal-group: 2;
      order: 1;
    }
    #saasbox_page_content .order-md-2 {
      -webkit-box-ordinal-group: 3;
      order: 2;
    }
    #saasbox_page_content .order-md-3 {
      -webkit-box-ordinal-group: 4;
      order: 3;
    }
    #saasbox_page_content .order-md-4 {
      -webkit-box-ordinal-group: 5;
      order: 4;
    }
    #saasbox_page_content .order-md-5 {
      -webkit-box-ordinal-group: 6;
      order: 5;
    }
    #saasbox_page_content .order-md-6 {
      -webkit-box-ordinal-group: 7;
      order: 6;
    }
    #saasbox_page_content .order-md-7 {
      -webkit-box-ordinal-group: 8;
      order: 7;
    }
    #saasbox_page_content .order-md-8 {
      -webkit-box-ordinal-group: 9;
      order: 8;
    }
    #saasbox_page_content .order-md-9 {
      -webkit-box-ordinal-group: 10;
      order: 9;
    }
    #saasbox_page_content .order-md-10 {
      -webkit-box-ordinal-group: 11;
      order: 10;
    }
    #saasbox_page_content .order-md-11 {
      -webkit-box-ordinal-group: 12;
      order: 11;
    }
    #saasbox_page_content .order-md-12 {
      -webkit-box-ordinal-group: 13;
      order: 12;
    }
    #saasbox_page_content .offset-md-0 {
      margin-left: 0;
    }
    #saasbox_page_content .offset-md-1 {
      margin-left: 8.33333%;
    }
    #saasbox_page_content .offset-md-2 {
      margin-left: 16.66667%;
    }
    #saasbox_page_content .offset-md-3 {
      margin-left: 25%;
    }
    #saasbox_page_content .offset-md-4 {
      margin-left: 33.33333%;
    }
    #saasbox_page_content .offset-md-5 {
      margin-left: 41.66667%;
    }
    #saasbox_page_content .offset-md-6 {
      margin-left: 50%;
    }
    #saasbox_page_content .offset-md-7 {
      margin-left: 58.33333%;
    }
    #saasbox_page_content .offset-md-8 {
      margin-left: 66.66667%;
    }
    #saasbox_page_content .offset-md-9 {
      margin-left: 75%;
    }
    #saasbox_page_content .offset-md-10 {
      margin-left: 83.33333%;
    }
    #saasbox_page_content .offset-md-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .col-lg {
      flex-basis: 0;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    #saasbox_page_content .col-lg-auto {
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    #saasbox_page_content .col-lg-1 {
      -webkit-box-flex: 0;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    #saasbox_page_content .col-lg-2 {
      -webkit-box-flex: 0;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    #saasbox_page_content .col-lg-3 {
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }
    #saasbox_page_content .col-lg-4 {
      -webkit-box-flex: 0;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    #saasbox_page_content .col-lg-5 {
      -webkit-box-flex: 0;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    #saasbox_page_content .col-lg-6 {
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }
    #saasbox_page_content .col-lg-7 {
      -webkit-box-flex: 0;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    #saasbox_page_content .col-lg-8 {
      -webkit-box-flex: 0;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    #saasbox_page_content .col-lg-9 {
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%;
    }
    #saasbox_page_content .col-lg-10 {
      -webkit-box-flex: 0;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    #saasbox_page_content .col-lg-11 {
      -webkit-box-flex: 0;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    #saasbox_page_content .col-lg-12 {
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    #saasbox_page_content .order-lg-first {
      -webkit-box-ordinal-group: 0;
      order: -1;
    }
    #saasbox_page_content .order-lg-last {
      -webkit-box-ordinal-group: 14;
      order: 13;
    }
    #saasbox_page_content .order-lg-0 {
      -webkit-box-ordinal-group: 1;
      order: 0;
    }
    #saasbox_page_content .order-lg-1 {
      -webkit-box-ordinal-group: 2;
      order: 1;
    }
    #saasbox_page_content .order-lg-2 {
      -webkit-box-ordinal-group: 3;
      order: 2;
    }
    #saasbox_page_content .order-lg-3 {
      -webkit-box-ordinal-group: 4;
      order: 3;
    }
    #saasbox_page_content .order-lg-4 {
      -webkit-box-ordinal-group: 5;
      order: 4;
    }
    #saasbox_page_content .order-lg-5 {
      -webkit-box-ordinal-group: 6;
      order: 5;
    }
    #saasbox_page_content .order-lg-6 {
      -webkit-box-ordinal-group: 7;
      order: 6;
    }
    #saasbox_page_content .order-lg-7 {
      -webkit-box-ordinal-group: 8;
      order: 7;
    }
    #saasbox_page_content .order-lg-8 {
      -webkit-box-ordinal-group: 9;
      order: 8;
    }
    #saasbox_page_content .order-lg-9 {
      -webkit-box-ordinal-group: 10;
      order: 9;
    }
    #saasbox_page_content .order-lg-10 {
      -webkit-box-ordinal-group: 11;
      order: 10;
    }
    #saasbox_page_content .order-lg-11 {
      -webkit-box-ordinal-group: 12;
      order: 11;
    }
    #saasbox_page_content .order-lg-12 {
      -webkit-box-ordinal-group: 13;
      order: 12;
    }
    #saasbox_page_content .offset-lg-0 {
      margin-left: 0;
    }
    #saasbox_page_content .offset-lg-1 {
      margin-left: 8.33333%;
    }
    #saasbox_page_content .offset-lg-2 {
      margin-left: 16.66667%;
    }
    #saasbox_page_content .offset-lg-3 {
      margin-left: 25%;
    }
    #saasbox_page_content .offset-lg-4 {
      margin-left: 33.33333%;
    }
    #saasbox_page_content .offset-lg-5 {
      margin-left: 41.66667%;
    }
    #saasbox_page_content .offset-lg-6 {
      margin-left: 50%;
    }
    #saasbox_page_content .offset-lg-7 {
      margin-left: 58.33333%;
    }
    #saasbox_page_content .offset-lg-8 {
      margin-left: 66.66667%;
    }
    #saasbox_page_content .offset-lg-9 {
      margin-left: 75%;
    }
    #saasbox_page_content .offset-lg-10 {
      margin-left: 83.33333%;
    }
    #saasbox_page_content .offset-lg-11 {
      margin-left: 91.66667%;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .col-xl {
      flex-basis: 0;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    #saasbox_page_content .col-xl-auto {
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
    }
    #saasbox_page_content .col-xl-1 {
      -webkit-box-flex: 0;
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
    #saasbox_page_content .col-xl-2 {
      -webkit-box-flex: 0;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
    #saasbox_page_content .col-xl-3 {
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }
    #saasbox_page_content .col-xl-4 {
      -webkit-box-flex: 0;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    #saasbox_page_content .col-xl-5 {
      -webkit-box-flex: 0;
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
    #saasbox_page_content .col-xl-6 {
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }
    #saasbox_page_content .col-xl-7 {
      -webkit-box-flex: 0;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
    #saasbox_page_content .col-xl-8 {
      -webkit-box-flex: 0;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
    #saasbox_page_content .col-xl-9 {
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%;
    }
    #saasbox_page_content .col-xl-10 {
      -webkit-box-flex: 0;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }
    #saasbox_page_content .col-xl-11 {
      -webkit-box-flex: 0;
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }
    #saasbox_page_content .col-xl-12 {
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    #saasbox_page_content .order-xl-first {
      -webkit-box-ordinal-group: 0;
      order: -1;
    }
    #saasbox_page_content .order-xl-last {
      -webkit-box-ordinal-group: 14;
      order: 13;
    }
    #saasbox_page_content .order-xl-0 {
      -webkit-box-ordinal-group: 1;
      order: 0;
    }
    #saasbox_page_content .order-xl-1 {
      -webkit-box-ordinal-group: 2;
      order: 1;
    }
    #saasbox_page_content .order-xl-2 {
      -webkit-box-ordinal-group: 3;
      order: 2;
    }
    #saasbox_page_content .order-xl-3 {
      -webkit-box-ordinal-group: 4;
      order: 3;
    }
    #saasbox_page_content .order-xl-4 {
      -webkit-box-ordinal-group: 5;
      order: 4;
    }
    #saasbox_page_content .order-xl-5 {
      -webkit-box-ordinal-group: 6;
      order: 5;
    }
    #saasbox_page_content .order-xl-6 {
      -webkit-box-ordinal-group: 7;
      order: 6;
    }
    #saasbox_page_content .order-xl-7 {
      -webkit-box-ordinal-group: 8;
      order: 7;
    }
    #saasbox_page_content .order-xl-8 {
      -webkit-box-ordinal-group: 9;
      order: 8;
    }
    #saasbox_page_content .order-xl-9 {
      -webkit-box-ordinal-group: 10;
      order: 9;
    }
    #saasbox_page_content .order-xl-10 {
      -webkit-box-ordinal-group: 11;
      order: 10;
    }
    #saasbox_page_content .order-xl-11 {
      -webkit-box-ordinal-group: 12;
      order: 11;
    }
    #saasbox_page_content .order-xl-12 {
      -webkit-box-ordinal-group: 13;
      order: 12;
    }
    #saasbox_page_content .offset-xl-0 {
      margin-left: 0;
    }
    #saasbox_page_content .offset-xl-1 {
      margin-left: 8.33333%;
    }
    #saasbox_page_content .offset-xl-2 {
      margin-left: 16.66667%;
    }
    #saasbox_page_content .offset-xl-3 {
      margin-left: 25%;
    }
    #saasbox_page_content .offset-xl-4 {
      margin-left: 33.33333%;
    }
    #saasbox_page_content .offset-xl-5 {
      margin-left: 41.66667%;
    }
    #saasbox_page_content .offset-xl-6 {
      margin-left: 50%;
    }
    #saasbox_page_content .offset-xl-7 {
      margin-left: 58.33333%;
    }
    #saasbox_page_content .offset-xl-8 {
      margin-left: 66.66667%;
    }
    #saasbox_page_content .offset-xl-9 {
      margin-left: 75%;
    }
    #saasbox_page_content .offset-xl-10 {
      margin-left: 83.33333%;
    }
    #saasbox_page_content .offset-xl-11 {
      margin-left: 91.66667%;
    }
  }
  #saasbox_page_content .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #757575;
  }
  #saasbox_page_content .table th,
  #saasbox_page_content .table td {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #f1f2f3;
  }
  #saasbox_page_content .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f1f2f3;
  }
  #saasbox_page_content .table tbody+tbody {
    border-top: 2px solid #f1f2f3;
  }
  #saasbox_page_content .table-sm th,
  #saasbox_page_content .table-sm td {
    padding: .3rem;
  }
  #saasbox_page_content .table-bordered {
    border: 1px solid #f1f2f3;
  }
  #saasbox_page_content .table-bordered th,
  #saasbox_page_content .table-bordered td {
    border: 1px solid #f1f2f3;
  }
  #saasbox_page_content .table-bordered thead th,
  #saasbox_page_content .table-bordered thead td {
    border-bottom-width: 2px;
  }
  #saasbox_page_content .table-borderless th,
  #saasbox_page_content .table-borderless td,
  #saasbox_page_content .table-borderless thead th,
  #saasbox_page_content .table-borderless tbody+tbody {
    border: 0;
  }
  #saasbox_page_content .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfdfe;
  }
  #saasbox_page_content .table-hover tbody tr:hover {
    color: #757575;
    background-color: #f9fafb;
  }
  #saasbox_page_content .table-primary,
  #saasbox_page_content .table-primary>th,
  #saasbox_page_content .table-primary>td {
    background-color: #cee5ff;
  }
  #saasbox_page_content .table-primary th,
  #saasbox_page_content .table-primary td,
  #saasbox_page_content .table-primary thead th,
  #saasbox_page_content .table-primary tbody+tbody {
    border-color: #a4ceff;
  }
  #saasbox_page_content .table-hover .table-primary:hover {
    background-color: #b5d7ff;
  }
  #saasbox_page_content .table-hover .table-primary:hover>td,
  #saasbox_page_content .table-hover .table-primary:hover>th {
    background-color: #b5d7ff;
  }
  #saasbox_page_content .table-secondary,
  #saasbox_page_content .table-secondary>th,
  #saasbox_page_content .table-secondary>td {
    background-color: #f9fafb;
  }
  #saasbox_page_content .table-secondary th,
  #saasbox_page_content .table-secondary td,
  #saasbox_page_content .table-secondary thead th,
  #saasbox_page_content .table-secondary tbody+tbody {
    border-color: #f4f5f7;
  }
  #saasbox_page_content .table-hover .table-secondary:hover {
    background-color: #eaedf1;
  }
  #saasbox_page_content .table-hover .table-secondary:hover>td,
  #saasbox_page_content .table-hover .table-secondary:hover>th {
    background-color: #eaedf1;
  }
  #saasbox_page_content .table-success,
  #saasbox_page_content .table-success>th,
  #saasbox_page_content .table-success>td {
    background-color: #c8f3d0;
  }
  #saasbox_page_content .table-success th,
  #saasbox_page_content .table-success td,
  #saasbox_page_content .table-success thead th,
  #saasbox_page_content .table-success tbody+tbody {
    border-color: #9ae9a8;
  }
  #saasbox_page_content .table-hover .table-success:hover {
    background-color: #b3eebe;
  }
  #saasbox_page_content .table-hover .table-success:hover>td,
  #saasbox_page_content .table-hover .table-success:hover>th {
    background-color: #b3eebe;
  }
  #saasbox_page_content .table-info,
  #saasbox_page_content .table-info>th,
  #saasbox_page_content .table-info>td {
    background-color: #e0d6f6;
  }
  #saasbox_page_content .table-info th,
  #saasbox_page_content .table-info td,
  #saasbox_page_content .table-info thead th,
  #saasbox_page_content .table-info tbody+tbody {
    border-color: #c6b3ee;
  }
  #saasbox_page_content .table-hover .table-info:hover {
    background-color: #d0c1f1;
  }
  #saasbox_page_content .table-hover .table-info:hover>td,
  #saasbox_page_content .table-hover .table-info:hover>th {
    background-color: #d0c1f1;
  }
  #saasbox_page_content .table-warning,
  #saasbox_page_content .table-warning>th,
  #saasbox_page_content .table-warning>td {
    background-color: #ffecb8;
  }
  #saasbox_page_content .table-warning th,
  #saasbox_page_content .table-warning td,
  #saasbox_page_content .table-warning thead th,
  #saasbox_page_content .table-warning tbody+tbody {
    border-color: #ffdb7a;
  }
  #saasbox_page_content .table-hover .table-warning:hover {
    background-color: #ffe59f;
  }
  #saasbox_page_content .table-hover .table-warning:hover>td,
  #saasbox_page_content .table-hover .table-warning:hover>th {
    background-color: #ffe59f;
  }
  #saasbox_page_content .table-danger,
  #saasbox_page_content .table-danger>th,
  #saasbox_page_content .table-danger>td {
    background-color: #ffcccf;
  }
  #saasbox_page_content .table-danger th,
  #saasbox_page_content .table-danger td,
  #saasbox_page_content .table-danger thead th,
  #saasbox_page_content .table-danger tbody+tbody {
    border-color: #ffa0a6;
  }
  #saasbox_page_content .table-hover .table-danger:hover {
    background-color: #ffb3b7;
  }
  #saasbox_page_content .table-hover .table-danger:hover>td,
  #saasbox_page_content .table-hover .table-danger:hover>th {
    background-color: #ffb3b7;
  }
  #saasbox_page_content .table-light,
  #saasbox_page_content .table-light>th,
  #saasbox_page_content .table-light>td {
    background-color: #fdfdfe;
  }
  #saasbox_page_content .table-light th,
  #saasbox_page_content .table-light td,
  #saasbox_page_content .table-light thead th,
  #saasbox_page_content .table-light tbody+tbody {
    border-color: #fbfcfc;
  }
  #saasbox_page_content .table-hover .table-light:hover {
    background-color: #ececf6;
  }
  #saasbox_page_content .table-hover .table-light:hover>td,
  #saasbox_page_content .table-hover .table-light:hover>th {
    background-color: #ececf6;
  }
  #saasbox_page_content .table-dark,
  #saasbox_page_content .table-dark>th,
  #saasbox_page_content .table-dark>td {
    background-color: #bfbfbf;
  }
  #saasbox_page_content .table-dark th,
  #saasbox_page_content .table-dark td,
  #saasbox_page_content .table-dark thead th,
  #saasbox_page_content .table-dark tbody+tbody {
    border-color: #878787;
  }
  #saasbox_page_content .table-hover .table-dark:hover {
    background-color: #b2b2b2;
  }
  #saasbox_page_content .table-hover .table-dark:hover>td,
  #saasbox_page_content .table-hover .table-dark:hover>th {
    background-color: #b2b2b2;
  }
  #saasbox_page_content .table-active,
  #saasbox_page_content .table-active>th,
  #saasbox_page_content .table-active>td {
    background-color: #f5f6f7;
  }
  #saasbox_page_content .table-hover .table-active:hover {
    background-color: #e7e9ec;
  }
  #saasbox_page_content .table-hover .table-active:hover>td,
  #saasbox_page_content .table-hover .table-active:hover>th {
    background-color: #e7e9ec;
  }
  #saasbox_page_content .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
  }
  #saasbox_page_content .table .thead-light th {
    color: #323d47;
    background-color: #fafbfb;
    border-color: #f1f2f3;
  }
  #saasbox_page_content .table-dark {
    color: #fff;
    background-color: #343a40;
  }
  #saasbox_page_content .table-dark th,
  #saasbox_page_content .table-dark td,
  #saasbox_page_content .table-dark thead th {
    border-color: #454d55;
  }
  #saasbox_page_content .table-dark.table-bordered {
    border: 0;
  }
  #saasbox_page_content .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255,255,255,0.05);
  }
  #saasbox_page_content .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255,255,255,0.075);
  }
  @media (max-width: 575.98px) {
    #saasbox_page_content .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    #saasbox_page_content .table-responsive-sm>.table-bordered {
      border: 0;
    }
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    #saasbox_page_content .table-responsive-md>.table-bordered {
      border: 0;
    }
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    #saasbox_page_content .table-responsive-lg>.table-bordered {
      border: 0;
    }
  }
  @media (max-width: 1199.98px) {
    #saasbox_page_content .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    #saasbox_page_content .table-responsive-xl>.table-bordered {
      border: 0;
    }
  }
  #saasbox_page_content .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  #saasbox_page_content .table-responsive>.table-bordered {
    border: 0;
  }
  #saasbox_page_content .form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .wpcf7-text,
  #saasbox_page_content .wpcf7-date,
  #saasbox_page_content .wpcf7-quiz,
  #saasbox_page_content .wpcf7-number,
  #saasbox_page_content .wpcf7-select,
  #saasbox_page_content .wpcf7-textarea {
    display: block;
    width: 100%;
    height: calc(4.65em);
    padding: .375rem .75rem;
    font-size: .9375rem;
    font-weight: 300;
    line-height: 1.9;
    color: #999;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #eaeff4;
    border-radius: 2px;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .form-control,
    #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text,
    #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text,
    #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text,
    #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field,
    #saasbox_page_content .wpcf7-text,
    #saasbox_page_content .wpcf7-date,
    #saasbox_page_content .wpcf7-quiz,
    #saasbox_page_content .wpcf7-number,
    #saasbox_page_content .wpcf7-select,
    #saasbox_page_content .wpcf7-textarea {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .form-control::-ms-expand,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text::-ms-expand,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text::-ms-expand,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text::-ms-expand,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field::-ms-expand,
  #saasbox_page_content .wpcf7-text::-ms-expand,
  #saasbox_page_content .wpcf7-date::-ms-expand,
  #saasbox_page_content .wpcf7-quiz::-ms-expand,
  #saasbox_page_content .wpcf7-number::-ms-expand,
  #saasbox_page_content .wpcf7-select::-ms-expand,
  #saasbox_page_content .wpcf7-textarea::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  #saasbox_page_content .form-control:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field:focus,
  #saasbox_page_content .wpcf7-text:focus,
  #saasbox_page_content .wpcf7-date:focus,
  #saasbox_page_content .wpcf7-quiz:focus,
  #saasbox_page_content .wpcf7-number:focus,
  #saasbox_page_content .wpcf7-select:focus,
  #saasbox_page_content .wpcf7-textarea:focus {
    color: #323d47;
    background-color: #fff;
    border-color: #f1f2f3;
    outline: 0;
    -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.045);
    box-shadow: 0 0 40px rgba(0,0,0,0.045);
  }
  #saasbox_page_content .form-control::-webkit-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field::-webkit-input-placeholder,
  #saasbox_page_content .wpcf7-text::-webkit-input-placeholder,
  #saasbox_page_content .wpcf7-date::-webkit-input-placeholder,
  #saasbox_page_content .wpcf7-quiz::-webkit-input-placeholder,
  #saasbox_page_content .wpcf7-number::-webkit-input-placeholder,
  #saasbox_page_content .wpcf7-select::-webkit-input-placeholder,
  #saasbox_page_content .wpcf7-textarea::-webkit-input-placeholder {
    color: #c9ccce;
    opacity: 1;
  }
  #saasbox_page_content .form-control::-moz-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field::-moz-placeholder,
  #saasbox_page_content .wpcf7-text::-moz-placeholder,
  #saasbox_page_content .wpcf7-date::-moz-placeholder,
  #saasbox_page_content .wpcf7-quiz::-moz-placeholder,
  #saasbox_page_content .wpcf7-number::-moz-placeholder,
  #saasbox_page_content .wpcf7-select::-moz-placeholder,
  #saasbox_page_content .wpcf7-textarea::-moz-placeholder {
    color: #c9ccce;
    opacity: 1;
  }
  #saasbox_page_content .form-control:-ms-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field:-ms-input-placeholder,
  #saasbox_page_content .wpcf7-text:-ms-input-placeholder,
  #saasbox_page_content .wpcf7-date:-ms-input-placeholder,
  #saasbox_page_content .wpcf7-quiz:-ms-input-placeholder,
  #saasbox_page_content .wpcf7-number:-ms-input-placeholder,
  #saasbox_page_content .wpcf7-select:-ms-input-placeholder,
  #saasbox_page_content .wpcf7-textarea:-ms-input-placeholder {
    color: #c9ccce;
    opacity: 1;
  }
  #saasbox_page_content .form-control::-ms-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field::-ms-input-placeholder,
  #saasbox_page_content .wpcf7-text::-ms-input-placeholder,
  #saasbox_page_content .wpcf7-date::-ms-input-placeholder,
  #saasbox_page_content .wpcf7-quiz::-ms-input-placeholder,
  #saasbox_page_content .wpcf7-number::-ms-input-placeholder,
  #saasbox_page_content .wpcf7-select::-ms-input-placeholder,
  #saasbox_page_content .wpcf7-textarea::-ms-input-placeholder {
    color: #c9ccce;
    opacity: 1;
  }
  #saasbox_page_content .form-control::placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field::placeholder,
  #saasbox_page_content .wpcf7-text::placeholder,
  #saasbox_page_content .wpcf7-date::placeholder,
  #saasbox_page_content .wpcf7-quiz::placeholder,
  #saasbox_page_content .wpcf7-number::placeholder,
  #saasbox_page_content .wpcf7-select::placeholder,
  #saasbox_page_content .wpcf7-textarea::placeholder {
    color: #c9ccce;
    opacity: 1;
  }
  #saasbox_page_content .form-control:disabled,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text:disabled,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text:disabled,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text:disabled,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field:disabled,
  #saasbox_page_content .wpcf7-text:disabled,
  #saasbox_page_content .wpcf7-date:disabled,
  #saasbox_page_content .wpcf7-quiz:disabled,
  #saasbox_page_content .wpcf7-number:disabled,
  #saasbox_page_content .wpcf7-select:disabled,
  #saasbox_page_content .wpcf7-textarea:disabled,
  #saasbox_page_content .form-control[readonly],
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text[readonly],
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text[readonly],
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text[readonly],
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field[readonly],
  #saasbox_page_content .wpcf7-text[readonly],
  #saasbox_page_content .wpcf7-date[readonly],
  #saasbox_page_content .wpcf7-quiz[readonly],
  #saasbox_page_content .wpcf7-number[readonly],
  #saasbox_page_content .wpcf7-select[readonly],
  #saasbox_page_content .wpcf7-textarea[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  #saasbox_page_content select.form-control:focus::-ms-value,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon select.input-text:focus::-ms-value,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon select.input-text:focus::-ms-value,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon select.input-text:focus::-ms-value,
  #saasbox_page_content .select2-container--default .select2-search--dropdown select.select2-search__field:focus::-ms-value,
  #saasbox_page_content select.wpcf7-text:focus::-ms-value,
  #saasbox_page_content select.wpcf7-date:focus::-ms-value,
  #saasbox_page_content select.wpcf7-quiz:focus::-ms-value,
  #saasbox_page_content select.wpcf7-number:focus::-ms-value,
  #saasbox_page_content select.wpcf7-select:focus::-ms-value,
  #saasbox_page_content select.wpcf7-textarea:focus::-ms-value {
    color: #999;
    background-color: #fff;
  }
  #saasbox_page_content .form-control-file,
  #saasbox_page_content .form-control-range {
    display: block;
    width: 100%;
  }
  #saasbox_page_content .col-form-label {
    padding-top: calc(1.375rem);
    padding-bottom: calc(1.375rem);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.9;
  }
  #saasbox_page_content .col-form-label-lg {
    padding-top: calc(1.375rem);
    padding-bottom: calc(1.375rem);
    font-size: 1.0625rem;
    line-height: 2.2;
  }
  #saasbox_page_content .col-form-label-sm {
    padding-top: calc(1.375rem);
    padding-bottom: calc(1.375rem);
    font-size: .9375rem;
    line-height: 1.5;
  }
  #saasbox_page_content .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
    margin-bottom: 0;
    line-height: 1.9;
    color: #757575;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }
  #saasbox_page_content .form-control-plaintext.form-control-sm,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .form-control-plaintext.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .form-control-plaintext.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .form-control-plaintext.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .form-control-plaintext.select2-search__field,
  #saasbox_page_content .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
  #saasbox_page_content .form-control-sm,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field {
    height: calc(4.25em);
    padding: .375rem .5rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: 2px;
  }
  #saasbox_page_content .form-control-lg {
    height: calc(4.95em);
    padding: .375rem 1rem;
    font-size: 1.0625rem;
    line-height: 2.2;
    border-radius: 2px;
  }
  #saasbox_page_content select.form-control[size],
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon select.input-text[size],
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon select.input-text[size],
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon select.input-text[size],
  #saasbox_page_content .select2-container--default .select2-search--dropdown select.select2-search__field[size],
  #saasbox_page_content select.wpcf7-text[size],
  #saasbox_page_content select.wpcf7-date[size],
  #saasbox_page_content select.wpcf7-quiz[size],
  #saasbox_page_content select.wpcf7-number[size],
  #saasbox_page_content select.wpcf7-select[size],
  #saasbox_page_content select.wpcf7-textarea[size],
  #saasbox_page_content select.form-control[multiple],
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon select.input-text[multiple],
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon select.input-text[multiple],
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon select.input-text[multiple],
  #saasbox_page_content .select2-container--default .select2-search--dropdown select.select2-search__field[multiple],
  #saasbox_page_content select.wpcf7-text[multiple],
  #saasbox_page_content select.wpcf7-date[multiple],
  #saasbox_page_content select.wpcf7-quiz[multiple],
  #saasbox_page_content select.wpcf7-number[multiple],
  #saasbox_page_content select.wpcf7-select[multiple],
  #saasbox_page_content select.wpcf7-textarea[multiple] {
    height: auto;
  }
  #saasbox_page_content textarea.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon textarea.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon textarea.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon textarea.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown textarea.select2-search__field,
  #saasbox_page_content textarea.wpcf7-text,
  #saasbox_page_content textarea.wpcf7-date,
  #saasbox_page_content textarea.wpcf7-quiz,
  #saasbox_page_content textarea.wpcf7-number,
  #saasbox_page_content textarea.wpcf7-select,
  #saasbox_page_content textarea.wpcf7-textarea {
    height: auto;
  }
  #saasbox_page_content .form-group {
    margin-bottom: 1rem;
  }
  #saasbox_page_content .form-text {
    display: block;
    margin-top: .25rem;
  }
  #saasbox_page_content .form-row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  #saasbox_page_content .form-row>.col,
  #saasbox_page_content .form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  #saasbox_page_content .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  #saasbox_page_content .form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
  }
  #saasbox_page_content .form-check-input:disabled ~ .form-check-label {
    color: #868e96;
  }
  #saasbox_page_content .form-check-label {
    margin-bottom: 0;
  }
  #saasbox_page_content .form-check-inline {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
  }
  #saasbox_page_content .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
  }
  #saasbox_page_content .valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 85%;
    color: #3cd458;
  }
  #saasbox_page_content .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .9375rem;
    line-height: 1.9;
    color: #fff;
    background-color: rgba(60,212,88,0.9);
    border-radius: .25rem;
  }
  #saasbox_page_content .was-validated .form-control:valid,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon .input-text:valid,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated .input-text:valid,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon .input-text:valid,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated .input-text:valid,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon .input-text:valid,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated .input-text:valid,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown .select2-search__field:valid,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated .select2-search__field:valid,
  #saasbox_page_content .was-validated .wpcf7-text:valid,
  #saasbox_page_content .was-validated .wpcf7-date:valid,
  #saasbox_page_content .was-validated .wpcf7-quiz:valid,
  #saasbox_page_content .was-validated .wpcf7-number:valid,
  #saasbox_page_content .was-validated .wpcf7-select:valid,
  #saasbox_page_content .was-validated .wpcf7-textarea:valid,
  #saasbox_page_content .form-control.is-valid,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .is-valid.select2-search__field,
  #saasbox_page_content .is-valid.wpcf7-text,
  #saasbox_page_content .is-valid.wpcf7-date,
  #saasbox_page_content .is-valid.wpcf7-quiz,
  #saasbox_page_content .is-valid.wpcf7-number,
  #saasbox_page_content .is-valid.wpcf7-select,
  #saasbox_page_content .is-valid.wpcf7-textarea {
    border-color: #3cd458;
    padding-right: calc(2.65em);
    background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0088'%3e%3cpathfill='%233cd458'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(0.6625em);
    -webkit-background-size: calc(1.325em) calc(1.325em);
    background-size: calc(1.325em) calc(1.325em);
  }
  #saasbox_page_content .was-validated .form-control:valid:focus,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon .input-text:valid:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated .input-text:valid:focus,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon .input-text:valid:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated .input-text:valid:focus,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon .input-text:valid:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated .input-text:valid:focus,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown .select2-search__field:valid:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated .select2-search__field:valid:focus,
  #saasbox_page_content .was-validated .wpcf7-text:valid:focus,
  #saasbox_page_content .was-validated .wpcf7-date:valid:focus,
  #saasbox_page_content .was-validated .wpcf7-quiz:valid:focus,
  #saasbox_page_content .was-validated .wpcf7-number:valid:focus,
  #saasbox_page_content .was-validated .wpcf7-select:valid:focus,
  #saasbox_page_content .was-validated .wpcf7-textarea:valid:focus,
  #saasbox_page_content .form-control.is-valid:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .is-valid.input-text:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .is-valid.input-text:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .is-valid.input-text:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .is-valid.select2-search__field:focus,
  #saasbox_page_content .is-valid.wpcf7-text:focus,
  #saasbox_page_content .is-valid.wpcf7-date:focus,
  #saasbox_page_content .is-valid.wpcf7-quiz:focus,
  #saasbox_page_content .is-valid.wpcf7-number:focus,
  #saasbox_page_content .is-valid.wpcf7-select:focus,
  #saasbox_page_content .is-valid.wpcf7-textarea:focus {
    border-color: #3cd458;
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.25);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.25);
  }
  #saasbox_page_content .was-validated .form-control:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon .input-text:valid ~ .valid-feedback,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated .input-text:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon .input-text:valid ~ .valid-feedback,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated .input-text:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon .input-text:valid ~ .valid-feedback,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated .input-text:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown .select2-search__field:valid ~ .valid-feedback,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated .select2-search__field:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .wpcf7-text:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .wpcf7-date:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .wpcf7-quiz:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .wpcf7-number:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .wpcf7-select:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .wpcf7-textarea:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .form-control:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon .input-text:valid ~ .valid-tooltip,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated .input-text:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon .input-text:valid ~ .valid-tooltip,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated .input-text:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon .input-text:valid ~ .valid-tooltip,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated .input-text:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown .select2-search__field:valid ~ .valid-tooltip,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated .select2-search__field:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-text:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-date:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-quiz:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-number:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-select:valid ~ .valid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-textarea:valid ~ .valid-tooltip,
  #saasbox_page_content .form-control.is-valid ~ .valid-feedback,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .is-valid.input-text ~ .valid-feedback,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .is-valid.input-text ~ .valid-feedback,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .is-valid.input-text ~ .valid-feedback,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .is-valid.select2-search__field ~ .valid-feedback,
  #saasbox_page_content .is-valid.wpcf7-text ~ .valid-feedback,
  #saasbox_page_content .is-valid.wpcf7-date ~ .valid-feedback,
  #saasbox_page_content .is-valid.wpcf7-quiz ~ .valid-feedback,
  #saasbox_page_content .is-valid.wpcf7-number ~ .valid-feedback,
  #saasbox_page_content .is-valid.wpcf7-select ~ .valid-feedback,
  #saasbox_page_content .is-valid.wpcf7-textarea ~ .valid-feedback,
  #saasbox_page_content .form-control.is-valid ~ .valid-tooltip,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .is-valid.input-text ~ .valid-tooltip,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .is-valid.input-text ~ .valid-tooltip,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .is-valid.input-text ~ .valid-tooltip,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .is-valid.select2-search__field ~ .valid-tooltip,
  #saasbox_page_content .is-valid.wpcf7-text ~ .valid-tooltip,
  #saasbox_page_content .is-valid.wpcf7-date ~ .valid-tooltip,
  #saasbox_page_content .is-valid.wpcf7-quiz ~ .valid-tooltip,
  #saasbox_page_content .is-valid.wpcf7-number ~ .valid-tooltip,
  #saasbox_page_content .is-valid.wpcf7-select ~ .valid-tooltip,
  #saasbox_page_content .is-valid.wpcf7-textarea ~ .valid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated textarea.form-control:valid,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon textarea.input-text:valid,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated textarea.input-text:valid,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon textarea.input-text:valid,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated textarea.input-text:valid,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon textarea.input-text:valid,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated textarea.input-text:valid,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown textarea.select2-search__field:valid,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated textarea.select2-search__field:valid,
  #saasbox_page_content .was-validated textarea.wpcf7-text:valid,
  #saasbox_page_content .was-validated textarea.wpcf7-date:valid,
  #saasbox_page_content .was-validated textarea.wpcf7-quiz:valid,
  #saasbox_page_content .was-validated textarea.wpcf7-number:valid,
  #saasbox_page_content .was-validated textarea.wpcf7-select:valid,
  #saasbox_page_content .was-validated textarea.wpcf7-textarea:valid,
  #saasbox_page_content textarea.form-control.is-valid,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon textarea.is-valid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon textarea.is-valid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon textarea.is-valid.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown textarea.is-valid.select2-search__field,
  #saasbox_page_content textarea.is-valid.wpcf7-text,
  #saasbox_page_content textarea.is-valid.wpcf7-date,
  #saasbox_page_content textarea.is-valid.wpcf7-quiz,
  #saasbox_page_content textarea.is-valid.wpcf7-number,
  #saasbox_page_content textarea.is-valid.wpcf7-select,
  #saasbox_page_content textarea.is-valid.wpcf7-textarea {
    padding-right: calc(2.65em);
    background-position: top calc(0.6625em) right calc(0.6625em);
  }
  #saasbox_page_content .was-validated .custom-select:valid,
  #saasbox_page_content .custom-select.is-valid {
    border-color: #3cd458;
    padding-right: calc(3.0625em);
    background: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px, url("data:image/svg+xml, %3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0088'%3e%3cpathfill='%233cd458'd='M2.36.73L.64.53c-.4-1.04.46-1.41.1-.8l1.11.43.4-3.8c.6-.631.6-.271.2.7l-44.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(1.325em) calc(1.325em);
  }
  #saasbox_page_content .was-validated .custom-select:valid:focus,
  #saasbox_page_content .custom-select.is-valid:focus {
    border-color: #3cd458;
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.25);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.25);
  }
  #saasbox_page_content .was-validated .custom-select:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .custom-select:valid ~ .valid-tooltip,
  #saasbox_page_content .custom-select.is-valid ~ .valid-feedback,
  #saasbox_page_content .custom-select.is-valid ~ .valid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .form-control-file:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .form-control-file:valid ~ .valid-tooltip,
  #saasbox_page_content .form-control-file.is-valid ~ .valid-feedback,
  #saasbox_page_content .form-control-file.is-valid ~ .valid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .form-check-input:valid ~ .form-check-label,
  #saasbox_page_content .form-check-input.is-valid ~ .form-check-label {
    color: #3cd458;
  }
  #saasbox_page_content .was-validated .form-check-input:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .form-check-input:valid ~ .valid-tooltip,
  #saasbox_page_content .form-check-input.is-valid ~ .valid-feedback,
  #saasbox_page_content .form-check-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .custom-control-input:valid ~ .custom-control-label,
  #saasbox_page_content .custom-control-input.is-valid ~ .custom-control-label {
    color: #3cd458;
  }
  #saasbox_page_content .was-validated .custom-control-input:valid ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #3cd458;
  }
  #saasbox_page_content .was-validated .custom-control-input:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .custom-control-input:valid ~ .valid-tooltip,
  #saasbox_page_content .custom-control-input.is-valid ~ .valid-feedback,
  #saasbox_page_content .custom-control-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #66dd7c;
    background-color: #66dd7c;
  }
  #saasbox_page_content .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.25);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.25);
  }
  #saasbox_page_content .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #3cd458;
  }
  #saasbox_page_content .was-validated .custom-file-input:valid ~ .custom-file-label,
  #saasbox_page_content .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #3cd458;
  }
  #saasbox_page_content .was-validated .custom-file-input:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .custom-file-input:valid ~ .valid-tooltip,
  #saasbox_page_content .custom-file-input.is-valid ~ .valid-feedback,
  #saasbox_page_content .custom-file-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .custom-file-input:valid:focus ~ .custom-file-label,
  #saasbox_page_content .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #3cd458;
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.25);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.25);
  }
  #saasbox_page_content .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 85%;
    color: #ff4954;
  }
  #saasbox_page_content .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .9375rem;
    line-height: 1.9;
    color: #fff;
    background-color: rgba(255,73,84,0.9);
    border-radius: .25rem;
  }
  #saasbox_page_content .was-validated .form-control:invalid,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon .input-text:invalid,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated .input-text:invalid,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon .input-text:invalid,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated .input-text:invalid,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon .input-text:invalid,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated .input-text:invalid,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown .select2-search__field:invalid,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated .select2-search__field:invalid,
  #saasbox_page_content .was-validated .wpcf7-text:invalid,
  #saasbox_page_content .was-validated .wpcf7-date:invalid,
  #saasbox_page_content .was-validated .wpcf7-quiz:invalid,
  #saasbox_page_content .was-validated .wpcf7-number:invalid,
  #saasbox_page_content .was-validated .wpcf7-select:invalid,
  #saasbox_page_content .was-validated .wpcf7-textarea:invalid,
  #saasbox_page_content .form-control.is-invalid,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .is-invalid.select2-search__field,
  #saasbox_page_content .is-invalid.wpcf7-text,
  #saasbox_page_content .is-invalid.wpcf7-date,
  #saasbox_page_content .is-invalid.wpcf7-quiz,
  #saasbox_page_content .is-invalid.wpcf7-number,
  #saasbox_page_content .is-invalid.wpcf7-select,
  #saasbox_page_content .is-invalid.wpcf7-textarea {
    border-color: #ff4954;
    padding-right: calc(2.65em);
    background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'fill='%23ff4954'viewBox='-2-277'%3e%3cpathstroke='%23ff4954'd='M00l33m0-3L03'/%3e%3ccircler='.5'/%3e%3ccirclecx='3'r='.5'/%3e%3ccirclecy='3'r='.5'/%3e%3ccirclecx='3'cy='3'r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.6625em);
    -webkit-background-size: calc(1.325em) calc(1.325em);
    background-size: calc(1.325em) calc(1.325em);
  }
  #saasbox_page_content .was-validated .form-control:invalid:focus,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon .input-text:invalid:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated .input-text:invalid:focus,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon .input-text:invalid:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated .input-text:invalid:focus,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon .input-text:invalid:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated .input-text:invalid:focus,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown .select2-search__field:invalid:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated .select2-search__field:invalid:focus,
  #saasbox_page_content .was-validated .wpcf7-text:invalid:focus,
  #saasbox_page_content .was-validated .wpcf7-date:invalid:focus,
  #saasbox_page_content .was-validated .wpcf7-quiz:invalid:focus,
  #saasbox_page_content .was-validated .wpcf7-number:invalid:focus,
  #saasbox_page_content .was-validated .wpcf7-select:invalid:focus,
  #saasbox_page_content .was-validated .wpcf7-textarea:invalid:focus,
  #saasbox_page_content .form-control.is-invalid:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .is-invalid.input-text:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .is-invalid.input-text:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .is-invalid.input-text:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .is-invalid.select2-search__field:focus,
  #saasbox_page_content .is-invalid.wpcf7-text:focus,
  #saasbox_page_content .is-invalid.wpcf7-date:focus,
  #saasbox_page_content .is-invalid.wpcf7-quiz:focus,
  #saasbox_page_content .is-invalid.wpcf7-number:focus,
  #saasbox_page_content .is-invalid.wpcf7-select:focus,
  #saasbox_page_content .is-invalid.wpcf7-textarea:focus {
    border-color: #ff4954;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.25);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.25);
  }
  #saasbox_page_content .was-validated .form-control:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon .input-text:invalid ~ .invalid-feedback,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated .input-text:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon .input-text:invalid ~ .invalid-feedback,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated .input-text:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon .input-text:invalid ~ .invalid-feedback,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated .input-text:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown .select2-search__field:invalid ~ .invalid-feedback,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated .select2-search__field:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .wpcf7-text:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .wpcf7-date:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .wpcf7-quiz:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .wpcf7-number:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .wpcf7-select:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .wpcf7-textarea:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .form-control:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon .input-text:invalid ~ .invalid-tooltip,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated .input-text:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon .input-text:invalid ~ .invalid-tooltip,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated .input-text:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon .input-text:invalid ~ .invalid-tooltip,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated .input-text:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown .select2-search__field:invalid ~ .invalid-tooltip,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated .select2-search__field:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-text:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-date:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-quiz:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-number:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-select:invalid ~ .invalid-tooltip,
  #saasbox_page_content .was-validated .wpcf7-textarea:invalid ~ .invalid-tooltip,
  #saasbox_page_content .form-control.is-invalid ~ .invalid-feedback,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .is-invalid.input-text ~ .invalid-feedback,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .is-invalid.input-text ~ .invalid-feedback,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .is-invalid.input-text ~ .invalid-feedback,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .is-invalid.select2-search__field ~ .invalid-feedback,
  #saasbox_page_content .is-invalid.wpcf7-text ~ .invalid-feedback,
  #saasbox_page_content .is-invalid.wpcf7-date ~ .invalid-feedback,
  #saasbox_page_content .is-invalid.wpcf7-quiz ~ .invalid-feedback,
  #saasbox_page_content .is-invalid.wpcf7-number ~ .invalid-feedback,
  #saasbox_page_content .is-invalid.wpcf7-select ~ .invalid-feedback,
  #saasbox_page_content .is-invalid.wpcf7-textarea ~ .invalid-feedback,
  #saasbox_page_content .form-control.is-invalid ~ .invalid-tooltip,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .is-invalid.input-text ~ .invalid-tooltip,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .is-invalid.input-text ~ .invalid-tooltip,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .is-invalid.input-text ~ .invalid-tooltip,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .is-invalid.select2-search__field ~ .invalid-tooltip,
  #saasbox_page_content .is-invalid.wpcf7-text ~ .invalid-tooltip,
  #saasbox_page_content .is-invalid.wpcf7-date ~ .invalid-tooltip,
  #saasbox_page_content .is-invalid.wpcf7-quiz ~ .invalid-tooltip,
  #saasbox_page_content .is-invalid.wpcf7-number ~ .invalid-tooltip,
  #saasbox_page_content .is-invalid.wpcf7-select ~ .invalid-tooltip,
  #saasbox_page_content .is-invalid.wpcf7-textarea ~ .invalid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated textarea.form-control:invalid,
  #saasbox_page_content .was-validated #add_payment_method table.cart td.actions .coupon textarea.input-text:invalid,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .was-validated textarea.input-text:invalid,
  #saasbox_page_content .was-validated .woocommerce-cart table.cart td.actions .coupon textarea.input-text:invalid,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .was-validated textarea.input-text:invalid,
  #saasbox_page_content .was-validated .woocommerce-checkout table.cart td.actions .coupon textarea.input-text:invalid,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .was-validated textarea.input-text:invalid,
  #saasbox_page_content .was-validated .select2-container--default .select2-search--dropdown textarea.select2-search__field:invalid,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .was-validated textarea.select2-search__field:invalid,
  #saasbox_page_content .was-validated textarea.wpcf7-text:invalid,
  #saasbox_page_content .was-validated textarea.wpcf7-date:invalid,
  #saasbox_page_content .was-validated textarea.wpcf7-quiz:invalid,
  #saasbox_page_content .was-validated textarea.wpcf7-number:invalid,
  #saasbox_page_content .was-validated textarea.wpcf7-select:invalid,
  #saasbox_page_content .was-validated textarea.wpcf7-textarea:invalid,
  #saasbox_page_content textarea.form-control.is-invalid,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon textarea.is-invalid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon textarea.is-invalid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon textarea.is-invalid.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown textarea.is-invalid.select2-search__field,
  #saasbox_page_content textarea.is-invalid.wpcf7-text,
  #saasbox_page_content textarea.is-invalid.wpcf7-date,
  #saasbox_page_content textarea.is-invalid.wpcf7-quiz,
  #saasbox_page_content textarea.is-invalid.wpcf7-number,
  #saasbox_page_content textarea.is-invalid.wpcf7-select,
  #saasbox_page_content textarea.is-invalid.wpcf7-textarea {
    padding-right: calc(2.65em);
    background-position: top calc(0.6625em) right calc(0.6625em);
  }
  #saasbox_page_content .was-validated .custom-select:invalid,
  #saasbox_page_content .custom-select.is-invalid {
    border-color: #ff4954;
    padding-right: calc(3.0625em);
    background: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px, url("data:image/svg+xml, %3csvgxmlns='http://www.w3.org/2000/svg'fill='%23ff4954'viewBox='-2-277'%3e%3cpathstroke='%23ff4954'd='M00l33m0-3L03'/%3e%3ccircler='.5'/%3e%3ccirclecx='3'r='.5'/%3e%3ccirclecy='3'r='.5'/%3e%3ccirclecx='3'cy='3'r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(1.325em) calc(1.325em);
  }
  #saasbox_page_content .was-validated .custom-select:invalid:focus,
  #saasbox_page_content .custom-select.is-invalid:focus {
    border-color: #ff4954;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.25);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.25);
  }
  #saasbox_page_content .was-validated .custom-select:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .custom-select:invalid ~ .invalid-tooltip,
  #saasbox_page_content .custom-select.is-invalid ~ .invalid-feedback,
  #saasbox_page_content .custom-select.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .form-control-file:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .form-control-file:invalid ~ .invalid-tooltip,
  #saasbox_page_content .form-control-file.is-invalid ~ .invalid-feedback,
  #saasbox_page_content .form-control-file.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .form-check-input:invalid ~ .form-check-label,
  #saasbox_page_content .form-check-input.is-invalid ~ .form-check-label {
    color: #ff4954;
  }
  #saasbox_page_content .was-validated .form-check-input:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .form-check-input:invalid ~ .invalid-tooltip,
  #saasbox_page_content .form-check-input.is-invalid ~ .invalid-feedback,
  #saasbox_page_content .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .custom-control-input:invalid ~ .custom-control-label,
  #saasbox_page_content .custom-control-input.is-invalid ~ .custom-control-label {
    color: #ff4954;
  }
  #saasbox_page_content .was-validated .custom-control-input:invalid ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #ff4954;
  }
  #saasbox_page_content .was-validated .custom-control-input:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .custom-control-input:invalid ~ .invalid-tooltip,
  #saasbox_page_content .custom-control-input.is-invalid ~ .invalid-feedback,
  #saasbox_page_content .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #ff7c84;
    background-color: #ff7c84;
  }
  #saasbox_page_content .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.25);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.25);
  }
  #saasbox_page_content .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ff4954;
  }
  #saasbox_page_content .was-validated .custom-file-input:invalid ~ .custom-file-label,
  #saasbox_page_content .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #ff4954;
  }
  #saasbox_page_content .was-validated .custom-file-input:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .custom-file-input:invalid ~ .invalid-tooltip,
  #saasbox_page_content .custom-file-input.is-invalid ~ .invalid-feedback,
  #saasbox_page_content .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
  #saasbox_page_content .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #ff4954;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.25);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.25);
  }
  #saasbox_page_content .form-inline {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    -webkit-box-align: center;
    align-items: center;
  }
  #saasbox_page_content .form-inline .form-check {
    width: 100%;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .form-inline label {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      margin-bottom: 0;
    }
    #saasbox_page_content .form-inline .form-group {
      display: -webkit-box;
      display: flex;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row wrap;
      -webkit-box-align: center;
      align-items: center;
      margin-bottom: 0;
    }
    #saasbox_page_content .form-inline .form-control,
    #saasbox_page_content .form-inline #add_payment_method table.cart td.actions .coupon .input-text,
    #saasbox_page_content #add_payment_method table.cart td.actions .coupon .form-inline .input-text,
    #saasbox_page_content .form-inline .woocommerce-cart table.cart td.actions .coupon .input-text,
    #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .form-inline .input-text,
    #saasbox_page_content .form-inline .woocommerce-checkout table.cart td.actions .coupon .input-text,
    #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .form-inline .input-text,
    #saasbox_page_content .form-inline .select2-container--default .select2-search--dropdown .select2-search__field,
    #saasbox_page_content .select2-container--default .select2-search--dropdown .form-inline .select2-search__field,
    #saasbox_page_content .form-inline .wpcf7-text,
    #saasbox_page_content .form-inline .wpcf7-date,
    #saasbox_page_content .form-inline .wpcf7-quiz,
    #saasbox_page_content .form-inline .wpcf7-number,
    #saasbox_page_content .form-inline .wpcf7-select,
    #saasbox_page_content .form-inline .wpcf7-textarea {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    #saasbox_page_content .form-inline .form-control-plaintext {
      display: inline-block;
    }
    #saasbox_page_content .form-inline .input-group,
    #saasbox_page_content .form-inline .custom-select {
      width: auto;
    }
    #saasbox_page_content .form-inline .form-check {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    #saasbox_page_content .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: .25rem;
      margin-left: 0;
    }
    #saasbox_page_content .form-inline .custom-control {
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }
    #saasbox_page_content .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }
  #saasbox_page_content .btn,
  #saasbox_page_content .comment-list .comment-reply-link,
  #saasbox_page_content .comment-form .submit,
  #saasbox_page_content .woocommerce a.button,
  #saasbox_page_content .woocommerce input.button,
  #saasbox_page_content .woocommerce button.button,
  #saasbox_page_content .woocommerce button.button.alt,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce #respond input#submit,
  #saasbox_page_content .wpcf7-submit {
    display: inline-block;
    font-weight: 600;
    color: #757575;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .9375rem;
    line-height: 1.9;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .btn,
    #saasbox_page_content .comment-list .comment-reply-link,
    #saasbox_page_content .comment-form .submit,
    #saasbox_page_content .woocommerce a.button,
    #saasbox_page_content .woocommerce input.button,
    #saasbox_page_content .woocommerce button.button,
    #saasbox_page_content .woocommerce button.button.alt,
    #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
    #saasbox_page_content .woocommerce #respond input#submit,
    #saasbox_page_content .wpcf7-submit {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .btn:hover,
  #saasbox_page_content .comment-list .comment-reply-link:hover,
  #saasbox_page_content .comment-form .submit:hover,
  #saasbox_page_content .woocommerce a.button:hover,
  #saasbox_page_content .woocommerce input.button:hover,
  #saasbox_page_content .woocommerce button.button:hover,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
  #saasbox_page_content .woocommerce #respond input#submit:hover,
  #saasbox_page_content .wpcf7-submit:hover {
    color: #757575;
    text-decoration: none;
  }
  #saasbox_page_content .btn:focus,
  #saasbox_page_content .comment-list .comment-reply-link:focus,
  #saasbox_page_content .comment-form .submit:focus,
  #saasbox_page_content .woocommerce a.button:focus,
  #saasbox_page_content .woocommerce input.button:focus,
  #saasbox_page_content .woocommerce button.button:focus,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
  #saasbox_page_content .woocommerce #respond input#submit:focus,
  #saasbox_page_content .wpcf7-submit:focus,
  #saasbox_page_content .btn.focus,
  #saasbox_page_content .comment-list .focus.comment-reply-link,
  #saasbox_page_content .comment-form .focus.submit,
  #saasbox_page_content .woocommerce a.focus.button,
  #saasbox_page_content .woocommerce input.focus.button,
  #saasbox_page_content .woocommerce button.focus.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.focus.checkout-button,
  #saasbox_page_content .woocommerce #respond input.focus#submit,
  #saasbox_page_content .focus.wpcf7-submit {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .btn.disabled,
  #saasbox_page_content .comment-list .disabled.comment-reply-link,
  #saasbox_page_content .comment-form .disabled.submit,
  #saasbox_page_content .woocommerce a.disabled.button,
  #saasbox_page_content .woocommerce input.disabled.button,
  #saasbox_page_content .woocommerce button.disabled.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.disabled.checkout-button,
  #saasbox_page_content .woocommerce #respond input.disabled#submit,
  #saasbox_page_content .disabled.wpcf7-submit,
  #saasbox_page_content .btn:disabled,
  #saasbox_page_content .comment-list .comment-reply-link:disabled,
  #saasbox_page_content .comment-form .submit:disabled,
  #saasbox_page_content .woocommerce a.button:disabled,
  #saasbox_page_content .woocommerce input.button:disabled,
  #saasbox_page_content .woocommerce button.button:disabled,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:disabled,
  #saasbox_page_content .woocommerce #respond input#submit:disabled,
  #saasbox_page_content .wpcf7-submit:disabled {
    opacity: .65;
  }
  #saasbox_page_content a.btn.disabled,
  #saasbox_page_content .comment-list a.disabled.comment-reply-link,
  #saasbox_page_content .comment-form a.disabled.submit,
  #saasbox_page_content .woocommerce a.disabled.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.disabled.checkout-button,
  #saasbox_page_content a.disabled.wpcf7-submit,
  #saasbox_page_content fieldset:disabled a.btn,
  #saasbox_page_content fieldset:disabled .comment-list a.comment-reply-link,
  #saasbox_page_content .comment-list fieldset:disabled a.comment-reply-link,
  #saasbox_page_content fieldset:disabled .comment-form a.submit,
  #saasbox_page_content .comment-form fieldset:disabled a.submit,
  #saasbox_page_content fieldset:disabled .woocommerce a.button,
  #saasbox_page_content .woocommerce fieldset:disabled a.button,
  #saasbox_page_content fieldset:disabled .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout fieldset:disabled a.checkout-button,
  #saasbox_page_content fieldset:disabled a.wpcf7-submit {
    pointer-events: none;
  }
  #saasbox_page_content .btn-primary,
  #saasbox_page_content .comment-form .submit,
  #saasbox_page_content .wpcf7-submit {
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff;
  }
  #saasbox_page_content .btn-primary:hover,
  #saasbox_page_content .comment-form .submit:hover,
  #saasbox_page_content .wpcf7-submit:hover {
    color: #fff;
    background-color: #3c96ff;
    border-color: #3c96ff;
    -webkit-box-shadow: 0 1px 10px rgba(80,161,255,0.4);
    box-shadow: 0 1px 10px rgba(80,161,255,0.4);
  }
  #saasbox_page_content .btn-primary:focus,
  #saasbox_page_content .comment-form .submit:focus,
  #saasbox_page_content .wpcf7-submit:focus,
  #saasbox_page_content .btn-primary.focus,
  #saasbox_page_content .comment-form .focus.submit,
  #saasbox_page_content .focus.wpcf7-submit {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
    box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
  }
  #saasbox_page_content .btn-primary.disabled,
  #saasbox_page_content .comment-form .disabled.submit,
  #saasbox_page_content .disabled.wpcf7-submit,
  #saasbox_page_content .btn-primary:disabled,
  #saasbox_page_content .comment-form .submit:disabled,
  #saasbox_page_content .wpcf7-submit:disabled {
    background-color: #50a1ff;
    border-color: #50a1ff;
  }
  #saasbox_page_content .btn-primary:not([disabled]):not(.disabled):active,
  #saasbox_page_content .comment-form .submit:not([disabled]):not(.disabled):active,
  #saasbox_page_content .wpcf7-submit:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-primary:not([disabled]):not(.disabled).active,
  #saasbox_page_content .comment-form .submit:not([disabled]):not(.disabled).active,
  #saasbox_page_content .wpcf7-submit:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-primary.dropdown-toggle,
  #saasbox_page_content .comment-form .show>.dropdown-toggle.submit,
  #saasbox_page_content .show>.dropdown-toggle.wpcf7-submit {
    color: #fff;
    background-color: #278bff;
    border-color: #278bff;
    -webkit-box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
    box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
  }
  #saasbox_page_content .btn-secondary,
  #saasbox_page_content .comment-list .comment-reply-link,
  #saasbox_page_content .woocommerce a.button,
  #saasbox_page_content .woocommerce input.button,
  #saasbox_page_content .woocommerce button.button,
  #saasbox_page_content .woocommerce button.button.alt,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce #respond input#submit {
    color: #757575;
    background-color: #e9ecf0;
    border-color: #e9ecf0;
  }
  #saasbox_page_content .btn-secondary:hover,
  #saasbox_page_content .comment-list .comment-reply-link:hover,
  #saasbox_page_content .woocommerce a.button:hover,
  #saasbox_page_content .woocommerce input.button:hover,
  #saasbox_page_content .woocommerce button.button:hover,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
  #saasbox_page_content .woocommerce #respond input#submit:hover {
    color: #757575;
    background-color: #dde2e8;
    border-color: #dde2e8;
    -webkit-box-shadow: 0 1px 10px rgba(233,236,240,0.4);
    box-shadow: 0 1px 10px rgba(233,236,240,0.4);
  }
  #saasbox_page_content .btn-secondary:focus,
  #saasbox_page_content .comment-list .comment-reply-link:focus,
  #saasbox_page_content .woocommerce a.button:focus,
  #saasbox_page_content .woocommerce input.button:focus,
  #saasbox_page_content .woocommerce button.button:focus,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
  #saasbox_page_content .woocommerce #respond input#submit:focus,
  #saasbox_page_content .btn-secondary.focus,
  #saasbox_page_content .comment-list .focus.comment-reply-link,
  #saasbox_page_content .woocommerce a.focus.button,
  #saasbox_page_content .woocommerce input.focus.button,
  #saasbox_page_content .woocommerce button.focus.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.focus.checkout-button,
  #saasbox_page_content .woocommerce #respond input.focus#submit {
    color: #757575;
    -webkit-box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
    box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
  }
  #saasbox_page_content .btn-secondary.disabled,
  #saasbox_page_content .comment-list .disabled.comment-reply-link,
  #saasbox_page_content .woocommerce a.disabled.button,
  #saasbox_page_content .woocommerce input.disabled.button,
  #saasbox_page_content .woocommerce button.disabled.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.disabled.checkout-button,
  #saasbox_page_content .woocommerce #respond input.disabled#submit,
  #saasbox_page_content .btn-secondary:disabled,
  #saasbox_page_content .comment-list .comment-reply-link:disabled,
  #saasbox_page_content .woocommerce a.button:disabled,
  #saasbox_page_content .woocommerce input.button:disabled,
  #saasbox_page_content .woocommerce button.button:disabled,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:disabled,
  #saasbox_page_content .woocommerce #respond input#submit:disabled {
    background-color: #e9ecf0;
    border-color: #e9ecf0;
  }
  #saasbox_page_content .btn-secondary:not([disabled]):not(.disabled):active,
  #saasbox_page_content .comment-list .comment-reply-link:not([disabled]):not(.disabled):active,
  #saasbox_page_content .woocommerce a.button:not([disabled]):not(.disabled):active,
  #saasbox_page_content .woocommerce input.button:not([disabled]):not(.disabled):active,
  #saasbox_page_content .woocommerce button.button:not([disabled]):not(.disabled):active,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:not([disabled]):not(.disabled):active,
  #saasbox_page_content .woocommerce #respond input#submit:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-secondary:not([disabled]):not(.disabled).active,
  #saasbox_page_content .comment-list .comment-reply-link:not([disabled]):not(.disabled).active,
  #saasbox_page_content .woocommerce a.button:not([disabled]):not(.disabled).active,
  #saasbox_page_content .woocommerce input.button:not([disabled]):not(.disabled).active,
  #saasbox_page_content .woocommerce button.button:not([disabled]):not(.disabled).active,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:not([disabled]):not(.disabled).active,
  #saasbox_page_content .woocommerce #respond input#submit:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-secondary.dropdown-toggle,
  #saasbox_page_content .comment-list .show>.dropdown-toggle.comment-reply-link,
  #saasbox_page_content .woocommerce .show>a.dropdown-toggle.button,
  #saasbox_page_content .woocommerce .show>input.dropdown-toggle.button,
  #saasbox_page_content .woocommerce .show>button.dropdown-toggle.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .show>a.dropdown-toggle.checkout-button,
  #saasbox_page_content .woocommerce #respond .show>input.dropdown-toggle#submit {
    color: #757575;
    background-color: #d1d7df;
    border-color: #d1d7df;
    -webkit-box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
    box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
  }
  #saasbox_page_content .btn-success {
    color: #fff;
    background-color: #3cd458;
    border-color: #3cd458;
  }
  #saasbox_page_content .btn-success:hover {
    color: #fff;
    background-color: #2dce4b;
    border-color: #2dce4b;
    -webkit-box-shadow: 0 1px 10px rgba(60,212,88,0.4);
    box-shadow: 0 1px 10px rgba(60,212,88,0.4);
  }
  #saasbox_page_content .btn-success:focus,
  #saasbox_page_content .btn-success.focus {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
  }
  #saasbox_page_content .btn-success.disabled,
  #saasbox_page_content .btn-success:disabled {
    background-color: #3cd458;
    border-color: #3cd458;
  }
  #saasbox_page_content .btn-success:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-success:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #2abd45;
    border-color: #2abd45;
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
  }
  #saasbox_page_content .btn-info {
    color: #fff;
    background-color: #926dde;
    border-color: #926dde;
  }
  #saasbox_page_content .btn-info:hover {
    color: #fff;
    background-color: #865cda;
    border-color: #865cda;
    -webkit-box-shadow: 0 1px 10px rgba(146,109,222,0.4);
    box-shadow: 0 1px 10px rgba(146,109,222,0.4);
  }
  #saasbox_page_content .btn-info:focus,
  #saasbox_page_content .btn-info.focus {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
    box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
  }
  #saasbox_page_content .btn-info.disabled,
  #saasbox_page_content .btn-info:disabled {
    background-color: #926dde;
    border-color: #926dde;
  }
  #saasbox_page_content .btn-info:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-info:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #794cd6;
    border-color: #794cd6;
    -webkit-box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
    box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
  }
  #saasbox_page_content .btn-warning {
    color: #fff;
    background-color: #ffba00;
    border-color: #ffba00;
  }
  #saasbox_page_content .btn-warning:hover {
    color: #fff;
    background-color: #ebab00;
    border-color: #ebab00;
    -webkit-box-shadow: 0 1px 10px rgba(255,186,0,0.4);
    box-shadow: 0 1px 10px rgba(255,186,0,0.4);
  }
  #saasbox_page_content .btn-warning:focus,
  #saasbox_page_content .btn-warning.focus {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
    box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
  }
  #saasbox_page_content .btn-warning.disabled,
  #saasbox_page_content .btn-warning:disabled {
    background-color: #ffba00;
    border-color: #ffba00;
  }
  #saasbox_page_content .btn-warning:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-warning:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #d69c00;
    border-color: #d69c00;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
    box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
  }
  #saasbox_page_content .btn-danger {
    color: #fff;
    background-color: #ff4954;
    border-color: #ff4954;
  }
  #saasbox_page_content .btn-danger:hover {
    color: #fff;
    background-color: #ff3541;
    border-color: #ff3541;
    -webkit-box-shadow: 0 1px 10px rgba(255,73,84,0.4);
    box-shadow: 0 1px 10px rgba(255,73,84,0.4);
  }
  #saasbox_page_content .btn-danger:focus,
  #saasbox_page_content .btn-danger.focus {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
  }
  #saasbox_page_content .btn-danger.disabled,
  #saasbox_page_content .btn-danger:disabled {
    background-color: #ff4954;
    border-color: #ff4954;
  }
  #saasbox_page_content .btn-danger:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-danger:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff202e;
    border-color: #ff202e;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
  }
  #saasbox_page_content .btn-light {
    color: #757575;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  #saasbox_page_content .btn-light:hover {
    color: #757575;
    background-color: #eceff2;
    border-color: #eceff2;
    -webkit-box-shadow: 0 1px 10px rgba(248,249,250,0.4);
    box-shadow: 0 1px 10px rgba(248,249,250,0.4);
  }
  #saasbox_page_content .btn-light:focus,
  #saasbox_page_content .btn-light.focus {
    color: #757575;
    -webkit-box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
    box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
  }
  #saasbox_page_content .btn-light.disabled,
  #saasbox_page_content .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  #saasbox_page_content .btn-light:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-light:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-light.dropdown-toggle {
    color: #757575;
    background-color: #e0e5e9;
    border-color: #e0e5e9;
    -webkit-box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
    box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
  }
  #saasbox_page_content .btn-dark {
    color: #fff;
    background-color: #191919;
    border-color: #191919;
  }
  #saasbox_page_content .btn-dark:hover {
    color: #fff;
    background-color: #0f0f0f;
    border-color: #0f0f0f;
    -webkit-box-shadow: 0 1px 10px rgba(25,25,25,0.4);
    box-shadow: 0 1px 10px rgba(25,25,25,0.4);
  }
  #saasbox_page_content .btn-dark:focus,
  #saasbox_page_content .btn-dark.focus {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
    box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
  }
  #saasbox_page_content .btn-dark.disabled,
  #saasbox_page_content .btn-dark:disabled {
    background-color: #191919;
    border-color: #191919;
  }
  #saasbox_page_content .btn-dark:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-dark:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #050505;
    border-color: #050505;
    -webkit-box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
    box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
  }
  #saasbox_page_content .btn-outline-primary {
    color: #50a1ff;
    background-color: transparent;
    background-image: none;
    border-color: #50a1ff;
  }
  #saasbox_page_content .btn-outline-primary:focus,
  #saasbox_page_content .btn-outline-primary.focus {
    color: #50a1ff;
    -webkit-box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
    box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
  }
  #saasbox_page_content .btn-outline-primary:hover {
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff;
    -webkit-box-shadow: 0 1px 10px rgba(80,161,255,0.4);
    box-shadow: 0 1px 10px rgba(80,161,255,0.4);
  }
  #saasbox_page_content .btn-outline-primary.disabled,
  #saasbox_page_content .btn-outline-primary:disabled {
    color: #50a1ff;
    background-color: transparent;
  }
  #saasbox_page_content .btn-outline-primary:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-outline-primary:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff;
    -webkit-box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
    box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
  }
  #saasbox_page_content .btn-outline-secondary {
    color: #e9ecf0;
    background-color: transparent;
    background-image: none;
    border-color: #e9ecf0;
  }
  #saasbox_page_content .btn-outline-secondary:focus,
  #saasbox_page_content .btn-outline-secondary.focus {
    color: #e9ecf0;
    -webkit-box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
    box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
  }
  #saasbox_page_content .btn-outline-secondary:hover {
    color: #757575;
    background-color: #e9ecf0;
    border-color: #e9ecf0;
    -webkit-box-shadow: 0 1px 10px rgba(233,236,240,0.4);
    box-shadow: 0 1px 10px rgba(233,236,240,0.4);
  }
  #saasbox_page_content .btn-outline-secondary.disabled,
  #saasbox_page_content .btn-outline-secondary:disabled {
    color: #e9ecf0;
    background-color: transparent;
  }
  #saasbox_page_content .btn-outline-secondary:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-outline-secondary:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-outline-secondary.dropdown-toggle {
    color: #757575;
    background-color: #e9ecf0;
    border-color: #e9ecf0;
    -webkit-box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
    box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
  }
  #saasbox_page_content .btn-outline-success {
    color: #3cd458;
    background-color: transparent;
    background-image: none;
    border-color: #3cd458;
  }
  #saasbox_page_content .btn-outline-success:focus,
  #saasbox_page_content .btn-outline-success.focus {
    color: #3cd458;
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
  }
  #saasbox_page_content .btn-outline-success:hover {
    color: #fff;
    background-color: #3cd458;
    border-color: #3cd458;
    -webkit-box-shadow: 0 1px 10px rgba(60,212,88,0.4);
    box-shadow: 0 1px 10px rgba(60,212,88,0.4);
  }
  #saasbox_page_content .btn-outline-success.disabled,
  #saasbox_page_content .btn-outline-success:disabled {
    color: #3cd458;
    background-color: transparent;
  }
  #saasbox_page_content .btn-outline-success:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-outline-success:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #3cd458;
    border-color: #3cd458;
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
  }
  #saasbox_page_content .btn-outline-info {
    color: #926dde;
    background-color: transparent;
    background-image: none;
    border-color: #926dde;
  }
  #saasbox_page_content .btn-outline-info:focus,
  #saasbox_page_content .btn-outline-info.focus {
    color: #926dde;
    -webkit-box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
    box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
  }
  #saasbox_page_content .btn-outline-info:hover {
    color: #fff;
    background-color: #926dde;
    border-color: #926dde;
    -webkit-box-shadow: 0 1px 10px rgba(146,109,222,0.4);
    box-shadow: 0 1px 10px rgba(146,109,222,0.4);
  }
  #saasbox_page_content .btn-outline-info.disabled,
  #saasbox_page_content .btn-outline-info:disabled {
    color: #926dde;
    background-color: transparent;
  }
  #saasbox_page_content .btn-outline-info:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-outline-info:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #926dde;
    border-color: #926dde;
    -webkit-box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
    box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
  }
  #saasbox_page_content .btn-outline-warning {
    color: #ffba00;
    background-color: transparent;
    background-image: none;
    border-color: #ffba00;
  }
  #saasbox_page_content .btn-outline-warning:focus,
  #saasbox_page_content .btn-outline-warning.focus {
    color: #ffba00;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
    box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
  }
  #saasbox_page_content .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffba00;
    border-color: #ffba00;
    -webkit-box-shadow: 0 1px 10px rgba(255,186,0,0.4);
    box-shadow: 0 1px 10px rgba(255,186,0,0.4);
  }
  #saasbox_page_content .btn-outline-warning.disabled,
  #saasbox_page_content .btn-outline-warning:disabled {
    color: #ffba00;
    background-color: transparent;
  }
  #saasbox_page_content .btn-outline-warning:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-outline-warning:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffba00;
    border-color: #ffba00;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
    box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
  }
  #saasbox_page_content .btn-outline-danger {
    color: #ff4954;
    background-color: transparent;
    background-image: none;
    border-color: #ff4954;
  }
  #saasbox_page_content .btn-outline-danger:focus,
  #saasbox_page_content .btn-outline-danger.focus {
    color: #ff4954;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
  }
  #saasbox_page_content .btn-outline-danger:hover {
    color: #fff;
    background-color: #ff4954;
    border-color: #ff4954;
    -webkit-box-shadow: 0 1px 10px rgba(255,73,84,0.4);
    box-shadow: 0 1px 10px rgba(255,73,84,0.4);
  }
  #saasbox_page_content .btn-outline-danger.disabled,
  #saasbox_page_content .btn-outline-danger:disabled {
    color: #ff4954;
    background-color: transparent;
  }
  #saasbox_page_content .btn-outline-danger:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-outline-danger:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff4954;
    border-color: #ff4954;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
  }
  #saasbox_page_content .btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
  }
  #saasbox_page_content .btn-outline-light:focus,
  #saasbox_page_content .btn-outline-light.focus {
    color: #f8f9fa;
    -webkit-box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
    box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
  }
  #saasbox_page_content .btn-outline-light:hover {
    color: #757575;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    -webkit-box-shadow: 0 1px 10px rgba(248,249,250,0.4);
    box-shadow: 0 1px 10px rgba(248,249,250,0.4);
  }
  #saasbox_page_content .btn-outline-light.disabled,
  #saasbox_page_content .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
  }
  #saasbox_page_content .btn-outline-light:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-outline-light:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-outline-light.dropdown-toggle {
    color: #757575;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    -webkit-box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
    box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
  }
  #saasbox_page_content .btn-outline-dark {
    color: #191919;
    background-color: transparent;
    background-image: none;
    border-color: #191919;
  }
  #saasbox_page_content .btn-outline-dark:focus,
  #saasbox_page_content .btn-outline-dark.focus {
    color: #191919;
    -webkit-box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
    box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
  }
  #saasbox_page_content .btn-outline-dark:hover {
    color: #fff;
    background-color: #191919;
    border-color: #191919;
    -webkit-box-shadow: 0 1px 10px rgba(25,25,25,0.4);
    box-shadow: 0 1px 10px rgba(25,25,25,0.4);
  }
  #saasbox_page_content .btn-outline-dark.disabled,
  #saasbox_page_content .btn-outline-dark:disabled {
    color: #191919;
    background-color: transparent;
  }
  #saasbox_page_content .btn-outline-dark:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-outline-dark:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #191919;
    border-color: #191919;
    -webkit-box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
    box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
  }
  #saasbox_page_content .btn-link {
    font-weight: 400;
    color: #50a1ff;
    text-decoration: none;
  }
  #saasbox_page_content .btn-link:hover {
    color: #0478ff;
    text-decoration: underline;
  }
  #saasbox_page_content .btn-link:focus,
  #saasbox_page_content .btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .btn-link:disabled,
  #saasbox_page_content .btn-link.disabled {
    color: #868e96;
    pointer-events: none;
  }
  #saasbox_page_content .btn-lg,
  #saasbox_page_content .btn-group-lg>.btn,
  #saasbox_page_content .comment-list .btn-group-lg>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-lg>.submit,
  #saasbox_page_content .woocommerce .btn-group-lg>a.button,
  #saasbox_page_content .woocommerce .btn-group-lg>input.button,
  #saasbox_page_content .woocommerce .btn-group-lg>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-lg>input#submit,
  #saasbox_page_content .btn-group-lg>.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-prepend>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-prepend>.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>a.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>input.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-prepend>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-prepend>input#submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-append>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-append>.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>a.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>input.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-append>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-append>input#submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.wpcf7-submit {
    padding: .375rem 1rem;
    font-size: 1.0625rem;
    line-height: 2.2;
    border-radius: .3rem;
  }
  #saasbox_page_content .btn-sm,
  #saasbox_page_content .btn-group-sm>.btn,
  #saasbox_page_content .comment-form .btn-group-sm>.submit,
  #saasbox_page_content .woocommerce .btn-group-sm>a.button,
  #saasbox_page_content .woocommerce .btn-group-sm>input.button,
  #saasbox_page_content .woocommerce .btn-group-sm>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-sm>input#submit,
  #saasbox_page_content .btn-group-sm>.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn,
  #saasbox_page_content .comment-list .input-group-sm>.input-group-prepend>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-prepend>.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>a.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>input.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-prepend>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-prepend>input#submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn,
  #saasbox_page_content .comment-list .input-group-sm>.input-group-append>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-append>.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>a.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>input.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-append>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-append>input#submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.wpcf7-submit,
  #saasbox_page_content .comment-list .comment-reply-link {
    padding: .375rem .5rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: .125rem;
  }
  #saasbox_page_content .btn-block {
    display: block;
    width: 100%;
  }
  #saasbox_page_content .btn-block+.btn-block {
    margin-top: .5rem;
  }
  #saasbox_page_content input[type="submit"].btn-block,
  #saasbox_page_content input[type="reset"].btn-block,
  #saasbox_page_content input[type="button"].btn-block {
    width: 100%;
  }
  #saasbox_page_content .fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .fade {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .fade:not(.show) {
    opacity: 0;
  }
  #saasbox_page_content .collapse:not(.show) {
    display: none;
  }
  #saasbox_page_content .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height .35s ease;
    transition: height .35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .collapsing {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .dropup,
  #saasbox_page_content .dropright,
  #saasbox_page_content .dropdown,
  #saasbox_page_content .dropleft {
    position: relative;
  }
  #saasbox_page_content .dropdown-toggle {
    white-space: nowrap;
  }
  #saasbox_page_content .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
  #saasbox_page_content .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  #saasbox_page_content .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: .9375rem;
    color: #757575;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: .25rem;
  }
  #saasbox_page_content .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  #saasbox_page_content .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .dropdown-menu-sm-left {
      right: auto;
      left: 0;
    }
    #saasbox_page_content .dropdown-menu-sm-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .dropdown-menu-md-left {
      right: auto;
      left: 0;
    }
    #saasbox_page_content .dropdown-menu-md-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .dropdown-menu-lg-left {
      right: auto;
      left: 0;
    }
    #saasbox_page_content .dropdown-menu-lg-right {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .dropdown-menu-xl-left {
      right: auto;
      left: 0;
    }
    #saasbox_page_content .dropdown-menu-xl-right {
      right: 0;
      left: auto;
    }
  }
  #saasbox_page_content .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem;
  }
  #saasbox_page_content .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
  }
  #saasbox_page_content .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  #saasbox_page_content .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem;
  }
  #saasbox_page_content .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
  }
  #saasbox_page_content .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  #saasbox_page_content .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  #saasbox_page_content .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem;
  }
  #saasbox_page_content .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
  }
  #saasbox_page_content .dropleft .dropdown-toggle::after {
    display: none;
  }
  #saasbox_page_content .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent;
  }
  #saasbox_page_content .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  #saasbox_page_content .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  #saasbox_page_content .dropdown-menu[x-placement^="top"],
  #saasbox_page_content .dropdown-menu[x-placement^="right"],
  #saasbox_page_content .dropdown-menu[x-placement^="bottom"],
  #saasbox_page_content .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
  #saasbox_page_content .dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  #saasbox_page_content .dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  #saasbox_page_content .dropdown-item:hover,
  #saasbox_page_content .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  #saasbox_page_content .dropdown-item.active,
  #saasbox_page_content .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #50a1ff;
  }
  #saasbox_page_content .dropdown-item.disabled,
  #saasbox_page_content .dropdown-item:disabled {
    color: #868e96;
    pointer-events: none;
    background-color: transparent;
  }
  #saasbox_page_content .dropdown-menu.show {
    display: block;
  }
  #saasbox_page_content .dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .9375rem;
    color: #868e96;
    white-space: nowrap;
  }
  #saasbox_page_content .dropdown-item-text {
    display: block;
    padding: .25rem 1.5rem;
    color: #212529;
  }
  #saasbox_page_content .btn-group,
  #saasbox_page_content .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    vertical-align: middle;
  }
  #saasbox_page_content .btn-group>.btn,
  #saasbox_page_content .comment-list .btn-group>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group>.submit,
  #saasbox_page_content .woocommerce .btn-group>a.button,
  #saasbox_page_content .woocommerce .btn-group>input.button,
  #saasbox_page_content .woocommerce .btn-group>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group>input#submit,
  #saasbox_page_content .btn-group>.wpcf7-submit,
  #saasbox_page_content .btn-group-vertical>.btn,
  #saasbox_page_content .comment-list .btn-group-vertical>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-vertical>.submit,
  #saasbox_page_content .woocommerce .btn-group-vertical>a.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>input.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input#submit,
  #saasbox_page_content .btn-group-vertical>.wpcf7-submit {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
  }
  #saasbox_page_content .btn-group>.btn:hover,
  #saasbox_page_content .comment-list .btn-group>.comment-reply-link:hover,
  #saasbox_page_content .comment-form .btn-group>.submit:hover,
  #saasbox_page_content .woocommerce .btn-group>a.button:hover,
  #saasbox_page_content .woocommerce .btn-group>input.button:hover,
  #saasbox_page_content .woocommerce .btn-group>button.button:hover,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:hover,
  #saasbox_page_content .woocommerce #respond .btn-group>input#submit:hover,
  #saasbox_page_content .btn-group>.wpcf7-submit:hover,
  #saasbox_page_content .btn-group-vertical>.btn:hover,
  #saasbox_page_content .comment-list .btn-group-vertical>.comment-reply-link:hover,
  #saasbox_page_content .comment-form .btn-group-vertical>.submit:hover,
  #saasbox_page_content .woocommerce .btn-group-vertical>a.button:hover,
  #saasbox_page_content .woocommerce .btn-group-vertical>input.button:hover,
  #saasbox_page_content .woocommerce .btn-group-vertical>button.button:hover,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:hover,
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input#submit:hover,
  #saasbox_page_content .btn-group-vertical>.wpcf7-submit:hover {
    z-index: 1;
  }
  #saasbox_page_content .btn-group>.btn:focus,
  #saasbox_page_content .comment-list .btn-group>.comment-reply-link:focus,
  #saasbox_page_content .comment-form .btn-group>.submit:focus,
  #saasbox_page_content .woocommerce .btn-group>a.button:focus,
  #saasbox_page_content .woocommerce .btn-group>input.button:focus,
  #saasbox_page_content .woocommerce .btn-group>button.button:focus,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:focus,
  #saasbox_page_content .woocommerce #respond .btn-group>input#submit:focus,
  #saasbox_page_content .btn-group>.wpcf7-submit:focus,
  #saasbox_page_content .btn-group>.btn:active,
  #saasbox_page_content .comment-list .btn-group>.comment-reply-link:active,
  #saasbox_page_content .comment-form .btn-group>.submit:active,
  #saasbox_page_content .woocommerce .btn-group>a.button:active,
  #saasbox_page_content .woocommerce .btn-group>input.button:active,
  #saasbox_page_content .woocommerce .btn-group>button.button:active,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:active,
  #saasbox_page_content .woocommerce #respond .btn-group>input#submit:active,
  #saasbox_page_content .btn-group>.wpcf7-submit:active,
  #saasbox_page_content .btn-group>.btn.active,
  #saasbox_page_content .comment-list .btn-group>.active.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group>.active.submit,
  #saasbox_page_content .woocommerce .btn-group>a.active.button,
  #saasbox_page_content .woocommerce .btn-group>input.active.button,
  #saasbox_page_content .woocommerce .btn-group>button.active.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>a.active.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group>input.active#submit,
  #saasbox_page_content .btn-group>.active.wpcf7-submit,
  #saasbox_page_content .btn-group-vertical>.btn:focus,
  #saasbox_page_content .comment-list .btn-group-vertical>.comment-reply-link:focus,
  #saasbox_page_content .comment-form .btn-group-vertical>.submit:focus,
  #saasbox_page_content .woocommerce .btn-group-vertical>a.button:focus,
  #saasbox_page_content .woocommerce .btn-group-vertical>input.button:focus,
  #saasbox_page_content .woocommerce .btn-group-vertical>button.button:focus,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:focus,
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input#submit:focus,
  #saasbox_page_content .btn-group-vertical>.wpcf7-submit:focus,
  #saasbox_page_content .btn-group-vertical>.btn:active,
  #saasbox_page_content .comment-list .btn-group-vertical>.comment-reply-link:active,
  #saasbox_page_content .comment-form .btn-group-vertical>.submit:active,
  #saasbox_page_content .woocommerce .btn-group-vertical>a.button:active,
  #saasbox_page_content .woocommerce .btn-group-vertical>input.button:active,
  #saasbox_page_content .woocommerce .btn-group-vertical>button.button:active,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:active,
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input#submit:active,
  #saasbox_page_content .btn-group-vertical>.wpcf7-submit:active,
  #saasbox_page_content .btn-group-vertical>.btn.active,
  #saasbox_page_content .comment-list .btn-group-vertical>.active.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-vertical>.active.submit,
  #saasbox_page_content .woocommerce .btn-group-vertical>a.active.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>input.active.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>button.active.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.active.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input.active#submit,
  #saasbox_page_content .btn-group-vertical>.active.wpcf7-submit {
    z-index: 1;
  }
  #saasbox_page_content .btn-toolbar {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  #saasbox_page_content .btn-toolbar .input-group {
    width: auto;
  }
  #saasbox_page_content .btn-group>.btn:not(:first-child),
  #saasbox_page_content .comment-list .btn-group>.comment-reply-link:not(:first-child),
  #saasbox_page_content .comment-form .btn-group>.submit:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group>a.button:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group>input.button:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group>button.button:not(:first-child),
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:not(:first-child),
  #saasbox_page_content .woocommerce #respond .btn-group>input#submit:not(:first-child),
  #saasbox_page_content .btn-group>.wpcf7-submit:not(:first-child),
  #saasbox_page_content .btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
  }
  #saasbox_page_content .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .comment-list .btn-group>.comment-reply-link:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .comment-form .btn-group>.submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .btn-group>a.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .btn-group>input.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .btn-group>button.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce #respond .btn-group>input#submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .btn-group>.wpcf7-submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .btn-group>.btn-group:not(:last-child)>.btn,
  #saasbox_page_content .comment-list .btn-group>.btn-group:not(:last-child)>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group>.btn-group:not(:last-child)>.submit,
  #saasbox_page_content .woocommerce .btn-group>.btn-group:not(:last-child)>a.button,
  #saasbox_page_content .woocommerce .btn-group>.btn-group:not(:last-child)>input.button,
  #saasbox_page_content .woocommerce .btn-group>.btn-group:not(:last-child)>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-group:not(:last-child)>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group>.btn-group:not(:last-child)>input#submit,
  #saasbox_page_content .btn-group>.btn-group:not(:last-child)>.wpcf7-submit {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  #saasbox_page_content .btn-group>.btn:not(:first-child),
  #saasbox_page_content .comment-list .btn-group>.comment-reply-link:not(:first-child),
  #saasbox_page_content .comment-form .btn-group>.submit:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group>a.button:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group>input.button:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group>button.button:not(:first-child),
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>a.checkout-button:not(:first-child),
  #saasbox_page_content .woocommerce #respond .btn-group>input#submit:not(:first-child),
  #saasbox_page_content .btn-group>.wpcf7-submit:not(:first-child),
  #saasbox_page_content .btn-group>.btn-group:not(:first-child)>.btn,
  #saasbox_page_content .comment-list .btn-group>.btn-group:not(:first-child)>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group>.btn-group:not(:first-child)>.submit,
  #saasbox_page_content .woocommerce .btn-group>.btn-group:not(:first-child)>a.button,
  #saasbox_page_content .woocommerce .btn-group>.btn-group:not(:first-child)>input.button,
  #saasbox_page_content .woocommerce .btn-group>.btn-group:not(:first-child)>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group>.btn-group:not(:first-child)>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group>.btn-group:not(:first-child)>input#submit,
  #saasbox_page_content .btn-group>.btn-group:not(:first-child)>.wpcf7-submit {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #saasbox_page_content .dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem;
  }
  #saasbox_page_content .dropdown-toggle-split::after,
  #saasbox_page_content .dropup .dropdown-toggle-split::after,
  #saasbox_page_content .dropright .dropdown-toggle-split::after {
    margin-left: 0;
  }
  #saasbox_page_content .dropleft .dropdown-toggle-split::before {
    margin-right: 0;
  }
  #saasbox_page_content .btn-sm+.dropdown-toggle-split,
  #saasbox_page_content .btn-group-sm>.btn+.dropdown-toggle-split,
  #saasbox_page_content .comment-list .btn-group-sm>.comment-reply-link+.dropdown-toggle-split,
  #saasbox_page_content .comment-form .btn-group-sm>.submit+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .btn-group-sm>a.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .btn-group-sm>input.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .btn-group-sm>button.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce #respond .btn-group-sm>input#submit+.dropdown-toggle-split,
  #saasbox_page_content .btn-group-sm>.wpcf7-submit+.dropdown-toggle-split,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn+.dropdown-toggle-split,
  #saasbox_page_content .comment-list .input-group-sm>.input-group-prepend>.comment-reply-link+.dropdown-toggle-split,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-prepend>.submit+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>a.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>input.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>button.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-prepend>a.checkout-button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-prepend>input#submit+.dropdown-toggle-split,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.wpcf7-submit+.dropdown-toggle-split,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn+.dropdown-toggle-split,
  #saasbox_page_content .comment-list .input-group-sm>.input-group-append>.comment-reply-link+.dropdown-toggle-split,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-append>.submit+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>a.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>input.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>button.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-append>a.checkout-button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-append>input#submit+.dropdown-toggle-split,
  #saasbox_page_content .input-group-sm>.input-group-append>.wpcf7-submit+.dropdown-toggle-split,
  #saasbox_page_content .comment-list .comment-reply-link+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem;
  }
  #saasbox_page_content .btn-lg+.dropdown-toggle-split,
  #saasbox_page_content .btn-group-lg>.btn+.dropdown-toggle-split,
  #saasbox_page_content .comment-list .btn-group-lg>.comment-reply-link+.dropdown-toggle-split,
  #saasbox_page_content .comment-form .btn-group-lg>.submit+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .btn-group-lg>a.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .btn-group-lg>input.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .btn-group-lg>button.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce #respond .btn-group-lg>input#submit+.dropdown-toggle-split,
  #saasbox_page_content .btn-group-lg>.wpcf7-submit+.dropdown-toggle-split,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn+.dropdown-toggle-split,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-prepend>.comment-reply-link+.dropdown-toggle-split,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-prepend>.submit+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>a.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>input.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>button.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-prepend>a.checkout-button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-prepend>input#submit+.dropdown-toggle-split,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.wpcf7-submit+.dropdown-toggle-split,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn+.dropdown-toggle-split,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-append>.comment-reply-link+.dropdown-toggle-split,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-append>.submit+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>a.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>input.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>button.button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-append>a.checkout-button+.dropdown-toggle-split,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-append>input#submit+.dropdown-toggle-split,
  #saasbox_page_content .input-group-lg>.input-group-append>.wpcf7-submit+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem;
  }
  #saasbox_page_content .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
  }
  #saasbox_page_content .btn-group-vertical>.btn,
  #saasbox_page_content .comment-list .btn-group-vertical>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-vertical>.submit,
  #saasbox_page_content .woocommerce .btn-group-vertical>a.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>input.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input#submit,
  #saasbox_page_content .btn-group-vertical>.wpcf7-submit,
  #saasbox_page_content .btn-group-vertical>.btn-group {
    width: 100%;
  }
  #saasbox_page_content .btn-group-vertical>.btn:not(:first-child),
  #saasbox_page_content .comment-list .btn-group-vertical>.comment-reply-link:not(:first-child),
  #saasbox_page_content .comment-form .btn-group-vertical>.submit:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group-vertical>a.button:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group-vertical>input.button:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group-vertical>button.button:not(:first-child),
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:not(:first-child),
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input#submit:not(:first-child),
  #saasbox_page_content .btn-group-vertical>.wpcf7-submit:not(:first-child),
  #saasbox_page_content .btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
  }
  #saasbox_page_content .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .comment-list .btn-group-vertical>.comment-reply-link:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .comment-form .btn-group-vertical>.submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .btn-group-vertical>a.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .btn-group-vertical>input.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .btn-group-vertical>button.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input#submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .btn-group-vertical>.wpcf7-submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .btn-group-vertical>.btn-group:not(:last-child)>.btn,
  #saasbox_page_content .comment-list .btn-group-vertical>.btn-group:not(:last-child)>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-vertical>.btn-group:not(:last-child)>.submit,
  #saasbox_page_content .woocommerce .btn-group-vertical>.btn-group:not(:last-child)>a.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>.btn-group:not(:last-child)>input.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>.btn-group:not(:last-child)>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-group:not(:last-child)>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>.btn-group:not(:last-child)>input#submit,
  #saasbox_page_content .btn-group-vertical>.btn-group:not(:last-child)>.wpcf7-submit {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  #saasbox_page_content .btn-group-vertical>.btn:not(:first-child),
  #saasbox_page_content .comment-list .btn-group-vertical>.comment-reply-link:not(:first-child),
  #saasbox_page_content .comment-form .btn-group-vertical>.submit:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group-vertical>a.button:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group-vertical>input.button:not(:first-child),
  #saasbox_page_content .woocommerce .btn-group-vertical>button.button:not(:first-child),
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>a.checkout-button:not(:first-child),
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>input#submit:not(:first-child),
  #saasbox_page_content .btn-group-vertical>.wpcf7-submit:not(:first-child),
  #saasbox_page_content .btn-group-vertical>.btn-group:not(:first-child)>.btn,
  #saasbox_page_content .comment-list .btn-group-vertical>.btn-group:not(:first-child)>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-vertical>.btn-group:not(:first-child)>.submit,
  #saasbox_page_content .woocommerce .btn-group-vertical>.btn-group:not(:first-child)>a.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>.btn-group:not(:first-child)>input.button,
  #saasbox_page_content .woocommerce .btn-group-vertical>.btn-group:not(:first-child)>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-vertical>.btn-group:not(:first-child)>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-vertical>.btn-group:not(:first-child)>input#submit,
  #saasbox_page_content .btn-group-vertical>.btn-group:not(:first-child)>.wpcf7-submit {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  #saasbox_page_content .btn-group-toggle>.btn,
  #saasbox_page_content .comment-list .btn-group-toggle>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-toggle>.submit,
  #saasbox_page_content .woocommerce .btn-group-toggle>a.button,
  #saasbox_page_content .woocommerce .btn-group-toggle>input.button,
  #saasbox_page_content .woocommerce .btn-group-toggle>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-toggle>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-toggle>input#submit,
  #saasbox_page_content .btn-group-toggle>.wpcf7-submit,
  #saasbox_page_content .btn-group-toggle>.btn-group>.btn,
  #saasbox_page_content .comment-list .btn-group-toggle>.btn-group>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-toggle>.btn-group>.submit,
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>a.button,
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>input.button,
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-toggle>.btn-group>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-toggle>.btn-group>input#submit,
  #saasbox_page_content .btn-group-toggle>.btn-group>.wpcf7-submit {
    margin-bottom: 0;
  }
  #saasbox_page_content .btn-group-toggle>.btn input[type="radio"],
  #saasbox_page_content .comment-list .btn-group-toggle>.comment-reply-link input[type="radio"],
  #saasbox_page_content .comment-form .btn-group-toggle>.submit input[type="radio"],
  #saasbox_page_content .woocommerce .btn-group-toggle>a.button input[type="radio"],
  #saasbox_page_content .woocommerce .btn-group-toggle>input.button input[type="radio"],
  #saasbox_page_content .woocommerce .btn-group-toggle>button.button input[type="radio"],
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-toggle>a.checkout-button input[type="radio"],
  #saasbox_page_content .woocommerce #respond .btn-group-toggle>input#submit input[type="radio"],
  #saasbox_page_content .btn-group-toggle>.wpcf7-submit input[type="radio"],
  #saasbox_page_content .btn-group-toggle>.btn input[type="checkbox"],
  #saasbox_page_content .comment-list .btn-group-toggle>.comment-reply-link input[type="checkbox"],
  #saasbox_page_content .comment-form .btn-group-toggle>.submit input[type="checkbox"],
  #saasbox_page_content .woocommerce .btn-group-toggle>a.button input[type="checkbox"],
  #saasbox_page_content .woocommerce .btn-group-toggle>input.button input[type="checkbox"],
  #saasbox_page_content .woocommerce .btn-group-toggle>button.button input[type="checkbox"],
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-toggle>a.checkout-button input[type="checkbox"],
  #saasbox_page_content .woocommerce #respond .btn-group-toggle>input#submit input[type="checkbox"],
  #saasbox_page_content .btn-group-toggle>.wpcf7-submit input[type="checkbox"],
  #saasbox_page_content .btn-group-toggle>.btn-group>.btn input[type="radio"],
  #saasbox_page_content .comment-list .btn-group-toggle>.btn-group>.comment-reply-link input[type="radio"],
  #saasbox_page_content .comment-form .btn-group-toggle>.btn-group>.submit input[type="radio"],
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>a.button input[type="radio"],
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>input.button input[type="radio"],
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>button.button input[type="radio"],
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-toggle>.btn-group>a.checkout-button input[type="radio"],
  #saasbox_page_content .woocommerce #respond .btn-group-toggle>.btn-group>input#submit input[type="radio"],
  #saasbox_page_content .btn-group-toggle>.btn-group>.wpcf7-submit input[type="radio"],
  #saasbox_page_content .btn-group-toggle>.btn-group>.btn input[type="checkbox"],
  #saasbox_page_content .comment-list .btn-group-toggle>.btn-group>.comment-reply-link input[type="checkbox"],
  #saasbox_page_content .comment-form .btn-group-toggle>.btn-group>.submit input[type="checkbox"],
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>a.button input[type="checkbox"],
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>input.button input[type="checkbox"],
  #saasbox_page_content .woocommerce .btn-group-toggle>.btn-group>button.button input[type="checkbox"],
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-toggle>.btn-group>a.checkout-button input[type="checkbox"],
  #saasbox_page_content .woocommerce #respond .btn-group-toggle>.btn-group>input#submit input[type="checkbox"],
  #saasbox_page_content .btn-group-toggle>.btn-group>.wpcf7-submit input[type="checkbox"] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
  }
  #saasbox_page_content .input-group {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%;
  }
  #saasbox_page_content .input-group>.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field,
  #saasbox_page_content .input-group>.wpcf7-text,
  #saasbox_page_content .input-group>.wpcf7-date,
  #saasbox_page_content .input-group>.wpcf7-quiz,
  #saasbox_page_content .input-group>.wpcf7-number,
  #saasbox_page_content .input-group>.wpcf7-select,
  #saasbox_page_content .input-group>.wpcf7-textarea,
  #saasbox_page_content .input-group>.form-control-plaintext,
  #saasbox_page_content .input-group>.custom-select,
  #saasbox_page_content .input-group>.custom-file {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }
  #saasbox_page_content .input-group>.form-control+.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.form-control,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.form-control,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.form-control,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.form-control,
  #saasbox_page_content .input-group>.wpcf7-text+.form-control,
  #saasbox_page_content .input-group>.wpcf7-date+.form-control,
  #saasbox_page_content .input-group>.wpcf7-quiz+.form-control,
  #saasbox_page_content .input-group>.wpcf7-number+.form-control,
  #saasbox_page_content .input-group>.wpcf7-select+.form-control,
  #saasbox_page_content .input-group>.wpcf7-textarea+.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.form-control+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-cart #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content #add_payment_method .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-checkout #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content #add_payment_method .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown #add_payment_method table.cart td.actions .coupon .input-group>.select2-search__field+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.wpcf7-text+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.wpcf7-date+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.wpcf7-quiz+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.wpcf7-number+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.wpcf7-select+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.wpcf7-textarea+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.form-control+.input-text,
  #saasbox_page_content #add_payment_method .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-cart #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-checkout .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-cart .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .woocommerce-cart table.cart td.actions .coupon .input-group>.select2-search__field+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.wpcf7-text+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.wpcf7-date+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.wpcf7-quiz+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.wpcf7-number+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.wpcf7-select+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.wpcf7-textarea+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.form-control+.input-text,
  #saasbox_page_content #add_payment_method .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-checkout #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-cart .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-checkout .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .woocommerce-checkout table.cart td.actions .coupon .input-group>.select2-search__field+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.wpcf7-text+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.wpcf7-date+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.wpcf7-quiz+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.wpcf7-number+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.wpcf7-select+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.wpcf7-textarea+.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.form-control+.select2-search__field,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .select2-container--default .select2-search--dropdown .input-group>.input-text+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.select2-search__field,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .select2-container--default .select2-search--dropdown .input-group>.input-text+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.select2-search__field,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .select2-container--default .select2-search--dropdown .input-group>.input-text+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.wpcf7-text+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.wpcf7-date+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.wpcf7-quiz+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.wpcf7-number+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.wpcf7-select+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.wpcf7-textarea+.select2-search__field,
  #saasbox_page_content .input-group>.form-control+.wpcf7-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.wpcf7-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.wpcf7-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.wpcf7-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.wpcf7-text,
  #saasbox_page_content .input-group>.wpcf7-text+.wpcf7-text,
  #saasbox_page_content .input-group>.wpcf7-date+.wpcf7-text,
  #saasbox_page_content .input-group>.wpcf7-quiz+.wpcf7-text,
  #saasbox_page_content .input-group>.wpcf7-number+.wpcf7-text,
  #saasbox_page_content .input-group>.wpcf7-select+.wpcf7-text,
  #saasbox_page_content .input-group>.wpcf7-textarea+.wpcf7-text,
  #saasbox_page_content .input-group>.form-control+.wpcf7-date,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.wpcf7-date,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.wpcf7-date,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.wpcf7-date,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.wpcf7-date,
  #saasbox_page_content .input-group>.wpcf7-text+.wpcf7-date,
  #saasbox_page_content .input-group>.wpcf7-date+.wpcf7-date,
  #saasbox_page_content .input-group>.wpcf7-quiz+.wpcf7-date,
  #saasbox_page_content .input-group>.wpcf7-number+.wpcf7-date,
  #saasbox_page_content .input-group>.wpcf7-select+.wpcf7-date,
  #saasbox_page_content .input-group>.wpcf7-textarea+.wpcf7-date,
  #saasbox_page_content .input-group>.form-control+.wpcf7-quiz,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.wpcf7-quiz,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.wpcf7-quiz,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.wpcf7-quiz,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.wpcf7-quiz,
  #saasbox_page_content .input-group>.wpcf7-text+.wpcf7-quiz,
  #saasbox_page_content .input-group>.wpcf7-date+.wpcf7-quiz,
  #saasbox_page_content .input-group>.wpcf7-quiz+.wpcf7-quiz,
  #saasbox_page_content .input-group>.wpcf7-number+.wpcf7-quiz,
  #saasbox_page_content .input-group>.wpcf7-select+.wpcf7-quiz,
  #saasbox_page_content .input-group>.wpcf7-textarea+.wpcf7-quiz,
  #saasbox_page_content .input-group>.form-control+.wpcf7-number,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.wpcf7-number,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.wpcf7-number,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.wpcf7-number,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.wpcf7-number,
  #saasbox_page_content .input-group>.wpcf7-text+.wpcf7-number,
  #saasbox_page_content .input-group>.wpcf7-date+.wpcf7-number,
  #saasbox_page_content .input-group>.wpcf7-quiz+.wpcf7-number,
  #saasbox_page_content .input-group>.wpcf7-number+.wpcf7-number,
  #saasbox_page_content .input-group>.wpcf7-select+.wpcf7-number,
  #saasbox_page_content .input-group>.wpcf7-textarea+.wpcf7-number,
  #saasbox_page_content .input-group>.form-control+.wpcf7-select,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.wpcf7-select,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.wpcf7-select,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.wpcf7-select,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.wpcf7-select,
  #saasbox_page_content .input-group>.wpcf7-text+.wpcf7-select,
  #saasbox_page_content .input-group>.wpcf7-date+.wpcf7-select,
  #saasbox_page_content .input-group>.wpcf7-quiz+.wpcf7-select,
  #saasbox_page_content .input-group>.wpcf7-number+.wpcf7-select,
  #saasbox_page_content .input-group>.wpcf7-select+.wpcf7-select,
  #saasbox_page_content .input-group>.wpcf7-textarea+.wpcf7-select,
  #saasbox_page_content .input-group>.form-control+.wpcf7-textarea,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.wpcf7-textarea,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.wpcf7-textarea,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.wpcf7-textarea,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.wpcf7-textarea,
  #saasbox_page_content .input-group>.wpcf7-text+.wpcf7-textarea,
  #saasbox_page_content .input-group>.wpcf7-date+.wpcf7-textarea,
  #saasbox_page_content .input-group>.wpcf7-quiz+.wpcf7-textarea,
  #saasbox_page_content .input-group>.wpcf7-number+.wpcf7-textarea,
  #saasbox_page_content .input-group>.wpcf7-select+.wpcf7-textarea,
  #saasbox_page_content .input-group>.wpcf7-textarea+.wpcf7-textarea,
  #saasbox_page_content .input-group>.form-control+.custom-select,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.custom-select,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.custom-select,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.custom-select,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.custom-select,
  #saasbox_page_content .input-group>.wpcf7-text+.custom-select,
  #saasbox_page_content .input-group>.wpcf7-date+.custom-select,
  #saasbox_page_content .input-group>.wpcf7-quiz+.custom-select,
  #saasbox_page_content .input-group>.wpcf7-number+.custom-select,
  #saasbox_page_content .input-group>.wpcf7-select+.custom-select,
  #saasbox_page_content .input-group>.wpcf7-textarea+.custom-select,
  #saasbox_page_content .input-group>.form-control+.custom-file,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text+.custom-file,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text+.custom-file,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text+.custom-file,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field+.custom-file,
  #saasbox_page_content .input-group>.wpcf7-text+.custom-file,
  #saasbox_page_content .input-group>.wpcf7-date+.custom-file,
  #saasbox_page_content .input-group>.wpcf7-quiz+.custom-file,
  #saasbox_page_content .input-group>.wpcf7-number+.custom-file,
  #saasbox_page_content .input-group>.wpcf7-select+.custom-file,
  #saasbox_page_content .input-group>.wpcf7-textarea+.custom-file,
  #saasbox_page_content .input-group>.form-control-plaintext+.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.form-control-plaintext+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.form-control-plaintext+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.form-control-plaintext+.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.form-control-plaintext+.select2-search__field,
  #saasbox_page_content .input-group>.form-control-plaintext+.wpcf7-text,
  #saasbox_page_content .input-group>.form-control-plaintext+.wpcf7-date,
  #saasbox_page_content .input-group>.form-control-plaintext+.wpcf7-quiz,
  #saasbox_page_content .input-group>.form-control-plaintext+.wpcf7-number,
  #saasbox_page_content .input-group>.form-control-plaintext+.wpcf7-select,
  #saasbox_page_content .input-group>.form-control-plaintext+.wpcf7-textarea,
  #saasbox_page_content .input-group>.form-control-plaintext+.custom-select,
  #saasbox_page_content .input-group>.form-control-plaintext+.custom-file,
  #saasbox_page_content .input-group>.custom-select+.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.custom-select+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.custom-select+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.custom-select+.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.custom-select+.select2-search__field,
  #saasbox_page_content .input-group>.custom-select+.wpcf7-text,
  #saasbox_page_content .input-group>.custom-select+.wpcf7-date,
  #saasbox_page_content .input-group>.custom-select+.wpcf7-quiz,
  #saasbox_page_content .input-group>.custom-select+.wpcf7-number,
  #saasbox_page_content .input-group>.custom-select+.wpcf7-select,
  #saasbox_page_content .input-group>.custom-select+.wpcf7-textarea,
  #saasbox_page_content .input-group>.custom-select+.custom-select,
  #saasbox_page_content .input-group>.custom-select+.custom-file,
  #saasbox_page_content .input-group>.custom-file+.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.custom-file+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.custom-file+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.custom-file+.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.custom-file+.select2-search__field,
  #saasbox_page_content .input-group>.custom-file+.wpcf7-text,
  #saasbox_page_content .input-group>.custom-file+.wpcf7-date,
  #saasbox_page_content .input-group>.custom-file+.wpcf7-quiz,
  #saasbox_page_content .input-group>.custom-file+.wpcf7-number,
  #saasbox_page_content .input-group>.custom-file+.wpcf7-select,
  #saasbox_page_content .input-group>.custom-file+.wpcf7-textarea,
  #saasbox_page_content .input-group>.custom-file+.custom-select,
  #saasbox_page_content .input-group>.custom-file+.custom-file {
    margin-left: -1px;
  }
  #saasbox_page_content .input-group>.form-control:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field:focus,
  #saasbox_page_content .input-group>.wpcf7-text:focus,
  #saasbox_page_content .input-group>.wpcf7-date:focus,
  #saasbox_page_content .input-group>.wpcf7-quiz:focus,
  #saasbox_page_content .input-group>.wpcf7-number:focus,
  #saasbox_page_content .input-group>.wpcf7-select:focus,
  #saasbox_page_content .input-group>.wpcf7-textarea:focus,
  #saasbox_page_content .input-group>.custom-select:focus,
  #saasbox_page_content .input-group>.custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }
  #saasbox_page_content .input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
  }
  #saasbox_page_content .input-group>.form-control:not(:last-child),
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text:not(:last-child),
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text:not(:last-child),
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text:not(:last-child),
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field:not(:last-child),
  #saasbox_page_content .input-group>.wpcf7-text:not(:last-child),
  #saasbox_page_content .input-group>.wpcf7-date:not(:last-child),
  #saasbox_page_content .input-group>.wpcf7-quiz:not(:last-child),
  #saasbox_page_content .input-group>.wpcf7-number:not(:last-child),
  #saasbox_page_content .input-group>.wpcf7-select:not(:last-child),
  #saasbox_page_content .input-group>.wpcf7-textarea:not(:last-child),
  #saasbox_page_content .input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  #saasbox_page_content .input-group>.form-control:not(:first-child),
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group>.input-text:not(:first-child),
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group>.input-text:not(:first-child),
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group>.input-text:not(:first-child),
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group>.select2-search__field:not(:first-child),
  #saasbox_page_content .input-group>.wpcf7-text:not(:first-child),
  #saasbox_page_content .input-group>.wpcf7-date:not(:first-child),
  #saasbox_page_content .input-group>.wpcf7-quiz:not(:first-child),
  #saasbox_page_content .input-group>.wpcf7-number:not(:first-child),
  #saasbox_page_content .input-group>.wpcf7-select:not(:first-child),
  #saasbox_page_content .input-group>.wpcf7-textarea:not(:first-child),
  #saasbox_page_content .input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #saasbox_page_content .input-group>.custom-file {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }
  #saasbox_page_content .input-group>.custom-file:not(:last-child) .custom-file-label,
  #saasbox_page_content .input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  #saasbox_page_content .input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #saasbox_page_content .input-group-prepend,
  #saasbox_page_content .input-group-append {
    display: -webkit-box;
    display: flex;
  }
  #saasbox_page_content .input-group-prepend .btn,
  #saasbox_page_content .input-group-prepend .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .comment-reply-link,
  #saasbox_page_content .input-group-prepend .comment-form .submit,
  #saasbox_page_content .comment-form .input-group-prepend .submit,
  #saasbox_page_content .input-group-prepend .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-group-prepend a.button,
  #saasbox_page_content .input-group-prepend .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-group-prepend input.button,
  #saasbox_page_content .input-group-prepend .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-group-prepend button.button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit,
  #saasbox_page_content .input-group-prepend .wpcf7-submit,
  #saasbox_page_content .input-group-append .btn,
  #saasbox_page_content .input-group-append .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .comment-reply-link,
  #saasbox_page_content .input-group-append .comment-form .submit,
  #saasbox_page_content .comment-form .input-group-append .submit,
  #saasbox_page_content .input-group-append .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-group-append a.button,
  #saasbox_page_content .input-group-append .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-group-append input.button,
  #saasbox_page_content .input-group-append .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-group-append button.button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit,
  #saasbox_page_content .input-group-append .wpcf7-submit {
    position: relative;
    z-index: 2;
  }
  #saasbox_page_content .input-group-prepend .btn:focus,
  #saasbox_page_content .input-group-prepend .comment-list .comment-reply-link:focus,
  #saasbox_page_content .comment-list .input-group-prepend .comment-reply-link:focus,
  #saasbox_page_content .input-group-prepend .comment-form .submit:focus,
  #saasbox_page_content .comment-form .input-group-prepend .submit:focus,
  #saasbox_page_content .input-group-prepend .woocommerce a.button:focus,
  #saasbox_page_content .woocommerce .input-group-prepend a.button:focus,
  #saasbox_page_content .input-group-prepend .woocommerce input.button:focus,
  #saasbox_page_content .woocommerce .input-group-prepend input.button:focus,
  #saasbox_page_content .input-group-prepend .woocommerce button.button:focus,
  #saasbox_page_content .woocommerce .input-group-prepend button.button:focus,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button:focus,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit:focus,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit:focus,
  #saasbox_page_content .input-group-prepend .wpcf7-submit:focus,
  #saasbox_page_content .input-group-append .btn:focus,
  #saasbox_page_content .input-group-append .comment-list .comment-reply-link:focus,
  #saasbox_page_content .comment-list .input-group-append .comment-reply-link:focus,
  #saasbox_page_content .input-group-append .comment-form .submit:focus,
  #saasbox_page_content .comment-form .input-group-append .submit:focus,
  #saasbox_page_content .input-group-append .woocommerce a.button:focus,
  #saasbox_page_content .woocommerce .input-group-append a.button:focus,
  #saasbox_page_content .input-group-append .woocommerce input.button:focus,
  #saasbox_page_content .woocommerce .input-group-append input.button:focus,
  #saasbox_page_content .input-group-append .woocommerce button.button:focus,
  #saasbox_page_content .woocommerce .input-group-append button.button:focus,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:focus,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button:focus,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit:focus,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit:focus,
  #saasbox_page_content .input-group-append .wpcf7-submit:focus {
    z-index: 3;
  }
  #saasbox_page_content .input-group-prepend .btn+.btn,
  #saasbox_page_content .input-group-prepend .comment-list .comment-reply-link+.btn,
  #saasbox_page_content .comment-list .input-group-prepend .comment-reply-link+.btn,
  #saasbox_page_content .input-group-prepend .comment-form .submit+.btn,
  #saasbox_page_content .comment-form .input-group-prepend .submit+.btn,
  #saasbox_page_content .input-group-prepend .woocommerce a.button+.btn,
  #saasbox_page_content .woocommerce .input-group-prepend a.button+.btn,
  #saasbox_page_content .input-group-prepend .woocommerce input.button+.btn,
  #saasbox_page_content .woocommerce .input-group-prepend input.button+.btn,
  #saasbox_page_content .input-group-prepend .woocommerce button.button+.btn,
  #saasbox_page_content .woocommerce .input-group-prepend button.button+.btn,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.btn,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+.btn,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit+.btn,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit+.btn,
  #saasbox_page_content .input-group-prepend .wpcf7-submit+.btn,
  #saasbox_page_content .input-group-prepend .comment-list .btn+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .btn+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .comment-list .comment-reply-link+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .comment-reply-link+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .comment-form .comment-list .submit+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .comment-form .submit+.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-prepend .comment-list .submit+.comment-reply-link,
  #saasbox_page_content .comment-list .comment-form .input-group-prepend .submit+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .woocommerce .comment-list a.button+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce a.button+.comment-reply-link,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-list a.button+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce .input-group-prepend a.button+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .woocommerce .comment-list input.button+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce input.button+.comment-reply-link,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-list input.button+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce .input-group-prepend input.button+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .woocommerce .comment-list button.button+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce button.button+.comment-reply-link,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-list button.button+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce .input-group-prepend button.button+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .comment-list a.checkout-button+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.comment-reply-link,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .comment-list a.checkout-button+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .woocommerce #respond .comment-list input#submit+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce #respond input#submit+.comment-reply-link,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .comment-list input#submit+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce #respond .input-group-prepend input#submit+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .comment-list .wpcf7-submit+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .wpcf7-submit+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .comment-form .btn+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .btn+.submit,
  #saasbox_page_content .input-group-prepend .comment-list .comment-form .comment-reply-link+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .comment-list .comment-reply-link+.submit,
  #saasbox_page_content .comment-list .input-group-prepend .comment-form .comment-reply-link+.submit,
  #saasbox_page_content .comment-form .comment-list .input-group-prepend .comment-reply-link+.submit,
  #saasbox_page_content .input-group-prepend .comment-form .submit+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .submit+.submit,
  #saasbox_page_content .input-group-prepend .woocommerce .comment-form a.button+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce a.button+.submit,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-form a.button+.submit,
  #saasbox_page_content .comment-form .woocommerce .input-group-prepend a.button+.submit,
  #saasbox_page_content .input-group-prepend .woocommerce .comment-form input.button+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce input.button+.submit,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-form input.button+.submit,
  #saasbox_page_content .comment-form .woocommerce .input-group-prepend input.button+.submit,
  #saasbox_page_content .input-group-prepend .woocommerce .comment-form button.button+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce button.button+.submit,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-form button.button+.submit,
  #saasbox_page_content .comment-form .woocommerce .input-group-prepend button.button+.submit,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .comment-form a.checkout-button+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.submit,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .comment-form a.checkout-button+.submit,
  #saasbox_page_content .comment-form .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+.submit,
  #saasbox_page_content .input-group-prepend .woocommerce #respond .comment-form input#submit+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce #respond input#submit+.submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .comment-form input#submit+.submit,
  #saasbox_page_content .comment-form .woocommerce #respond .input-group-prepend input#submit+.submit,
  #saasbox_page_content .input-group-prepend .comment-form .wpcf7-submit+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .wpcf7-submit+.submit,
  #saasbox_page_content .input-group-prepend .woocommerce .btn+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend .btn+a.button,
  #saasbox_page_content .input-group-prepend .comment-list .woocommerce .comment-reply-link+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-list .comment-reply-link+a.button,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce .comment-reply-link+a.button,
  #saasbox_page_content .woocommerce .comment-list .input-group-prepend .comment-reply-link+a.button,
  #saasbox_page_content .input-group-prepend .comment-form .woocommerce .submit+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-form .submit+a.button,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce .submit+a.button,
  #saasbox_page_content .woocommerce .comment-form .input-group-prepend .submit+a.button,
  #saasbox_page_content .input-group-prepend .woocommerce a.button+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend a.button+a.button,
  #saasbox_page_content .input-group-prepend .woocommerce input.button+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend input.button+a.button,
  #saasbox_page_content .input-group-prepend .woocommerce button.button+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend button.button+a.button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .woocommerce a.checkout-button+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+a.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .woocommerce a.checkout-button+a.button,
  #saasbox_page_content .woocommerce .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+a.button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit+a.button,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit+a.button,
  #saasbox_page_content .input-group-prepend .woocommerce .wpcf7-submit+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend .wpcf7-submit+a.button,
  #saasbox_page_content .input-group-prepend .woocommerce .btn+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend .btn+input.button,
  #saasbox_page_content .input-group-prepend .comment-list .woocommerce .comment-reply-link+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-list .comment-reply-link+input.button,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce .comment-reply-link+input.button,
  #saasbox_page_content .woocommerce .comment-list .input-group-prepend .comment-reply-link+input.button,
  #saasbox_page_content .input-group-prepend .comment-form .woocommerce .submit+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-form .submit+input.button,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce .submit+input.button,
  #saasbox_page_content .woocommerce .comment-form .input-group-prepend .submit+input.button,
  #saasbox_page_content .input-group-prepend .woocommerce a.button+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend a.button+input.button,
  #saasbox_page_content .input-group-prepend .woocommerce input.button+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend input.button+input.button,
  #saasbox_page_content .input-group-prepend .woocommerce button.button+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend button.button+input.button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .woocommerce a.checkout-button+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+input.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .woocommerce a.checkout-button+input.button,
  #saasbox_page_content .woocommerce .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+input.button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit+input.button,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit+input.button,
  #saasbox_page_content .input-group-prepend .woocommerce .wpcf7-submit+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend .wpcf7-submit+input.button,
  #saasbox_page_content .input-group-prepend .woocommerce .btn+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend .btn+button.button,
  #saasbox_page_content .input-group-prepend .comment-list .woocommerce .comment-reply-link+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-list .comment-reply-link+button.button,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce .comment-reply-link+button.button,
  #saasbox_page_content .woocommerce .comment-list .input-group-prepend .comment-reply-link+button.button,
  #saasbox_page_content .input-group-prepend .comment-form .woocommerce .submit+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend .comment-form .submit+button.button,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce .submit+button.button,
  #saasbox_page_content .woocommerce .comment-form .input-group-prepend .submit+button.button,
  #saasbox_page_content .input-group-prepend .woocommerce a.button+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend a.button+button.button,
  #saasbox_page_content .input-group-prepend .woocommerce input.button+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend input.button+button.button,
  #saasbox_page_content .input-group-prepend .woocommerce button.button+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend button.button+button.button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .woocommerce a.checkout-button+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .woocommerce a.checkout-button+button.button,
  #saasbox_page_content .woocommerce .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+button.button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit+button.button,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit+button.button,
  #saasbox_page_content .input-group-prepend .woocommerce .wpcf7-submit+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend .wpcf7-submit+button.button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .btn+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .btn+a.checkout-button,
  #saasbox_page_content .input-group-prepend .comment-list .woocommerce-cart .wc-proceed-to-checkout .comment-reply-link+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .comment-list .comment-reply-link+a.checkout-button,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .comment-reply-link+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .comment-list .input-group-prepend .comment-reply-link+a.checkout-button,
  #saasbox_page_content .input-group-prepend .comment-form .woocommerce-cart .wc-proceed-to-checkout .submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .comment-form .submit+a.checkout-button,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .comment-form .input-group-prepend .submit+a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce .woocommerce-cart .wc-proceed-to-checkout a.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .woocommerce a.button+a.checkout-button,
  #saasbox_page_content .woocommerce .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .woocommerce .input-group-prepend a.button+a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce .woocommerce-cart .wc-proceed-to-checkout input.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .woocommerce input.button+a.checkout-button,
  #saasbox_page_content .woocommerce .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout input.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .woocommerce .input-group-prepend input.button+a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce .woocommerce-cart .wc-proceed-to-checkout button.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .woocommerce button.button+a.checkout-button,
  #saasbox_page_content .woocommerce .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout button.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .woocommerce .input-group-prepend button.button+a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond .woocommerce-cart .wc-proceed-to-checkout input#submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .woocommerce #respond input#submit+a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout input#submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .woocommerce #respond .input-group-prepend input#submit+a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .wpcf7-submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .wpcf7-submit+a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond .btn+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .btn+input#submit,
  #saasbox_page_content .input-group-prepend .comment-list .woocommerce #respond .comment-reply-link+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .comment-list .comment-reply-link+input#submit,
  #saasbox_page_content .comment-list .input-group-prepend .woocommerce #respond .comment-reply-link+input#submit,
  #saasbox_page_content .woocommerce #respond .comment-list .input-group-prepend .comment-reply-link+input#submit,
  #saasbox_page_content .input-group-prepend .comment-form .woocommerce #respond .submit+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .comment-form .submit+input#submit,
  #saasbox_page_content .comment-form .input-group-prepend .woocommerce #respond .submit+input#submit,
  #saasbox_page_content .woocommerce #respond .comment-form .input-group-prepend .submit+input#submit,
  #saasbox_page_content .input-group-prepend .woocommerce #respond a.button+input#submit,
  #saasbox_page_content .woocommerce .input-group-prepend #respond a.button+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend a.button+input#submit,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input.button+input#submit,
  #saasbox_page_content .woocommerce .input-group-prepend #respond input.button+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input.button+input#submit,
  #saasbox_page_content .input-group-prepend .woocommerce #respond button.button+input#submit,
  #saasbox_page_content .woocommerce .input-group-prepend #respond button.button+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend button.button+input#submit,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .woocommerce #respond a.checkout-button+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+input#submit,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .woocommerce #respond a.checkout-button+input#submit,
  #saasbox_page_content .woocommerce #respond .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+input#submit,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit+input#submit,
  #saasbox_page_content .input-group-prepend .woocommerce #respond .wpcf7-submit+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .wpcf7-submit+input#submit,
  #saasbox_page_content .input-group-prepend .btn+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .comment-list .comment-reply-link+.wpcf7-submit,
  #saasbox_page_content .comment-list .input-group-prepend .comment-reply-link+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .comment-form .submit+.wpcf7-submit,
  #saasbox_page_content .comment-form .input-group-prepend .submit+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .woocommerce a.button+.wpcf7-submit,
  #saasbox_page_content .woocommerce .input-group-prepend a.button+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .woocommerce input.button+.wpcf7-submit,
  #saasbox_page_content .woocommerce .input-group-prepend input.button+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .woocommerce button.button+.wpcf7-submit,
  #saasbox_page_content .woocommerce .input-group-prepend button.button+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.wpcf7-submit,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit+.wpcf7-submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .wpcf7-submit+.wpcf7-submit,
  #saasbox_page_content .input-group-prepend .btn+.input-group-text,
  #saasbox_page_content .input-group-prepend .comment-list .comment-reply-link+.input-group-text,
  #saasbox_page_content .comment-list .input-group-prepend .comment-reply-link+.input-group-text,
  #saasbox_page_content .input-group-prepend .comment-form .submit+.input-group-text,
  #saasbox_page_content .comment-form .input-group-prepend .submit+.input-group-text,
  #saasbox_page_content .input-group-prepend .woocommerce a.button+.input-group-text,
  #saasbox_page_content .woocommerce .input-group-prepend a.button+.input-group-text,
  #saasbox_page_content .input-group-prepend .woocommerce input.button+.input-group-text,
  #saasbox_page_content .woocommerce .input-group-prepend input.button+.input-group-text,
  #saasbox_page_content .input-group-prepend .woocommerce button.button+.input-group-text,
  #saasbox_page_content .woocommerce .input-group-prepend button.button+.input-group-text,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.input-group-text,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button+.input-group-text,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit+.input-group-text,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit+.input-group-text,
  #saasbox_page_content .input-group-prepend .wpcf7-submit+.input-group-text,
  #saasbox_page_content .input-group-prepend .input-group-text+.input-group-text,
  #saasbox_page_content .input-group-prepend .input-group-text+.btn,
  #saasbox_page_content .input-group-prepend .comment-list .input-group-text+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .input-group-text+.comment-reply-link,
  #saasbox_page_content .input-group-prepend .comment-form .input-group-text+.submit,
  #saasbox_page_content .comment-form .input-group-prepend .input-group-text+.submit,
  #saasbox_page_content .input-group-prepend .woocommerce .input-group-text+a.button,
  #saasbox_page_content .woocommerce .input-group-prepend .input-group-text+a.button,
  #saasbox_page_content .input-group-prepend .woocommerce .input-group-text+input.button,
  #saasbox_page_content .woocommerce .input-group-prepend .input-group-text+input.button,
  #saasbox_page_content .input-group-prepend .woocommerce .input-group-text+button.button,
  #saasbox_page_content .woocommerce .input-group-prepend .input-group-text+button.button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout .input-group-text+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend .input-group-text+a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond .input-group-text+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend .input-group-text+input#submit,
  #saasbox_page_content .input-group-prepend .input-group-text+.wpcf7-submit,
  #saasbox_page_content .input-group-append .btn+.btn,
  #saasbox_page_content .input-group-append .comment-list .comment-reply-link+.btn,
  #saasbox_page_content .comment-list .input-group-append .comment-reply-link+.btn,
  #saasbox_page_content .input-group-append .comment-form .submit+.btn,
  #saasbox_page_content .comment-form .input-group-append .submit+.btn,
  #saasbox_page_content .input-group-append .woocommerce a.button+.btn,
  #saasbox_page_content .woocommerce .input-group-append a.button+.btn,
  #saasbox_page_content .input-group-append .woocommerce input.button+.btn,
  #saasbox_page_content .woocommerce .input-group-append input.button+.btn,
  #saasbox_page_content .input-group-append .woocommerce button.button+.btn,
  #saasbox_page_content .woocommerce .input-group-append button.button+.btn,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.btn,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+.btn,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit+.btn,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit+.btn,
  #saasbox_page_content .input-group-append .wpcf7-submit+.btn,
  #saasbox_page_content .input-group-append .comment-list .btn+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .btn+.comment-reply-link,
  #saasbox_page_content .input-group-append .comment-list .comment-reply-link+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .comment-reply-link+.comment-reply-link,
  #saasbox_page_content .input-group-append .comment-form .comment-list .submit+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .comment-form .submit+.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-append .comment-list .submit+.comment-reply-link,
  #saasbox_page_content .comment-list .comment-form .input-group-append .submit+.comment-reply-link,
  #saasbox_page_content .input-group-append .woocommerce .comment-list a.button+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .woocommerce a.button+.comment-reply-link,
  #saasbox_page_content .woocommerce .input-group-append .comment-list a.button+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce .input-group-append a.button+.comment-reply-link,
  #saasbox_page_content .input-group-append .woocommerce .comment-list input.button+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .woocommerce input.button+.comment-reply-link,
  #saasbox_page_content .woocommerce .input-group-append .comment-list input.button+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce .input-group-append input.button+.comment-reply-link,
  #saasbox_page_content .input-group-append .woocommerce .comment-list button.button+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .woocommerce button.button+.comment-reply-link,
  #saasbox_page_content .woocommerce .input-group-append .comment-list button.button+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce .input-group-append button.button+.comment-reply-link,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .comment-list a.checkout-button+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.comment-reply-link,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .comment-list a.checkout-button+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+.comment-reply-link,
  #saasbox_page_content .input-group-append .woocommerce #respond .comment-list input#submit+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .woocommerce #respond input#submit+.comment-reply-link,
  #saasbox_page_content .woocommerce #respond .input-group-append .comment-list input#submit+.comment-reply-link,
  #saasbox_page_content .comment-list .woocommerce #respond .input-group-append input#submit+.comment-reply-link,
  #saasbox_page_content .input-group-append .comment-list .wpcf7-submit+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .wpcf7-submit+.comment-reply-link,
  #saasbox_page_content .input-group-append .comment-form .btn+.submit,
  #saasbox_page_content .comment-form .input-group-append .btn+.submit,
  #saasbox_page_content .input-group-append .comment-list .comment-form .comment-reply-link+.submit,
  #saasbox_page_content .comment-form .input-group-append .comment-list .comment-reply-link+.submit,
  #saasbox_page_content .comment-list .input-group-append .comment-form .comment-reply-link+.submit,
  #saasbox_page_content .comment-form .comment-list .input-group-append .comment-reply-link+.submit,
  #saasbox_page_content .input-group-append .comment-form .submit+.submit,
  #saasbox_page_content .comment-form .input-group-append .submit+.submit,
  #saasbox_page_content .input-group-append .woocommerce .comment-form a.button+.submit,
  #saasbox_page_content .comment-form .input-group-append .woocommerce a.button+.submit,
  #saasbox_page_content .woocommerce .input-group-append .comment-form a.button+.submit,
  #saasbox_page_content .comment-form .woocommerce .input-group-append a.button+.submit,
  #saasbox_page_content .input-group-append .woocommerce .comment-form input.button+.submit,
  #saasbox_page_content .comment-form .input-group-append .woocommerce input.button+.submit,
  #saasbox_page_content .woocommerce .input-group-append .comment-form input.button+.submit,
  #saasbox_page_content .comment-form .woocommerce .input-group-append input.button+.submit,
  #saasbox_page_content .input-group-append .woocommerce .comment-form button.button+.submit,
  #saasbox_page_content .comment-form .input-group-append .woocommerce button.button+.submit,
  #saasbox_page_content .woocommerce .input-group-append .comment-form button.button+.submit,
  #saasbox_page_content .comment-form .woocommerce .input-group-append button.button+.submit,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .comment-form a.checkout-button+.submit,
  #saasbox_page_content .comment-form .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.submit,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .comment-form a.checkout-button+.submit,
  #saasbox_page_content .comment-form .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+.submit,
  #saasbox_page_content .input-group-append .woocommerce #respond .comment-form input#submit+.submit,
  #saasbox_page_content .comment-form .input-group-append .woocommerce #respond input#submit+.submit,
  #saasbox_page_content .woocommerce #respond .input-group-append .comment-form input#submit+.submit,
  #saasbox_page_content .comment-form .woocommerce #respond .input-group-append input#submit+.submit,
  #saasbox_page_content .input-group-append .comment-form .wpcf7-submit+.submit,
  #saasbox_page_content .comment-form .input-group-append .wpcf7-submit+.submit,
  #saasbox_page_content .input-group-append .woocommerce .btn+a.button,
  #saasbox_page_content .woocommerce .input-group-append .btn+a.button,
  #saasbox_page_content .input-group-append .comment-list .woocommerce .comment-reply-link+a.button,
  #saasbox_page_content .woocommerce .input-group-append .comment-list .comment-reply-link+a.button,
  #saasbox_page_content .comment-list .input-group-append .woocommerce .comment-reply-link+a.button,
  #saasbox_page_content .woocommerce .comment-list .input-group-append .comment-reply-link+a.button,
  #saasbox_page_content .input-group-append .comment-form .woocommerce .submit+a.button,
  #saasbox_page_content .woocommerce .input-group-append .comment-form .submit+a.button,
  #saasbox_page_content .comment-form .input-group-append .woocommerce .submit+a.button,
  #saasbox_page_content .woocommerce .comment-form .input-group-append .submit+a.button,
  #saasbox_page_content .input-group-append .woocommerce a.button+a.button,
  #saasbox_page_content .woocommerce .input-group-append a.button+a.button,
  #saasbox_page_content .input-group-append .woocommerce input.button+a.button,
  #saasbox_page_content .woocommerce .input-group-append input.button+a.button,
  #saasbox_page_content .input-group-append .woocommerce button.button+a.button,
  #saasbox_page_content .woocommerce .input-group-append button.button+a.button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .woocommerce a.checkout-button+a.button,
  #saasbox_page_content .woocommerce .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+a.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .woocommerce a.checkout-button+a.button,
  #saasbox_page_content .woocommerce .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+a.button,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit+a.button,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit+a.button,
  #saasbox_page_content .input-group-append .woocommerce .wpcf7-submit+a.button,
  #saasbox_page_content .woocommerce .input-group-append .wpcf7-submit+a.button,
  #saasbox_page_content .input-group-append .woocommerce .btn+input.button,
  #saasbox_page_content .woocommerce .input-group-append .btn+input.button,
  #saasbox_page_content .input-group-append .comment-list .woocommerce .comment-reply-link+input.button,
  #saasbox_page_content .woocommerce .input-group-append .comment-list .comment-reply-link+input.button,
  #saasbox_page_content .comment-list .input-group-append .woocommerce .comment-reply-link+input.button,
  #saasbox_page_content .woocommerce .comment-list .input-group-append .comment-reply-link+input.button,
  #saasbox_page_content .input-group-append .comment-form .woocommerce .submit+input.button,
  #saasbox_page_content .woocommerce .input-group-append .comment-form .submit+input.button,
  #saasbox_page_content .comment-form .input-group-append .woocommerce .submit+input.button,
  #saasbox_page_content .woocommerce .comment-form .input-group-append .submit+input.button,
  #saasbox_page_content .input-group-append .woocommerce a.button+input.button,
  #saasbox_page_content .woocommerce .input-group-append a.button+input.button,
  #saasbox_page_content .input-group-append .woocommerce input.button+input.button,
  #saasbox_page_content .woocommerce .input-group-append input.button+input.button,
  #saasbox_page_content .input-group-append .woocommerce button.button+input.button,
  #saasbox_page_content .woocommerce .input-group-append button.button+input.button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .woocommerce a.checkout-button+input.button,
  #saasbox_page_content .woocommerce .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+input.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .woocommerce a.checkout-button+input.button,
  #saasbox_page_content .woocommerce .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+input.button,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit+input.button,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit+input.button,
  #saasbox_page_content .input-group-append .woocommerce .wpcf7-submit+input.button,
  #saasbox_page_content .woocommerce .input-group-append .wpcf7-submit+input.button,
  #saasbox_page_content .input-group-append .woocommerce .btn+button.button,
  #saasbox_page_content .woocommerce .input-group-append .btn+button.button,
  #saasbox_page_content .input-group-append .comment-list .woocommerce .comment-reply-link+button.button,
  #saasbox_page_content .woocommerce .input-group-append .comment-list .comment-reply-link+button.button,
  #saasbox_page_content .comment-list .input-group-append .woocommerce .comment-reply-link+button.button,
  #saasbox_page_content .woocommerce .comment-list .input-group-append .comment-reply-link+button.button,
  #saasbox_page_content .input-group-append .comment-form .woocommerce .submit+button.button,
  #saasbox_page_content .woocommerce .input-group-append .comment-form .submit+button.button,
  #saasbox_page_content .comment-form .input-group-append .woocommerce .submit+button.button,
  #saasbox_page_content .woocommerce .comment-form .input-group-append .submit+button.button,
  #saasbox_page_content .input-group-append .woocommerce a.button+button.button,
  #saasbox_page_content .woocommerce .input-group-append a.button+button.button,
  #saasbox_page_content .input-group-append .woocommerce input.button+button.button,
  #saasbox_page_content .woocommerce .input-group-append input.button+button.button,
  #saasbox_page_content .input-group-append .woocommerce button.button+button.button,
  #saasbox_page_content .woocommerce .input-group-append button.button+button.button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .woocommerce a.checkout-button+button.button,
  #saasbox_page_content .woocommerce .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .woocommerce a.checkout-button+button.button,
  #saasbox_page_content .woocommerce .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+button.button,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit+button.button,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit+button.button,
  #saasbox_page_content .input-group-append .woocommerce .wpcf7-submit+button.button,
  #saasbox_page_content .woocommerce .input-group-append .wpcf7-submit+button.button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .btn+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .btn+a.checkout-button,
  #saasbox_page_content .input-group-append .comment-list .woocommerce-cart .wc-proceed-to-checkout .comment-reply-link+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .comment-list .comment-reply-link+a.checkout-button,
  #saasbox_page_content .comment-list .input-group-append .woocommerce-cart .wc-proceed-to-checkout .comment-reply-link+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .comment-list .input-group-append .comment-reply-link+a.checkout-button,
  #saasbox_page_content .input-group-append .comment-form .woocommerce-cart .wc-proceed-to-checkout .submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .comment-form .submit+a.checkout-button,
  #saasbox_page_content .comment-form .input-group-append .woocommerce-cart .wc-proceed-to-checkout .submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .comment-form .input-group-append .submit+a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce .woocommerce-cart .wc-proceed-to-checkout a.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .woocommerce a.button+a.checkout-button,
  #saasbox_page_content .woocommerce .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .woocommerce .input-group-append a.button+a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce .woocommerce-cart .wc-proceed-to-checkout input.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .woocommerce input.button+a.checkout-button,
  #saasbox_page_content .woocommerce .input-group-append .woocommerce-cart .wc-proceed-to-checkout input.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .woocommerce .input-group-append input.button+a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce .woocommerce-cart .wc-proceed-to-checkout button.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .woocommerce button.button+a.checkout-button,
  #saasbox_page_content .woocommerce .input-group-append .woocommerce-cart .wc-proceed-to-checkout button.button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .woocommerce .input-group-append button.button+a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce #respond .woocommerce-cart .wc-proceed-to-checkout input#submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .woocommerce #respond input#submit+a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-append .woocommerce-cart .wc-proceed-to-checkout input#submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .woocommerce #respond .input-group-append input#submit+a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .wpcf7-submit+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .wpcf7-submit+a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce #respond .btn+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append .btn+input#submit,
  #saasbox_page_content .input-group-append .comment-list .woocommerce #respond .comment-reply-link+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append .comment-list .comment-reply-link+input#submit,
  #saasbox_page_content .comment-list .input-group-append .woocommerce #respond .comment-reply-link+input#submit,
  #saasbox_page_content .woocommerce #respond .comment-list .input-group-append .comment-reply-link+input#submit,
  #saasbox_page_content .input-group-append .comment-form .woocommerce #respond .submit+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append .comment-form .submit+input#submit,
  #saasbox_page_content .comment-form .input-group-append .woocommerce #respond .submit+input#submit,
  #saasbox_page_content .woocommerce #respond .comment-form .input-group-append .submit+input#submit,
  #saasbox_page_content .input-group-append .woocommerce #respond a.button+input#submit,
  #saasbox_page_content .woocommerce .input-group-append #respond a.button+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append a.button+input#submit,
  #saasbox_page_content .input-group-append .woocommerce #respond input.button+input#submit,
  #saasbox_page_content .woocommerce .input-group-append #respond input.button+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append input.button+input#submit,
  #saasbox_page_content .input-group-append .woocommerce #respond button.button+input#submit,
  #saasbox_page_content .woocommerce .input-group-append #respond button.button+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append button.button+input#submit,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .woocommerce #respond a.checkout-button+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+input#submit,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .woocommerce #respond a.checkout-button+input#submit,
  #saasbox_page_content .woocommerce #respond .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+input#submit,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit+input#submit,
  #saasbox_page_content .input-group-append .woocommerce #respond .wpcf7-submit+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append .wpcf7-submit+input#submit,
  #saasbox_page_content .input-group-append .btn+.wpcf7-submit,
  #saasbox_page_content .input-group-append .comment-list .comment-reply-link+.wpcf7-submit,
  #saasbox_page_content .comment-list .input-group-append .comment-reply-link+.wpcf7-submit,
  #saasbox_page_content .input-group-append .comment-form .submit+.wpcf7-submit,
  #saasbox_page_content .comment-form .input-group-append .submit+.wpcf7-submit,
  #saasbox_page_content .input-group-append .woocommerce a.button+.wpcf7-submit,
  #saasbox_page_content .woocommerce .input-group-append a.button+.wpcf7-submit,
  #saasbox_page_content .input-group-append .woocommerce input.button+.wpcf7-submit,
  #saasbox_page_content .woocommerce .input-group-append input.button+.wpcf7-submit,
  #saasbox_page_content .input-group-append .woocommerce button.button+.wpcf7-submit,
  #saasbox_page_content .woocommerce .input-group-append button.button+.wpcf7-submit,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.wpcf7-submit,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+.wpcf7-submit,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit+.wpcf7-submit,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit+.wpcf7-submit,
  #saasbox_page_content .input-group-append .wpcf7-submit+.wpcf7-submit,
  #saasbox_page_content .input-group-append .btn+.input-group-text,
  #saasbox_page_content .input-group-append .comment-list .comment-reply-link+.input-group-text,
  #saasbox_page_content .comment-list .input-group-append .comment-reply-link+.input-group-text,
  #saasbox_page_content .input-group-append .comment-form .submit+.input-group-text,
  #saasbox_page_content .comment-form .input-group-append .submit+.input-group-text,
  #saasbox_page_content .input-group-append .woocommerce a.button+.input-group-text,
  #saasbox_page_content .woocommerce .input-group-append a.button+.input-group-text,
  #saasbox_page_content .input-group-append .woocommerce input.button+.input-group-text,
  #saasbox_page_content .woocommerce .input-group-append input.button+.input-group-text,
  #saasbox_page_content .input-group-append .woocommerce button.button+.input-group-text,
  #saasbox_page_content .woocommerce .input-group-append button.button+.input-group-text,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button+.input-group-text,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button+.input-group-text,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit+.input-group-text,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit+.input-group-text,
  #saasbox_page_content .input-group-append .wpcf7-submit+.input-group-text,
  #saasbox_page_content .input-group-append .input-group-text+.input-group-text,
  #saasbox_page_content .input-group-append .input-group-text+.btn,
  #saasbox_page_content .input-group-append .comment-list .input-group-text+.comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .input-group-text+.comment-reply-link,
  #saasbox_page_content .input-group-append .comment-form .input-group-text+.submit,
  #saasbox_page_content .comment-form .input-group-append .input-group-text+.submit,
  #saasbox_page_content .input-group-append .woocommerce .input-group-text+a.button,
  #saasbox_page_content .woocommerce .input-group-append .input-group-text+a.button,
  #saasbox_page_content .input-group-append .woocommerce .input-group-text+input.button,
  #saasbox_page_content .woocommerce .input-group-append .input-group-text+input.button,
  #saasbox_page_content .input-group-append .woocommerce .input-group-text+button.button,
  #saasbox_page_content .woocommerce .input-group-append .input-group-text+button.button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout .input-group-text+a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append .input-group-text+a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce #respond .input-group-text+input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append .input-group-text+input#submit,
  #saasbox_page_content .input-group-append .input-group-text+.wpcf7-submit {
    margin-left: -1px;
  }
  #saasbox_page_content .input-group-prepend {
    margin-right: -1px;
  }
  #saasbox_page_content .input-group-append {
    margin-left: -1px;
  }
  #saasbox_page_content .input-group-text {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.9;
    color: #999;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #eaeff4;
    border-radius: 2px;
  }
  #saasbox_page_content .input-group-text input[type="radio"],
  #saasbox_page_content .input-group-text input[type="checkbox"] {
    margin-top: 0;
  }
  #saasbox_page_content .input-group-lg>.form-control:not(textarea),
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group-lg>.input-text:not(textarea),
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group-lg>.input-text:not(textarea),
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group-lg>.input-text:not(textarea),
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group-lg>.select2-search__field:not(textarea),
  #saasbox_page_content .input-group-lg>.wpcf7-text:not(textarea),
  #saasbox_page_content .input-group-lg>.wpcf7-date:not(textarea),
  #saasbox_page_content .input-group-lg>.wpcf7-quiz:not(textarea),
  #saasbox_page_content .input-group-lg>.wpcf7-number:not(textarea),
  #saasbox_page_content .input-group-lg>.wpcf7-select:not(textarea),
  #saasbox_page_content .input-group-lg>.wpcf7-textarea:not(textarea),
  #saasbox_page_content .input-group-lg>.custom-select {
    height: calc(4.95em);
  }
  #saasbox_page_content .input-group-lg>.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group-lg>.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group-lg>.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group-lg>.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group-lg>.select2-search__field,
  #saasbox_page_content .input-group-lg>.wpcf7-text,
  #saasbox_page_content .input-group-lg>.wpcf7-date,
  #saasbox_page_content .input-group-lg>.wpcf7-quiz,
  #saasbox_page_content .input-group-lg>.wpcf7-number,
  #saasbox_page_content .input-group-lg>.wpcf7-select,
  #saasbox_page_content .input-group-lg>.wpcf7-textarea,
  #saasbox_page_content .input-group-lg>.custom-select,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.input-group-text,
  #saasbox_page_content .input-group-lg>.input-group-append>.input-group-text,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-prepend>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-prepend>.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>a.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>input.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-prepend>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-prepend>input#submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-append>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-append>.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>a.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>input.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-append>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-append>input#submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.wpcf7-submit {
    padding: .375rem 1rem;
    font-size: 1.0625rem;
    line-height: 2.2;
    border-radius: 2px;
  }
  #saasbox_page_content .input-group-sm>.form-control:not(textarea),
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group-sm>.input-text:not(textarea),
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group-sm>.input-text:not(textarea),
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group-sm>.input-text:not(textarea),
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group-sm>.select2-search__field:not(textarea),
  #saasbox_page_content .input-group-sm>.wpcf7-text:not(textarea),
  #saasbox_page_content .input-group-sm>.wpcf7-date:not(textarea),
  #saasbox_page_content .input-group-sm>.wpcf7-quiz:not(textarea),
  #saasbox_page_content .input-group-sm>.wpcf7-number:not(textarea),
  #saasbox_page_content .input-group-sm>.wpcf7-select:not(textarea),
  #saasbox_page_content .input-group-sm>.wpcf7-textarea:not(textarea),
  #saasbox_page_content .input-group-sm>.custom-select {
    height: calc(4.25em);
  }
  #saasbox_page_content .input-group-sm>.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group-sm>.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group-sm>.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group-sm>.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group-sm>.select2-search__field,
  #saasbox_page_content .input-group-sm>.wpcf7-text,
  #saasbox_page_content .input-group-sm>.wpcf7-date,
  #saasbox_page_content .input-group-sm>.wpcf7-quiz,
  #saasbox_page_content .input-group-sm>.wpcf7-number,
  #saasbox_page_content .input-group-sm>.wpcf7-select,
  #saasbox_page_content .input-group-sm>.wpcf7-textarea,
  #saasbox_page_content .input-group-sm>.custom-select,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.input-group-text,
  #saasbox_page_content .input-group-sm>.input-group-append>.input-group-text,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn,
  #saasbox_page_content .comment-list .input-group-sm>.input-group-prepend>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-prepend>.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>a.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>input.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-prepend>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-prepend>input#submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn,
  #saasbox_page_content .comment-list .input-group-sm>.input-group-append>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-append>.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>a.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>input.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-append>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-append>input#submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.wpcf7-submit {
    padding: .375rem .5rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: 2px;
  }
  #saasbox_page_content .input-group-lg>.custom-select,
  #saasbox_page_content .input-group-sm>.custom-select {
    padding-right: 1.75rem;
  }
  #saasbox_page_content .input-group>.input-group-prepend>.btn,
  #saasbox_page_content .comment-list .input-group>.input-group-prepend>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group>.input-group-prepend>.submit,
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend>a.button,
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend>input.button,
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group>.input-group-prepend>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group>.input-group-prepend>input#submit,
  #saasbox_page_content .input-group>.input-group-prepend>.wpcf7-submit,
  #saasbox_page_content .input-group>.input-group-prepend>.input-group-text,
  #saasbox_page_content .input-group>.input-group-append:not(:last-child)>.btn,
  #saasbox_page_content .comment-list .input-group>.input-group-append:not(:last-child)>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group>.input-group-append:not(:last-child)>.submit,
  #saasbox_page_content .woocommerce .input-group>.input-group-append:not(:last-child)>a.button,
  #saasbox_page_content .woocommerce .input-group>.input-group-append:not(:last-child)>input.button,
  #saasbox_page_content .woocommerce .input-group>.input-group-append:not(:last-child)>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group>.input-group-append:not(:last-child)>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group>.input-group-append:not(:last-child)>input#submit,
  #saasbox_page_content .input-group>.input-group-append:not(:last-child)>.wpcf7-submit,
  #saasbox_page_content .input-group>.input-group-append:not(:last-child)>.input-group-text,
  #saasbox_page_content .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .comment-list .input-group>.input-group-append:last-child>.comment-reply-link:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .comment-form .input-group>.input-group-append:last-child>.submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .input-group>.input-group-append:last-child>a.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .input-group>.input-group-append:last-child>input.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce .input-group>.input-group-append:last-child>button.button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group>.input-group-append:last-child>a.checkout-button:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .woocommerce #respond .input-group>.input-group-append:last-child>input#submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .input-group>.input-group-append:last-child>.wpcf7-submit:not(:last-child):not(.dropdown-toggle),
  #saasbox_page_content .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  #saasbox_page_content .input-group>.input-group-append>.btn,
  #saasbox_page_content .comment-list .input-group>.input-group-append>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group>.input-group-append>.submit,
  #saasbox_page_content .woocommerce .input-group>.input-group-append>a.button,
  #saasbox_page_content .woocommerce .input-group>.input-group-append>input.button,
  #saasbox_page_content .woocommerce .input-group>.input-group-append>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group>.input-group-append>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group>.input-group-append>input#submit,
  #saasbox_page_content .input-group>.input-group-append>.wpcf7-submit,
  #saasbox_page_content .input-group>.input-group-append>.input-group-text,
  #saasbox_page_content .input-group>.input-group-prepend:not(:first-child)>.btn,
  #saasbox_page_content .comment-list .input-group>.input-group-prepend:not(:first-child)>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group>.input-group-prepend:not(:first-child)>.submit,
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend:not(:first-child)>a.button,
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend:not(:first-child)>input.button,
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend:not(:first-child)>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group>.input-group-prepend:not(:first-child)>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group>.input-group-prepend:not(:first-child)>input#submit,
  #saasbox_page_content .input-group>.input-group-prepend:not(:first-child)>.wpcf7-submit,
  #saasbox_page_content .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
  #saasbox_page_content .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
  #saasbox_page_content .comment-list .input-group>.input-group-prepend:first-child>.comment-reply-link:not(:first-child),
  #saasbox_page_content .comment-form .input-group>.input-group-prepend:first-child>.submit:not(:first-child),
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend:first-child>a.button:not(:first-child),
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend:first-child>input.button:not(:first-child),
  #saasbox_page_content .woocommerce .input-group>.input-group-prepend:first-child>button.button:not(:first-child),
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group>.input-group-prepend:first-child>a.checkout-button:not(:first-child),
  #saasbox_page_content .woocommerce #respond .input-group>.input-group-prepend:first-child>input#submit:not(:first-child),
  #saasbox_page_content .input-group>.input-group-prepend:first-child>.wpcf7-submit:not(:first-child),
  #saasbox_page_content .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  #saasbox_page_content .custom-control {
    position: relative;
    display: block;
    min-height: 1.78125rem;
    padding-left: 1.625rem;
  }
  #saasbox_page_content .custom-control-inline {
    display: -webkit-inline-box;
    display: inline-flex;
    margin-right: 1rem;
  }
  #saasbox_page_content .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  #saasbox_page_content .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #fafbfb;
    background-color: #fafbfb;
  }
  #saasbox_page_content .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.045);
    box-shadow: 0 0 40px rgba(0,0,0,0.045);
  }
  #saasbox_page_content .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #f1f2f3;
  }
  #saasbox_page_content .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fafbfb;
    border-color: #fafbfb;
  }
  #saasbox_page_content .custom-control-input:disabled ~ .custom-control-label {
    color: #868e96;
  }
  #saasbox_page_content .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #fafbfb;
  }
  #saasbox_page_content .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
  #saasbox_page_content .custom-control-label::before {
    position: absolute;
    top: .32813rem;
    left: -1.625rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    pointer-events: none;
    content: "";
    background-color: #fafbfb;
    border: #adb5bd solid 1px;
  }
  #saasbox_page_content .custom-control-label::after {
    position: absolute;
    top: .32813rem;
    left: -1.625rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    content: "";
    background: no-repeat 1% 50%;
  }
  #saasbox_page_content .custom-checkbox .custom-control-label::before {
    border-radius: 0;
  }
  #saasbox_page_content .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
  }
  #saasbox_page_content .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #fafbfb;
    background-color: #fafbfb;
  }
  #saasbox_page_content .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: none;
  }
  #saasbox_page_content .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #fafbfb;
  }
  #saasbox_page_content .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: #fafbfb;
  }
  #saasbox_page_content .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  #saasbox_page_content .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
  }
  #saasbox_page_content .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #fafbfb;
  }
  #saasbox_page_content .custom-switch {
    padding-left: 2.46875rem;
  }
  #saasbox_page_content .custom-switch .custom-control-label::before {
    left: -2.46875rem;
    width: 1.96875rem;
    pointer-events: all;
    border-radius: .5625rem;
  }
  #saasbox_page_content .custom-switch .custom-control-label::after {
    top: calc(2.32813rem);
    left: calc(-0.46875rem);
    width: calc(-2.875rem);
    height: calc(-2.875rem);
    background-color: #adb5bd;
    border-radius: .5625rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .custom-switch .custom-control-label::after {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fafbfb;
    -webkit-transform: translateX(0.84375rem);
    transform: translateX(0.84375rem);
  }
  #saasbox_page_content .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #fafbfb;
  }
  #saasbox_page_content .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(4.65em);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: .9375rem;
    font-weight: 300;
    line-height: 1.9;
    color: #999;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0045'%3e%3cpathfill='%23343a40'd='M20L02h4zm05L03h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #eaeff4;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  #saasbox_page_content .custom-select:focus {
    border-color: #f1f2f3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(80,161,255,0.25);
    box-shadow: 0 0 0 0rem rgba(80,161,255,0.25);
  }
  #saasbox_page_content .custom-select:focus::-ms-value {
    color: #999;
    background-color: #fff;
  }
  #saasbox_page_content .custom-select[multiple],
  #saasbox_page_content .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: .75rem;
    background-image: none;
  }
  #saasbox_page_content .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef;
  }
  #saasbox_page_content .custom-select::-ms-expand {
    display: none;
  }
  #saasbox_page_content .custom-select-sm {
    height: calc(4.25em);
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: .5rem;
    font-size: .9375rem;
  }
  #saasbox_page_content .custom-select-lg {
    height: calc(4.95em);
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 1rem;
    font-size: 1.0625rem;
  }
  #saasbox_page_content .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(4.65em);
    margin-bottom: 0;
  }
  #saasbox_page_content .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(4.65em);
    margin: 0;
    opacity: 0;
  }
  #saasbox_page_content .custom-file-input:focus ~ .custom-file-label {
    border-color: #f1f2f3;
    -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.045);
    box-shadow: 0 0 40px rgba(0,0,0,0.045);
  }
  #saasbox_page_content .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef;
  }
  #saasbox_page_content .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }
  #saasbox_page_content .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }
  #saasbox_page_content .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(4.65em);
    padding: .375rem .75rem;
    font-weight: 300;
    line-height: 1.9;
    color: #999;
    background-color: #fff;
    border: 1px solid #eaeff4;
    border-radius: 2px;
  }
  #saasbox_page_content .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(2.65em);
    padding: .375rem .75rem;
    line-height: 1.9;
    color: #999;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 2px 2px 0;
  }
  #saasbox_page_content .custom-range {
    width: 100%;
    height: calc(1rem);
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  #saasbox_page_content .custom-range:focus {
    outline: 0;
  }
  #saasbox_page_content .custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 40px rgba(0,0,0,0.045);
    box-shadow: 0 0 0 1px #fff, 0 0 40px rgba(0,0,0,0.045);
  }
  #saasbox_page_content .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 40px rgba(0,0,0,0.045);
  }
  #saasbox_page_content .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 40px rgba(0,0,0,0.045);
  }
  #saasbox_page_content .custom-range::-moz-focus-outer {
    border: 0;
  }
  #saasbox_page_content .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #50a1ff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .custom-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .custom-range::-webkit-slider-thumb:active {
    background-color: white;
  }
  #saasbox_page_content .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }
  #saasbox_page_content .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #50a1ff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .custom-range::-moz-range-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .custom-range::-moz-range-thumb:active {
    background-color: white;
  }
  #saasbox_page_content .custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }
  #saasbox_page_content .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0rem;
    margin-left: 0rem;
    background-color: #50a1ff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .custom-range::-ms-thumb {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .custom-range::-ms-thumb:active {
    background-color: white;
  }
  #saasbox_page_content .custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem;
  }
  #saasbox_page_content .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
  }
  #saasbox_page_content .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
  }
  #saasbox_page_content .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }
  #saasbox_page_content .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
  }
  #saasbox_page_content .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }
  #saasbox_page_content .custom-range:disabled::-moz-range-track {
    cursor: default;
  }
  #saasbox_page_content .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
  }
  #saasbox_page_content .custom-control-label::before,
  #saasbox_page_content .custom-file-label,
  #saasbox_page_content .custom-select {
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .custom-control-label::before,
    #saasbox_page_content .custom-file-label,
    #saasbox_page_content .custom-select {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .nav {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  #saasbox_page_content .nav-link,
  #saasbox_page_content .menu-item a {
    display: block;
    padding: .5rem 1rem;
  }
  #saasbox_page_content .nav-link:hover,
  #saasbox_page_content .menu-item a:hover,
  #saasbox_page_content .nav-link:focus,
  #saasbox_page_content .menu-item a:focus {
    text-decoration: none;
  }
  #saasbox_page_content .nav-link.disabled,
  #saasbox_page_content .menu-item a.disabled {
    color: #a5b3c7;
    pointer-events: none;
    cursor: default;
  }
  #saasbox_page_content .nav-tabs {
    border-bottom: 1px solid #f1f2f3;
  }
  #saasbox_page_content .nav-tabs .nav-item,
  #saasbox_page_content .nav-tabs .menu-item {
    margin-bottom: -1px;
  }
  #saasbox_page_content .nav-tabs .nav-link,
  #saasbox_page_content .nav-tabs .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs a {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  #saasbox_page_content .nav-tabs .nav-link:hover,
  #saasbox_page_content .nav-tabs .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-tabs a:hover,
  #saasbox_page_content .nav-tabs .nav-link:focus,
  #saasbox_page_content .nav-tabs .menu-item a:focus,
  #saasbox_page_content .menu-item .nav-tabs a:focus {
    border-color: transparent transparent #f1f2f3;
  }
  #saasbox_page_content .nav-tabs .nav-link.disabled,
  #saasbox_page_content .nav-tabs .menu-item a.disabled,
  #saasbox_page_content .menu-item .nav-tabs a.disabled {
    color: #a5b3c7;
    background-color: transparent;
    border-color: transparent;
  }
  #saasbox_page_content .nav-tabs .nav-link.active,
  #saasbox_page_content .nav-tabs .menu-item a.active,
  #saasbox_page_content .menu-item .nav-tabs a.active,
  #saasbox_page_content .nav-tabs .nav-item.show .nav-link,
  #saasbox_page_content .nav-tabs .show.menu-item .nav-link,
  #saasbox_page_content .nav-tabs .nav-item.show .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs .nav-item.show a,
  #saasbox_page_content .nav-tabs .show.menu-item a {
    color: #757575;
    background-color: #fff;
    border-color: #50a1ff #f1f2f3 #fff;
  }
  #saasbox_page_content .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  #saasbox_page_content .nav-pills .nav-link,
  #saasbox_page_content .nav-pills .menu-item a,
  #saasbox_page_content .menu-item .nav-pills a {
    border-radius: .125rem;
  }
  #saasbox_page_content .nav-pills .nav-link.active,
  #saasbox_page_content .nav-pills .menu-item a.active,
  #saasbox_page_content .menu-item .nav-pills a.active,
  #saasbox_page_content .nav-pills .show>.nav-link,
  #saasbox_page_content .nav-pills .menu-item .show>a,
  #saasbox_page_content .menu-item .nav-pills .show>a {
    color: #757575;
    background-color: #e9ecf0;
  }
  #saasbox_page_content .nav-fill .nav-item,
  #saasbox_page_content .nav-fill .menu-item {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    text-align: center;
  }
  #saasbox_page_content .nav-justified .nav-item,
  #saasbox_page_content .nav-justified .menu-item {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    text-align: center;
  }
  #saasbox_page_content .tab-content>.tab-pane {
    display: none;
  }
  #saasbox_page_content .tab-content>.active {
    display: block;
  }
  #saasbox_page_content .navbar {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
  }
  #saasbox_page_content .navbar>.container,
  #saasbox_page_content .woocommerce-page .navbar>.woocommerce,
  #saasbox_page_content .navbar>.container-fluid {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  #saasbox_page_content .navbar-brand {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 1rem;
    font-size: 1.0625rem;
    line-height: inherit;
    white-space: nowrap;
  }
  #saasbox_page_content .navbar-brand:hover,
  #saasbox_page_content .navbar-brand:focus {
    text-decoration: none;
  }
  #saasbox_page_content .navbar-nav {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  #saasbox_page_content .navbar-nav .nav-link,
  #saasbox_page_content .navbar-nav .menu-item a,
  #saasbox_page_content .menu-item .navbar-nav a {
    padding-right: 0;
    padding-left: 0;
  }
  #saasbox_page_content .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  #saasbox_page_content .navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  #saasbox_page_content .navbar-collapse {
    flex-basis: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    align-items: center;
  }
  #saasbox_page_content .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.0625rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
  }
  #saasbox_page_content .navbar-toggler:hover,
  #saasbox_page_content .navbar-toggler:focus {
    text-decoration: none;
  }
  #saasbox_page_content .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
  }
  @media (max-width: 575.98px) {
    #saasbox_page_content .navbar-expand-sm>.container,
    #saasbox_page_content .woocommerce-page .navbar-expand-sm>.woocommerce,
    #saasbox_page_content .navbar-expand-sm>.container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 576px) {
    #saasbox_page_content .navbar-expand-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      justify-content: flex-start;
    }
    #saasbox_page_content .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }
    #saasbox_page_content .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    #saasbox_page_content .navbar-expand-sm .navbar-nav .nav-link,
    #saasbox_page_content .navbar-expand-sm .navbar-nav .menu-item a,
    #saasbox_page_content .menu-item .navbar-expand-sm .navbar-nav a {
      padding-right: .5rem;
      padding-left: .5rem;
    }
    #saasbox_page_content .navbar-expand-sm>.container,
    #saasbox_page_content .woocommerce-page .navbar-expand-sm>.woocommerce,
    #saasbox_page_content .navbar-expand-sm>.container-fluid {
      flex-wrap: nowrap;
    }
    #saasbox_page_content .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto;
    }
    #saasbox_page_content .navbar-expand-sm .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .navbar-expand-md>.container,
    #saasbox_page_content .woocommerce-page .navbar-expand-md>.woocommerce,
    #saasbox_page_content .navbar-expand-md>.container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .navbar-expand-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      justify-content: flex-start;
    }
    #saasbox_page_content .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }
    #saasbox_page_content .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    #saasbox_page_content .navbar-expand-md .navbar-nav .nav-link,
    #saasbox_page_content .navbar-expand-md .navbar-nav .menu-item a,
    #saasbox_page_content .menu-item .navbar-expand-md .navbar-nav a {
      padding-right: .5rem;
      padding-left: .5rem;
    }
    #saasbox_page_content .navbar-expand-md>.container,
    #saasbox_page_content .woocommerce-page .navbar-expand-md>.woocommerce,
    #saasbox_page_content .navbar-expand-md>.container-fluid {
      flex-wrap: nowrap;
    }
    #saasbox_page_content .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto;
    }
    #saasbox_page_content .navbar-expand-md .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .navbar-expand-lg>.container,
    #saasbox_page_content .woocommerce-page .navbar-expand-lg>.woocommerce,
    #saasbox_page_content .navbar-expand-lg>.container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      justify-content: flex-start;
    }
    #saasbox_page_content .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }
    #saasbox_page_content .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    #saasbox_page_content .navbar-expand-lg .navbar-nav .nav-link,
    #saasbox_page_content .navbar-expand-lg .navbar-nav .menu-item a,
    #saasbox_page_content .menu-item .navbar-expand-lg .navbar-nav a {
      padding-right: .5rem;
      padding-left: .5rem;
    }
    #saasbox_page_content .navbar-expand-lg>.container,
    #saasbox_page_content .woocommerce-page .navbar-expand-lg>.woocommerce,
    #saasbox_page_content .navbar-expand-lg>.container-fluid {
      flex-wrap: nowrap;
    }
    #saasbox_page_content .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto;
    }
    #saasbox_page_content .navbar-expand-lg .navbar-toggler {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    #saasbox_page_content .navbar-expand-xl>.container,
    #saasbox_page_content .woocommerce-page .navbar-expand-xl>.woocommerce,
    #saasbox_page_content .navbar-expand-xl>.container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .navbar-expand-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      justify-content: flex-start;
    }
    #saasbox_page_content .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }
    #saasbox_page_content .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    #saasbox_page_content .navbar-expand-xl .navbar-nav .nav-link,
    #saasbox_page_content .navbar-expand-xl .navbar-nav .menu-item a,
    #saasbox_page_content .menu-item .navbar-expand-xl .navbar-nav a {
      padding-right: .5rem;
      padding-left: .5rem;
    }
    #saasbox_page_content .navbar-expand-xl>.container,
    #saasbox_page_content .woocommerce-page .navbar-expand-xl>.woocommerce,
    #saasbox_page_content .navbar-expand-xl>.container-fluid {
      flex-wrap: nowrap;
    }
    #saasbox_page_content .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: flex !important;
      flex-basis: auto;
    }
    #saasbox_page_content .navbar-expand-xl .navbar-toggler {
      display: none;
    }
  }
  #saasbox_page_content .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  #saasbox_page_content .navbar-expand>.container,
  #saasbox_page_content .woocommerce-page .navbar-expand>.woocommerce,
  #saasbox_page_content .navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  #saasbox_page_content .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  #saasbox_page_content .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  #saasbox_page_content .navbar-expand .navbar-nav .nav-link,
  #saasbox_page_content .navbar-expand .navbar-nav .menu-item a,
  #saasbox_page_content .menu-item .navbar-expand .navbar-nav a {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  #saasbox_page_content .navbar-expand>.container,
  #saasbox_page_content .woocommerce-page .navbar-expand>.woocommerce,
  #saasbox_page_content .navbar-expand>.container-fluid {
    flex-wrap: nowrap;
  }
  #saasbox_page_content .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: flex !important;
    flex-basis: auto;
  }
  #saasbox_page_content .navbar-expand .navbar-toggler {
    display: none;
  }
  #saasbox_page_content .navbar-light .navbar-brand {
    color: rgba(0,0,0,0.9);
  }
  #saasbox_page_content .navbar-light .navbar-brand:hover,
  #saasbox_page_content .navbar-light .navbar-brand:focus {
    color: rgba(0,0,0,0.9);
  }
  #saasbox_page_content .navbar-light .navbar-nav .nav-link,
  #saasbox_page_content .navbar-light .navbar-nav .menu-item a,
  #saasbox_page_content .menu-item .navbar-light .navbar-nav a {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .navbar-light .navbar-nav .nav-link:hover,
  #saasbox_page_content .navbar-light .navbar-nav .menu-item a:hover,
  #saasbox_page_content .menu-item .navbar-light .navbar-nav a:hover,
  #saasbox_page_content .navbar-light .navbar-nav .nav-link:focus,
  #saasbox_page_content .navbar-light .navbar-nav .menu-item a:focus,
  #saasbox_page_content .menu-item .navbar-light .navbar-nav a:focus {
    color: rgba(0,0,0,0.7);
  }
  #saasbox_page_content .navbar-light .navbar-nav .nav-link.disabled,
  #saasbox_page_content .navbar-light .navbar-nav .menu-item a.disabled,
  #saasbox_page_content .menu-item .navbar-light .navbar-nav a.disabled {
    color: rgba(0,0,0,0.3);
  }
  #saasbox_page_content .navbar-light .navbar-nav .show>.nav-link,
  #saasbox_page_content .navbar-light .navbar-nav .menu-item .show>a,
  #saasbox_page_content .menu-item .navbar-light .navbar-nav .show>a,
  #saasbox_page_content .navbar-light .navbar-nav .active>.nav-link,
  #saasbox_page_content .navbar-light .navbar-nav .menu-item .active>a,
  #saasbox_page_content .menu-item .navbar-light .navbar-nav .active>a,
  #saasbox_page_content .navbar-light .navbar-nav .nav-link.show,
  #saasbox_page_content .navbar-light .navbar-nav .menu-item a.show,
  #saasbox_page_content .menu-item .navbar-light .navbar-nav a.show,
  #saasbox_page_content .navbar-light .navbar-nav .nav-link.active,
  #saasbox_page_content .navbar-light .navbar-nav .menu-item a.active,
  #saasbox_page_content .menu-item .navbar-light .navbar-nav a.active {
    color: rgba(0,0,0,0.9);
  }
  #saasbox_page_content .navbar-light .navbar-toggler {
    color: rgba(255,255,255,0.65);
    border-color: rgba(0,0,0,0.1);
  }
  #saasbox_page_content .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvgviewBox='003030'xmlns='http://www.w3.org/2000/svg'%3e%3cpathstroke='rgba(255,255,255,0.65)'stroke-width='2'stroke-linecap='round'stroke-miterlimit='10'd='M47h22M415h22M423h22'/%3e%3c/svg%3e");
  }
  #saasbox_page_content .navbar-light .navbar-text {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .navbar-light .navbar-text a {
    color: rgba(0,0,0,0.9);
  }
  #saasbox_page_content .navbar-light .navbar-text a:hover,
  #saasbox_page_content .navbar-light .navbar-text a:focus {
    color: rgba(0,0,0,0.9);
  }
  #saasbox_page_content .navbar-dark .navbar-brand {
    color: #fff;
  }
  #saasbox_page_content .navbar-dark .navbar-brand:hover,
  #saasbox_page_content .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  #saasbox_page_content .navbar-dark .navbar-nav .nav-link,
  #saasbox_page_content .navbar-dark .navbar-nav .menu-item a,
  #saasbox_page_content .menu-item .navbar-dark .navbar-nav a {
    color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-dark .navbar-nav .nav-link:hover,
  #saasbox_page_content .navbar-dark .navbar-nav .menu-item a:hover,
  #saasbox_page_content .menu-item .navbar-dark .navbar-nav a:hover,
  #saasbox_page_content .navbar-dark .navbar-nav .nav-link:focus,
  #saasbox_page_content .navbar-dark .navbar-nav .menu-item a:focus,
  #saasbox_page_content .menu-item .navbar-dark .navbar-nav a:focus {
    color: rgba(255,255,255,0.75);
  }
  #saasbox_page_content .navbar-dark .navbar-nav .nav-link.disabled,
  #saasbox_page_content .navbar-dark .navbar-nav .menu-item a.disabled,
  #saasbox_page_content .menu-item .navbar-dark .navbar-nav a.disabled {
    color: rgba(255,255,255,0.25);
  }
  #saasbox_page_content .navbar-dark .navbar-nav .show>.nav-link,
  #saasbox_page_content .navbar-dark .navbar-nav .menu-item .show>a,
  #saasbox_page_content .menu-item .navbar-dark .navbar-nav .show>a,
  #saasbox_page_content .navbar-dark .navbar-nav .active>.nav-link,
  #saasbox_page_content .navbar-dark .navbar-nav .menu-item .active>a,
  #saasbox_page_content .menu-item .navbar-dark .navbar-nav .active>a,
  #saasbox_page_content .navbar-dark .navbar-nav .nav-link.show,
  #saasbox_page_content .navbar-dark .navbar-nav .menu-item a.show,
  #saasbox_page_content .menu-item .navbar-dark .navbar-nav a.show,
  #saasbox_page_content .navbar-dark .navbar-nav .nav-link.active,
  #saasbox_page_content .navbar-dark .navbar-nav .menu-item a.active,
  #saasbox_page_content .menu-item .navbar-dark .navbar-nav a.active {
    color: #fff;
  }
  #saasbox_page_content .navbar-dark .navbar-toggler {
    color: rgba(117,117,117,0.8);
    border-color: rgba(255,255,255,0.1);
  }
  #saasbox_page_content .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvgviewBox='003030'xmlns='http://www.w3.org/2000/svg'%3e%3cpathstroke='rgba(117,117,117,0.8)'stroke-width='2'stroke-linecap='round'stroke-miterlimit='10'd='M47h22M415h22M423h22'/%3e%3c/svg%3e");
  }
  #saasbox_page_content .navbar-dark .navbar-text {
    color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-dark .navbar-text a {
    color: #fff;
  }
  #saasbox_page_content .navbar-dark .navbar-text a:hover,
  #saasbox_page_content .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
  #saasbox_page_content .card {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,0.125);
    border-radius: .25rem;
  }
  #saasbox_page_content .card>hr {
    margin-right: 0;
    margin-left: 0;
  }
  #saasbox_page_content .card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }
  #saasbox_page_content .card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }
  #saasbox_page_content .card-body {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  #saasbox_page_content .card-title {
    margin-bottom: .75rem;
  }
  #saasbox_page_content .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
  }
  #saasbox_page_content .card-text:last-child {
    margin-bottom: 0;
  }
  #saasbox_page_content .card-link:hover {
    text-decoration: none;
  }
  #saasbox_page_content .card-link+.card-link {
    margin-left: 1.25rem;
  }
  #saasbox_page_content .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,0.03);
    border-bottom: 0 solid rgba(0,0,0,0.125);
  }
  #saasbox_page_content .card-header:first-child {
    border-radius: calc(0.25rem) calc(0.25rem) 0 0;
  }
  #saasbox_page_content .card-header+.list-group .list-group-item:first-child {
    border-top: 0;
  }
  #saasbox_page_content .card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0,0,0,0.03);
    border-top: 0 solid rgba(0,0,0,0.125);
  }
  #saasbox_page_content .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem) calc(0.25rem);
  }
  #saasbox_page_content .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  #saasbox_page_content .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  #saasbox_page_content .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  #saasbox_page_content .card-img {
    width: 100%;
    border-radius: calc(0.25rem);
  }
  #saasbox_page_content .card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem);
    border-top-right-radius: calc(0.25rem);
  }
  #saasbox_page_content .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem);
    border-bottom-left-radius: calc(0.25rem);
  }
  #saasbox_page_content .card-deck {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  #saasbox_page_content .card-deck .card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    #saasbox_page_content .card-deck .card {
      display: -webkit-box;
      display: flex;
      -webkit-box-flex: 1;
      flex: 1 0 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  #saasbox_page_content .card-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  #saasbox_page_content .card-group>.card {
    margin-bottom: 15px;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row wrap;
    }
    #saasbox_page_content .card-group>.card {
      -webkit-box-flex: 1;
      flex: 1 0 0;
      margin-bottom: 0;
    }
    #saasbox_page_content .card-group>.card+.card {
      margin-left: 0;
      border-left: 0;
    }
    #saasbox_page_content .card-group>.card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    #saasbox_page_content .card-group>.card:not(:last-child) .card-img-top,
    #saasbox_page_content .card-group>.card:not(:last-child) .card-header {
      border-top-right-radius: 0;
    }
    #saasbox_page_content .card-group>.card:not(:last-child) .card-img-bottom,
    #saasbox_page_content .card-group>.card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0;
    }
    #saasbox_page_content .card-group>.card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    #saasbox_page_content .card-group>.card:not(:first-child) .card-img-top,
    #saasbox_page_content .card-group>.card:not(:first-child) .card-header {
      border-top-left-radius: 0;
    }
    #saasbox_page_content .card-group>.card:not(:first-child) .card-img-bottom,
    #saasbox_page_content .card-group>.card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0;
    }
  }
  #saasbox_page_content .card-columns .card {
    margin-bottom: .75rem;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
    }
    #saasbox_page_content .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  #saasbox_page_content .accordion>.card {
    overflow: hidden;
  }
  #saasbox_page_content .accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
  }
  #saasbox_page_content .accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }
  #saasbox_page_content .accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  #saasbox_page_content .accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  #saasbox_page_content .accordion>.card .card-header {
    margin-bottom: 0;
  }
  #saasbox_page_content .breadcrumb {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: transparent;
    border-radius: .25rem;
  }
  #saasbox_page_content .breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem;
  }
  #saasbox_page_content .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #bfc5ca;
    content: "";
  }
  #saasbox_page_content .breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  #saasbox_page_content .breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
  }
  #saasbox_page_content .breadcrumb-item.active {
    color: #757575;
  }
  #saasbox_page_content .pagination {
    display: -webkit-box;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
  }
  #saasbox_page_content .page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 32px;
    color: #757575;
    background-color: #fff;
    border: 1px solid #eaeff4;
  }
  #saasbox_page_content .page-link:hover {
    z-index: 2;
    color: #757575;
    text-decoration: none;
    background-color: #fafbfb;
    border-color: #eaeff4;
  }
  #saasbox_page_content .page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }
  #saasbox_page_content .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }
  #saasbox_page_content .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff;
  }
  #saasbox_page_content .page-item.disabled .page-link {
    color: #868e96;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }
  #saasbox_page_content .pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.0625rem;
    line-height: 2.2;
  }
  #saasbox_page_content .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
  }
  #saasbox_page_content .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
  #saasbox_page_content .pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .9375rem;
    line-height: 1.5;
  }
  #saasbox_page_content .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }
  #saasbox_page_content .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }
  #saasbox_page_content .badge,
  #saasbox_page_content .tagcloud .tag-cloud-link {
    display: inline-block;
    padding: .25em .5em;
    font-size: 80%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .125rem;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .badge,
    #saasbox_page_content .tagcloud .tag-cloud-link {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content a.badge:hover,
  #saasbox_page_content .tagcloud a.tag-cloud-link:hover,
  #saasbox_page_content a.badge:focus,
  #saasbox_page_content .tagcloud a.tag-cloud-link:focus {
    text-decoration: none;
  }
  #saasbox_page_content .badge:empty,
  #saasbox_page_content .tagcloud .tag-cloud-link:empty {
    display: none;
  }
  #saasbox_page_content .btn .badge,
  #saasbox_page_content .comment-list .comment-reply-link .badge,
  #saasbox_page_content .comment-form .submit .badge,
  #saasbox_page_content .woocommerce a.button .badge,
  #saasbox_page_content .woocommerce input.button .badge,
  #saasbox_page_content .woocommerce button.button .badge,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button .badge,
  #saasbox_page_content .woocommerce #respond input#submit .badge,
  #saasbox_page_content .wpcf7-submit .badge,
  #saasbox_page_content .btn .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .btn .tag-cloud-link,
  #saasbox_page_content .comment-list .comment-reply-link .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .comment-list .comment-reply-link .tag-cloud-link,
  #saasbox_page_content .comment-form .submit .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .comment-form .submit .tag-cloud-link,
  #saasbox_page_content .woocommerce a.button .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .woocommerce a.button .tag-cloud-link,
  #saasbox_page_content .woocommerce input.button .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .woocommerce input.button .tag-cloud-link,
  #saasbox_page_content .woocommerce button.button .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .woocommerce button.button .tag-cloud-link,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .woocommerce-cart .wc-proceed-to-checkout a.checkout-button .tag-cloud-link,
  #saasbox_page_content .woocommerce #respond input#submit .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .woocommerce #respond input#submit .tag-cloud-link,
  #saasbox_page_content .wpcf7-submit .tagcloud .tag-cloud-link,
  #saasbox_page_content .tagcloud .wpcf7-submit .tag-cloud-link {
    position: relative;
    top: -1px;
  }
  #saasbox_page_content .badge-pill {
    padding-right: .75em;
    padding-left: .75em;
    border-radius: 10rem;
  }
  #saasbox_page_content .badge-primary {
    color: #fff;
    background-color: #50a1ff;
  }
  #saasbox_page_content a.badge-primary:hover,
  #saasbox_page_content a.badge-primary:focus {
    color: #fff;
    background-color: #1d86ff;
  }
  #saasbox_page_content a.badge-primary:focus,
  #saasbox_page_content a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
    box-shadow: 0 0 0 0rem rgba(80,161,255,0.5);
  }
  #saasbox_page_content .badge-secondary,
  #saasbox_page_content .tagcloud .tag-cloud-link {
    color: #757575;
    background-color: #e9ecf0;
  }
  #saasbox_page_content a.badge-secondary:hover,
  #saasbox_page_content .tagcloud a.tag-cloud-link:hover,
  #saasbox_page_content a.badge-secondary:focus,
  #saasbox_page_content .tagcloud a.tag-cloud-link:focus {
    color: #757575;
    background-color: #cbd2db;
  }
  #saasbox_page_content a.badge-secondary:focus,
  #saasbox_page_content .tagcloud a.tag-cloud-link:focus,
  #saasbox_page_content a.badge-secondary.focus,
  #saasbox_page_content .tagcloud a.focus.tag-cloud-link {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
    box-shadow: 0 0 0 0rem rgba(233,236,240,0.5);
  }
  #saasbox_page_content .badge-success {
    color: #fff;
    background-color: #3cd458;
  }
  #saasbox_page_content a.badge-success:hover,
  #saasbox_page_content a.badge-success:focus {
    color: #fff;
    background-color: #28b542;
  }
  #saasbox_page_content a.badge-success:focus,
  #saasbox_page_content a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
    box-shadow: 0 0 0 0rem rgba(60,212,88,0.5);
  }
  #saasbox_page_content .badge-info {
    color: #fff;
    background-color: #926dde;
  }
  #saasbox_page_content a.badge-info:hover,
  #saasbox_page_content a.badge-info:focus {
    color: #fff;
    background-color: #7343d5;
  }
  #saasbox_page_content a.badge-info:focus,
  #saasbox_page_content a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
    box-shadow: 0 0 0 0rem rgba(146,109,222,0.5);
  }
  #saasbox_page_content .badge-warning {
    color: #fff;
    background-color: #ffba00;
  }
  #saasbox_page_content a.badge-warning:hover,
  #saasbox_page_content a.badge-warning:focus {
    color: #fff;
    background-color: #cc9500;
  }
  #saasbox_page_content a.badge-warning:focus,
  #saasbox_page_content a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
    box-shadow: 0 0 0 0rem rgba(255,186,0,0.5);
  }
  #saasbox_page_content .badge-danger {
    color: #fff;
    background-color: #ff4954;
  }
  #saasbox_page_content a.badge-danger:hover,
  #saasbox_page_content a.badge-danger:focus {
    color: #fff;
    background-color: #ff1624;
  }
  #saasbox_page_content a.badge-danger:focus,
  #saasbox_page_content a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
    box-shadow: 0 0 0 0rem rgba(255,73,84,0.5);
  }
  #saasbox_page_content .badge-light {
    color: #757575;
    background-color: #f8f9fa;
  }
  #saasbox_page_content a.badge-light:hover,
  #saasbox_page_content a.badge-light:focus {
    color: #757575;
    background-color: #dae0e5;
  }
  #saasbox_page_content a.badge-light:focus,
  #saasbox_page_content a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
    box-shadow: 0 0 0 0rem rgba(248,249,250,0.5);
  }
  #saasbox_page_content .badge-dark {
    color: #fff;
    background-color: #191919;
  }
  #saasbox_page_content a.badge-dark:hover,
  #saasbox_page_content a.badge-dark:focus {
    color: #fff;
    background-color: black;
  }
  #saasbox_page_content a.badge-dark:focus,
  #saasbox_page_content a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
    box-shadow: 0 0 0 0rem rgba(25,25,25,0.5);
  }
  #saasbox_page_content .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: .3rem;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .jumbotron {
      padding: 4rem 2rem;
    }
  }
  #saasbox_page_content .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  #saasbox_page_content .alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
  }
  #saasbox_page_content .alert-heading {
    color: inherit;
  }
  #saasbox_page_content .alert-link {
    font-weight: 600;
  }
  #saasbox_page_content .alert-dismissible {
    padding-right: 3.90625rem;
  }
  #saasbox_page_content .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit;
  }
  #saasbox_page_content .alert-primary {
    color: #2a5485;
    background-color: #dcecff;
    border-color: #cee5ff;
  }
  #saasbox_page_content .alert-primary hr {
    border-top-color: #b5d7ff;
  }
  #saasbox_page_content .alert-primary .alert-link {
    color: #1e3c5e;
  }
  #saasbox_page_content .alert-secondary {
    color: #797b7d;
    background-color: #fbfbfc;
    border-color: #f9fafb;
  }
  #saasbox_page_content .alert-secondary hr {
    border-top-color: #eaedf1;
  }
  #saasbox_page_content .alert-secondary .alert-link {
    color: #606263;
  }
  #saasbox_page_content .alert-success {
    color: #1f6e2e;
    background-color: #d8f6de;
    border-color: #c8f3d0;
  }
  #saasbox_page_content .alert-success hr {
    border-top-color: #b3eebe;
  }
  #saasbox_page_content .alert-success .alert-link {
    color: #14461d;
  }
  #saasbox_page_content .alert-info {
    color: #4c3973;
    background-color: #e9e2f8;
    border-color: #e0d6f6;
  }
  #saasbox_page_content .alert-info hr {
    border-top-color: #d0c1f1;
  }
  #saasbox_page_content .alert-info .alert-link {
    color: #352851;
  }
  #saasbox_page_content .alert-warning {
    color: #856100;
    background-color: #fff1cc;
    border-color: #ffecb8;
  }
  #saasbox_page_content .alert-warning hr {
    border-top-color: #ffe59f;
  }
  #saasbox_page_content .alert-warning .alert-link {
    color: #523c00;
  }
  #saasbox_page_content .alert-danger {
    color: #85262c;
    background-color: #ffdbdd;
    border-color: #ffcccf;
  }
  #saasbox_page_content .alert-danger hr {
    border-top-color: #ffb3b7;
  }
  #saasbox_page_content .alert-danger .alert-link {
    color: #5d1b1f;
  }
  #saasbox_page_content .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }
  #saasbox_page_content .alert-light hr {
    border-top-color: #ececf6;
  }
  #saasbox_page_content .alert-light .alert-link {
    color: #686868;
  }
  #saasbox_page_content .alert-dark {
    color: #0d0d0d;
    background-color: #d1d1d1;
    border-color: #bfbfbf;
  }
  #saasbox_page_content .alert-dark hr {
    border-top-color: #b2b2b2;
  }
  #saasbox_page_content .alert-dark .alert-link {
    color: black;
  }
  @-webkit-keyframes progress-bar-stripes {
    #saasbox_page_content from {
      background-position: 1rem 0;
    }
    #saasbox_page_content to {
      background-position: 0 0;
    }
  }
  @keyframes progress-bar-stripes {
    #saasbox_page_content from {
      background-position: 1rem 0;
    }
    #saasbox_page_content to {
      background-position: 0 0;
    }
  }
  #saasbox_page_content .progress {
    display: -webkit-box;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .70313rem;
    background-color: #f5f6f7;
    border-radius: .125rem;
  }
  #saasbox_page_content .progress-bar {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #50a1ff;
    -webkit-transition: width .6s ease;
    transition: width .6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .progress-bar {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,0.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,0.15) 50%,rgba(255,255,255,0.15) 75%,transparent 75%,transparent);
    background-image: linear-gradient(45deg,rgba(255,255,255,0.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,0.15) 50%,rgba(255,255,255,0.15) 75%,transparent 75%,transparent);
    -webkit-background-size: 1rem 1rem;
    background-size: 1rem 1rem;
  }
  #saasbox_page_content .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
    }
  }
  #saasbox_page_content .media {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  #saasbox_page_content .media-body {
    -webkit-box-flex: 1;
    flex: 1;
  }
  #saasbox_page_content .list-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
  }
  #saasbox_page_content .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }
  #saasbox_page_content .list-group-item-action:hover,
  #saasbox_page_content .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  #saasbox_page_content .list-group-item-action:active {
    color: #757575;
    background-color: #e9ecef;
  }
  #saasbox_page_content .list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.125);
  }
  #saasbox_page_content .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }
  #saasbox_page_content .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }
  #saasbox_page_content .list-group-item.disabled,
  #saasbox_page_content .list-group-item:disabled {
    color: #868e96;
    pointer-events: none;
    background-color: #fff;
  }
  #saasbox_page_content .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff;
  }
  #saasbox_page_content .list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }
  #saasbox_page_content .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  #saasbox_page_content .list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }
  #saasbox_page_content .list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .list-group-horizontal-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }
    #saasbox_page_content .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;
    }
    #saasbox_page_content .list-group-horizontal-sm .list-group-item:first-child {
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0;
    }
    #saasbox_page_content .list-group-horizontal-sm .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: 0;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .list-group-horizontal-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }
    #saasbox_page_content .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;
    }
    #saasbox_page_content .list-group-horizontal-md .list-group-item:first-child {
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0;
    }
    #saasbox_page_content .list-group-horizontal-md .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: 0;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .list-group-horizontal-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }
    #saasbox_page_content .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;
    }
    #saasbox_page_content .list-group-horizontal-lg .list-group-item:first-child {
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0;
    }
    #saasbox_page_content .list-group-horizontal-lg .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: 0;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .list-group-horizontal-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }
    #saasbox_page_content .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;
    }
    #saasbox_page_content .list-group-horizontal-xl .list-group-item:first-child {
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0;
    }
    #saasbox_page_content .list-group-horizontal-xl .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: .25rem;
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: 0;
    }
  }
  #saasbox_page_content .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  #saasbox_page_content .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px;
  }
  #saasbox_page_content .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
  }
  #saasbox_page_content .list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
  #saasbox_page_content .list-group-item-primary {
    color: #2a5485;
    background-color: #cee5ff;
  }
  #saasbox_page_content .list-group-item-primary.list-group-item-action:hover,
  #saasbox_page_content .list-group-item-primary.list-group-item-action:focus {
    color: #2a5485;
    background-color: #b5d7ff;
  }
  #saasbox_page_content .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #2a5485;
    border-color: #2a5485;
  }
  #saasbox_page_content .list-group-item-secondary {
    color: #797b7d;
    background-color: #f9fafb;
  }
  #saasbox_page_content .list-group-item-secondary.list-group-item-action:hover,
  #saasbox_page_content .list-group-item-secondary.list-group-item-action:focus {
    color: #797b7d;
    background-color: #eaedf1;
  }
  #saasbox_page_content .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #797b7d;
    border-color: #797b7d;
  }
  #saasbox_page_content .list-group-item-success {
    color: #1f6e2e;
    background-color: #c8f3d0;
  }
  #saasbox_page_content .list-group-item-success.list-group-item-action:hover,
  #saasbox_page_content .list-group-item-success.list-group-item-action:focus {
    color: #1f6e2e;
    background-color: #b3eebe;
  }
  #saasbox_page_content .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1f6e2e;
    border-color: #1f6e2e;
  }
  #saasbox_page_content .list-group-item-info {
    color: #4c3973;
    background-color: #e0d6f6;
  }
  #saasbox_page_content .list-group-item-info.list-group-item-action:hover,
  #saasbox_page_content .list-group-item-info.list-group-item-action:focus {
    color: #4c3973;
    background-color: #d0c1f1;
  }
  #saasbox_page_content .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #4c3973;
    border-color: #4c3973;
  }
  #saasbox_page_content .list-group-item-warning {
    color: #856100;
    background-color: #ffecb8;
  }
  #saasbox_page_content .list-group-item-warning.list-group-item-action:hover,
  #saasbox_page_content .list-group-item-warning.list-group-item-action:focus {
    color: #856100;
    background-color: #ffe59f;
  }
  #saasbox_page_content .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856100;
    border-color: #856100;
  }
  #saasbox_page_content .list-group-item-danger {
    color: #85262c;
    background-color: #ffcccf;
  }
  #saasbox_page_content .list-group-item-danger.list-group-item-action:hover,
  #saasbox_page_content .list-group-item-danger.list-group-item-action:focus {
    color: #85262c;
    background-color: #ffb3b7;
  }
  #saasbox_page_content .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #85262c;
    border-color: #85262c;
  }
  #saasbox_page_content .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
  }
  #saasbox_page_content .list-group-item-light.list-group-item-action:hover,
  #saasbox_page_content .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
  }
  #saasbox_page_content .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
  }
  #saasbox_page_content .list-group-item-dark {
    color: #0d0d0d;
    background-color: #bfbfbf;
  }
  #saasbox_page_content .list-group-item-dark.list-group-item-action:hover,
  #saasbox_page_content .list-group-item-dark.list-group-item-action:focus {
    color: #0d0d0d;
    background-color: #b2b2b2;
  }
  #saasbox_page_content .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0d0d0d;
    border-color: #0d0d0d;
  }
  #saasbox_page_content .close {
    float: right;
    font-size: 1.40625rem;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: none;
    opacity: .5;
  }
  #saasbox_page_content .close:hover {
    color: #000;
    text-decoration: none;
  }
  #saasbox_page_content .close:not(:disabled):not(.disabled):hover,
  #saasbox_page_content .close:not(:disabled):not(.disabled):focus {
    opacity: .75;
  }
  #saasbox_page_content button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  #saasbox_page_content a.close.disabled {
    pointer-events: none;
  }
  #saasbox_page_content .modal-open {
    overflow: hidden;
  }
  #saasbox_page_content .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  #saasbox_page_content .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
  }
  #saasbox_page_content .modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
  }
  #saasbox_page_content .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px);
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .modal.fade .modal-dialog {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
  }
  #saasbox_page_content .modal-dialog-scrollable {
    display: -webkit-box;
    display: flex;
    max-height: calc(99%);
  }
  #saasbox_page_content .modal-dialog-scrollable .modal-content {
    max-height: calc(99vh);
    overflow: hidden;
  }
  #saasbox_page_content .modal-dialog-scrollable .modal-header,
  #saasbox_page_content .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
  }
  #saasbox_page_content .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }
  #saasbox_page_content .modal-dialog-centered {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: calc(99%);
  }
  #saasbox_page_content .modal-dialog-centered::before {
    display: block;
    height: calc(99vh);
    content: "";
  }
  #saasbox_page_content .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
  }
  #saasbox_page_content .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
  }
  #saasbox_page_content .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
  }
  #saasbox_page_content .modal-content {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: .3rem;
    outline: 0;
  }
  #saasbox_page_content .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
  #saasbox_page_content .modal-backdrop.fade {
    opacity: 0;
  }
  #saasbox_page_content .modal-backdrop.show {
    opacity: .5;
  }
  #saasbox_page_content .modal-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1.75rem;
    border-bottom: 1px solid #f1f2f3;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
  }
  #saasbox_page_content .modal-header .close {
    padding: 1.75rem;
    margin: -1rem -1rem -1rem auto;
  }
  #saasbox_page_content .modal-title {
    margin-bottom: 0;
    line-height: 1.9;
  }
  #saasbox_page_content .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    padding: 1.75rem;
  }
  #saasbox_page_content .modal-footer {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding: 1.75rem;
    border-top: 1px solid #f1f2f3;
    border-bottom-right-radius: .3rem;
    border-bottom-left-radius: .3rem;
  }
  #saasbox_page_content .modal-footer>:not(:first-child) {
    margin-left: .25rem;
  }
  #saasbox_page_content .modal-footer>:not(:last-child) {
    margin-right: .25rem;
  }
  #saasbox_page_content .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .modal-dialog {
      max-width: 540px;
      margin: 1.75rem auto;
    }
    #saasbox_page_content .modal-dialog-scrollable {
      max-height: calc(96.5%);
    }
    #saasbox_page_content .modal-dialog-scrollable .modal-content {
      max-height: calc(96.5vh);
    }
    #saasbox_page_content .modal-dialog-centered {
      min-height: calc(96.5%);
    }
    #saasbox_page_content .modal-dialog-centered::before {
      height: calc(96.5vh);
    }
    #saasbox_page_content .modal-sm {
      max-width: 360px;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .modal-lg,
    #saasbox_page_content .modal-xl {
      max-width: 800px;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .modal-xl {
      max-width: 1140px;
    }
  }
  #saasbox_page_content .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.9;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .9375rem;
    word-wrap: break-word;
    opacity: 0;
  }
  #saasbox_page_content .tooltip.show {
    opacity: .9;
  }
  #saasbox_page_content .tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem;
  }
  #saasbox_page_content .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  #saasbox_page_content .bs-tooltip-top,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="top"] {
    padding: .4rem 0;
  }
  #saasbox_page_content .bs-tooltip-top .arrow,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
  }
  #saasbox_page_content .bs-tooltip-top .arrow::before,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000;
  }
  #saasbox_page_content .bs-tooltip-right,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 .4rem;
  }
  #saasbox_page_content .bs-tooltip-right .arrow,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: .4rem;
    height: .8rem;
  }
  #saasbox_page_content .bs-tooltip-right .arrow::before,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000;
  }
  #saasbox_page_content .bs-tooltip-bottom,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="bottom"] {
    padding: .4rem 0;
  }
  #saasbox_page_content .bs-tooltip-bottom .arrow,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
  }
  #saasbox_page_content .bs-tooltip-bottom .arrow::before,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000;
  }
  #saasbox_page_content .bs-tooltip-left,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 .4rem;
  }
  #saasbox_page_content .bs-tooltip-left .arrow,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: .4rem;
    height: .8rem;
  }
  #saasbox_page_content .bs-tooltip-left .arrow::before,
  #saasbox_page_content .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000;
  }
  #saasbox_page_content .tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem;
  }
  #saasbox_page_content .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.9;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .9375rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f1f2f3;
    border-radius: .3rem;
  }
  #saasbox_page_content .popover .arrow {
    position: absolute;
    display: block;
    width: .6rem;
    height: .3rem;
    margin: 0 .3rem;
  }
  #saasbox_page_content .popover .arrow::before,
  #saasbox_page_content .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  #saasbox_page_content .bs-popover-top,
  #saasbox_page_content .bs-popover-auto[x-placement^="top"] {
    margin-bottom: .3rem;
  }
  #saasbox_page_content .bs-popover-top>.arrow,
  #saasbox_page_content .bs-popover-auto[x-placement^="top"]>.arrow {
    bottom: calc(-1.3rem);
  }
  #saasbox_page_content .bs-popover-top>.arrow::before,
  #saasbox_page_content .bs-popover-auto[x-placement^="top"]>.arrow::before {
    bottom: 0;
    border-width: .3rem .3rem 0;
    border-top-color: #f1f2f3;
  }
  #saasbox_page_content .bs-popover-top>.arrow::after,
  #saasbox_page_content .bs-popover-auto[x-placement^="top"]>.arrow::after {
    bottom: 1px;
    border-width: .3rem .3rem 0;
    border-top-color: #fff;
  }
  #saasbox_page_content .bs-popover-right,
  #saasbox_page_content .bs-popover-auto[x-placement^="right"] {
    margin-left: .3rem;
  }
  #saasbox_page_content .bs-popover-right>.arrow,
  #saasbox_page_content .bs-popover-auto[x-placement^="right"]>.arrow {
    left: calc(-1.3rem);
    width: .3rem;
    height: .6rem;
    margin: .3rem 0;
  }
  #saasbox_page_content .bs-popover-right>.arrow::before,
  #saasbox_page_content .bs-popover-auto[x-placement^="right"]>.arrow::before {
    left: 0;
    border-width: .3rem .3rem .3rem 0;
    border-right-color: #f1f2f3;
  }
  #saasbox_page_content .bs-popover-right>.arrow::after,
  #saasbox_page_content .bs-popover-auto[x-placement^="right"]>.arrow::after {
    left: 1px;
    border-width: .3rem .3rem .3rem 0;
    border-right-color: #fff;
  }
  #saasbox_page_content .bs-popover-bottom,
  #saasbox_page_content .bs-popover-auto[x-placement^="bottom"] {
    margin-top: .3rem;
  }
  #saasbox_page_content .bs-popover-bottom>.arrow,
  #saasbox_page_content .bs-popover-auto[x-placement^="bottom"]>.arrow {
    top: calc(-1.3rem);
  }
  #saasbox_page_content .bs-popover-bottom>.arrow::before,
  #saasbox_page_content .bs-popover-auto[x-placement^="bottom"]>.arrow::before {
    top: 0;
    border-width: 0 .3rem .3rem .3rem;
    border-bottom-color: #f1f2f3;
  }
  #saasbox_page_content .bs-popover-bottom>.arrow::after,
  #saasbox_page_content .bs-popover-auto[x-placement^="bottom"]>.arrow::after {
    top: 1px;
    border-width: 0 .3rem .3rem .3rem;
    border-bottom-color: #fff;
  }
  #saasbox_page_content .bs-popover-bottom .popover-header::before,
  #saasbox_page_content .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: .6rem;
    margin-left: -0.3rem;
    content: "";
    border-bottom: 1px solid #f9fafb;
  }
  #saasbox_page_content .bs-popover-left,
  #saasbox_page_content .bs-popover-auto[x-placement^="left"] {
    margin-right: .3rem;
  }
  #saasbox_page_content .bs-popover-left>.arrow,
  #saasbox_page_content .bs-popover-auto[x-placement^="left"]>.arrow {
    right: calc(-1.3rem);
    width: .3rem;
    height: .6rem;
    margin: .3rem 0;
  }
  #saasbox_page_content .bs-popover-left>.arrow::before,
  #saasbox_page_content .bs-popover-auto[x-placement^="left"]>.arrow::before {
    right: 0;
    border-width: .3rem 0 .3rem .3rem;
    border-left-color: #f1f2f3;
  }
  #saasbox_page_content .bs-popover-left>.arrow::after,
  #saasbox_page_content .bs-popover-auto[x-placement^="left"]>.arrow::after {
    right: 1px;
    border-width: .3rem 0 .3rem .3rem;
    border-left-color: #fff;
  }
  #saasbox_page_content .popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: .9375rem;
    color: #323d47;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaedf1;
    border-top-left-radius: calc(-0.7rem);
    border-top-right-radius: calc(-0.7rem);
  }
  #saasbox_page_content .popover-header:empty {
    display: none;
  }
  #saasbox_page_content .popover-body {
    padding: .5rem .75rem;
    color: #757575;
  }
  #saasbox_page_content .carousel {
    position: relative;
  }
  #saasbox_page_content .carousel.pointer-event {
    touch-action: pan-y;
  }
  #saasbox_page_content .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  #saasbox_page_content .carousel-inner::after {
    display: block;
    clear: both;
    content: "";
  }
  #saasbox_page_content .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .6s ease-in-out;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .carousel-item {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .carousel-item.active,
  #saasbox_page_content .carousel-item-next,
  #saasbox_page_content .carousel-item-prev {
    display: block;
  }
  #saasbox_page_content .carousel-item-next:not(.carousel-item-left),
  #saasbox_page_content .active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  #saasbox_page_content .carousel-item-prev:not(.carousel-item-right),
  #saasbox_page_content .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  #saasbox_page_content .carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none;
  }
  #saasbox_page_content .carousel-fade .carousel-item.active,
  #saasbox_page_content .carousel-fade .carousel-item-next.carousel-item-left,
  #saasbox_page_content .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
  }
  #saasbox_page_content .carousel-fade .active.carousel-item-left,
  #saasbox_page_content .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: 0s .6s opacity;
    transition: 0s .6s opacity;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .carousel-fade .active.carousel-item-left,
    #saasbox_page_content .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .carousel-control-prev,
  #saasbox_page_content .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    -webkit-transition: opacity .15s ease;
    transition: opacity .15s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .carousel-control-prev,
    #saasbox_page_content .carousel-control-next {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .carousel-control-prev:hover,
  #saasbox_page_content .carousel-control-prev:focus,
  #saasbox_page_content .carousel-control-next:hover,
  #saasbox_page_content .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }
  #saasbox_page_content .carousel-control-prev {
    left: 0;
  }
  #saasbox_page_content .carousel-control-next {
    right: 0;
  }
  #saasbox_page_content .carousel-control-prev-icon,
  #saasbox_page_content .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 0.5% 100%;
  }
  #saasbox_page_content .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'fill='%23fff'viewBox='0088'%3e%3cpathd='M5.250l-44441.5-1.5-2.5-2.52.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  }
  #saasbox_page_content .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvgxmlns='http://www.w3.org/2000/svg'fill='%23fff'viewBox='0088'%3e%3cpathd='M2.750l-1.51.52.52.5-2.52.51.51.54-4-4-4z'/%3e%3c/svg%3e");
  }
  #saasbox_page_content .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  #saasbox_page_content .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity .6s ease;
    transition: opacity .6s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    #saasbox_page_content .carousel-indicators li {
      -webkit-transition: none;
      transition: none;
    }
  }
  #saasbox_page_content .carousel-indicators .active {
    opacity: 1;
  }
  #saasbox_page_content .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  #saasbox_page_content .align-baseline {
    vertical-align: baseline !important;
  }
  #saasbox_page_content .align-top {
    vertical-align: top !important;
  }
  #saasbox_page_content .align-middle {
    vertical-align: middle !important;
  }
  #saasbox_page_content .align-bottom {
    vertical-align: bottom !important;
  }
  #saasbox_page_content .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  #saasbox_page_content .align-text-top {
    vertical-align: text-top !important;
  }
  #saasbox_page_content .bg-primary {
    background-color: #50a1ff !important;
  }
  #saasbox_page_content a.bg-primary:hover,
  #saasbox_page_content a.bg-primary:focus,
  #saasbox_page_content button.bg-primary:hover,
  #saasbox_page_content button.bg-primary:focus {
    background-color: #1d86ff !important;
  }
  #saasbox_page_content .bg-secondary {
    background-color: #e9ecf0 !important;
  }
  #saasbox_page_content a.bg-secondary:hover,
  #saasbox_page_content a.bg-secondary:focus,
  #saasbox_page_content button.bg-secondary:hover,
  #saasbox_page_content button.bg-secondary:focus {
    background-color: #cbd2db !important;
  }
  #saasbox_page_content .bg-success {
    background-color: #3cd458 !important;
  }
  #saasbox_page_content a.bg-success:hover,
  #saasbox_page_content a.bg-success:focus,
  #saasbox_page_content button.bg-success:hover,
  #saasbox_page_content button.bg-success:focus {
    background-color: #28b542 !important;
  }
  #saasbox_page_content .bg-info {
    background-color: #926dde !important;
  }
  #saasbox_page_content a.bg-info:hover,
  #saasbox_page_content a.bg-info:focus,
  #saasbox_page_content button.bg-info:hover,
  #saasbox_page_content button.bg-info:focus {
    background-color: #7343d5 !important;
  }
  #saasbox_page_content .bg-warning {
    background-color: #ffba00 !important;
  }
  #saasbox_page_content a.bg-warning:hover,
  #saasbox_page_content a.bg-warning:focus,
  #saasbox_page_content button.bg-warning:hover,
  #saasbox_page_content button.bg-warning:focus {
    background-color: #cc9500 !important;
  }
  #saasbox_page_content .bg-danger {
    background-color: #ff4954 !important;
  }
  #saasbox_page_content a.bg-danger:hover,
  #saasbox_page_content a.bg-danger:focus,
  #saasbox_page_content button.bg-danger:hover,
  #saasbox_page_content button.bg-danger:focus {
    background-color: #ff1624 !important;
  }
  #saasbox_page_content .bg-light {
    background-color: #f8f9fa !important;
  }
  #saasbox_page_content a.bg-light:hover,
  #saasbox_page_content a.bg-light:focus,
  #saasbox_page_content button.bg-light:hover,
  #saasbox_page_content button.bg-light:focus {
    background-color: #dae0e5 !important;
  }
  #saasbox_page_content .bg-dark {
    background-color: #191919 !important;
  }
  #saasbox_page_content a.bg-dark:hover,
  #saasbox_page_content a.bg-dark:focus,
  #saasbox_page_content button.bg-dark:hover,
  #saasbox_page_content button.bg-dark:focus {
    background-color: black !important;
  }
  #saasbox_page_content .bg-white {
    background-color: #fff !important;
  }
  #saasbox_page_content .bg-transparent {
    background-color: transparent !important;
  }
  #saasbox_page_content .border {
    border: 1px solid #f1f2f3 !important;
  }
  #saasbox_page_content .border-top {
    border-top: 1px solid #f1f2f3 !important;
  }
  #saasbox_page_content .border-right {
    border-right: 1px solid #f1f2f3 !important;
  }
  #saasbox_page_content .border-bottom {
    border-bottom: 1px solid #f1f2f3 !important;
  }
  #saasbox_page_content .border-left {
    border-left: 1px solid #f1f2f3 !important;
  }
  #saasbox_page_content .border-0 {
    border: 0 !important;
  }
  #saasbox_page_content .border-top-0 {
    border-top: 0 !important;
  }
  #saasbox_page_content .border-right-0 {
    border-right: 0 !important;
  }
  #saasbox_page_content .border-bottom-0 {
    border-bottom: 0 !important;
  }
  #saasbox_page_content .border-left-0 {
    border-left: 0 !important;
  }
  #saasbox_page_content .border-primary {
    border-color: #50a1ff !important;
  }
  #saasbox_page_content .border-secondary {
    border-color: #e9ecf0 !important;
  }
  #saasbox_page_content .border-success {
    border-color: #3cd458 !important;
  }
  #saasbox_page_content .border-info {
    border-color: #926dde !important;
  }
  #saasbox_page_content .border-warning {
    border-color: #ffba00 !important;
  }
  #saasbox_page_content .border-danger {
    border-color: #ff4954 !important;
  }
  #saasbox_page_content .border-light {
    border-color: #f8f9fa !important;
  }
  #saasbox_page_content .border-dark {
    border-color: #191919 !important;
  }
  #saasbox_page_content .border-white {
    border-color: #fff !important;
  }
  #saasbox_page_content .rounded-sm {
    border-radius: .125rem !important;
  }
  #saasbox_page_content .rounded {
    border-radius: .25rem !important;
  }
  #saasbox_page_content .rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }
  #saasbox_page_content .rounded-right {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }
  #saasbox_page_content .rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }
  #saasbox_page_content .rounded-left {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }
  #saasbox_page_content .rounded-lg {
    border-radius: .3rem !important;
  }
  #saasbox_page_content .rounded-circle {
    border-radius: 50% !important;
  }
  #saasbox_page_content .rounded-pill {
    border-radius: 50rem !important;
  }
  #saasbox_page_content .rounded-0 {
    border-radius: 0 !important;
  }
  #saasbox_page_content .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  #saasbox_page_content .d-none {
    display: none !important;
  }
  #saasbox_page_content .d-inline {
    display: inline !important;
  }
  #saasbox_page_content .d-inline-block {
    display: inline-block !important;
  }
  #saasbox_page_content .d-block {
    display: block !important;
  }
  #saasbox_page_content .d-table {
    display: table !important;
  }
  #saasbox_page_content .d-table-row {
    display: table-row !important;
  }
  #saasbox_page_content .d-table-cell {
    display: table-cell !important;
  }
  #saasbox_page_content .d-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  #saasbox_page_content .d-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .d-sm-none {
      display: none !important;
    }
    #saasbox_page_content .d-sm-inline {
      display: inline !important;
    }
    #saasbox_page_content .d-sm-inline-block {
      display: inline-block !important;
    }
    #saasbox_page_content .d-sm-block {
      display: block !important;
    }
    #saasbox_page_content .d-sm-table {
      display: table !important;
    }
    #saasbox_page_content .d-sm-table-row {
      display: table-row !important;
    }
    #saasbox_page_content .d-sm-table-cell {
      display: table-cell !important;
    }
    #saasbox_page_content .d-sm-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .d-md-none {
      display: none !important;
    }
    #saasbox_page_content .d-md-inline {
      display: inline !important;
    }
    #saasbox_page_content .d-md-inline-block {
      display: inline-block !important;
    }
    #saasbox_page_content .d-md-block {
      display: block !important;
    }
    #saasbox_page_content .d-md-table {
      display: table !important;
    }
    #saasbox_page_content .d-md-table-row {
      display: table-row !important;
    }
    #saasbox_page_content .d-md-table-cell {
      display: table-cell !important;
    }
    #saasbox_page_content .d-md-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .d-lg-none {
      display: none !important;
    }
    #saasbox_page_content .d-lg-inline {
      display: inline !important;
    }
    #saasbox_page_content .d-lg-inline-block {
      display: inline-block !important;
    }
    #saasbox_page_content .d-lg-block {
      display: block !important;
    }
    #saasbox_page_content .d-lg-table {
      display: table !important;
    }
    #saasbox_page_content .d-lg-table-row {
      display: table-row !important;
    }
    #saasbox_page_content .d-lg-table-cell {
      display: table-cell !important;
    }
    #saasbox_page_content .d-lg-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .d-xl-none {
      display: none !important;
    }
    #saasbox_page_content .d-xl-inline {
      display: inline !important;
    }
    #saasbox_page_content .d-xl-inline-block {
      display: inline-block !important;
    }
    #saasbox_page_content .d-xl-block {
      display: block !important;
    }
    #saasbox_page_content .d-xl-table {
      display: table !important;
    }
    #saasbox_page_content .d-xl-table-row {
      display: table-row !important;
    }
    #saasbox_page_content .d-xl-table-cell {
      display: table-cell !important;
    }
    #saasbox_page_content .d-xl-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
  }
  @media print {
    #saasbox_page_content .d-print-none {
      display: none !important;
    }
    #saasbox_page_content .d-print-inline {
      display: inline !important;
    }
    #saasbox_page_content .d-print-inline-block {
      display: inline-block !important;
    }
    #saasbox_page_content .d-print-block {
      display: block !important;
    }
    #saasbox_page_content .d-print-table {
      display: table !important;
    }
    #saasbox_page_content .d-print-table-row {
      display: table-row !important;
    }
    #saasbox_page_content .d-print-table-cell {
      display: table-cell !important;
    }
    #saasbox_page_content .d-print-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .d-print-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
  }
  #saasbox_page_content .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  #saasbox_page_content .embed-responsive::before {
    display: block;
    content: "";
  }
  #saasbox_page_content .embed-responsive .embed-responsive-item,
  #saasbox_page_content .embed-responsive iframe,
  #saasbox_page_content .embed-responsive embed,
  #saasbox_page_content .embed-responsive object,
  #saasbox_page_content .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  #saasbox_page_content .embed-responsive-21by9::before {
    padding-top: 42.85714%;
  }
  #saasbox_page_content .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  #saasbox_page_content .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  #saasbox_page_content .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  #saasbox_page_content .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
  }
  #saasbox_page_content .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    flex-direction: column !important;
  }
  #saasbox_page_content .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    flex-direction: row-reverse !important;
  }
  #saasbox_page_content .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    flex-direction: column-reverse !important;
  }
  #saasbox_page_content .flex-wrap {
    flex-wrap: wrap !important;
  }
  #saasbox_page_content .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  #saasbox_page_content .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  #saasbox_page_content .flex-fill {
    -webkit-box-flex: 1 !important;
    flex: 1 1 auto !important;
  }
  #saasbox_page_content .flex-grow-0 {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }
  #saasbox_page_content .flex-grow-1 {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }
  #saasbox_page_content .flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  #saasbox_page_content .flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  #saasbox_page_content .justify-content-start {
    -webkit-box-pack: start !important;
    justify-content: flex-start !important;
  }
  #saasbox_page_content .justify-content-end {
    -webkit-box-pack: end !important;
    justify-content: flex-end !important;
  }
  #saasbox_page_content .justify-content-center,
  #saasbox_page_content .nav.nav-center {
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }
  #saasbox_page_content .justify-content-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }
  #saasbox_page_content .justify-content-around {
    justify-content: space-around !important;
  }
  #saasbox_page_content .align-items-start {
    -webkit-box-align: start !important;
    align-items: flex-start !important;
  }
  #saasbox_page_content .align-items-end {
    -webkit-box-align: end !important;
    align-items: flex-end !important;
  }
  #saasbox_page_content .align-items-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  #saasbox_page_content .align-items-baseline {
    -webkit-box-align: baseline !important;
    align-items: baseline !important;
  }
  #saasbox_page_content .align-items-stretch {
    -webkit-box-align: stretch !important;
    align-items: stretch !important;
  }
  #saasbox_page_content .align-content-start {
    align-content: flex-start !important;
  }
  #saasbox_page_content .align-content-end {
    align-content: flex-end !important;
  }
  #saasbox_page_content .align-content-center {
    align-content: center !important;
  }
  #saasbox_page_content .align-content-between {
    align-content: space-between !important;
  }
  #saasbox_page_content .align-content-around {
    align-content: space-around !important;
  }
  #saasbox_page_content .align-content-stretch {
    align-content: stretch !important;
  }
  #saasbox_page_content .align-self-auto {
    align-self: auto !important;
  }
  #saasbox_page_content .align-self-start {
    align-self: flex-start !important;
  }
  #saasbox_page_content .align-self-end {
    align-self: flex-end !important;
  }
  #saasbox_page_content .align-self-center {
    align-self: center !important;
  }
  #saasbox_page_content .align-self-baseline {
    align-self: baseline !important;
  }
  #saasbox_page_content .align-self-stretch {
    align-self: stretch !important;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      flex-direction: row !important;
    }
    #saasbox_page_content .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      flex-direction: column !important;
    }
    #saasbox_page_content .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      flex-direction: row-reverse !important;
    }
    #saasbox_page_content .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      flex-direction: column-reverse !important;
    }
    #saasbox_page_content .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    #saasbox_page_content .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    #saasbox_page_content .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    #saasbox_page_content .flex-sm-fill {
      -webkit-box-flex: 1 !important;
      flex: 1 1 auto !important;
    }
    #saasbox_page_content .flex-sm-grow-0 {
      -webkit-box-flex: 0 !important;
      flex-grow: 0 !important;
    }
    #saasbox_page_content .flex-sm-grow-1 {
      -webkit-box-flex: 1 !important;
      flex-grow: 1 !important;
    }
    #saasbox_page_content .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    #saasbox_page_content .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    #saasbox_page_content .justify-content-sm-start {
      -webkit-box-pack: start !important;
      justify-content: flex-start !important;
    }
    #saasbox_page_content .justify-content-sm-end {
      -webkit-box-pack: end !important;
      justify-content: flex-end !important;
    }
    #saasbox_page_content .justify-content-sm-center {
      -webkit-box-pack: center !important;
      justify-content: center !important;
    }
    #saasbox_page_content .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      justify-content: space-between !important;
    }
    #saasbox_page_content .justify-content-sm-around {
      justify-content: space-around !important;
    }
    #saasbox_page_content .align-items-sm-start {
      -webkit-box-align: start !important;
      align-items: flex-start !important;
    }
    #saasbox_page_content .align-items-sm-end {
      -webkit-box-align: end !important;
      align-items: flex-end !important;
    }
    #saasbox_page_content .align-items-sm-center {
      -webkit-box-align: center !important;
      align-items: center !important;
    }
    #saasbox_page_content .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      align-items: baseline !important;
    }
    #saasbox_page_content .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      align-items: stretch !important;
    }
    #saasbox_page_content .align-content-sm-start {
      align-content: flex-start !important;
    }
    #saasbox_page_content .align-content-sm-end {
      align-content: flex-end !important;
    }
    #saasbox_page_content .align-content-sm-center {
      align-content: center !important;
    }
    #saasbox_page_content .align-content-sm-between {
      align-content: space-between !important;
    }
    #saasbox_page_content .align-content-sm-around {
      align-content: space-around !important;
    }
    #saasbox_page_content .align-content-sm-stretch {
      align-content: stretch !important;
    }
    #saasbox_page_content .align-self-sm-auto {
      align-self: auto !important;
    }
    #saasbox_page_content .align-self-sm-start {
      align-self: flex-start !important;
    }
    #saasbox_page_content .align-self-sm-end {
      align-self: flex-end !important;
    }
    #saasbox_page_content .align-self-sm-center {
      align-self: center !important;
    }
    #saasbox_page_content .align-self-sm-baseline {
      align-self: baseline !important;
    }
    #saasbox_page_content .align-self-sm-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      flex-direction: row !important;
    }
    #saasbox_page_content .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      flex-direction: column !important;
    }
    #saasbox_page_content .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      flex-direction: row-reverse !important;
    }
    #saasbox_page_content .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      flex-direction: column-reverse !important;
    }
    #saasbox_page_content .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    #saasbox_page_content .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    #saasbox_page_content .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    #saasbox_page_content .flex-md-fill {
      -webkit-box-flex: 1 !important;
      flex: 1 1 auto !important;
    }
    #saasbox_page_content .flex-md-grow-0 {
      -webkit-box-flex: 0 !important;
      flex-grow: 0 !important;
    }
    #saasbox_page_content .flex-md-grow-1 {
      -webkit-box-flex: 1 !important;
      flex-grow: 1 !important;
    }
    #saasbox_page_content .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    #saasbox_page_content .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    #saasbox_page_content .justify-content-md-start {
      -webkit-box-pack: start !important;
      justify-content: flex-start !important;
    }
    #saasbox_page_content .justify-content-md-end {
      -webkit-box-pack: end !important;
      justify-content: flex-end !important;
    }
    #saasbox_page_content .justify-content-md-center {
      -webkit-box-pack: center !important;
      justify-content: center !important;
    }
    #saasbox_page_content .justify-content-md-between {
      -webkit-box-pack: justify !important;
      justify-content: space-between !important;
    }
    #saasbox_page_content .justify-content-md-around {
      justify-content: space-around !important;
    }
    #saasbox_page_content .align-items-md-start {
      -webkit-box-align: start !important;
      align-items: flex-start !important;
    }
    #saasbox_page_content .align-items-md-end {
      -webkit-box-align: end !important;
      align-items: flex-end !important;
    }
    #saasbox_page_content .align-items-md-center {
      -webkit-box-align: center !important;
      align-items: center !important;
    }
    #saasbox_page_content .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      align-items: baseline !important;
    }
    #saasbox_page_content .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      align-items: stretch !important;
    }
    #saasbox_page_content .align-content-md-start {
      align-content: flex-start !important;
    }
    #saasbox_page_content .align-content-md-end {
      align-content: flex-end !important;
    }
    #saasbox_page_content .align-content-md-center {
      align-content: center !important;
    }
    #saasbox_page_content .align-content-md-between {
      align-content: space-between !important;
    }
    #saasbox_page_content .align-content-md-around {
      align-content: space-around !important;
    }
    #saasbox_page_content .align-content-md-stretch {
      align-content: stretch !important;
    }
    #saasbox_page_content .align-self-md-auto {
      align-self: auto !important;
    }
    #saasbox_page_content .align-self-md-start {
      align-self: flex-start !important;
    }
    #saasbox_page_content .align-self-md-end {
      align-self: flex-end !important;
    }
    #saasbox_page_content .align-self-md-center {
      align-self: center !important;
    }
    #saasbox_page_content .align-self-md-baseline {
      align-self: baseline !important;
    }
    #saasbox_page_content .align-self-md-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      flex-direction: row !important;
    }
    #saasbox_page_content .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      flex-direction: column !important;
    }
    #saasbox_page_content .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      flex-direction: row-reverse !important;
    }
    #saasbox_page_content .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      flex-direction: column-reverse !important;
    }
    #saasbox_page_content .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    #saasbox_page_content .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    #saasbox_page_content .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    #saasbox_page_content .flex-lg-fill {
      -webkit-box-flex: 1 !important;
      flex: 1 1 auto !important;
    }
    #saasbox_page_content .flex-lg-grow-0 {
      -webkit-box-flex: 0 !important;
      flex-grow: 0 !important;
    }
    #saasbox_page_content .flex-lg-grow-1 {
      -webkit-box-flex: 1 !important;
      flex-grow: 1 !important;
    }
    #saasbox_page_content .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    #saasbox_page_content .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    #saasbox_page_content .justify-content-lg-start {
      -webkit-box-pack: start !important;
      justify-content: flex-start !important;
    }
    #saasbox_page_content .justify-content-lg-end {
      -webkit-box-pack: end !important;
      justify-content: flex-end !important;
    }
    #saasbox_page_content .justify-content-lg-center {
      -webkit-box-pack: center !important;
      justify-content: center !important;
    }
    #saasbox_page_content .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      justify-content: space-between !important;
    }
    #saasbox_page_content .justify-content-lg-around {
      justify-content: space-around !important;
    }
    #saasbox_page_content .align-items-lg-start {
      -webkit-box-align: start !important;
      align-items: flex-start !important;
    }
    #saasbox_page_content .align-items-lg-end {
      -webkit-box-align: end !important;
      align-items: flex-end !important;
    }
    #saasbox_page_content .align-items-lg-center {
      -webkit-box-align: center !important;
      align-items: center !important;
    }
    #saasbox_page_content .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      align-items: baseline !important;
    }
    #saasbox_page_content .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      align-items: stretch !important;
    }
    #saasbox_page_content .align-content-lg-start {
      align-content: flex-start !important;
    }
    #saasbox_page_content .align-content-lg-end {
      align-content: flex-end !important;
    }
    #saasbox_page_content .align-content-lg-center {
      align-content: center !important;
    }
    #saasbox_page_content .align-content-lg-between {
      align-content: space-between !important;
    }
    #saasbox_page_content .align-content-lg-around {
      align-content: space-around !important;
    }
    #saasbox_page_content .align-content-lg-stretch {
      align-content: stretch !important;
    }
    #saasbox_page_content .align-self-lg-auto {
      align-self: auto !important;
    }
    #saasbox_page_content .align-self-lg-start {
      align-self: flex-start !important;
    }
    #saasbox_page_content .align-self-lg-end {
      align-self: flex-end !important;
    }
    #saasbox_page_content .align-self-lg-center {
      align-self: center !important;
    }
    #saasbox_page_content .align-self-lg-baseline {
      align-self: baseline !important;
    }
    #saasbox_page_content .align-self-lg-stretch {
      align-self: stretch !important;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      flex-direction: row !important;
    }
    #saasbox_page_content .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      flex-direction: column !important;
    }
    #saasbox_page_content .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      flex-direction: row-reverse !important;
    }
    #saasbox_page_content .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      flex-direction: column-reverse !important;
    }
    #saasbox_page_content .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    #saasbox_page_content .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    #saasbox_page_content .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    #saasbox_page_content .flex-xl-fill {
      -webkit-box-flex: 1 !important;
      flex: 1 1 auto !important;
    }
    #saasbox_page_content .flex-xl-grow-0 {
      -webkit-box-flex: 0 !important;
      flex-grow: 0 !important;
    }
    #saasbox_page_content .flex-xl-grow-1 {
      -webkit-box-flex: 1 !important;
      flex-grow: 1 !important;
    }
    #saasbox_page_content .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    #saasbox_page_content .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    #saasbox_page_content .justify-content-xl-start {
      -webkit-box-pack: start !important;
      justify-content: flex-start !important;
    }
    #saasbox_page_content .justify-content-xl-end {
      -webkit-box-pack: end !important;
      justify-content: flex-end !important;
    }
    #saasbox_page_content .justify-content-xl-center {
      -webkit-box-pack: center !important;
      justify-content: center !important;
    }
    #saasbox_page_content .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      justify-content: space-between !important;
    }
    #saasbox_page_content .justify-content-xl-around {
      justify-content: space-around !important;
    }
    #saasbox_page_content .align-items-xl-start {
      -webkit-box-align: start !important;
      align-items: flex-start !important;
    }
    #saasbox_page_content .align-items-xl-end {
      -webkit-box-align: end !important;
      align-items: flex-end !important;
    }
    #saasbox_page_content .align-items-xl-center {
      -webkit-box-align: center !important;
      align-items: center !important;
    }
    #saasbox_page_content .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      align-items: baseline !important;
    }
    #saasbox_page_content .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      align-items: stretch !important;
    }
    #saasbox_page_content .align-content-xl-start {
      align-content: flex-start !important;
    }
    #saasbox_page_content .align-content-xl-end {
      align-content: flex-end !important;
    }
    #saasbox_page_content .align-content-xl-center {
      align-content: center !important;
    }
    #saasbox_page_content .align-content-xl-between {
      align-content: space-between !important;
    }
    #saasbox_page_content .align-content-xl-around {
      align-content: space-around !important;
    }
    #saasbox_page_content .align-content-xl-stretch {
      align-content: stretch !important;
    }
    #saasbox_page_content .align-self-xl-auto {
      align-self: auto !important;
    }
    #saasbox_page_content .align-self-xl-start {
      align-self: flex-start !important;
    }
    #saasbox_page_content .align-self-xl-end {
      align-self: flex-end !important;
    }
    #saasbox_page_content .align-self-xl-center {
      align-self: center !important;
    }
    #saasbox_page_content .align-self-xl-baseline {
      align-self: baseline !important;
    }
    #saasbox_page_content .align-self-xl-stretch {
      align-self: stretch !important;
    }
  }
  #saasbox_page_content .float-left {
    float: left !important;
  }
  #saasbox_page_content .float-right {
    float: right !important;
  }
  #saasbox_page_content .float-none {
    float: none !important;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .float-sm-left {
      float: left !important;
    }
    #saasbox_page_content .float-sm-right {
      float: right !important;
    }
    #saasbox_page_content .float-sm-none {
      float: none !important;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .float-md-left {
      float: left !important;
    }
    #saasbox_page_content .float-md-right {
      float: right !important;
    }
    #saasbox_page_content .float-md-none {
      float: none !important;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .float-lg-left {
      float: left !important;
    }
    #saasbox_page_content .float-lg-right {
      float: right !important;
    }
    #saasbox_page_content .float-lg-none {
      float: none !important;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .float-xl-left {
      float: left !important;
    }
    #saasbox_page_content .float-xl-right {
      float: right !important;
    }
    #saasbox_page_content .float-xl-none {
      float: none !important;
    }
  }
  #saasbox_page_content .overflow-auto {
    overflow: auto !important;
  }
  #saasbox_page_content .overflow-hidden {
    overflow: hidden !important;
  }
  #saasbox_page_content .position-static {
    position: static !important;
  }
  #saasbox_page_content .position-relative {
    position: relative !important;
  }
  #saasbox_page_content .position-absolute {
    position: absolute !important;
  }
  #saasbox_page_content .position-fixed {
    position: fixed !important;
  }
  #saasbox_page_content .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  #saasbox_page_content .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  #saasbox_page_content .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  #saasbox_page_content .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
  }
  #saasbox_page_content .sr-only-focusable:active,
  #saasbox_page_content .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  #saasbox_page_content .shadow-sm {
    -webkit-box-shadow: 0 .125rem .25rem rgba(0,0,0,0.075) !important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,0.075) !important;
  }
  #saasbox_page_content .shadow {
    -webkit-box-shadow: 0 1px 15px rgba(0,0,0,0.05) !important;
    box-shadow: 0 1px 15px rgba(0,0,0,0.05) !important;
  }
  #saasbox_page_content .shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(0,0,0,0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0,0,0,0.175) !important;
  }
  #saasbox_page_content .shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  #saasbox_page_content .w-25 {
    width: 25% !important;
  }
  #saasbox_page_content .w-50 {
    width: 50% !important;
  }
  #saasbox_page_content .w-75 {
    width: 75% !important;
  }
  #saasbox_page_content .w-100 {
    width: 100% !important;
  }
  #saasbox_page_content .w-auto {
    width: auto !important;
  }
  #saasbox_page_content .w-5 {
    width: 5% !important;
  }
  #saasbox_page_content .w-10 {
    width: 10% !important;
  }
  #saasbox_page_content .w-15 {
    width: 15% !important;
  }
  #saasbox_page_content .w-20 {
    width: 20% !important;
  }
  #saasbox_page_content .w-30 {
    width: 30% !important;
  }
  #saasbox_page_content .w-40 {
    width: 40% !important;
  }
  #saasbox_page_content .w-60 {
    width: 60% !important;
  }
  #saasbox_page_content .w-70 {
    width: 70% !important;
  }
  #saasbox_page_content .w-80 {
    width: 80% !important;
  }
  #saasbox_page_content .w-90 {
    width: 90% !important;
  }
  #saasbox_page_content .h-25 {
    height: 25% !important;
  }
  #saasbox_page_content .h-50 {
    height: 50% !important;
  }
  #saasbox_page_content .h-75 {
    height: 75% !important;
  }
  #saasbox_page_content .h-100 {
    height: 100% !important;
  }
  #saasbox_page_content .h-auto {
    height: auto !important;
  }
  #saasbox_page_content .h-5 {
    height: 5% !important;
  }
  #saasbox_page_content .h-10 {
    height: 10% !important;
  }
  #saasbox_page_content .h-15 {
    height: 15% !important;
  }
  #saasbox_page_content .h-20 {
    height: 20% !important;
  }
  #saasbox_page_content .h-30 {
    height: 30% !important;
  }
  #saasbox_page_content .h-40 {
    height: 40% !important;
  }
  #saasbox_page_content .h-60 {
    height: 60% !important;
  }
  #saasbox_page_content .h-70 {
    height: 70% !important;
  }
  #saasbox_page_content .h-80 {
    height: 80% !important;
  }
  #saasbox_page_content .h-90 {
    height: 90% !important;
  }
  #saasbox_page_content .mw-100 {
    max-width: 100% !important;
  }
  #saasbox_page_content .mh-100 {
    max-height: 100% !important;
  }
  #saasbox_page_content .min-vw-100 {
    min-width: 100vw !important;
  }
  #saasbox_page_content .min-vh-100 {
    min-height: 100vh !important;
  }
  #saasbox_page_content .vw-100 {
    width: 100vw !important;
  }
  #saasbox_page_content .vh-100 {
    height: 100vh !important;
  }
  #saasbox_page_content .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0,0,0,0);
  }
  #saasbox_page_content .m-0 {
    margin: 0 !important;
  }
  #saasbox_page_content .mt-0,
  #saasbox_page_content .my-0 {
    margin-top: 0 !important;
  }
  #saasbox_page_content .mr-0,
  #saasbox_page_content .mx-0 {
    margin-right: 0 !important;
  }
  #saasbox_page_content .mb-0,
  #saasbox_page_content .my-0 {
    margin-bottom: 0 !important;
  }
  #saasbox_page_content .ml-0,
  #saasbox_page_content .mx-0 {
    margin-left: 0 !important;
  }
  #saasbox_page_content .m-1 {
    margin: .25rem !important;
  }
  #saasbox_page_content .mt-1,
  #saasbox_page_content .my-1 {
    margin-top: .25rem !important;
  }
  #saasbox_page_content .mr-1,
  #saasbox_page_content .mx-1 {
    margin-right: .25rem !important;
  }
  #saasbox_page_content .mb-1,
  #saasbox_page_content .my-1 {
    margin-bottom: .25rem !important;
  }
  #saasbox_page_content .ml-1,
  #saasbox_page_content .mx-1 {
    margin-left: .25rem !important;
  }
  #saasbox_page_content .m-2 {
    margin: .5rem !important;
  }
  #saasbox_page_content .mt-2,
  #saasbox_page_content .my-2 {
    margin-top: .5rem !important;
  }
  #saasbox_page_content .mr-2,
  #saasbox_page_content .mx-2 {
    margin-right: .5rem !important;
  }
  #saasbox_page_content .mb-2,
  #saasbox_page_content .my-2 {
    margin-bottom: .5rem !important;
  }
  #saasbox_page_content .ml-2,
  #saasbox_page_content .mx-2 {
    margin-left: .5rem !important;
  }
  #saasbox_page_content .m-3 {
    margin: .75rem !important;
  }
  #saasbox_page_content .mt-3,
  #saasbox_page_content .my-3 {
    margin-top: .75rem !important;
  }
  #saasbox_page_content .mr-3,
  #saasbox_page_content .mx-3 {
    margin-right: .75rem !important;
  }
  #saasbox_page_content .mb-3,
  #saasbox_page_content .my-3 {
    margin-bottom: .75rem !important;
  }
  #saasbox_page_content .ml-3,
  #saasbox_page_content .mx-3 {
    margin-left: .75rem !important;
  }
  #saasbox_page_content .m-4 {
    margin: 1rem !important;
  }
  #saasbox_page_content .mt-4,
  #saasbox_page_content .my-4 {
    margin-top: 1rem !important;
  }
  #saasbox_page_content .mr-4,
  #saasbox_page_content .mx-4 {
    margin-right: 1rem !important;
  }
  #saasbox_page_content .mb-4,
  #saasbox_page_content .my-4 {
    margin-bottom: 1rem !important;
  }
  #saasbox_page_content .ml-4,
  #saasbox_page_content .mx-4 {
    margin-left: 1rem !important;
  }
  #saasbox_page_content .m-5 {
    margin: 1.5rem !important;
  }
  #saasbox_page_content .mt-5,
  #saasbox_page_content .my-5 {
    margin-top: 1.5rem !important;
  }
  #saasbox_page_content .mr-5,
  #saasbox_page_content .mx-5 {
    margin-right: 1.5rem !important;
  }
  #saasbox_page_content .mb-5,
  #saasbox_page_content .my-5 {
    margin-bottom: 1.5rem !important;
  }
  #saasbox_page_content .ml-5,
  #saasbox_page_content .mx-5 {
    margin-left: 1.5rem !important;
  }
  #saasbox_page_content .m-6 {
    margin: 2rem !important;
  }
  #saasbox_page_content .mt-6,
  #saasbox_page_content .my-6 {
    margin-top: 2rem !important;
  }
  #saasbox_page_content .mr-6,
  #saasbox_page_content .mx-6 {
    margin-right: 2rem !important;
  }
  #saasbox_page_content .mb-6,
  #saasbox_page_content .my-6 {
    margin-bottom: 2rem !important;
  }
  #saasbox_page_content .ml-6,
  #saasbox_page_content .mx-6 {
    margin-left: 2rem !important;
  }
  #saasbox_page_content .m-7 {
    margin: 3rem !important;
  }
  #saasbox_page_content .mt-7,
  #saasbox_page_content .my-7 {
    margin-top: 3rem !important;
  }
  #saasbox_page_content .mr-7,
  #saasbox_page_content .mx-7 {
    margin-right: 3rem !important;
  }
  #saasbox_page_content .mb-7,
  #saasbox_page_content .my-7 {
    margin-bottom: 3rem !important;
  }
  #saasbox_page_content .ml-7,
  #saasbox_page_content .mx-7 {
    margin-left: 3rem !important;
  }
  #saasbox_page_content .m-8 {
    margin: 5rem !important;
  }
  #saasbox_page_content .mt-8,
  #saasbox_page_content .my-8 {
    margin-top: 5rem !important;
  }
  #saasbox_page_content .mr-8,
  #saasbox_page_content .mx-8 {
    margin-right: 5rem !important;
  }
  #saasbox_page_content .mb-8,
  #saasbox_page_content .my-8 {
    margin-bottom: 5rem !important;
  }
  #saasbox_page_content .ml-8,
  #saasbox_page_content .mx-8 {
    margin-left: 5rem !important;
  }
  #saasbox_page_content .m-9 {
    margin: 7rem !important;
  }
  #saasbox_page_content .mt-9,
  #saasbox_page_content .my-9 {
    margin-top: 7rem !important;
  }
  #saasbox_page_content .mr-9,
  #saasbox_page_content .mx-9 {
    margin-right: 7rem !important;
  }
  #saasbox_page_content .mb-9,
  #saasbox_page_content .my-9 {
    margin-bottom: 7rem !important;
  }
  #saasbox_page_content .ml-9,
  #saasbox_page_content .mx-9 {
    margin-left: 7rem !important;
  }
  #saasbox_page_content .m-10 {
    margin: 9rem !important;
  }
  #saasbox_page_content .mt-10,
  #saasbox_page_content .my-10 {
    margin-top: 9rem !important;
  }
  #saasbox_page_content .mr-10,
  #saasbox_page_content .mx-10 {
    margin-right: 9rem !important;
  }
  #saasbox_page_content .mb-10,
  #saasbox_page_content .my-10 {
    margin-bottom: 9rem !important;
  }
  #saasbox_page_content .ml-10,
  #saasbox_page_content .mx-10 {
    margin-left: 9rem !important;
  }
  #saasbox_page_content .m-11 {
    margin: 11rem !important;
  }
  #saasbox_page_content .mt-11,
  #saasbox_page_content .my-11 {
    margin-top: 11rem !important;
  }
  #saasbox_page_content .mr-11,
  #saasbox_page_content .mx-11 {
    margin-right: 11rem !important;
  }
  #saasbox_page_content .mb-11,
  #saasbox_page_content .my-11 {
    margin-bottom: 11rem !important;
  }
  #saasbox_page_content .ml-11,
  #saasbox_page_content .mx-11 {
    margin-left: 11rem !important;
  }
  #saasbox_page_content .m-12 {
    margin: 13rem !important;
  }
  #saasbox_page_content .mt-12,
  #saasbox_page_content .my-12 {
    margin-top: 13rem !important;
  }
  #saasbox_page_content .mr-12,
  #saasbox_page_content .mx-12 {
    margin-right: 13rem !important;
  }
  #saasbox_page_content .mb-12,
  #saasbox_page_content .my-12 {
    margin-bottom: 13rem !important;
  }
  #saasbox_page_content .ml-12,
  #saasbox_page_content .mx-12 {
    margin-left: 13rem !important;
  }
  #saasbox_page_content .m-13 {
    margin: 16rem !important;
  }
  #saasbox_page_content .mt-13,
  #saasbox_page_content .my-13 {
    margin-top: 16rem !important;
  }
  #saasbox_page_content .mr-13,
  #saasbox_page_content .mx-13 {
    margin-right: 16rem !important;
  }
  #saasbox_page_content .mb-13,
  #saasbox_page_content .my-13 {
    margin-bottom: 16rem !important;
  }
  #saasbox_page_content .ml-13,
  #saasbox_page_content .mx-13 {
    margin-left: 16rem !important;
  }
  #saasbox_page_content .p-0 {
    padding: 0 !important;
  }
  #saasbox_page_content .pt-0,
  #saasbox_page_content .py-0 {
    padding-top: 0 !important;
  }
  #saasbox_page_content .pr-0,
  #saasbox_page_content .px-0 {
    padding-right: 0 !important;
  }
  #saasbox_page_content .pb-0,
  #saasbox_page_content .py-0 {
    padding-bottom: 0 !important;
  }
  #saasbox_page_content .pl-0,
  #saasbox_page_content .px-0 {
    padding-left: 0 !important;
  }
  #saasbox_page_content .p-1 {
    padding: .25rem !important;
  }
  #saasbox_page_content .pt-1,
  #saasbox_page_content .py-1 {
    padding-top: .25rem !important;
  }
  #saasbox_page_content .pr-1,
  #saasbox_page_content .px-1 {
    padding-right: .25rem !important;
  }
  #saasbox_page_content .pb-1,
  #saasbox_page_content .py-1 {
    padding-bottom: .25rem !important;
  }
  #saasbox_page_content .pl-1,
  #saasbox_page_content .px-1 {
    padding-left: .25rem !important;
  }
  #saasbox_page_content .p-2 {
    padding: .5rem !important;
  }
  #saasbox_page_content .pt-2,
  #saasbox_page_content .py-2 {
    padding-top: .5rem !important;
  }
  #saasbox_page_content .pr-2,
  #saasbox_page_content .px-2 {
    padding-right: .5rem !important;
  }
  #saasbox_page_content .pb-2,
  #saasbox_page_content .py-2 {
    padding-bottom: .5rem !important;
  }
  #saasbox_page_content .pl-2,
  #saasbox_page_content .px-2 {
    padding-left: .5rem !important;
  }
  #saasbox_page_content .p-3 {
    padding: .75rem !important;
  }
  #saasbox_page_content .pt-3,
  #saasbox_page_content .py-3 {
    padding-top: .75rem !important;
  }
  #saasbox_page_content .pr-3,
  #saasbox_page_content .px-3 {
    padding-right: .75rem !important;
  }
  #saasbox_page_content .pb-3,
  #saasbox_page_content .py-3 {
    padding-bottom: .75rem !important;
  }
  #saasbox_page_content .pl-3,
  #saasbox_page_content .px-3 {
    padding-left: .75rem !important;
  }
  #saasbox_page_content .p-4 {
    padding: 1rem !important;
  }
  #saasbox_page_content .pt-4,
  #saasbox_page_content .py-4 {
    padding-top: 1rem !important;
  }
  #saasbox_page_content .pr-4,
  #saasbox_page_content .px-4 {
    padding-right: 1rem !important;
  }
  #saasbox_page_content .pb-4,
  #saasbox_page_content .py-4 {
    padding-bottom: 1rem !important;
  }
  #saasbox_page_content .pl-4,
  #saasbox_page_content .px-4 {
    padding-left: 1rem !important;
  }
  #saasbox_page_content .p-5 {
    padding: 1.5rem !important;
  }
  #saasbox_page_content .pt-5,
  #saasbox_page_content .py-5 {
    padding-top: 1.5rem !important;
  }
  #saasbox_page_content .pr-5,
  #saasbox_page_content .px-5 {
    padding-right: 1.5rem !important;
  }
  #saasbox_page_content .pb-5,
  #saasbox_page_content .py-5 {
    padding-bottom: 1.5rem !important;
  }
  #saasbox_page_content .pl-5,
  #saasbox_page_content .px-5 {
    padding-left: 1.5rem !important;
  }
  #saasbox_page_content .p-6 {
    padding: 2rem !important;
  }
  #saasbox_page_content .pt-6,
  #saasbox_page_content .py-6 {
    padding-top: 2rem !important;
  }
  #saasbox_page_content .pr-6,
  #saasbox_page_content .px-6 {
    padding-right: 2rem !important;
  }
  #saasbox_page_content .pb-6,
  #saasbox_page_content .py-6 {
    padding-bottom: 2rem !important;
  }
  #saasbox_page_content .pl-6,
  #saasbox_page_content .px-6 {
    padding-left: 2rem !important;
  }
  #saasbox_page_content .p-7 {
    padding: 3rem !important;
  }
  #saasbox_page_content .pt-7,
  #saasbox_page_content .py-7 {
    padding-top: 3rem !important;
  }
  #saasbox_page_content .pr-7,
  #saasbox_page_content .px-7 {
    padding-right: 3rem !important;
  }
  #saasbox_page_content .pb-7,
  #saasbox_page_content .py-7 {
    padding-bottom: 3rem !important;
  }
  #saasbox_page_content .pl-7,
  #saasbox_page_content .px-7 {
    padding-left: 3rem !important;
  }
  #saasbox_page_content .p-8 {
    padding: 5rem !important;
  }
  #saasbox_page_content .pt-8,
  #saasbox_page_content .py-8 {
    padding-top: 5rem !important;
  }
  #saasbox_page_content .pr-8,
  #saasbox_page_content .px-8 {
    padding-right: 5rem !important;
  }
  #saasbox_page_content .pb-8,
  #saasbox_page_content .py-8 {
    padding-bottom: 5rem !important;
  }
  #saasbox_page_content .pl-8,
  #saasbox_page_content .px-8 {
    padding-left: 5rem !important;
  }
  #saasbox_page_content .p-9 {
    padding: 7rem !important;
  }
  #saasbox_page_content .pt-9,
  #saasbox_page_content .py-9 {
    padding-top: 7rem !important;
  }
  #saasbox_page_content .pr-9,
  #saasbox_page_content .px-9 {
    padding-right: 7rem !important;
  }
  #saasbox_page_content .pb-9,
  #saasbox_page_content .py-9 {
    padding-bottom: 7rem !important;
  }
  #saasbox_page_content .pl-9,
  #saasbox_page_content .px-9 {
    padding-left: 7rem !important;
  }
  #saasbox_page_content .p-10 {
    padding: 9rem !important;
  }
  #saasbox_page_content .pt-10,
  #saasbox_page_content .py-10 {
    padding-top: 9rem !important;
  }
  #saasbox_page_content .pr-10,
  #saasbox_page_content .px-10 {
    padding-right: 9rem !important;
  }
  #saasbox_page_content .pb-10,
  #saasbox_page_content .py-10 {
    padding-bottom: 9rem !important;
  }
  #saasbox_page_content .pl-10,
  #saasbox_page_content .px-10 {
    padding-left: 9rem !important;
  }
  #saasbox_page_content .p-11 {
    padding: 11rem !important;
  }
  #saasbox_page_content .pt-11,
  #saasbox_page_content .py-11 {
    padding-top: 11rem !important;
  }
  #saasbox_page_content .pr-11,
  #saasbox_page_content .px-11 {
    padding-right: 11rem !important;
  }
  #saasbox_page_content .pb-11,
  #saasbox_page_content .py-11 {
    padding-bottom: 11rem !important;
  }
  #saasbox_page_content .pl-11,
  #saasbox_page_content .px-11 {
    padding-left: 11rem !important;
  }
  #saasbox_page_content .p-12 {
    padding: 13rem !important;
  }
  #saasbox_page_content .pt-12,
  #saasbox_page_content .py-12 {
    padding-top: 13rem !important;
  }
  #saasbox_page_content .pr-12,
  #saasbox_page_content .px-12 {
    padding-right: 13rem !important;
  }
  #saasbox_page_content .pb-12,
  #saasbox_page_content .py-12 {
    padding-bottom: 13rem !important;
  }
  #saasbox_page_content .pl-12,
  #saasbox_page_content .px-12 {
    padding-left: 13rem !important;
  }
  #saasbox_page_content .p-13 {
    padding: 16rem !important;
  }
  #saasbox_page_content .pt-13,
  #saasbox_page_content .py-13 {
    padding-top: 16rem !important;
  }
  #saasbox_page_content .pr-13,
  #saasbox_page_content .px-13 {
    padding-right: 16rem !important;
  }
  #saasbox_page_content .pb-13,
  #saasbox_page_content .py-13 {
    padding-bottom: 16rem !important;
  }
  #saasbox_page_content .pl-13,
  #saasbox_page_content .px-13 {
    padding-left: 16rem !important;
  }
  #saasbox_page_content .m-n1 {
    margin: -0.25rem !important;
  }
  #saasbox_page_content .mt-n1,
  #saasbox_page_content .my-n1 {
    margin-top: -0.25rem !important;
  }
  #saasbox_page_content .mr-n1,
  #saasbox_page_content .mx-n1 {
    margin-right: -0.25rem !important;
  }
  #saasbox_page_content .mb-n1,
  #saasbox_page_content .my-n1 {
    margin-bottom: -0.25rem !important;
  }
  #saasbox_page_content .ml-n1,
  #saasbox_page_content .mx-n1 {
    margin-left: -0.25rem !important;
  }
  #saasbox_page_content .m-n2 {
    margin: -0.5rem !important;
  }
  #saasbox_page_content .mt-n2,
  #saasbox_page_content .my-n2 {
    margin-top: -0.5rem !important;
  }
  #saasbox_page_content .mr-n2,
  #saasbox_page_content .mx-n2 {
    margin-right: -0.5rem !important;
  }
  #saasbox_page_content .mb-n2,
  #saasbox_page_content .my-n2 {
    margin-bottom: -0.5rem !important;
  }
  #saasbox_page_content .ml-n2,
  #saasbox_page_content .mx-n2 {
    margin-left: -0.5rem !important;
  }
  #saasbox_page_content .m-n3 {
    margin: -0.75rem !important;
  }
  #saasbox_page_content .mt-n3,
  #saasbox_page_content .my-n3 {
    margin-top: -0.75rem !important;
  }
  #saasbox_page_content .mr-n3,
  #saasbox_page_content .mx-n3 {
    margin-right: -0.75rem !important;
  }
  #saasbox_page_content .mb-n3,
  #saasbox_page_content .my-n3 {
    margin-bottom: -0.75rem !important;
  }
  #saasbox_page_content .ml-n3,
  #saasbox_page_content .mx-n3 {
    margin-left: -0.75rem !important;
  }
  #saasbox_page_content .m-n4 {
    margin: -1rem !important;
  }
  #saasbox_page_content .mt-n4,
  #saasbox_page_content .my-n4 {
    margin-top: -1rem !important;
  }
  #saasbox_page_content .mr-n4,
  #saasbox_page_content .mx-n4 {
    margin-right: -1rem !important;
  }
  #saasbox_page_content .mb-n4,
  #saasbox_page_content .my-n4 {
    margin-bottom: -1rem !important;
  }
  #saasbox_page_content .ml-n4,
  #saasbox_page_content .mx-n4 {
    margin-left: -1rem !important;
  }
  #saasbox_page_content .m-n5 {
    margin: -1.5rem !important;
  }
  #saasbox_page_content .mt-n5,
  #saasbox_page_content .my-n5 {
    margin-top: -1.5rem !important;
  }
  #saasbox_page_content .mr-n5,
  #saasbox_page_content .mx-n5 {
    margin-right: -1.5rem !important;
  }
  #saasbox_page_content .mb-n5,
  #saasbox_page_content .my-n5 {
    margin-bottom: -1.5rem !important;
  }
  #saasbox_page_content .ml-n5,
  #saasbox_page_content .mx-n5 {
    margin-left: -1.5rem !important;
  }
  #saasbox_page_content .m-n6 {
    margin: -2rem !important;
  }
  #saasbox_page_content .mt-n6,
  #saasbox_page_content .my-n6 {
    margin-top: -2rem !important;
  }
  #saasbox_page_content .mr-n6,
  #saasbox_page_content .mx-n6 {
    margin-right: -2rem !important;
  }
  #saasbox_page_content .mb-n6,
  #saasbox_page_content .my-n6 {
    margin-bottom: -2rem !important;
  }
  #saasbox_page_content .ml-n6,
  #saasbox_page_content .mx-n6 {
    margin-left: -2rem !important;
  }
  #saasbox_page_content .m-n7 {
    margin: -3rem !important;
  }
  #saasbox_page_content .mt-n7,
  #saasbox_page_content .my-n7 {
    margin-top: -3rem !important;
  }
  #saasbox_page_content .mr-n7,
  #saasbox_page_content .mx-n7 {
    margin-right: -3rem !important;
  }
  #saasbox_page_content .mb-n7,
  #saasbox_page_content .my-n7 {
    margin-bottom: -3rem !important;
  }
  #saasbox_page_content .ml-n7,
  #saasbox_page_content .mx-n7 {
    margin-left: -3rem !important;
  }
  #saasbox_page_content .m-n8 {
    margin: -5rem !important;
  }
  #saasbox_page_content .mt-n8,
  #saasbox_page_content .my-n8 {
    margin-top: -5rem !important;
  }
  #saasbox_page_content .mr-n8,
  #saasbox_page_content .mx-n8 {
    margin-right: -5rem !important;
  }
  #saasbox_page_content .mb-n8,
  #saasbox_page_content .my-n8 {
    margin-bottom: -5rem !important;
  }
  #saasbox_page_content .ml-n8,
  #saasbox_page_content .mx-n8 {
    margin-left: -5rem !important;
  }
  #saasbox_page_content .m-n9 {
    margin: -7rem !important;
  }
  #saasbox_page_content .mt-n9,
  #saasbox_page_content .my-n9 {
    margin-top: -7rem !important;
  }
  #saasbox_page_content .mr-n9,
  #saasbox_page_content .mx-n9 {
    margin-right: -7rem !important;
  }
  #saasbox_page_content .mb-n9,
  #saasbox_page_content .my-n9 {
    margin-bottom: -7rem !important;
  }
  #saasbox_page_content .ml-n9,
  #saasbox_page_content .mx-n9 {
    margin-left: -7rem !important;
  }
  #saasbox_page_content .m-n10 {
    margin: -9rem !important;
  }
  #saasbox_page_content .mt-n10,
  #saasbox_page_content .my-n10 {
    margin-top: -9rem !important;
  }
  #saasbox_page_content .mr-n10,
  #saasbox_page_content .mx-n10 {
    margin-right: -9rem !important;
  }
  #saasbox_page_content .mb-n10,
  #saasbox_page_content .my-n10 {
    margin-bottom: -9rem !important;
  }
  #saasbox_page_content .ml-n10,
  #saasbox_page_content .mx-n10 {
    margin-left: -9rem !important;
  }
  #saasbox_page_content .m-n11 {
    margin: -11rem !important;
  }
  #saasbox_page_content .mt-n11,
  #saasbox_page_content .my-n11 {
    margin-top: -11rem !important;
  }
  #saasbox_page_content .mr-n11,
  #saasbox_page_content .mx-n11 {
    margin-right: -11rem !important;
  }
  #saasbox_page_content .mb-n11,
  #saasbox_page_content .my-n11 {
    margin-bottom: -11rem !important;
  }
  #saasbox_page_content .ml-n11,
  #saasbox_page_content .mx-n11 {
    margin-left: -11rem !important;
  }
  #saasbox_page_content .m-n12 {
    margin: -13rem !important;
  }
  #saasbox_page_content .mt-n12,
  #saasbox_page_content .my-n12 {
    margin-top: -13rem !important;
  }
  #saasbox_page_content .mr-n12,
  #saasbox_page_content .mx-n12 {
    margin-right: -13rem !important;
  }
  #saasbox_page_content .mb-n12,
  #saasbox_page_content .my-n12 {
    margin-bottom: -13rem !important;
  }
  #saasbox_page_content .ml-n12,
  #saasbox_page_content .mx-n12 {
    margin-left: -13rem !important;
  }
  #saasbox_page_content .m-n13 {
    margin: -16rem !important;
  }
  #saasbox_page_content .mt-n13,
  #saasbox_page_content .my-n13 {
    margin-top: -16rem !important;
  }
  #saasbox_page_content .mr-n13,
  #saasbox_page_content .mx-n13 {
    margin-right: -16rem !important;
  }
  #saasbox_page_content .mb-n13,
  #saasbox_page_content .my-n13 {
    margin-bottom: -16rem !important;
  }
  #saasbox_page_content .ml-n13,
  #saasbox_page_content .mx-n13 {
    margin-left: -16rem !important;
  }
  #saasbox_page_content .m-auto {
    margin: auto !important;
  }
  #saasbox_page_content .mt-auto,
  #saasbox_page_content .my-auto {
    margin-top: auto !important;
  }
  #saasbox_page_content .mr-auto,
  #saasbox_page_content .mx-auto {
    margin-right: auto !important;
  }
  #saasbox_page_content .mb-auto,
  #saasbox_page_content .my-auto {
    margin-bottom: auto !important;
  }
  #saasbox_page_content .ml-auto,
  #saasbox_page_content .mx-auto {
    margin-left: auto !important;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .m-sm-0 {
      margin: 0 !important;
    }
    #saasbox_page_content .mt-sm-0,
    #saasbox_page_content .my-sm-0 {
      margin-top: 0 !important;
    }
    #saasbox_page_content .mr-sm-0,
    #saasbox_page_content .mx-sm-0 {
      margin-right: 0 !important;
    }
    #saasbox_page_content .mb-sm-0,
    #saasbox_page_content .my-sm-0 {
      margin-bottom: 0 !important;
    }
    #saasbox_page_content .ml-sm-0,
    #saasbox_page_content .mx-sm-0 {
      margin-left: 0 !important;
    }
    #saasbox_page_content .m-sm-1 {
      margin: .25rem !important;
    }
    #saasbox_page_content .mt-sm-1,
    #saasbox_page_content .my-sm-1 {
      margin-top: .25rem !important;
    }
    #saasbox_page_content .mr-sm-1,
    #saasbox_page_content .mx-sm-1 {
      margin-right: .25rem !important;
    }
    #saasbox_page_content .mb-sm-1,
    #saasbox_page_content .my-sm-1 {
      margin-bottom: .25rem !important;
    }
    #saasbox_page_content .ml-sm-1,
    #saasbox_page_content .mx-sm-1 {
      margin-left: .25rem !important;
    }
    #saasbox_page_content .m-sm-2 {
      margin: .5rem !important;
    }
    #saasbox_page_content .mt-sm-2,
    #saasbox_page_content .my-sm-2 {
      margin-top: .5rem !important;
    }
    #saasbox_page_content .mr-sm-2,
    #saasbox_page_content .mx-sm-2 {
      margin-right: .5rem !important;
    }
    #saasbox_page_content .mb-sm-2,
    #saasbox_page_content .my-sm-2 {
      margin-bottom: .5rem !important;
    }
    #saasbox_page_content .ml-sm-2,
    #saasbox_page_content .mx-sm-2 {
      margin-left: .5rem !important;
    }
    #saasbox_page_content .m-sm-3 {
      margin: .75rem !important;
    }
    #saasbox_page_content .mt-sm-3,
    #saasbox_page_content .my-sm-3 {
      margin-top: .75rem !important;
    }
    #saasbox_page_content .mr-sm-3,
    #saasbox_page_content .mx-sm-3 {
      margin-right: .75rem !important;
    }
    #saasbox_page_content .mb-sm-3,
    #saasbox_page_content .my-sm-3 {
      margin-bottom: .75rem !important;
    }
    #saasbox_page_content .ml-sm-3,
    #saasbox_page_content .mx-sm-3 {
      margin-left: .75rem !important;
    }
    #saasbox_page_content .m-sm-4 {
      margin: 1rem !important;
    }
    #saasbox_page_content .mt-sm-4,
    #saasbox_page_content .my-sm-4 {
      margin-top: 1rem !important;
    }
    #saasbox_page_content .mr-sm-4,
    #saasbox_page_content .mx-sm-4 {
      margin-right: 1rem !important;
    }
    #saasbox_page_content .mb-sm-4,
    #saasbox_page_content .my-sm-4 {
      margin-bottom: 1rem !important;
    }
    #saasbox_page_content .ml-sm-4,
    #saasbox_page_content .mx-sm-4 {
      margin-left: 1rem !important;
    }
    #saasbox_page_content .m-sm-5 {
      margin: 1.5rem !important;
    }
    #saasbox_page_content .mt-sm-5,
    #saasbox_page_content .my-sm-5 {
      margin-top: 1.5rem !important;
    }
    #saasbox_page_content .mr-sm-5,
    #saasbox_page_content .mx-sm-5 {
      margin-right: 1.5rem !important;
    }
    #saasbox_page_content .mb-sm-5,
    #saasbox_page_content .my-sm-5 {
      margin-bottom: 1.5rem !important;
    }
    #saasbox_page_content .ml-sm-5,
    #saasbox_page_content .mx-sm-5 {
      margin-left: 1.5rem !important;
    }
    #saasbox_page_content .m-sm-6 {
      margin: 2rem !important;
    }
    #saasbox_page_content .mt-sm-6,
    #saasbox_page_content .my-sm-6 {
      margin-top: 2rem !important;
    }
    #saasbox_page_content .mr-sm-6,
    #saasbox_page_content .mx-sm-6 {
      margin-right: 2rem !important;
    }
    #saasbox_page_content .mb-sm-6,
    #saasbox_page_content .my-sm-6 {
      margin-bottom: 2rem !important;
    }
    #saasbox_page_content .ml-sm-6,
    #saasbox_page_content .mx-sm-6 {
      margin-left: 2rem !important;
    }
    #saasbox_page_content .m-sm-7 {
      margin: 3rem !important;
    }
    #saasbox_page_content .mt-sm-7,
    #saasbox_page_content .my-sm-7 {
      margin-top: 3rem !important;
    }
    #saasbox_page_content .mr-sm-7,
    #saasbox_page_content .mx-sm-7 {
      margin-right: 3rem !important;
    }
    #saasbox_page_content .mb-sm-7,
    #saasbox_page_content .my-sm-7 {
      margin-bottom: 3rem !important;
    }
    #saasbox_page_content .ml-sm-7,
    #saasbox_page_content .mx-sm-7 {
      margin-left: 3rem !important;
    }
    #saasbox_page_content .m-sm-8 {
      margin: 5rem !important;
    }
    #saasbox_page_content .mt-sm-8,
    #saasbox_page_content .my-sm-8 {
      margin-top: 5rem !important;
    }
    #saasbox_page_content .mr-sm-8,
    #saasbox_page_content .mx-sm-8 {
      margin-right: 5rem !important;
    }
    #saasbox_page_content .mb-sm-8,
    #saasbox_page_content .my-sm-8 {
      margin-bottom: 5rem !important;
    }
    #saasbox_page_content .ml-sm-8,
    #saasbox_page_content .mx-sm-8 {
      margin-left: 5rem !important;
    }
    #saasbox_page_content .m-sm-9 {
      margin: 7rem !important;
    }
    #saasbox_page_content .mt-sm-9,
    #saasbox_page_content .my-sm-9 {
      margin-top: 7rem !important;
    }
    #saasbox_page_content .mr-sm-9,
    #saasbox_page_content .mx-sm-9 {
      margin-right: 7rem !important;
    }
    #saasbox_page_content .mb-sm-9,
    #saasbox_page_content .my-sm-9 {
      margin-bottom: 7rem !important;
    }
    #saasbox_page_content .ml-sm-9,
    #saasbox_page_content .mx-sm-9 {
      margin-left: 7rem !important;
    }
    #saasbox_page_content .m-sm-10 {
      margin: 9rem !important;
    }
    #saasbox_page_content .mt-sm-10,
    #saasbox_page_content .my-sm-10 {
      margin-top: 9rem !important;
    }
    #saasbox_page_content .mr-sm-10,
    #saasbox_page_content .mx-sm-10 {
      margin-right: 9rem !important;
    }
    #saasbox_page_content .mb-sm-10,
    #saasbox_page_content .my-sm-10 {
      margin-bottom: 9rem !important;
    }
    #saasbox_page_content .ml-sm-10,
    #saasbox_page_content .mx-sm-10 {
      margin-left: 9rem !important;
    }
    #saasbox_page_content .m-sm-11 {
      margin: 11rem !important;
    }
    #saasbox_page_content .mt-sm-11,
    #saasbox_page_content .my-sm-11 {
      margin-top: 11rem !important;
    }
    #saasbox_page_content .mr-sm-11,
    #saasbox_page_content .mx-sm-11 {
      margin-right: 11rem !important;
    }
    #saasbox_page_content .mb-sm-11,
    #saasbox_page_content .my-sm-11 {
      margin-bottom: 11rem !important;
    }
    #saasbox_page_content .ml-sm-11,
    #saasbox_page_content .mx-sm-11 {
      margin-left: 11rem !important;
    }
    #saasbox_page_content .m-sm-12 {
      margin: 13rem !important;
    }
    #saasbox_page_content .mt-sm-12,
    #saasbox_page_content .my-sm-12 {
      margin-top: 13rem !important;
    }
    #saasbox_page_content .mr-sm-12,
    #saasbox_page_content .mx-sm-12 {
      margin-right: 13rem !important;
    }
    #saasbox_page_content .mb-sm-12,
    #saasbox_page_content .my-sm-12 {
      margin-bottom: 13rem !important;
    }
    #saasbox_page_content .ml-sm-12,
    #saasbox_page_content .mx-sm-12 {
      margin-left: 13rem !important;
    }
    #saasbox_page_content .m-sm-13 {
      margin: 16rem !important;
    }
    #saasbox_page_content .mt-sm-13,
    #saasbox_page_content .my-sm-13 {
      margin-top: 16rem !important;
    }
    #saasbox_page_content .mr-sm-13,
    #saasbox_page_content .mx-sm-13 {
      margin-right: 16rem !important;
    }
    #saasbox_page_content .mb-sm-13,
    #saasbox_page_content .my-sm-13 {
      margin-bottom: 16rem !important;
    }
    #saasbox_page_content .ml-sm-13,
    #saasbox_page_content .mx-sm-13 {
      margin-left: 16rem !important;
    }
    #saasbox_page_content .p-sm-0 {
      padding: 0 !important;
    }
    #saasbox_page_content .pt-sm-0,
    #saasbox_page_content .py-sm-0 {
      padding-top: 0 !important;
    }
    #saasbox_page_content .pr-sm-0,
    #saasbox_page_content .px-sm-0 {
      padding-right: 0 !important;
    }
    #saasbox_page_content .pb-sm-0,
    #saasbox_page_content .py-sm-0 {
      padding-bottom: 0 !important;
    }
    #saasbox_page_content .pl-sm-0,
    #saasbox_page_content .px-sm-0 {
      padding-left: 0 !important;
    }
    #saasbox_page_content .p-sm-1 {
      padding: .25rem !important;
    }
    #saasbox_page_content .pt-sm-1,
    #saasbox_page_content .py-sm-1 {
      padding-top: .25rem !important;
    }
    #saasbox_page_content .pr-sm-1,
    #saasbox_page_content .px-sm-1 {
      padding-right: .25rem !important;
    }
    #saasbox_page_content .pb-sm-1,
    #saasbox_page_content .py-sm-1 {
      padding-bottom: .25rem !important;
    }
    #saasbox_page_content .pl-sm-1,
    #saasbox_page_content .px-sm-1 {
      padding-left: .25rem !important;
    }
    #saasbox_page_content .p-sm-2 {
      padding: .5rem !important;
    }
    #saasbox_page_content .pt-sm-2,
    #saasbox_page_content .py-sm-2 {
      padding-top: .5rem !important;
    }
    #saasbox_page_content .pr-sm-2,
    #saasbox_page_content .px-sm-2 {
      padding-right: .5rem !important;
    }
    #saasbox_page_content .pb-sm-2,
    #saasbox_page_content .py-sm-2 {
      padding-bottom: .5rem !important;
    }
    #saasbox_page_content .pl-sm-2,
    #saasbox_page_content .px-sm-2 {
      padding-left: .5rem !important;
    }
    #saasbox_page_content .p-sm-3 {
      padding: .75rem !important;
    }
    #saasbox_page_content .pt-sm-3,
    #saasbox_page_content .py-sm-3 {
      padding-top: .75rem !important;
    }
    #saasbox_page_content .pr-sm-3,
    #saasbox_page_content .px-sm-3 {
      padding-right: .75rem !important;
    }
    #saasbox_page_content .pb-sm-3,
    #saasbox_page_content .py-sm-3 {
      padding-bottom: .75rem !important;
    }
    #saasbox_page_content .pl-sm-3,
    #saasbox_page_content .px-sm-3 {
      padding-left: .75rem !important;
    }
    #saasbox_page_content .p-sm-4 {
      padding: 1rem !important;
    }
    #saasbox_page_content .pt-sm-4,
    #saasbox_page_content .py-sm-4 {
      padding-top: 1rem !important;
    }
    #saasbox_page_content .pr-sm-4,
    #saasbox_page_content .px-sm-4 {
      padding-right: 1rem !important;
    }
    #saasbox_page_content .pb-sm-4,
    #saasbox_page_content .py-sm-4 {
      padding-bottom: 1rem !important;
    }
    #saasbox_page_content .pl-sm-4,
    #saasbox_page_content .px-sm-4 {
      padding-left: 1rem !important;
    }
    #saasbox_page_content .p-sm-5 {
      padding: 1.5rem !important;
    }
    #saasbox_page_content .pt-sm-5,
    #saasbox_page_content .py-sm-5 {
      padding-top: 1.5rem !important;
    }
    #saasbox_page_content .pr-sm-5,
    #saasbox_page_content .px-sm-5 {
      padding-right: 1.5rem !important;
    }
    #saasbox_page_content .pb-sm-5,
    #saasbox_page_content .py-sm-5 {
      padding-bottom: 1.5rem !important;
    }
    #saasbox_page_content .pl-sm-5,
    #saasbox_page_content .px-sm-5 {
      padding-left: 1.5rem !important;
    }
    #saasbox_page_content .p-sm-6 {
      padding: 2rem !important;
    }
    #saasbox_page_content .pt-sm-6,
    #saasbox_page_content .py-sm-6 {
      padding-top: 2rem !important;
    }
    #saasbox_page_content .pr-sm-6,
    #saasbox_page_content .px-sm-6 {
      padding-right: 2rem !important;
    }
    #saasbox_page_content .pb-sm-6,
    #saasbox_page_content .py-sm-6 {
      padding-bottom: 2rem !important;
    }
    #saasbox_page_content .pl-sm-6,
    #saasbox_page_content .px-sm-6 {
      padding-left: 2rem !important;
    }
    #saasbox_page_content .p-sm-7 {
      padding: 3rem !important;
    }
    #saasbox_page_content .pt-sm-7,
    #saasbox_page_content .py-sm-7 {
      padding-top: 3rem !important;
    }
    #saasbox_page_content .pr-sm-7,
    #saasbox_page_content .px-sm-7 {
      padding-right: 3rem !important;
    }
    #saasbox_page_content .pb-sm-7,
    #saasbox_page_content .py-sm-7 {
      padding-bottom: 3rem !important;
    }
    #saasbox_page_content .pl-sm-7,
    #saasbox_page_content .px-sm-7 {
      padding-left: 3rem !important;
    }
    #saasbox_page_content .p-sm-8 {
      padding: 5rem !important;
    }
    #saasbox_page_content .pt-sm-8,
    #saasbox_page_content .py-sm-8 {
      padding-top: 5rem !important;
    }
    #saasbox_page_content .pr-sm-8,
    #saasbox_page_content .px-sm-8 {
      padding-right: 5rem !important;
    }
    #saasbox_page_content .pb-sm-8,
    #saasbox_page_content .py-sm-8 {
      padding-bottom: 5rem !important;
    }
    #saasbox_page_content .pl-sm-8,
    #saasbox_page_content .px-sm-8 {
      padding-left: 5rem !important;
    }
    #saasbox_page_content .p-sm-9 {
      padding: 7rem !important;
    }
    #saasbox_page_content .pt-sm-9,
    #saasbox_page_content .py-sm-9 {
      padding-top: 7rem !important;
    }
    #saasbox_page_content .pr-sm-9,
    #saasbox_page_content .px-sm-9 {
      padding-right: 7rem !important;
    }
    #saasbox_page_content .pb-sm-9,
    #saasbox_page_content .py-sm-9 {
      padding-bottom: 7rem !important;
    }
    #saasbox_page_content .pl-sm-9,
    #saasbox_page_content .px-sm-9 {
      padding-left: 7rem !important;
    }
    #saasbox_page_content .p-sm-10 {
      padding: 9rem !important;
    }
    #saasbox_page_content .pt-sm-10,
    #saasbox_page_content .py-sm-10 {
      padding-top: 9rem !important;
    }
    #saasbox_page_content .pr-sm-10,
    #saasbox_page_content .px-sm-10 {
      padding-right: 9rem !important;
    }
    #saasbox_page_content .pb-sm-10,
    #saasbox_page_content .py-sm-10 {
      padding-bottom: 9rem !important;
    }
    #saasbox_page_content .pl-sm-10,
    #saasbox_page_content .px-sm-10 {
      padding-left: 9rem !important;
    }
    #saasbox_page_content .p-sm-11 {
      padding: 11rem !important;
    }
    #saasbox_page_content .pt-sm-11,
    #saasbox_page_content .py-sm-11 {
      padding-top: 11rem !important;
    }
    #saasbox_page_content .pr-sm-11,
    #saasbox_page_content .px-sm-11 {
      padding-right: 11rem !important;
    }
    #saasbox_page_content .pb-sm-11,
    #saasbox_page_content .py-sm-11 {
      padding-bottom: 11rem !important;
    }
    #saasbox_page_content .pl-sm-11,
    #saasbox_page_content .px-sm-11 {
      padding-left: 11rem !important;
    }
    #saasbox_page_content .p-sm-12 {
      padding: 13rem !important;
    }
    #saasbox_page_content .pt-sm-12,
    #saasbox_page_content .py-sm-12 {
      padding-top: 13rem !important;
    }
    #saasbox_page_content .pr-sm-12,
    #saasbox_page_content .px-sm-12 {
      padding-right: 13rem !important;
    }
    #saasbox_page_content .pb-sm-12,
    #saasbox_page_content .py-sm-12 {
      padding-bottom: 13rem !important;
    }
    #saasbox_page_content .pl-sm-12,
    #saasbox_page_content .px-sm-12 {
      padding-left: 13rem !important;
    }
    #saasbox_page_content .p-sm-13 {
      padding: 16rem !important;
    }
    #saasbox_page_content .pt-sm-13,
    #saasbox_page_content .py-sm-13 {
      padding-top: 16rem !important;
    }
    #saasbox_page_content .pr-sm-13,
    #saasbox_page_content .px-sm-13 {
      padding-right: 16rem !important;
    }
    #saasbox_page_content .pb-sm-13,
    #saasbox_page_content .py-sm-13 {
      padding-bottom: 16rem !important;
    }
    #saasbox_page_content .pl-sm-13,
    #saasbox_page_content .px-sm-13 {
      padding-left: 16rem !important;
    }
    #saasbox_page_content .m-sm-n1 {
      margin: -0.25rem !important;
    }
    #saasbox_page_content .mt-sm-n1,
    #saasbox_page_content .my-sm-n1 {
      margin-top: -0.25rem !important;
    }
    #saasbox_page_content .mr-sm-n1,
    #saasbox_page_content .mx-sm-n1 {
      margin-right: -0.25rem !important;
    }
    #saasbox_page_content .mb-sm-n1,
    #saasbox_page_content .my-sm-n1 {
      margin-bottom: -0.25rem !important;
    }
    #saasbox_page_content .ml-sm-n1,
    #saasbox_page_content .mx-sm-n1 {
      margin-left: -0.25rem !important;
    }
    #saasbox_page_content .m-sm-n2 {
      margin: -0.5rem !important;
    }
    #saasbox_page_content .mt-sm-n2,
    #saasbox_page_content .my-sm-n2 {
      margin-top: -0.5rem !important;
    }
    #saasbox_page_content .mr-sm-n2,
    #saasbox_page_content .mx-sm-n2 {
      margin-right: -0.5rem !important;
    }
    #saasbox_page_content .mb-sm-n2,
    #saasbox_page_content .my-sm-n2 {
      margin-bottom: -0.5rem !important;
    }
    #saasbox_page_content .ml-sm-n2,
    #saasbox_page_content .mx-sm-n2 {
      margin-left: -0.5rem !important;
    }
    #saasbox_page_content .m-sm-n3 {
      margin: -0.75rem !important;
    }
    #saasbox_page_content .mt-sm-n3,
    #saasbox_page_content .my-sm-n3 {
      margin-top: -0.75rem !important;
    }
    #saasbox_page_content .mr-sm-n3,
    #saasbox_page_content .mx-sm-n3 {
      margin-right: -0.75rem !important;
    }
    #saasbox_page_content .mb-sm-n3,
    #saasbox_page_content .my-sm-n3 {
      margin-bottom: -0.75rem !important;
    }
    #saasbox_page_content .ml-sm-n3,
    #saasbox_page_content .mx-sm-n3 {
      margin-left: -0.75rem !important;
    }
    #saasbox_page_content .m-sm-n4 {
      margin: -1rem !important;
    }
    #saasbox_page_content .mt-sm-n4,
    #saasbox_page_content .my-sm-n4 {
      margin-top: -1rem !important;
    }
    #saasbox_page_content .mr-sm-n4,
    #saasbox_page_content .mx-sm-n4 {
      margin-right: -1rem !important;
    }
    #saasbox_page_content .mb-sm-n4,
    #saasbox_page_content .my-sm-n4 {
      margin-bottom: -1rem !important;
    }
    #saasbox_page_content .ml-sm-n4,
    #saasbox_page_content .mx-sm-n4 {
      margin-left: -1rem !important;
    }
    #saasbox_page_content .m-sm-n5 {
      margin: -1.5rem !important;
    }
    #saasbox_page_content .mt-sm-n5,
    #saasbox_page_content .my-sm-n5 {
      margin-top: -1.5rem !important;
    }
    #saasbox_page_content .mr-sm-n5,
    #saasbox_page_content .mx-sm-n5 {
      margin-right: -1.5rem !important;
    }
    #saasbox_page_content .mb-sm-n5,
    #saasbox_page_content .my-sm-n5 {
      margin-bottom: -1.5rem !important;
    }
    #saasbox_page_content .ml-sm-n5,
    #saasbox_page_content .mx-sm-n5 {
      margin-left: -1.5rem !important;
    }
    #saasbox_page_content .m-sm-n6 {
      margin: -2rem !important;
    }
    #saasbox_page_content .mt-sm-n6,
    #saasbox_page_content .my-sm-n6 {
      margin-top: -2rem !important;
    }
    #saasbox_page_content .mr-sm-n6,
    #saasbox_page_content .mx-sm-n6 {
      margin-right: -2rem !important;
    }
    #saasbox_page_content .mb-sm-n6,
    #saasbox_page_content .my-sm-n6 {
      margin-bottom: -2rem !important;
    }
    #saasbox_page_content .ml-sm-n6,
    #saasbox_page_content .mx-sm-n6 {
      margin-left: -2rem !important;
    }
    #saasbox_page_content .m-sm-n7 {
      margin: -3rem !important;
    }
    #saasbox_page_content .mt-sm-n7,
    #saasbox_page_content .my-sm-n7 {
      margin-top: -3rem !important;
    }
    #saasbox_page_content .mr-sm-n7,
    #saasbox_page_content .mx-sm-n7 {
      margin-right: -3rem !important;
    }
    #saasbox_page_content .mb-sm-n7,
    #saasbox_page_content .my-sm-n7 {
      margin-bottom: -3rem !important;
    }
    #saasbox_page_content .ml-sm-n7,
    #saasbox_page_content .mx-sm-n7 {
      margin-left: -3rem !important;
    }
    #saasbox_page_content .m-sm-n8 {
      margin: -5rem !important;
    }
    #saasbox_page_content .mt-sm-n8,
    #saasbox_page_content .my-sm-n8 {
      margin-top: -5rem !important;
    }
    #saasbox_page_content .mr-sm-n8,
    #saasbox_page_content .mx-sm-n8 {
      margin-right: -5rem !important;
    }
    #saasbox_page_content .mb-sm-n8,
    #saasbox_page_content .my-sm-n8 {
      margin-bottom: -5rem !important;
    }
    #saasbox_page_content .ml-sm-n8,
    #saasbox_page_content .mx-sm-n8 {
      margin-left: -5rem !important;
    }
    #saasbox_page_content .m-sm-n9 {
      margin: -7rem !important;
    }
    #saasbox_page_content .mt-sm-n9,
    #saasbox_page_content .my-sm-n9 {
      margin-top: -7rem !important;
    }
    #saasbox_page_content .mr-sm-n9,
    #saasbox_page_content .mx-sm-n9 {
      margin-right: -7rem !important;
    }
    #saasbox_page_content .mb-sm-n9,
    #saasbox_page_content .my-sm-n9 {
      margin-bottom: -7rem !important;
    }
    #saasbox_page_content .ml-sm-n9,
    #saasbox_page_content .mx-sm-n9 {
      margin-left: -7rem !important;
    }
    #saasbox_page_content .m-sm-n10 {
      margin: -9rem !important;
    }
    #saasbox_page_content .mt-sm-n10,
    #saasbox_page_content .my-sm-n10 {
      margin-top: -9rem !important;
    }
    #saasbox_page_content .mr-sm-n10,
    #saasbox_page_content .mx-sm-n10 {
      margin-right: -9rem !important;
    }
    #saasbox_page_content .mb-sm-n10,
    #saasbox_page_content .my-sm-n10 {
      margin-bottom: -9rem !important;
    }
    #saasbox_page_content .ml-sm-n10,
    #saasbox_page_content .mx-sm-n10 {
      margin-left: -9rem !important;
    }
    #saasbox_page_content .m-sm-n11 {
      margin: -11rem !important;
    }
    #saasbox_page_content .mt-sm-n11,
    #saasbox_page_content .my-sm-n11 {
      margin-top: -11rem !important;
    }
    #saasbox_page_content .mr-sm-n11,
    #saasbox_page_content .mx-sm-n11 {
      margin-right: -11rem !important;
    }
    #saasbox_page_content .mb-sm-n11,
    #saasbox_page_content .my-sm-n11 {
      margin-bottom: -11rem !important;
    }
    #saasbox_page_content .ml-sm-n11,
    #saasbox_page_content .mx-sm-n11 {
      margin-left: -11rem !important;
    }
    #saasbox_page_content .m-sm-n12 {
      margin: -13rem !important;
    }
    #saasbox_page_content .mt-sm-n12,
    #saasbox_page_content .my-sm-n12 {
      margin-top: -13rem !important;
    }
    #saasbox_page_content .mr-sm-n12,
    #saasbox_page_content .mx-sm-n12 {
      margin-right: -13rem !important;
    }
    #saasbox_page_content .mb-sm-n12,
    #saasbox_page_content .my-sm-n12 {
      margin-bottom: -13rem !important;
    }
    #saasbox_page_content .ml-sm-n12,
    #saasbox_page_content .mx-sm-n12 {
      margin-left: -13rem !important;
    }
    #saasbox_page_content .m-sm-n13 {
      margin: -16rem !important;
    }
    #saasbox_page_content .mt-sm-n13,
    #saasbox_page_content .my-sm-n13 {
      margin-top: -16rem !important;
    }
    #saasbox_page_content .mr-sm-n13,
    #saasbox_page_content .mx-sm-n13 {
      margin-right: -16rem !important;
    }
    #saasbox_page_content .mb-sm-n13,
    #saasbox_page_content .my-sm-n13 {
      margin-bottom: -16rem !important;
    }
    #saasbox_page_content .ml-sm-n13,
    #saasbox_page_content .mx-sm-n13 {
      margin-left: -16rem !important;
    }
    #saasbox_page_content .m-sm-auto {
      margin: auto !important;
    }
    #saasbox_page_content .mt-sm-auto,
    #saasbox_page_content .my-sm-auto {
      margin-top: auto !important;
    }
    #saasbox_page_content .mr-sm-auto,
    #saasbox_page_content .mx-sm-auto {
      margin-right: auto !important;
    }
    #saasbox_page_content .mb-sm-auto,
    #saasbox_page_content .my-sm-auto {
      margin-bottom: auto !important;
    }
    #saasbox_page_content .ml-sm-auto,
    #saasbox_page_content .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .m-md-0 {
      margin: 0 !important;
    }
    #saasbox_page_content .mt-md-0,
    #saasbox_page_content .my-md-0 {
      margin-top: 0 !important;
    }
    #saasbox_page_content .mr-md-0,
    #saasbox_page_content .mx-md-0 {
      margin-right: 0 !important;
    }
    #saasbox_page_content .mb-md-0,
    #saasbox_page_content .my-md-0 {
      margin-bottom: 0 !important;
    }
    #saasbox_page_content .ml-md-0,
    #saasbox_page_content .mx-md-0 {
      margin-left: 0 !important;
    }
    #saasbox_page_content .m-md-1 {
      margin: .25rem !important;
    }
    #saasbox_page_content .mt-md-1,
    #saasbox_page_content .my-md-1 {
      margin-top: .25rem !important;
    }
    #saasbox_page_content .mr-md-1,
    #saasbox_page_content .mx-md-1 {
      margin-right: .25rem !important;
    }
    #saasbox_page_content .mb-md-1,
    #saasbox_page_content .my-md-1 {
      margin-bottom: .25rem !important;
    }
    #saasbox_page_content .ml-md-1,
    #saasbox_page_content .mx-md-1 {
      margin-left: .25rem !important;
    }
    #saasbox_page_content .m-md-2 {
      margin: .5rem !important;
    }
    #saasbox_page_content .mt-md-2,
    #saasbox_page_content .my-md-2 {
      margin-top: .5rem !important;
    }
    #saasbox_page_content .mr-md-2,
    #saasbox_page_content .mx-md-2 {
      margin-right: .5rem !important;
    }
    #saasbox_page_content .mb-md-2,
    #saasbox_page_content .my-md-2 {
      margin-bottom: .5rem !important;
    }
    #saasbox_page_content .ml-md-2,
    #saasbox_page_content .mx-md-2 {
      margin-left: .5rem !important;
    }
    #saasbox_page_content .m-md-3 {
      margin: .75rem !important;
    }
    #saasbox_page_content .mt-md-3,
    #saasbox_page_content .my-md-3 {
      margin-top: .75rem !important;
    }
    #saasbox_page_content .mr-md-3,
    #saasbox_page_content .mx-md-3 {
      margin-right: .75rem !important;
    }
    #saasbox_page_content .mb-md-3,
    #saasbox_page_content .my-md-3 {
      margin-bottom: .75rem !important;
    }
    #saasbox_page_content .ml-md-3,
    #saasbox_page_content .mx-md-3 {
      margin-left: .75rem !important;
    }
    #saasbox_page_content .m-md-4 {
      margin: 1rem !important;
    }
    #saasbox_page_content .mt-md-4,
    #saasbox_page_content .my-md-4 {
      margin-top: 1rem !important;
    }
    #saasbox_page_content .mr-md-4,
    #saasbox_page_content .mx-md-4 {
      margin-right: 1rem !important;
    }
    #saasbox_page_content .mb-md-4,
    #saasbox_page_content .my-md-4 {
      margin-bottom: 1rem !important;
    }
    #saasbox_page_content .ml-md-4,
    #saasbox_page_content .mx-md-4 {
      margin-left: 1rem !important;
    }
    #saasbox_page_content .m-md-5 {
      margin: 1.5rem !important;
    }
    #saasbox_page_content .mt-md-5,
    #saasbox_page_content .my-md-5 {
      margin-top: 1.5rem !important;
    }
    #saasbox_page_content .mr-md-5,
    #saasbox_page_content .mx-md-5 {
      margin-right: 1.5rem !important;
    }
    #saasbox_page_content .mb-md-5,
    #saasbox_page_content .my-md-5 {
      margin-bottom: 1.5rem !important;
    }
    #saasbox_page_content .ml-md-5,
    #saasbox_page_content .mx-md-5 {
      margin-left: 1.5rem !important;
    }
    #saasbox_page_content .m-md-6 {
      margin: 2rem !important;
    }
    #saasbox_page_content .mt-md-6,
    #saasbox_page_content .my-md-6 {
      margin-top: 2rem !important;
    }
    #saasbox_page_content .mr-md-6,
    #saasbox_page_content .mx-md-6 {
      margin-right: 2rem !important;
    }
    #saasbox_page_content .mb-md-6,
    #saasbox_page_content .my-md-6 {
      margin-bottom: 2rem !important;
    }
    #saasbox_page_content .ml-md-6,
    #saasbox_page_content .mx-md-6 {
      margin-left: 2rem !important;
    }
    #saasbox_page_content .m-md-7 {
      margin: 3rem !important;
    }
    #saasbox_page_content .mt-md-7,
    #saasbox_page_content .my-md-7 {
      margin-top: 3rem !important;
    }
    #saasbox_page_content .mr-md-7,
    #saasbox_page_content .mx-md-7 {
      margin-right: 3rem !important;
    }
    #saasbox_page_content .mb-md-7,
    #saasbox_page_content .my-md-7 {
      margin-bottom: 3rem !important;
    }
    #saasbox_page_content .ml-md-7,
    #saasbox_page_content .mx-md-7 {
      margin-left: 3rem !important;
    }
    #saasbox_page_content .m-md-8 {
      margin: 5rem !important;
    }
    #saasbox_page_content .mt-md-8,
    #saasbox_page_content .my-md-8 {
      margin-top: 5rem !important;
    }
    #saasbox_page_content .mr-md-8,
    #saasbox_page_content .mx-md-8 {
      margin-right: 5rem !important;
    }
    #saasbox_page_content .mb-md-8,
    #saasbox_page_content .my-md-8 {
      margin-bottom: 5rem !important;
    }
    #saasbox_page_content .ml-md-8,
    #saasbox_page_content .mx-md-8 {
      margin-left: 5rem !important;
    }
    #saasbox_page_content .m-md-9 {
      margin: 7rem !important;
    }
    #saasbox_page_content .mt-md-9,
    #saasbox_page_content .my-md-9 {
      margin-top: 7rem !important;
    }
    #saasbox_page_content .mr-md-9,
    #saasbox_page_content .mx-md-9 {
      margin-right: 7rem !important;
    }
    #saasbox_page_content .mb-md-9,
    #saasbox_page_content .my-md-9 {
      margin-bottom: 7rem !important;
    }
    #saasbox_page_content .ml-md-9,
    #saasbox_page_content .mx-md-9 {
      margin-left: 7rem !important;
    }
    #saasbox_page_content .m-md-10 {
      margin: 9rem !important;
    }
    #saasbox_page_content .mt-md-10,
    #saasbox_page_content .my-md-10 {
      margin-top: 9rem !important;
    }
    #saasbox_page_content .mr-md-10,
    #saasbox_page_content .mx-md-10 {
      margin-right: 9rem !important;
    }
    #saasbox_page_content .mb-md-10,
    #saasbox_page_content .my-md-10 {
      margin-bottom: 9rem !important;
    }
    #saasbox_page_content .ml-md-10,
    #saasbox_page_content .mx-md-10 {
      margin-left: 9rem !important;
    }
    #saasbox_page_content .m-md-11 {
      margin: 11rem !important;
    }
    #saasbox_page_content .mt-md-11,
    #saasbox_page_content .my-md-11 {
      margin-top: 11rem !important;
    }
    #saasbox_page_content .mr-md-11,
    #saasbox_page_content .mx-md-11 {
      margin-right: 11rem !important;
    }
    #saasbox_page_content .mb-md-11,
    #saasbox_page_content .my-md-11 {
      margin-bottom: 11rem !important;
    }
    #saasbox_page_content .ml-md-11,
    #saasbox_page_content .mx-md-11 {
      margin-left: 11rem !important;
    }
    #saasbox_page_content .m-md-12 {
      margin: 13rem !important;
    }
    #saasbox_page_content .mt-md-12,
    #saasbox_page_content .my-md-12 {
      margin-top: 13rem !important;
    }
    #saasbox_page_content .mr-md-12,
    #saasbox_page_content .mx-md-12 {
      margin-right: 13rem !important;
    }
    #saasbox_page_content .mb-md-12,
    #saasbox_page_content .my-md-12 {
      margin-bottom: 13rem !important;
    }
    #saasbox_page_content .ml-md-12,
    #saasbox_page_content .mx-md-12 {
      margin-left: 13rem !important;
    }
    #saasbox_page_content .m-md-13 {
      margin: 16rem !important;
    }
    #saasbox_page_content .mt-md-13,
    #saasbox_page_content .my-md-13 {
      margin-top: 16rem !important;
    }
    #saasbox_page_content .mr-md-13,
    #saasbox_page_content .mx-md-13 {
      margin-right: 16rem !important;
    }
    #saasbox_page_content .mb-md-13,
    #saasbox_page_content .my-md-13 {
      margin-bottom: 16rem !important;
    }
    #saasbox_page_content .ml-md-13,
    #saasbox_page_content .mx-md-13 {
      margin-left: 16rem !important;
    }
    #saasbox_page_content .p-md-0 {
      padding: 0 !important;
    }
    #saasbox_page_content .pt-md-0,
    #saasbox_page_content .py-md-0 {
      padding-top: 0 !important;
    }
    #saasbox_page_content .pr-md-0,
    #saasbox_page_content .px-md-0 {
      padding-right: 0 !important;
    }
    #saasbox_page_content .pb-md-0,
    #saasbox_page_content .py-md-0 {
      padding-bottom: 0 !important;
    }
    #saasbox_page_content .pl-md-0,
    #saasbox_page_content .px-md-0 {
      padding-left: 0 !important;
    }
    #saasbox_page_content .p-md-1 {
      padding: .25rem !important;
    }
    #saasbox_page_content .pt-md-1,
    #saasbox_page_content .py-md-1 {
      padding-top: .25rem !important;
    }
    #saasbox_page_content .pr-md-1,
    #saasbox_page_content .px-md-1 {
      padding-right: .25rem !important;
    }
    #saasbox_page_content .pb-md-1,
    #saasbox_page_content .py-md-1 {
      padding-bottom: .25rem !important;
    }
    #saasbox_page_content .pl-md-1,
    #saasbox_page_content .px-md-1 {
      padding-left: .25rem !important;
    }
    #saasbox_page_content .p-md-2 {
      padding: .5rem !important;
    }
    #saasbox_page_content .pt-md-2,
    #saasbox_page_content .py-md-2 {
      padding-top: .5rem !important;
    }
    #saasbox_page_content .pr-md-2,
    #saasbox_page_content .px-md-2 {
      padding-right: .5rem !important;
    }
    #saasbox_page_content .pb-md-2,
    #saasbox_page_content .py-md-2 {
      padding-bottom: .5rem !important;
    }
    #saasbox_page_content .pl-md-2,
    #saasbox_page_content .px-md-2 {
      padding-left: .5rem !important;
    }
    #saasbox_page_content .p-md-3 {
      padding: .75rem !important;
    }
    #saasbox_page_content .pt-md-3,
    #saasbox_page_content .py-md-3 {
      padding-top: .75rem !important;
    }
    #saasbox_page_content .pr-md-3,
    #saasbox_page_content .px-md-3 {
      padding-right: .75rem !important;
    }
    #saasbox_page_content .pb-md-3,
    #saasbox_page_content .py-md-3 {
      padding-bottom: .75rem !important;
    }
    #saasbox_page_content .pl-md-3,
    #saasbox_page_content .px-md-3 {
      padding-left: .75rem !important;
    }
    #saasbox_page_content .p-md-4 {
      padding: 1rem !important;
    }
    #saasbox_page_content .pt-md-4,
    #saasbox_page_content .py-md-4 {
      padding-top: 1rem !important;
    }
    #saasbox_page_content .pr-md-4,
    #saasbox_page_content .px-md-4 {
      padding-right: 1rem !important;
    }
    #saasbox_page_content .pb-md-4,
    #saasbox_page_content .py-md-4 {
      padding-bottom: 1rem !important;
    }
    #saasbox_page_content .pl-md-4,
    #saasbox_page_content .px-md-4 {
      padding-left: 1rem !important;
    }
    #saasbox_page_content .p-md-5 {
      padding: 1.5rem !important;
    }
    #saasbox_page_content .pt-md-5,
    #saasbox_page_content .py-md-5 {
      padding-top: 1.5rem !important;
    }
    #saasbox_page_content .pr-md-5,
    #saasbox_page_content .px-md-5 {
      padding-right: 1.5rem !important;
    }
    #saasbox_page_content .pb-md-5,
    #saasbox_page_content .py-md-5 {
      padding-bottom: 1.5rem !important;
    }
    #saasbox_page_content .pl-md-5,
    #saasbox_page_content .px-md-5 {
      padding-left: 1.5rem !important;
    }
    #saasbox_page_content .p-md-6 {
      padding: 2rem !important;
    }
    #saasbox_page_content .pt-md-6,
    #saasbox_page_content .py-md-6 {
      padding-top: 2rem !important;
    }
    #saasbox_page_content .pr-md-6,
    #saasbox_page_content .px-md-6 {
      padding-right: 2rem !important;
    }
    #saasbox_page_content .pb-md-6,
    #saasbox_page_content .py-md-6 {
      padding-bottom: 2rem !important;
    }
    #saasbox_page_content .pl-md-6,
    #saasbox_page_content .px-md-6 {
      padding-left: 2rem !important;
    }
    #saasbox_page_content .p-md-7 {
      padding: 3rem !important;
    }
    #saasbox_page_content .pt-md-7,
    #saasbox_page_content .py-md-7 {
      padding-top: 3rem !important;
    }
    #saasbox_page_content .pr-md-7,
    #saasbox_page_content .px-md-7 {
      padding-right: 3rem !important;
    }
    #saasbox_page_content .pb-md-7,
    #saasbox_page_content .py-md-7 {
      padding-bottom: 3rem !important;
    }
    #saasbox_page_content .pl-md-7,
    #saasbox_page_content .px-md-7 {
      padding-left: 3rem !important;
    }
    #saasbox_page_content .p-md-8 {
      padding: 5rem !important;
    }
    #saasbox_page_content .pt-md-8,
    #saasbox_page_content .py-md-8 {
      padding-top: 5rem !important;
    }
    #saasbox_page_content .pr-md-8,
    #saasbox_page_content .px-md-8 {
      padding-right: 5rem !important;
    }
    #saasbox_page_content .pb-md-8,
    #saasbox_page_content .py-md-8 {
      padding-bottom: 5rem !important;
    }
    #saasbox_page_content .pl-md-8,
    #saasbox_page_content .px-md-8 {
      padding-left: 5rem !important;
    }
    #saasbox_page_content .p-md-9 {
      padding: 7rem !important;
    }
    #saasbox_page_content .pt-md-9,
    #saasbox_page_content .py-md-9 {
      padding-top: 7rem !important;
    }
    #saasbox_page_content .pr-md-9,
    #saasbox_page_content .px-md-9 {
      padding-right: 7rem !important;
    }
    #saasbox_page_content .pb-md-9,
    #saasbox_page_content .py-md-9 {
      padding-bottom: 7rem !important;
    }
    #saasbox_page_content .pl-md-9,
    #saasbox_page_content .px-md-9 {
      padding-left: 7rem !important;
    }
    #saasbox_page_content .p-md-10 {
      padding: 9rem !important;
    }
    #saasbox_page_content .pt-md-10,
    #saasbox_page_content .py-md-10 {
      padding-top: 9rem !important;
    }
    #saasbox_page_content .pr-md-10,
    #saasbox_page_content .px-md-10 {
      padding-right: 9rem !important;
    }
    #saasbox_page_content .pb-md-10,
    #saasbox_page_content .py-md-10 {
      padding-bottom: 9rem !important;
    }
    #saasbox_page_content .pl-md-10,
    #saasbox_page_content .px-md-10 {
      padding-left: 9rem !important;
    }
    #saasbox_page_content .p-md-11 {
      padding: 11rem !important;
    }
    #saasbox_page_content .pt-md-11,
    #saasbox_page_content .py-md-11 {
      padding-top: 11rem !important;
    }
    #saasbox_page_content .pr-md-11,
    #saasbox_page_content .px-md-11 {
      padding-right: 11rem !important;
    }
    #saasbox_page_content .pb-md-11,
    #saasbox_page_content .py-md-11 {
      padding-bottom: 11rem !important;
    }
    #saasbox_page_content .pl-md-11,
    #saasbox_page_content .px-md-11 {
      padding-left: 11rem !important;
    }
    #saasbox_page_content .p-md-12 {
      padding: 13rem !important;
    }
    #saasbox_page_content .pt-md-12,
    #saasbox_page_content .py-md-12 {
      padding-top: 13rem !important;
    }
    #saasbox_page_content .pr-md-12,
    #saasbox_page_content .px-md-12 {
      padding-right: 13rem !important;
    }
    #saasbox_page_content .pb-md-12,
    #saasbox_page_content .py-md-12 {
      padding-bottom: 13rem !important;
    }
    #saasbox_page_content .pl-md-12,
    #saasbox_page_content .px-md-12 {
      padding-left: 13rem !important;
    }
    #saasbox_page_content .p-md-13 {
      padding: 16rem !important;
    }
    #saasbox_page_content .pt-md-13,
    #saasbox_page_content .py-md-13 {
      padding-top: 16rem !important;
    }
    #saasbox_page_content .pr-md-13,
    #saasbox_page_content .px-md-13 {
      padding-right: 16rem !important;
    }
    #saasbox_page_content .pb-md-13,
    #saasbox_page_content .py-md-13 {
      padding-bottom: 16rem !important;
    }
    #saasbox_page_content .pl-md-13,
    #saasbox_page_content .px-md-13 {
      padding-left: 16rem !important;
    }
    #saasbox_page_content .m-md-n1 {
      margin: -0.25rem !important;
    }
    #saasbox_page_content .mt-md-n1,
    #saasbox_page_content .my-md-n1 {
      margin-top: -0.25rem !important;
    }
    #saasbox_page_content .mr-md-n1,
    #saasbox_page_content .mx-md-n1 {
      margin-right: -0.25rem !important;
    }
    #saasbox_page_content .mb-md-n1,
    #saasbox_page_content .my-md-n1 {
      margin-bottom: -0.25rem !important;
    }
    #saasbox_page_content .ml-md-n1,
    #saasbox_page_content .mx-md-n1 {
      margin-left: -0.25rem !important;
    }
    #saasbox_page_content .m-md-n2 {
      margin: -0.5rem !important;
    }
    #saasbox_page_content .mt-md-n2,
    #saasbox_page_content .my-md-n2 {
      margin-top: -0.5rem !important;
    }
    #saasbox_page_content .mr-md-n2,
    #saasbox_page_content .mx-md-n2 {
      margin-right: -0.5rem !important;
    }
    #saasbox_page_content .mb-md-n2,
    #saasbox_page_content .my-md-n2 {
      margin-bottom: -0.5rem !important;
    }
    #saasbox_page_content .ml-md-n2,
    #saasbox_page_content .mx-md-n2 {
      margin-left: -0.5rem !important;
    }
    #saasbox_page_content .m-md-n3 {
      margin: -0.75rem !important;
    }
    #saasbox_page_content .mt-md-n3,
    #saasbox_page_content .my-md-n3 {
      margin-top: -0.75rem !important;
    }
    #saasbox_page_content .mr-md-n3,
    #saasbox_page_content .mx-md-n3 {
      margin-right: -0.75rem !important;
    }
    #saasbox_page_content .mb-md-n3,
    #saasbox_page_content .my-md-n3 {
      margin-bottom: -0.75rem !important;
    }
    #saasbox_page_content .ml-md-n3,
    #saasbox_page_content .mx-md-n3 {
      margin-left: -0.75rem !important;
    }
    #saasbox_page_content .m-md-n4 {
      margin: -1rem !important;
    }
    #saasbox_page_content .mt-md-n4,
    #saasbox_page_content .my-md-n4 {
      margin-top: -1rem !important;
    }
    #saasbox_page_content .mr-md-n4,
    #saasbox_page_content .mx-md-n4 {
      margin-right: -1rem !important;
    }
    #saasbox_page_content .mb-md-n4,
    #saasbox_page_content .my-md-n4 {
      margin-bottom: -1rem !important;
    }
    #saasbox_page_content .ml-md-n4,
    #saasbox_page_content .mx-md-n4 {
      margin-left: -1rem !important;
    }
    #saasbox_page_content .m-md-n5 {
      margin: -1.5rem !important;
    }
    #saasbox_page_content .mt-md-n5,
    #saasbox_page_content .my-md-n5 {
      margin-top: -1.5rem !important;
    }
    #saasbox_page_content .mr-md-n5,
    #saasbox_page_content .mx-md-n5 {
      margin-right: -1.5rem !important;
    }
    #saasbox_page_content .mb-md-n5,
    #saasbox_page_content .my-md-n5 {
      margin-bottom: -1.5rem !important;
    }
    #saasbox_page_content .ml-md-n5,
    #saasbox_page_content .mx-md-n5 {
      margin-left: -1.5rem !important;
    }
    #saasbox_page_content .m-md-n6 {
      margin: -2rem !important;
    }
    #saasbox_page_content .mt-md-n6,
    #saasbox_page_content .my-md-n6 {
      margin-top: -2rem !important;
    }
    #saasbox_page_content .mr-md-n6,
    #saasbox_page_content .mx-md-n6 {
      margin-right: -2rem !important;
    }
    #saasbox_page_content .mb-md-n6,
    #saasbox_page_content .my-md-n6 {
      margin-bottom: -2rem !important;
    }
    #saasbox_page_content .ml-md-n6,
    #saasbox_page_content .mx-md-n6 {
      margin-left: -2rem !important;
    }
    #saasbox_page_content .m-md-n7 {
      margin: -3rem !important;
    }
    #saasbox_page_content .mt-md-n7,
    #saasbox_page_content .my-md-n7 {
      margin-top: -3rem !important;
    }
    #saasbox_page_content .mr-md-n7,
    #saasbox_page_content .mx-md-n7 {
      margin-right: -3rem !important;
    }
    #saasbox_page_content .mb-md-n7,
    #saasbox_page_content .my-md-n7 {
      margin-bottom: -3rem !important;
    }
    #saasbox_page_content .ml-md-n7,
    #saasbox_page_content .mx-md-n7 {
      margin-left: -3rem !important;
    }
    #saasbox_page_content .m-md-n8 {
      margin: -5rem !important;
    }
    #saasbox_page_content .mt-md-n8,
    #saasbox_page_content .my-md-n8 {
      margin-top: -5rem !important;
    }
    #saasbox_page_content .mr-md-n8,
    #saasbox_page_content .mx-md-n8 {
      margin-right: -5rem !important;
    }
    #saasbox_page_content .mb-md-n8,
    #saasbox_page_content .my-md-n8 {
      margin-bottom: -5rem !important;
    }
    #saasbox_page_content .ml-md-n8,
    #saasbox_page_content .mx-md-n8 {
      margin-left: -5rem !important;
    }
    #saasbox_page_content .m-md-n9 {
      margin: -7rem !important;
    }
    #saasbox_page_content .mt-md-n9,
    #saasbox_page_content .my-md-n9 {
      margin-top: -7rem !important;
    }
    #saasbox_page_content .mr-md-n9,
    #saasbox_page_content .mx-md-n9 {
      margin-right: -7rem !important;
    }
    #saasbox_page_content .mb-md-n9,
    #saasbox_page_content .my-md-n9 {
      margin-bottom: -7rem !important;
    }
    #saasbox_page_content .ml-md-n9,
    #saasbox_page_content .mx-md-n9 {
      margin-left: -7rem !important;
    }
    #saasbox_page_content .m-md-n10 {
      margin: -9rem !important;
    }
    #saasbox_page_content .mt-md-n10,
    #saasbox_page_content .my-md-n10 {
      margin-top: -9rem !important;
    }
    #saasbox_page_content .mr-md-n10,
    #saasbox_page_content .mx-md-n10 {
      margin-right: -9rem !important;
    }
    #saasbox_page_content .mb-md-n10,
    #saasbox_page_content .my-md-n10 {
      margin-bottom: -9rem !important;
    }
    #saasbox_page_content .ml-md-n10,
    #saasbox_page_content .mx-md-n10 {
      margin-left: -9rem !important;
    }
    #saasbox_page_content .m-md-n11 {
      margin: -11rem !important;
    }
    #saasbox_page_content .mt-md-n11,
    #saasbox_page_content .my-md-n11 {
      margin-top: -11rem !important;
    }
    #saasbox_page_content .mr-md-n11,
    #saasbox_page_content .mx-md-n11 {
      margin-right: -11rem !important;
    }
    #saasbox_page_content .mb-md-n11,
    #saasbox_page_content .my-md-n11 {
      margin-bottom: -11rem !important;
    }
    #saasbox_page_content .ml-md-n11,
    #saasbox_page_content .mx-md-n11 {
      margin-left: -11rem !important;
    }
    #saasbox_page_content .m-md-n12 {
      margin: -13rem !important;
    }
    #saasbox_page_content .mt-md-n12,
    #saasbox_page_content .my-md-n12 {
      margin-top: -13rem !important;
    }
    #saasbox_page_content .mr-md-n12,
    #saasbox_page_content .mx-md-n12 {
      margin-right: -13rem !important;
    }
    #saasbox_page_content .mb-md-n12,
    #saasbox_page_content .my-md-n12 {
      margin-bottom: -13rem !important;
    }
    #saasbox_page_content .ml-md-n12,
    #saasbox_page_content .mx-md-n12 {
      margin-left: -13rem !important;
    }
    #saasbox_page_content .m-md-n13 {
      margin: -16rem !important;
    }
    #saasbox_page_content .mt-md-n13,
    #saasbox_page_content .my-md-n13 {
      margin-top: -16rem !important;
    }
    #saasbox_page_content .mr-md-n13,
    #saasbox_page_content .mx-md-n13 {
      margin-right: -16rem !important;
    }
    #saasbox_page_content .mb-md-n13,
    #saasbox_page_content .my-md-n13 {
      margin-bottom: -16rem !important;
    }
    #saasbox_page_content .ml-md-n13,
    #saasbox_page_content .mx-md-n13 {
      margin-left: -16rem !important;
    }
    #saasbox_page_content .m-md-auto {
      margin: auto !important;
    }
    #saasbox_page_content .mt-md-auto,
    #saasbox_page_content .my-md-auto {
      margin-top: auto !important;
    }
    #saasbox_page_content .mr-md-auto,
    #saasbox_page_content .mx-md-auto {
      margin-right: auto !important;
    }
    #saasbox_page_content .mb-md-auto,
    #saasbox_page_content .my-md-auto {
      margin-bottom: auto !important;
    }
    #saasbox_page_content .ml-md-auto,
    #saasbox_page_content .mx-md-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .m-lg-0 {
      margin: 0 !important;
    }
    #saasbox_page_content .mt-lg-0,
    #saasbox_page_content .my-lg-0 {
      margin-top: 0 !important;
    }
    #saasbox_page_content .mr-lg-0,
    #saasbox_page_content .mx-lg-0 {
      margin-right: 0 !important;
    }
    #saasbox_page_content .mb-lg-0,
    #saasbox_page_content .my-lg-0 {
      margin-bottom: 0 !important;
    }
    #saasbox_page_content .ml-lg-0,
    #saasbox_page_content .mx-lg-0 {
      margin-left: 0 !important;
    }
    #saasbox_page_content .m-lg-1 {
      margin: .25rem !important;
    }
    #saasbox_page_content .mt-lg-1,
    #saasbox_page_content .my-lg-1 {
      margin-top: .25rem !important;
    }
    #saasbox_page_content .mr-lg-1,
    #saasbox_page_content .mx-lg-1 {
      margin-right: .25rem !important;
    }
    #saasbox_page_content .mb-lg-1,
    #saasbox_page_content .my-lg-1 {
      margin-bottom: .25rem !important;
    }
    #saasbox_page_content .ml-lg-1,
    #saasbox_page_content .mx-lg-1 {
      margin-left: .25rem !important;
    }
    #saasbox_page_content .m-lg-2 {
      margin: .5rem !important;
    }
    #saasbox_page_content .mt-lg-2,
    #saasbox_page_content .my-lg-2 {
      margin-top: .5rem !important;
    }
    #saasbox_page_content .mr-lg-2,
    #saasbox_page_content .mx-lg-2 {
      margin-right: .5rem !important;
    }
    #saasbox_page_content .mb-lg-2,
    #saasbox_page_content .my-lg-2 {
      margin-bottom: .5rem !important;
    }
    #saasbox_page_content .ml-lg-2,
    #saasbox_page_content .mx-lg-2 {
      margin-left: .5rem !important;
    }
    #saasbox_page_content .m-lg-3 {
      margin: .75rem !important;
    }
    #saasbox_page_content .mt-lg-3,
    #saasbox_page_content .my-lg-3 {
      margin-top: .75rem !important;
    }
    #saasbox_page_content .mr-lg-3,
    #saasbox_page_content .mx-lg-3 {
      margin-right: .75rem !important;
    }
    #saasbox_page_content .mb-lg-3,
    #saasbox_page_content .my-lg-3 {
      margin-bottom: .75rem !important;
    }
    #saasbox_page_content .ml-lg-3,
    #saasbox_page_content .mx-lg-3 {
      margin-left: .75rem !important;
    }
    #saasbox_page_content .m-lg-4 {
      margin: 1rem !important;
    }
    #saasbox_page_content .mt-lg-4,
    #saasbox_page_content .my-lg-4 {
      margin-top: 1rem !important;
    }
    #saasbox_page_content .mr-lg-4,
    #saasbox_page_content .mx-lg-4 {
      margin-right: 1rem !important;
    }
    #saasbox_page_content .mb-lg-4,
    #saasbox_page_content .my-lg-4 {
      margin-bottom: 1rem !important;
    }
    #saasbox_page_content .ml-lg-4,
    #saasbox_page_content .mx-lg-4 {
      margin-left: 1rem !important;
    }
    #saasbox_page_content .m-lg-5 {
      margin: 1.5rem !important;
    }
    #saasbox_page_content .mt-lg-5,
    #saasbox_page_content .my-lg-5 {
      margin-top: 1.5rem !important;
    }
    #saasbox_page_content .mr-lg-5,
    #saasbox_page_content .mx-lg-5 {
      margin-right: 1.5rem !important;
    }
    #saasbox_page_content .mb-lg-5,
    #saasbox_page_content .my-lg-5 {
      margin-bottom: 1.5rem !important;
    }
    #saasbox_page_content .ml-lg-5,
    #saasbox_page_content .mx-lg-5 {
      margin-left: 1.5rem !important;
    }
    #saasbox_page_content .m-lg-6 {
      margin: 2rem !important;
    }
    #saasbox_page_content .mt-lg-6,
    #saasbox_page_content .my-lg-6 {
      margin-top: 2rem !important;
    }
    #saasbox_page_content .mr-lg-6,
    #saasbox_page_content .mx-lg-6 {
      margin-right: 2rem !important;
    }
    #saasbox_page_content .mb-lg-6,
    #saasbox_page_content .my-lg-6 {
      margin-bottom: 2rem !important;
    }
    #saasbox_page_content .ml-lg-6,
    #saasbox_page_content .mx-lg-6 {
      margin-left: 2rem !important;
    }
    #saasbox_page_content .m-lg-7 {
      margin: 3rem !important;
    }
    #saasbox_page_content .mt-lg-7,
    #saasbox_page_content .my-lg-7 {
      margin-top: 3rem !important;
    }
    #saasbox_page_content .mr-lg-7,
    #saasbox_page_content .mx-lg-7 {
      margin-right: 3rem !important;
    }
    #saasbox_page_content .mb-lg-7,
    #saasbox_page_content .my-lg-7 {
      margin-bottom: 3rem !important;
    }
    #saasbox_page_content .ml-lg-7,
    #saasbox_page_content .mx-lg-7 {
      margin-left: 3rem !important;
    }
    #saasbox_page_content .m-lg-8 {
      margin: 5rem !important;
    }
    #saasbox_page_content .mt-lg-8,
    #saasbox_page_content .my-lg-8 {
      margin-top: 5rem !important;
    }
    #saasbox_page_content .mr-lg-8,
    #saasbox_page_content .mx-lg-8 {
      margin-right: 5rem !important;
    }
    #saasbox_page_content .mb-lg-8,
    #saasbox_page_content .my-lg-8 {
      margin-bottom: 5rem !important;
    }
    #saasbox_page_content .ml-lg-8,
    #saasbox_page_content .mx-lg-8 {
      margin-left: 5rem !important;
    }
    #saasbox_page_content .m-lg-9 {
      margin: 7rem !important;
    }
    #saasbox_page_content .mt-lg-9,
    #saasbox_page_content .my-lg-9 {
      margin-top: 7rem !important;
    }
    #saasbox_page_content .mr-lg-9,
    #saasbox_page_content .mx-lg-9 {
      margin-right: 7rem !important;
    }
    #saasbox_page_content .mb-lg-9,
    #saasbox_page_content .my-lg-9 {
      margin-bottom: 7rem !important;
    }
    #saasbox_page_content .ml-lg-9,
    #saasbox_page_content .mx-lg-9 {
      margin-left: 7rem !important;
    }
    #saasbox_page_content .m-lg-10 {
      margin: 9rem !important;
    }
    #saasbox_page_content .mt-lg-10,
    #saasbox_page_content .my-lg-10 {
      margin-top: 9rem !important;
    }
    #saasbox_page_content .mr-lg-10,
    #saasbox_page_content .mx-lg-10 {
      margin-right: 9rem !important;
    }
    #saasbox_page_content .mb-lg-10,
    #saasbox_page_content .my-lg-10 {
      margin-bottom: 9rem !important;
    }
    #saasbox_page_content .ml-lg-10,
    #saasbox_page_content .mx-lg-10 {
      margin-left: 9rem !important;
    }
    #saasbox_page_content .m-lg-11 {
      margin: 11rem !important;
    }
    #saasbox_page_content .mt-lg-11,
    #saasbox_page_content .my-lg-11 {
      margin-top: 11rem !important;
    }
    #saasbox_page_content .mr-lg-11,
    #saasbox_page_content .mx-lg-11 {
      margin-right: 11rem !important;
    }
    #saasbox_page_content .mb-lg-11,
    #saasbox_page_content .my-lg-11 {
      margin-bottom: 11rem !important;
    }
    #saasbox_page_content .ml-lg-11,
    #saasbox_page_content .mx-lg-11 {
      margin-left: 11rem !important;
    }
    #saasbox_page_content .m-lg-12 {
      margin: 13rem !important;
    }
    #saasbox_page_content .mt-lg-12,
    #saasbox_page_content .my-lg-12 {
      margin-top: 13rem !important;
    }
    #saasbox_page_content .mr-lg-12,
    #saasbox_page_content .mx-lg-12 {
      margin-right: 13rem !important;
    }
    #saasbox_page_content .mb-lg-12,
    #saasbox_page_content .my-lg-12 {
      margin-bottom: 13rem !important;
    }
    #saasbox_page_content .ml-lg-12,
    #saasbox_page_content .mx-lg-12 {
      margin-left: 13rem !important;
    }
    #saasbox_page_content .m-lg-13 {
      margin: 16rem !important;
    }
    #saasbox_page_content .mt-lg-13,
    #saasbox_page_content .my-lg-13 {
      margin-top: 16rem !important;
    }
    #saasbox_page_content .mr-lg-13,
    #saasbox_page_content .mx-lg-13 {
      margin-right: 16rem !important;
    }
    #saasbox_page_content .mb-lg-13,
    #saasbox_page_content .my-lg-13 {
      margin-bottom: 16rem !important;
    }
    #saasbox_page_content .ml-lg-13,
    #saasbox_page_content .mx-lg-13 {
      margin-left: 16rem !important;
    }
    #saasbox_page_content .p-lg-0 {
      padding: 0 !important;
    }
    #saasbox_page_content .pt-lg-0,
    #saasbox_page_content .py-lg-0 {
      padding-top: 0 !important;
    }
    #saasbox_page_content .pr-lg-0,
    #saasbox_page_content .px-lg-0 {
      padding-right: 0 !important;
    }
    #saasbox_page_content .pb-lg-0,
    #saasbox_page_content .py-lg-0 {
      padding-bottom: 0 !important;
    }
    #saasbox_page_content .pl-lg-0,
    #saasbox_page_content .px-lg-0 {
      padding-left: 0 !important;
    }
    #saasbox_page_content .p-lg-1 {
      padding: .25rem !important;
    }
    #saasbox_page_content .pt-lg-1,
    #saasbox_page_content .py-lg-1 {
      padding-top: .25rem !important;
    }
    #saasbox_page_content .pr-lg-1,
    #saasbox_page_content .px-lg-1 {
      padding-right: .25rem !important;
    }
    #saasbox_page_content .pb-lg-1,
    #saasbox_page_content .py-lg-1 {
      padding-bottom: .25rem !important;
    }
    #saasbox_page_content .pl-lg-1,
    #saasbox_page_content .px-lg-1 {
      padding-left: .25rem !important;
    }
    #saasbox_page_content .p-lg-2 {
      padding: .5rem !important;
    }
    #saasbox_page_content .pt-lg-2,
    #saasbox_page_content .py-lg-2 {
      padding-top: .5rem !important;
    }
    #saasbox_page_content .pr-lg-2,
    #saasbox_page_content .px-lg-2 {
      padding-right: .5rem !important;
    }
    #saasbox_page_content .pb-lg-2,
    #saasbox_page_content .py-lg-2 {
      padding-bottom: .5rem !important;
    }
    #saasbox_page_content .pl-lg-2,
    #saasbox_page_content .px-lg-2 {
      padding-left: .5rem !important;
    }
    #saasbox_page_content .p-lg-3 {
      padding: .75rem !important;
    }
    #saasbox_page_content .pt-lg-3,
    #saasbox_page_content .py-lg-3 {
      padding-top: .75rem !important;
    }
    #saasbox_page_content .pr-lg-3,
    #saasbox_page_content .px-lg-3 {
      padding-right: .75rem !important;
    }
    #saasbox_page_content .pb-lg-3,
    #saasbox_page_content .py-lg-3 {
      padding-bottom: .75rem !important;
    }
    #saasbox_page_content .pl-lg-3,
    #saasbox_page_content .px-lg-3 {
      padding-left: .75rem !important;
    }
    #saasbox_page_content .p-lg-4 {
      padding: 1rem !important;
    }
    #saasbox_page_content .pt-lg-4,
    #saasbox_page_content .py-lg-4 {
      padding-top: 1rem !important;
    }
    #saasbox_page_content .pr-lg-4,
    #saasbox_page_content .px-lg-4 {
      padding-right: 1rem !important;
    }
    #saasbox_page_content .pb-lg-4,
    #saasbox_page_content .py-lg-4 {
      padding-bottom: 1rem !important;
    }
    #saasbox_page_content .pl-lg-4,
    #saasbox_page_content .px-lg-4 {
      padding-left: 1rem !important;
    }
    #saasbox_page_content .p-lg-5 {
      padding: 1.5rem !important;
    }
    #saasbox_page_content .pt-lg-5,
    #saasbox_page_content .py-lg-5 {
      padding-top: 1.5rem !important;
    }
    #saasbox_page_content .pr-lg-5,
    #saasbox_page_content .px-lg-5 {
      padding-right: 1.5rem !important;
    }
    #saasbox_page_content .pb-lg-5,
    #saasbox_page_content .py-lg-5 {
      padding-bottom: 1.5rem !important;
    }
    #saasbox_page_content .pl-lg-5,
    #saasbox_page_content .px-lg-5 {
      padding-left: 1.5rem !important;
    }
    #saasbox_page_content .p-lg-6 {
      padding: 2rem !important;
    }
    #saasbox_page_content .pt-lg-6,
    #saasbox_page_content .py-lg-6 {
      padding-top: 2rem !important;
    }
    #saasbox_page_content .pr-lg-6,
    #saasbox_page_content .px-lg-6 {
      padding-right: 2rem !important;
    }
    #saasbox_page_content .pb-lg-6,
    #saasbox_page_content .py-lg-6 {
      padding-bottom: 2rem !important;
    }
    #saasbox_page_content .pl-lg-6,
    #saasbox_page_content .px-lg-6 {
      padding-left: 2rem !important;
    }
    #saasbox_page_content .p-lg-7 {
      padding: 3rem !important;
    }
    #saasbox_page_content .pt-lg-7,
    #saasbox_page_content .py-lg-7 {
      padding-top: 3rem !important;
    }
    #saasbox_page_content .pr-lg-7,
    #saasbox_page_content .px-lg-7 {
      padding-right: 3rem !important;
    }
    #saasbox_page_content .pb-lg-7,
    #saasbox_page_content .py-lg-7 {
      padding-bottom: 3rem !important;
    }
    #saasbox_page_content .pl-lg-7,
    #saasbox_page_content .px-lg-7 {
      padding-left: 3rem !important;
    }
    #saasbox_page_content .p-lg-8 {
      padding: 5rem !important;
    }
    #saasbox_page_content .pt-lg-8,
    #saasbox_page_content .py-lg-8 {
      padding-top: 5rem !important;
    }
    #saasbox_page_content .pr-lg-8,
    #saasbox_page_content .px-lg-8 {
      padding-right: 5rem !important;
    }
    #saasbox_page_content .pb-lg-8,
    #saasbox_page_content .py-lg-8 {
      padding-bottom: 5rem !important;
    }
    #saasbox_page_content .pl-lg-8,
    #saasbox_page_content .px-lg-8 {
      padding-left: 5rem !important;
    }
    #saasbox_page_content .p-lg-9 {
      padding: 7rem !important;
    }
    #saasbox_page_content .pt-lg-9,
    #saasbox_page_content .py-lg-9 {
      padding-top: 7rem !important;
    }
    #saasbox_page_content .pr-lg-9,
    #saasbox_page_content .px-lg-9 {
      padding-right: 7rem !important;
    }
    #saasbox_page_content .pb-lg-9,
    #saasbox_page_content .py-lg-9 {
      padding-bottom: 7rem !important;
    }
    #saasbox_page_content .pl-lg-9,
    #saasbox_page_content .px-lg-9 {
      padding-left: 7rem !important;
    }
    #saasbox_page_content .p-lg-10 {
      padding: 9rem !important;
    }
    #saasbox_page_content .pt-lg-10,
    #saasbox_page_content .py-lg-10 {
      padding-top: 9rem !important;
    }
    #saasbox_page_content .pr-lg-10,
    #saasbox_page_content .px-lg-10 {
      padding-right: 9rem !important;
    }
    #saasbox_page_content .pb-lg-10,
    #saasbox_page_content .py-lg-10 {
      padding-bottom: 9rem !important;
    }
    #saasbox_page_content .pl-lg-10,
    #saasbox_page_content .px-lg-10 {
      padding-left: 9rem !important;
    }
    #saasbox_page_content .p-lg-11 {
      padding: 11rem !important;
    }
    #saasbox_page_content .pt-lg-11,
    #saasbox_page_content .py-lg-11 {
      padding-top: 11rem !important;
    }
    #saasbox_page_content .pr-lg-11,
    #saasbox_page_content .px-lg-11 {
      padding-right: 11rem !important;
    }
    #saasbox_page_content .pb-lg-11,
    #saasbox_page_content .py-lg-11 {
      padding-bottom: 11rem !important;
    }
    #saasbox_page_content .pl-lg-11,
    #saasbox_page_content .px-lg-11 {
      padding-left: 11rem !important;
    }
    #saasbox_page_content .p-lg-12 {
      padding: 13rem !important;
    }
    #saasbox_page_content .pt-lg-12,
    #saasbox_page_content .py-lg-12 {
      padding-top: 13rem !important;
    }
    #saasbox_page_content .pr-lg-12,
    #saasbox_page_content .px-lg-12 {
      padding-right: 13rem !important;
    }
    #saasbox_page_content .pb-lg-12,
    #saasbox_page_content .py-lg-12 {
      padding-bottom: 13rem !important;
    }
    #saasbox_page_content .pl-lg-12,
    #saasbox_page_content .px-lg-12 {
      padding-left: 13rem !important;
    }
    #saasbox_page_content .p-lg-13 {
      padding: 16rem !important;
    }
    #saasbox_page_content .pt-lg-13,
    #saasbox_page_content .py-lg-13 {
      padding-top: 16rem !important;
    }
    #saasbox_page_content .pr-lg-13,
    #saasbox_page_content .px-lg-13 {
      padding-right: 16rem !important;
    }
    #saasbox_page_content .pb-lg-13,
    #saasbox_page_content .py-lg-13 {
      padding-bottom: 16rem !important;
    }
    #saasbox_page_content .pl-lg-13,
    #saasbox_page_content .px-lg-13 {
      padding-left: 16rem !important;
    }
    #saasbox_page_content .m-lg-n1 {
      margin: -0.25rem !important;
    }
    #saasbox_page_content .mt-lg-n1,
    #saasbox_page_content .my-lg-n1 {
      margin-top: -0.25rem !important;
    }
    #saasbox_page_content .mr-lg-n1,
    #saasbox_page_content .mx-lg-n1 {
      margin-right: -0.25rem !important;
    }
    #saasbox_page_content .mb-lg-n1,
    #saasbox_page_content .my-lg-n1 {
      margin-bottom: -0.25rem !important;
    }
    #saasbox_page_content .ml-lg-n1,
    #saasbox_page_content .mx-lg-n1 {
      margin-left: -0.25rem !important;
    }
    #saasbox_page_content .m-lg-n2 {
      margin: -0.5rem !important;
    }
    #saasbox_page_content .mt-lg-n2,
    #saasbox_page_content .my-lg-n2 {
      margin-top: -0.5rem !important;
    }
    #saasbox_page_content .mr-lg-n2,
    #saasbox_page_content .mx-lg-n2 {
      margin-right: -0.5rem !important;
    }
    #saasbox_page_content .mb-lg-n2,
    #saasbox_page_content .my-lg-n2 {
      margin-bottom: -0.5rem !important;
    }
    #saasbox_page_content .ml-lg-n2,
    #saasbox_page_content .mx-lg-n2 {
      margin-left: -0.5rem !important;
    }
    #saasbox_page_content .m-lg-n3 {
      margin: -0.75rem !important;
    }
    #saasbox_page_content .mt-lg-n3,
    #saasbox_page_content .my-lg-n3 {
      margin-top: -0.75rem !important;
    }
    #saasbox_page_content .mr-lg-n3,
    #saasbox_page_content .mx-lg-n3 {
      margin-right: -0.75rem !important;
    }
    #saasbox_page_content .mb-lg-n3,
    #saasbox_page_content .my-lg-n3 {
      margin-bottom: -0.75rem !important;
    }
    #saasbox_page_content .ml-lg-n3,
    #saasbox_page_content .mx-lg-n3 {
      margin-left: -0.75rem !important;
    }
    #saasbox_page_content .m-lg-n4 {
      margin: -1rem !important;
    }
    #saasbox_page_content .mt-lg-n4,
    #saasbox_page_content .my-lg-n4 {
      margin-top: -1rem !important;
    }
    #saasbox_page_content .mr-lg-n4,
    #saasbox_page_content .mx-lg-n4 {
      margin-right: -1rem !important;
    }
    #saasbox_page_content .mb-lg-n4,
    #saasbox_page_content .my-lg-n4 {
      margin-bottom: -1rem !important;
    }
    #saasbox_page_content .ml-lg-n4,
    #saasbox_page_content .mx-lg-n4 {
      margin-left: -1rem !important;
    }
    #saasbox_page_content .m-lg-n5 {
      margin: -1.5rem !important;
    }
    #saasbox_page_content .mt-lg-n5,
    #saasbox_page_content .my-lg-n5 {
      margin-top: -1.5rem !important;
    }
    #saasbox_page_content .mr-lg-n5,
    #saasbox_page_content .mx-lg-n5 {
      margin-right: -1.5rem !important;
    }
    #saasbox_page_content .mb-lg-n5,
    #saasbox_page_content .my-lg-n5 {
      margin-bottom: -1.5rem !important;
    }
    #saasbox_page_content .ml-lg-n5,
    #saasbox_page_content .mx-lg-n5 {
      margin-left: -1.5rem !important;
    }
    #saasbox_page_content .m-lg-n6 {
      margin: -2rem !important;
    }
    #saasbox_page_content .mt-lg-n6,
    #saasbox_page_content .my-lg-n6 {
      margin-top: -2rem !important;
    }
    #saasbox_page_content .mr-lg-n6,
    #saasbox_page_content .mx-lg-n6 {
      margin-right: -2rem !important;
    }
    #saasbox_page_content .mb-lg-n6,
    #saasbox_page_content .my-lg-n6 {
      margin-bottom: -2rem !important;
    }
    #saasbox_page_content .ml-lg-n6,
    #saasbox_page_content .mx-lg-n6 {
      margin-left: -2rem !important;
    }
    #saasbox_page_content .m-lg-n7 {
      margin: -3rem !important;
    }
    #saasbox_page_content .mt-lg-n7,
    #saasbox_page_content .my-lg-n7 {
      margin-top: -3rem !important;
    }
    #saasbox_page_content .mr-lg-n7,
    #saasbox_page_content .mx-lg-n7 {
      margin-right: -3rem !important;
    }
    #saasbox_page_content .mb-lg-n7,
    #saasbox_page_content .my-lg-n7 {
      margin-bottom: -3rem !important;
    }
    #saasbox_page_content .ml-lg-n7,
    #saasbox_page_content .mx-lg-n7 {
      margin-left: -3rem !important;
    }
    #saasbox_page_content .m-lg-n8 {
      margin: -5rem !important;
    }
    #saasbox_page_content .mt-lg-n8,
    #saasbox_page_content .my-lg-n8 {
      margin-top: -5rem !important;
    }
    #saasbox_page_content .mr-lg-n8,
    #saasbox_page_content .mx-lg-n8 {
      margin-right: -5rem !important;
    }
    #saasbox_page_content .mb-lg-n8,
    #saasbox_page_content .my-lg-n8 {
      margin-bottom: -5rem !important;
    }
    #saasbox_page_content .ml-lg-n8,
    #saasbox_page_content .mx-lg-n8 {
      margin-left: -5rem !important;
    }
    #saasbox_page_content .m-lg-n9 {
      margin: -7rem !important;
    }
    #saasbox_page_content .mt-lg-n9,
    #saasbox_page_content .my-lg-n9 {
      margin-top: -7rem !important;
    }
    #saasbox_page_content .mr-lg-n9,
    #saasbox_page_content .mx-lg-n9 {
      margin-right: -7rem !important;
    }
    #saasbox_page_content .mb-lg-n9,
    #saasbox_page_content .my-lg-n9 {
      margin-bottom: -7rem !important;
    }
    #saasbox_page_content .ml-lg-n9,
    #saasbox_page_content .mx-lg-n9 {
      margin-left: -7rem !important;
    }
    #saasbox_page_content .m-lg-n10 {
      margin: -9rem !important;
    }
    #saasbox_page_content .mt-lg-n10,
    #saasbox_page_content .my-lg-n10 {
      margin-top: -9rem !important;
    }
    #saasbox_page_content .mr-lg-n10,
    #saasbox_page_content .mx-lg-n10 {
      margin-right: -9rem !important;
    }
    #saasbox_page_content .mb-lg-n10,
    #saasbox_page_content .my-lg-n10 {
      margin-bottom: -9rem !important;
    }
    #saasbox_page_content .ml-lg-n10,
    #saasbox_page_content .mx-lg-n10 {
      margin-left: -9rem !important;
    }
    #saasbox_page_content .m-lg-n11 {
      margin: -11rem !important;
    }
    #saasbox_page_content .mt-lg-n11,
    #saasbox_page_content .my-lg-n11 {
      margin-top: -11rem !important;
    }
    #saasbox_page_content .mr-lg-n11,
    #saasbox_page_content .mx-lg-n11 {
      margin-right: -11rem !important;
    }
    #saasbox_page_content .mb-lg-n11,
    #saasbox_page_content .my-lg-n11 {
      margin-bottom: -11rem !important;
    }
    #saasbox_page_content .ml-lg-n11,
    #saasbox_page_content .mx-lg-n11 {
      margin-left: -11rem !important;
    }
    #saasbox_page_content .m-lg-n12 {
      margin: -13rem !important;
    }
    #saasbox_page_content .mt-lg-n12,
    #saasbox_page_content .my-lg-n12 {
      margin-top: -13rem !important;
    }
    #saasbox_page_content .mr-lg-n12,
    #saasbox_page_content .mx-lg-n12 {
      margin-right: -13rem !important;
    }
    #saasbox_page_content .mb-lg-n12,
    #saasbox_page_content .my-lg-n12 {
      margin-bottom: -13rem !important;
    }
    #saasbox_page_content .ml-lg-n12,
    #saasbox_page_content .mx-lg-n12 {
      margin-left: -13rem !important;
    }
    #saasbox_page_content .m-lg-n13 {
      margin: -16rem !important;
    }
    #saasbox_page_content .mt-lg-n13,
    #saasbox_page_content .my-lg-n13 {
      margin-top: -16rem !important;
    }
    #saasbox_page_content .mr-lg-n13,
    #saasbox_page_content .mx-lg-n13 {
      margin-right: -16rem !important;
    }
    #saasbox_page_content .mb-lg-n13,
    #saasbox_page_content .my-lg-n13 {
      margin-bottom: -16rem !important;
    }
    #saasbox_page_content .ml-lg-n13,
    #saasbox_page_content .mx-lg-n13 {
      margin-left: -16rem !important;
    }
    #saasbox_page_content .m-lg-auto {
      margin: auto !important;
    }
    #saasbox_page_content .mt-lg-auto,
    #saasbox_page_content .my-lg-auto {
      margin-top: auto !important;
    }
    #saasbox_page_content .mr-lg-auto,
    #saasbox_page_content .mx-lg-auto {
      margin-right: auto !important;
    }
    #saasbox_page_content .mb-lg-auto,
    #saasbox_page_content .my-lg-auto {
      margin-bottom: auto !important;
    }
    #saasbox_page_content .ml-lg-auto,
    #saasbox_page_content .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .m-xl-0 {
      margin: 0 !important;
    }
    #saasbox_page_content .mt-xl-0,
    #saasbox_page_content .my-xl-0 {
      margin-top: 0 !important;
    }
    #saasbox_page_content .mr-xl-0,
    #saasbox_page_content .mx-xl-0 {
      margin-right: 0 !important;
    }
    #saasbox_page_content .mb-xl-0,
    #saasbox_page_content .my-xl-0 {
      margin-bottom: 0 !important;
    }
    #saasbox_page_content .ml-xl-0,
    #saasbox_page_content .mx-xl-0 {
      margin-left: 0 !important;
    }
    #saasbox_page_content .m-xl-1 {
      margin: .25rem !important;
    }
    #saasbox_page_content .mt-xl-1,
    #saasbox_page_content .my-xl-1 {
      margin-top: .25rem !important;
    }
    #saasbox_page_content .mr-xl-1,
    #saasbox_page_content .mx-xl-1 {
      margin-right: .25rem !important;
    }
    #saasbox_page_content .mb-xl-1,
    #saasbox_page_content .my-xl-1 {
      margin-bottom: .25rem !important;
    }
    #saasbox_page_content .ml-xl-1,
    #saasbox_page_content .mx-xl-1 {
      margin-left: .25rem !important;
    }
    #saasbox_page_content .m-xl-2 {
      margin: .5rem !important;
    }
    #saasbox_page_content .mt-xl-2,
    #saasbox_page_content .my-xl-2 {
      margin-top: .5rem !important;
    }
    #saasbox_page_content .mr-xl-2,
    #saasbox_page_content .mx-xl-2 {
      margin-right: .5rem !important;
    }
    #saasbox_page_content .mb-xl-2,
    #saasbox_page_content .my-xl-2 {
      margin-bottom: .5rem !important;
    }
    #saasbox_page_content .ml-xl-2,
    #saasbox_page_content .mx-xl-2 {
      margin-left: .5rem !important;
    }
    #saasbox_page_content .m-xl-3 {
      margin: .75rem !important;
    }
    #saasbox_page_content .mt-xl-3,
    #saasbox_page_content .my-xl-3 {
      margin-top: .75rem !important;
    }
    #saasbox_page_content .mr-xl-3,
    #saasbox_page_content .mx-xl-3 {
      margin-right: .75rem !important;
    }
    #saasbox_page_content .mb-xl-3,
    #saasbox_page_content .my-xl-3 {
      margin-bottom: .75rem !important;
    }
    #saasbox_page_content .ml-xl-3,
    #saasbox_page_content .mx-xl-3 {
      margin-left: .75rem !important;
    }
    #saasbox_page_content .m-xl-4 {
      margin: 1rem !important;
    }
    #saasbox_page_content .mt-xl-4,
    #saasbox_page_content .my-xl-4 {
      margin-top: 1rem !important;
    }
    #saasbox_page_content .mr-xl-4,
    #saasbox_page_content .mx-xl-4 {
      margin-right: 1rem !important;
    }
    #saasbox_page_content .mb-xl-4,
    #saasbox_page_content .my-xl-4 {
      margin-bottom: 1rem !important;
    }
    #saasbox_page_content .ml-xl-4,
    #saasbox_page_content .mx-xl-4 {
      margin-left: 1rem !important;
    }
    #saasbox_page_content .m-xl-5 {
      margin: 1.5rem !important;
    }
    #saasbox_page_content .mt-xl-5,
    #saasbox_page_content .my-xl-5 {
      margin-top: 1.5rem !important;
    }
    #saasbox_page_content .mr-xl-5,
    #saasbox_page_content .mx-xl-5 {
      margin-right: 1.5rem !important;
    }
    #saasbox_page_content .mb-xl-5,
    #saasbox_page_content .my-xl-5 {
      margin-bottom: 1.5rem !important;
    }
    #saasbox_page_content .ml-xl-5,
    #saasbox_page_content .mx-xl-5 {
      margin-left: 1.5rem !important;
    }
    #saasbox_page_content .m-xl-6 {
      margin: 2rem !important;
    }
    #saasbox_page_content .mt-xl-6,
    #saasbox_page_content .my-xl-6 {
      margin-top: 2rem !important;
    }
    #saasbox_page_content .mr-xl-6,
    #saasbox_page_content .mx-xl-6 {
      margin-right: 2rem !important;
    }
    #saasbox_page_content .mb-xl-6,
    #saasbox_page_content .my-xl-6 {
      margin-bottom: 2rem !important;
    }
    #saasbox_page_content .ml-xl-6,
    #saasbox_page_content .mx-xl-6 {
      margin-left: 2rem !important;
    }
    #saasbox_page_content .m-xl-7 {
      margin: 3rem !important;
    }
    #saasbox_page_content .mt-xl-7,
    #saasbox_page_content .my-xl-7 {
      margin-top: 3rem !important;
    }
    #saasbox_page_content .mr-xl-7,
    #saasbox_page_content .mx-xl-7 {
      margin-right: 3rem !important;
    }
    #saasbox_page_content .mb-xl-7,
    #saasbox_page_content .my-xl-7 {
      margin-bottom: 3rem !important;
    }
    #saasbox_page_content .ml-xl-7,
    #saasbox_page_content .mx-xl-7 {
      margin-left: 3rem !important;
    }
    #saasbox_page_content .m-xl-8 {
      margin: 5rem !important;
    }
    #saasbox_page_content .mt-xl-8,
    #saasbox_page_content .my-xl-8 {
      margin-top: 5rem !important;
    }
    #saasbox_page_content .mr-xl-8,
    #saasbox_page_content .mx-xl-8 {
      margin-right: 5rem !important;
    }
    #saasbox_page_content .mb-xl-8,
    #saasbox_page_content .my-xl-8 {
      margin-bottom: 5rem !important;
    }
    #saasbox_page_content .ml-xl-8,
    #saasbox_page_content .mx-xl-8 {
      margin-left: 5rem !important;
    }
    #saasbox_page_content .m-xl-9 {
      margin: 7rem !important;
    }
    #saasbox_page_content .mt-xl-9,
    #saasbox_page_content .my-xl-9 {
      margin-top: 7rem !important;
    }
    #saasbox_page_content .mr-xl-9,
    #saasbox_page_content .mx-xl-9 {
      margin-right: 7rem !important;
    }
    #saasbox_page_content .mb-xl-9,
    #saasbox_page_content .my-xl-9 {
      margin-bottom: 7rem !important;
    }
    #saasbox_page_content .ml-xl-9,
    #saasbox_page_content .mx-xl-9 {
      margin-left: 7rem !important;
    }
    #saasbox_page_content .m-xl-10 {
      margin: 9rem !important;
    }
    #saasbox_page_content .mt-xl-10,
    #saasbox_page_content .my-xl-10 {
      margin-top: 9rem !important;
    }
    #saasbox_page_content .mr-xl-10,
    #saasbox_page_content .mx-xl-10 {
      margin-right: 9rem !important;
    }
    #saasbox_page_content .mb-xl-10,
    #saasbox_page_content .my-xl-10 {
      margin-bottom: 9rem !important;
    }
    #saasbox_page_content .ml-xl-10,
    #saasbox_page_content .mx-xl-10 {
      margin-left: 9rem !important;
    }
    #saasbox_page_content .m-xl-11 {
      margin: 11rem !important;
    }
    #saasbox_page_content .mt-xl-11,
    #saasbox_page_content .my-xl-11 {
      margin-top: 11rem !important;
    }
    #saasbox_page_content .mr-xl-11,
    #saasbox_page_content .mx-xl-11 {
      margin-right: 11rem !important;
    }
    #saasbox_page_content .mb-xl-11,
    #saasbox_page_content .my-xl-11 {
      margin-bottom: 11rem !important;
    }
    #saasbox_page_content .ml-xl-11,
    #saasbox_page_content .mx-xl-11 {
      margin-left: 11rem !important;
    }
    #saasbox_page_content .m-xl-12 {
      margin: 13rem !important;
    }
    #saasbox_page_content .mt-xl-12,
    #saasbox_page_content .my-xl-12 {
      margin-top: 13rem !important;
    }
    #saasbox_page_content .mr-xl-12,
    #saasbox_page_content .mx-xl-12 {
      margin-right: 13rem !important;
    }
    #saasbox_page_content .mb-xl-12,
    #saasbox_page_content .my-xl-12 {
      margin-bottom: 13rem !important;
    }
    #saasbox_page_content .ml-xl-12,
    #saasbox_page_content .mx-xl-12 {
      margin-left: 13rem !important;
    }
    #saasbox_page_content .m-xl-13 {
      margin: 16rem !important;
    }
    #saasbox_page_content .mt-xl-13,
    #saasbox_page_content .my-xl-13 {
      margin-top: 16rem !important;
    }
    #saasbox_page_content .mr-xl-13,
    #saasbox_page_content .mx-xl-13 {
      margin-right: 16rem !important;
    }
    #saasbox_page_content .mb-xl-13,
    #saasbox_page_content .my-xl-13 {
      margin-bottom: 16rem !important;
    }
    #saasbox_page_content .ml-xl-13,
    #saasbox_page_content .mx-xl-13 {
      margin-left: 16rem !important;
    }
    #saasbox_page_content .p-xl-0 {
      padding: 0 !important;
    }
    #saasbox_page_content .pt-xl-0,
    #saasbox_page_content .py-xl-0 {
      padding-top: 0 !important;
    }
    #saasbox_page_content .pr-xl-0,
    #saasbox_page_content .px-xl-0 {
      padding-right: 0 !important;
    }
    #saasbox_page_content .pb-xl-0,
    #saasbox_page_content .py-xl-0 {
      padding-bottom: 0 !important;
    }
    #saasbox_page_content .pl-xl-0,
    #saasbox_page_content .px-xl-0 {
      padding-left: 0 !important;
    }
    #saasbox_page_content .p-xl-1 {
      padding: .25rem !important;
    }
    #saasbox_page_content .pt-xl-1,
    #saasbox_page_content .py-xl-1 {
      padding-top: .25rem !important;
    }
    #saasbox_page_content .pr-xl-1,
    #saasbox_page_content .px-xl-1 {
      padding-right: .25rem !important;
    }
    #saasbox_page_content .pb-xl-1,
    #saasbox_page_content .py-xl-1 {
      padding-bottom: .25rem !important;
    }
    #saasbox_page_content .pl-xl-1,
    #saasbox_page_content .px-xl-1 {
      padding-left: .25rem !important;
    }
    #saasbox_page_content .p-xl-2 {
      padding: .5rem !important;
    }
    #saasbox_page_content .pt-xl-2,
    #saasbox_page_content .py-xl-2 {
      padding-top: .5rem !important;
    }
    #saasbox_page_content .pr-xl-2,
    #saasbox_page_content .px-xl-2 {
      padding-right: .5rem !important;
    }
    #saasbox_page_content .pb-xl-2,
    #saasbox_page_content .py-xl-2 {
      padding-bottom: .5rem !important;
    }
    #saasbox_page_content .pl-xl-2,
    #saasbox_page_content .px-xl-2 {
      padding-left: .5rem !important;
    }
    #saasbox_page_content .p-xl-3 {
      padding: .75rem !important;
    }
    #saasbox_page_content .pt-xl-3,
    #saasbox_page_content .py-xl-3 {
      padding-top: .75rem !important;
    }
    #saasbox_page_content .pr-xl-3,
    #saasbox_page_content .px-xl-3 {
      padding-right: .75rem !important;
    }
    #saasbox_page_content .pb-xl-3,
    #saasbox_page_content .py-xl-3 {
      padding-bottom: .75rem !important;
    }
    #saasbox_page_content .pl-xl-3,
    #saasbox_page_content .px-xl-3 {
      padding-left: .75rem !important;
    }
    #saasbox_page_content .p-xl-4 {
      padding: 1rem !important;
    }
    #saasbox_page_content .pt-xl-4,
    #saasbox_page_content .py-xl-4 {
      padding-top: 1rem !important;
    }
    #saasbox_page_content .pr-xl-4,
    #saasbox_page_content .px-xl-4 {
      padding-right: 1rem !important;
    }
    #saasbox_page_content .pb-xl-4,
    #saasbox_page_content .py-xl-4 {
      padding-bottom: 1rem !important;
    }
    #saasbox_page_content .pl-xl-4,
    #saasbox_page_content .px-xl-4 {
      padding-left: 1rem !important;
    }
    #saasbox_page_content .p-xl-5 {
      padding: 1.5rem !important;
    }
    #saasbox_page_content .pt-xl-5,
    #saasbox_page_content .py-xl-5 {
      padding-top: 1.5rem !important;
    }
    #saasbox_page_content .pr-xl-5,
    #saasbox_page_content .px-xl-5 {
      padding-right: 1.5rem !important;
    }
    #saasbox_page_content .pb-xl-5,
    #saasbox_page_content .py-xl-5 {
      padding-bottom: 1.5rem !important;
    }
    #saasbox_page_content .pl-xl-5,
    #saasbox_page_content .px-xl-5 {
      padding-left: 1.5rem !important;
    }
    #saasbox_page_content .p-xl-6 {
      padding: 2rem !important;
    }
    #saasbox_page_content .pt-xl-6,
    #saasbox_page_content .py-xl-6 {
      padding-top: 2rem !important;
    }
    #saasbox_page_content .pr-xl-6,
    #saasbox_page_content .px-xl-6 {
      padding-right: 2rem !important;
    }
    #saasbox_page_content .pb-xl-6,
    #saasbox_page_content .py-xl-6 {
      padding-bottom: 2rem !important;
    }
    #saasbox_page_content .pl-xl-6,
    #saasbox_page_content .px-xl-6 {
      padding-left: 2rem !important;
    }
    #saasbox_page_content .p-xl-7 {
      padding: 3rem !important;
    }
    #saasbox_page_content .pt-xl-7,
    #saasbox_page_content .py-xl-7 {
      padding-top: 3rem !important;
    }
    #saasbox_page_content .pr-xl-7,
    #saasbox_page_content .px-xl-7 {
      padding-right: 3rem !important;
    }
    #saasbox_page_content .pb-xl-7,
    #saasbox_page_content .py-xl-7 {
      padding-bottom: 3rem !important;
    }
    #saasbox_page_content .pl-xl-7,
    #saasbox_page_content .px-xl-7 {
      padding-left: 3rem !important;
    }
    #saasbox_page_content .p-xl-8 {
      padding: 5rem !important;
    }
    #saasbox_page_content .pt-xl-8,
    #saasbox_page_content .py-xl-8 {
      padding-top: 5rem !important;
    }
    #saasbox_page_content .pr-xl-8,
    #saasbox_page_content .px-xl-8 {
      padding-right: 5rem !important;
    }
    #saasbox_page_content .pb-xl-8,
    #saasbox_page_content .py-xl-8 {
      padding-bottom: 5rem !important;
    }
    #saasbox_page_content .pl-xl-8,
    #saasbox_page_content .px-xl-8 {
      padding-left: 5rem !important;
    }
    #saasbox_page_content .p-xl-9 {
      padding: 7rem !important;
    }
    #saasbox_page_content .pt-xl-9,
    #saasbox_page_content .py-xl-9 {
      padding-top: 7rem !important;
    }
    #saasbox_page_content .pr-xl-9,
    #saasbox_page_content .px-xl-9 {
      padding-right: 7rem !important;
    }
    #saasbox_page_content .pb-xl-9,
    #saasbox_page_content .py-xl-9 {
      padding-bottom: 7rem !important;
    }
    #saasbox_page_content .pl-xl-9,
    #saasbox_page_content .px-xl-9 {
      padding-left: 7rem !important;
    }
    #saasbox_page_content .p-xl-10 {
      padding: 9rem !important;
    }
    #saasbox_page_content .pt-xl-10,
    #saasbox_page_content .py-xl-10 {
      padding-top: 9rem !important;
    }
    #saasbox_page_content .pr-xl-10,
    #saasbox_page_content .px-xl-10 {
      padding-right: 9rem !important;
    }
    #saasbox_page_content .pb-xl-10,
    #saasbox_page_content .py-xl-10 {
      padding-bottom: 9rem !important;
    }
    #saasbox_page_content .pl-xl-10,
    #saasbox_page_content .px-xl-10 {
      padding-left: 9rem !important;
    }
    #saasbox_page_content .p-xl-11 {
      padding: 11rem !important;
    }
    #saasbox_page_content .pt-xl-11,
    #saasbox_page_content .py-xl-11 {
      padding-top: 11rem !important;
    }
    #saasbox_page_content .pr-xl-11,
    #saasbox_page_content .px-xl-11 {
      padding-right: 11rem !important;
    }
    #saasbox_page_content .pb-xl-11,
    #saasbox_page_content .py-xl-11 {
      padding-bottom: 11rem !important;
    }
    #saasbox_page_content .pl-xl-11,
    #saasbox_page_content .px-xl-11 {
      padding-left: 11rem !important;
    }
    #saasbox_page_content .p-xl-12 {
      padding: 13rem !important;
    }
    #saasbox_page_content .pt-xl-12,
    #saasbox_page_content .py-xl-12 {
      padding-top: 13rem !important;
    }
    #saasbox_page_content .pr-xl-12,
    #saasbox_page_content .px-xl-12 {
      padding-right: 13rem !important;
    }
    #saasbox_page_content .pb-xl-12,
    #saasbox_page_content .py-xl-12 {
      padding-bottom: 13rem !important;
    }
    #saasbox_page_content .pl-xl-12,
    #saasbox_page_content .px-xl-12 {
      padding-left: 13rem !important;
    }
    #saasbox_page_content .p-xl-13 {
      padding: 16rem !important;
    }
    #saasbox_page_content .pt-xl-13,
    #saasbox_page_content .py-xl-13 {
      padding-top: 16rem !important;
    }
    #saasbox_page_content .pr-xl-13,
    #saasbox_page_content .px-xl-13 {
      padding-right: 16rem !important;
    }
    #saasbox_page_content .pb-xl-13,
    #saasbox_page_content .py-xl-13 {
      padding-bottom: 16rem !important;
    }
    #saasbox_page_content .pl-xl-13,
    #saasbox_page_content .px-xl-13 {
      padding-left: 16rem !important;
    }
    #saasbox_page_content .m-xl-n1 {
      margin: -0.25rem !important;
    }
    #saasbox_page_content .mt-xl-n1,
    #saasbox_page_content .my-xl-n1 {
      margin-top: -0.25rem !important;
    }
    #saasbox_page_content .mr-xl-n1,
    #saasbox_page_content .mx-xl-n1 {
      margin-right: -0.25rem !important;
    }
    #saasbox_page_content .mb-xl-n1,
    #saasbox_page_content .my-xl-n1 {
      margin-bottom: -0.25rem !important;
    }
    #saasbox_page_content .ml-xl-n1,
    #saasbox_page_content .mx-xl-n1 {
      margin-left: -0.25rem !important;
    }
    #saasbox_page_content .m-xl-n2 {
      margin: -0.5rem !important;
    }
    #saasbox_page_content .mt-xl-n2,
    #saasbox_page_content .my-xl-n2 {
      margin-top: -0.5rem !important;
    }
    #saasbox_page_content .mr-xl-n2,
    #saasbox_page_content .mx-xl-n2 {
      margin-right: -0.5rem !important;
    }
    #saasbox_page_content .mb-xl-n2,
    #saasbox_page_content .my-xl-n2 {
      margin-bottom: -0.5rem !important;
    }
    #saasbox_page_content .ml-xl-n2,
    #saasbox_page_content .mx-xl-n2 {
      margin-left: -0.5rem !important;
    }
    #saasbox_page_content .m-xl-n3 {
      margin: -0.75rem !important;
    }
    #saasbox_page_content .mt-xl-n3,
    #saasbox_page_content .my-xl-n3 {
      margin-top: -0.75rem !important;
    }
    #saasbox_page_content .mr-xl-n3,
    #saasbox_page_content .mx-xl-n3 {
      margin-right: -0.75rem !important;
    }
    #saasbox_page_content .mb-xl-n3,
    #saasbox_page_content .my-xl-n3 {
      margin-bottom: -0.75rem !important;
    }
    #saasbox_page_content .ml-xl-n3,
    #saasbox_page_content .mx-xl-n3 {
      margin-left: -0.75rem !important;
    }
    #saasbox_page_content .m-xl-n4 {
      margin: -1rem !important;
    }
    #saasbox_page_content .mt-xl-n4,
    #saasbox_page_content .my-xl-n4 {
      margin-top: -1rem !important;
    }
    #saasbox_page_content .mr-xl-n4,
    #saasbox_page_content .mx-xl-n4 {
      margin-right: -1rem !important;
    }
    #saasbox_page_content .mb-xl-n4,
    #saasbox_page_content .my-xl-n4 {
      margin-bottom: -1rem !important;
    }
    #saasbox_page_content .ml-xl-n4,
    #saasbox_page_content .mx-xl-n4 {
      margin-left: -1rem !important;
    }
    #saasbox_page_content .m-xl-n5 {
      margin: -1.5rem !important;
    }
    #saasbox_page_content .mt-xl-n5,
    #saasbox_page_content .my-xl-n5 {
      margin-top: -1.5rem !important;
    }
    #saasbox_page_content .mr-xl-n5,
    #saasbox_page_content .mx-xl-n5 {
      margin-right: -1.5rem !important;
    }
    #saasbox_page_content .mb-xl-n5,
    #saasbox_page_content .my-xl-n5 {
      margin-bottom: -1.5rem !important;
    }
    #saasbox_page_content .ml-xl-n5,
    #saasbox_page_content .mx-xl-n5 {
      margin-left: -1.5rem !important;
    }
    #saasbox_page_content .m-xl-n6 {
      margin: -2rem !important;
    }
    #saasbox_page_content .mt-xl-n6,
    #saasbox_page_content .my-xl-n6 {
      margin-top: -2rem !important;
    }
    #saasbox_page_content .mr-xl-n6,
    #saasbox_page_content .mx-xl-n6 {
      margin-right: -2rem !important;
    }
    #saasbox_page_content .mb-xl-n6,
    #saasbox_page_content .my-xl-n6 {
      margin-bottom: -2rem !important;
    }
    #saasbox_page_content .ml-xl-n6,
    #saasbox_page_content .mx-xl-n6 {
      margin-left: -2rem !important;
    }
    #saasbox_page_content .m-xl-n7 {
      margin: -3rem !important;
    }
    #saasbox_page_content .mt-xl-n7,
    #saasbox_page_content .my-xl-n7 {
      margin-top: -3rem !important;
    }
    #saasbox_page_content .mr-xl-n7,
    #saasbox_page_content .mx-xl-n7 {
      margin-right: -3rem !important;
    }
    #saasbox_page_content .mb-xl-n7,
    #saasbox_page_content .my-xl-n7 {
      margin-bottom: -3rem !important;
    }
    #saasbox_page_content .ml-xl-n7,
    #saasbox_page_content .mx-xl-n7 {
      margin-left: -3rem !important;
    }
    #saasbox_page_content .m-xl-n8 {
      margin: -5rem !important;
    }
    #saasbox_page_content .mt-xl-n8,
    #saasbox_page_content .my-xl-n8 {
      margin-top: -5rem !important;
    }
    #saasbox_page_content .mr-xl-n8,
    #saasbox_page_content .mx-xl-n8 {
      margin-right: -5rem !important;
    }
    #saasbox_page_content .mb-xl-n8,
    #saasbox_page_content .my-xl-n8 {
      margin-bottom: -5rem !important;
    }
    #saasbox_page_content .ml-xl-n8,
    #saasbox_page_content .mx-xl-n8 {
      margin-left: -5rem !important;
    }
    #saasbox_page_content .m-xl-n9 {
      margin: -7rem !important;
    }
    #saasbox_page_content .mt-xl-n9,
    #saasbox_page_content .my-xl-n9 {
      margin-top: -7rem !important;
    }
    #saasbox_page_content .mr-xl-n9,
    #saasbox_page_content .mx-xl-n9 {
      margin-right: -7rem !important;
    }
    #saasbox_page_content .mb-xl-n9,
    #saasbox_page_content .my-xl-n9 {
      margin-bottom: -7rem !important;
    }
    #saasbox_page_content .ml-xl-n9,
    #saasbox_page_content .mx-xl-n9 {
      margin-left: -7rem !important;
    }
    #saasbox_page_content .m-xl-n10 {
      margin: -9rem !important;
    }
    #saasbox_page_content .mt-xl-n10,
    #saasbox_page_content .my-xl-n10 {
      margin-top: -9rem !important;
    }
    #saasbox_page_content .mr-xl-n10,
    #saasbox_page_content .mx-xl-n10 {
      margin-right: -9rem !important;
    }
    #saasbox_page_content .mb-xl-n10,
    #saasbox_page_content .my-xl-n10 {
      margin-bottom: -9rem !important;
    }
    #saasbox_page_content .ml-xl-n10,
    #saasbox_page_content .mx-xl-n10 {
      margin-left: -9rem !important;
    }
    #saasbox_page_content .m-xl-n11 {
      margin: -11rem !important;
    }
    #saasbox_page_content .mt-xl-n11,
    #saasbox_page_content .my-xl-n11 {
      margin-top: -11rem !important;
    }
    #saasbox_page_content .mr-xl-n11,
    #saasbox_page_content .mx-xl-n11 {
      margin-right: -11rem !important;
    }
    #saasbox_page_content .mb-xl-n11,
    #saasbox_page_content .my-xl-n11 {
      margin-bottom: -11rem !important;
    }
    #saasbox_page_content .ml-xl-n11,
    #saasbox_page_content .mx-xl-n11 {
      margin-left: -11rem !important;
    }
    #saasbox_page_content .m-xl-n12 {
      margin: -13rem !important;
    }
    #saasbox_page_content .mt-xl-n12,
    #saasbox_page_content .my-xl-n12 {
      margin-top: -13rem !important;
    }
    #saasbox_page_content .mr-xl-n12,
    #saasbox_page_content .mx-xl-n12 {
      margin-right: -13rem !important;
    }
    #saasbox_page_content .mb-xl-n12,
    #saasbox_page_content .my-xl-n12 {
      margin-bottom: -13rem !important;
    }
    #saasbox_page_content .ml-xl-n12,
    #saasbox_page_content .mx-xl-n12 {
      margin-left: -13rem !important;
    }
    #saasbox_page_content .m-xl-n13 {
      margin: -16rem !important;
    }
    #saasbox_page_content .mt-xl-n13,
    #saasbox_page_content .my-xl-n13 {
      margin-top: -16rem !important;
    }
    #saasbox_page_content .mr-xl-n13,
    #saasbox_page_content .mx-xl-n13 {
      margin-right: -16rem !important;
    }
    #saasbox_page_content .mb-xl-n13,
    #saasbox_page_content .my-xl-n13 {
      margin-bottom: -16rem !important;
    }
    #saasbox_page_content .ml-xl-n13,
    #saasbox_page_content .mx-xl-n13 {
      margin-left: -16rem !important;
    }
    #saasbox_page_content .m-xl-auto {
      margin: auto !important;
    }
    #saasbox_page_content .mt-xl-auto,
    #saasbox_page_content .my-xl-auto {
      margin-top: auto !important;
    }
    #saasbox_page_content .mr-xl-auto,
    #saasbox_page_content .mx-xl-auto {
      margin-right: auto !important;
    }
    #saasbox_page_content .mb-xl-auto,
    #saasbox_page_content .my-xl-auto {
      margin-bottom: auto !important;
    }
    #saasbox_page_content .ml-xl-auto,
    #saasbox_page_content .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  #saasbox_page_content .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }
  #saasbox_page_content .text-justify {
    text-align: justify !important;
  }
  #saasbox_page_content .text-wrap {
    white-space: normal !important;
  }
  #saasbox_page_content .text-nowrap {
    white-space: nowrap !important;
  }
  #saasbox_page_content .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  #saasbox_page_content .text-left {
    text-align: left !important;
  }
  #saasbox_page_content .text-right {
    text-align: right !important;
  }
  #saasbox_page_content .text-center {
    text-align: center !important;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .text-sm-left {
      text-align: left !important;
    }
    #saasbox_page_content .text-sm-right {
      text-align: right !important;
    }
    #saasbox_page_content .text-sm-center {
      text-align: center !important;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .text-md-left {
      text-align: left !important;
    }
    #saasbox_page_content .text-md-right {
      text-align: right !important;
    }
    #saasbox_page_content .text-md-center {
      text-align: center !important;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .text-lg-left {
      text-align: left !important;
    }
    #saasbox_page_content .text-lg-right {
      text-align: right !important;
    }
    #saasbox_page_content .text-lg-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .text-xl-left {
      text-align: left !important;
    }
    #saasbox_page_content .text-xl-right {
      text-align: right !important;
    }
    #saasbox_page_content .text-xl-center {
      text-align: center !important;
    }
  }
  #saasbox_page_content .text-lowercase {
    text-transform: lowercase !important;
  }
  #saasbox_page_content .text-uppercase {
    text-transform: uppercase !important;
  }
  #saasbox_page_content .text-capitalize {
    text-transform: capitalize !important;
  }
  #saasbox_page_content .font-weight-light {
    font-weight: 300 !important;
  }
  #saasbox_page_content .font-weight-lighter {
    font-weight: lighter !important;
  }
  #saasbox_page_content .font-weight-normal {
    font-weight: 400 !important;
  }
  #saasbox_page_content .font-weight-bold {
    font-weight: 600 !important;
  }
  #saasbox_page_content .font-weight-bolder {
    font-weight: bolder !important;
  }
  #saasbox_page_content .font-italic {
    font-style: italic !important;
  }
  #saasbox_page_content .text-white {
    color: #fff !important;
  }
  #saasbox_page_content .text-primary {
    color: #50a1ff !important;
  }
  #saasbox_page_content a.text-primary:hover,
  #saasbox_page_content a.text-primary:focus {
    color: #0478ff !important;
  }
  #saasbox_page_content .text-secondary {
    color: #e9ecf0 !important;
  }
  #saasbox_page_content a.text-secondary:hover,
  #saasbox_page_content a.text-secondary:focus {
    color: #bcc5d1 !important;
  }
  #saasbox_page_content .text-success {
    color: #3cd458 !important;
  }
  #saasbox_page_content a.text-success:hover,
  #saasbox_page_content a.text-success:focus {
    color: #23a03a !important;
  }
  #saasbox_page_content .text-info {
    color: #926dde !important;
  }
  #saasbox_page_content a.text-info:hover,
  #saasbox_page_content a.text-info:focus {
    color: #642fd0 !important;
  }
  #saasbox_page_content .text-warning {
    color: #ffba00 !important;
  }
  #saasbox_page_content a.text-warning:hover,
  #saasbox_page_content a.text-warning:focus {
    color: #b38200 !important;
  }
  #saasbox_page_content .text-danger {
    color: #ff4954 !important;
  }
  #saasbox_page_content a.text-danger:hover,
  #saasbox_page_content a.text-danger:focus {
    color: #fc000f !important;
  }
  #saasbox_page_content .text-light {
    color: #f8f9fa !important;
  }
  #saasbox_page_content a.text-light:hover,
  #saasbox_page_content a.text-light:focus {
    color: #cbd3da !important;
  }
  #saasbox_page_content .text-dark {
    color: #191919 !important;
  }
  #saasbox_page_content a.text-dark:hover,
  #saasbox_page_content a.text-dark:focus {
    color: black !important;
  }
  #saasbox_page_content .text-body {
    color: #757575 !important;
  }
  #saasbox_page_content .text-muted {
    color: #868e96 !important;
  }
  #saasbox_page_content .text-black-50 {
    color: rgba(0,0,0,0.5) !important;
  }
  #saasbox_page_content .text-white-50 {
    color: rgba(255,255,255,0.5) !important;
  }
  #saasbox_page_content .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  #saasbox_page_content .text-decoration-none {
    text-decoration: none !important;
  }
  #saasbox_page_content .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
  }
  #saasbox_page_content .text-reset {
    color: inherit !important;
  }
  #saasbox_page_content .visible {
    visibility: visible !important;
  }
  #saasbox_page_content .invisible {
    visibility: hidden !important;
  }
  @media print {
    #saasbox_page_content *,
    #saasbox_page_content *::before,
    #saasbox_page_content *::after {
      text-shadow: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
    #saasbox_page_content a:not(.btn):not(.wpcf7-submit) {
      text-decoration: underline;
    }
    #saasbox_page_content abbr[title]::after {
      content: " (" attr(title) ")";
    }
    #saasbox_page_content pre {
      white-space: pre-wrap !important;
    }
    #saasbox_page_content pre,
    #saasbox_page_content blockquote {
      border: 1px solid #adb5bd;
      page-break-inside: avoid;
    }
    #saasbox_page_content thead {
      display: table-header-group;
    }
    #saasbox_page_content tr,
    #saasbox_page_content img {
      page-break-inside: avoid;
    }
    #saasbox_page_content p,
    #saasbox_page_content h2,
    #saasbox_page_content h3 {
      orphans: 3;
      widows: 3;
    }
    #saasbox_page_content h2,
    #saasbox_page_content h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    #saasbox_page_content .container,
    #saasbox_page_content .woocommerce-page .woocommerce {
      min-width: 992px !important;
    }
    #saasbox_page_content .navbar {
      display: none;
    }
    #saasbox_page_content .badge,
    #saasbox_page_content .tagcloud .tag-cloud-link {
      border: 1px solid #000;
    }
    #saasbox_page_content .table {
      border-collapse: collapse !important;
    }
    #saasbox_page_content .table td,
    #saasbox_page_content .table th {
      background-color: #fff !important;
    }
    #saasbox_page_content .table-bordered th,
    #saasbox_page_content .table-bordered td {
      border: 1px solid #dee2e6 !important;
    }
    #saasbox_page_content .table-dark {
      color: inherit;
    }
    #saasbox_page_content .table-dark th,
    #saasbox_page_content .table-dark td,
    #saasbox_page_content .table-dark thead th,
    #saasbox_page_content .table-dark tbody+tbody {
      border-color: #f1f2f3;
    }
    #saasbox_page_content .table .thead-dark th {
      color: inherit;
      border-color: #f1f2f3;
    }
  }
  #saasbox_page_content body[data-aos-duration='50'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='50'] {
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
  }
  #saasbox_page_content body[data-aos-delay='50'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='50'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='50'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms;
  }
  #saasbox_page_content body[data-aos-duration='100'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='100'] {
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
  }
  #saasbox_page_content body[data-aos-delay='100'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='100'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='100'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
  }
  #saasbox_page_content body[data-aos-duration='150'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='150'] {
    -webkit-transition-duration: 150ms;
    transition-duration: 150ms;
  }
  #saasbox_page_content body[data-aos-delay='150'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='150'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='150'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    -webkit-transition-delay: 150ms;
    transition-delay: 150ms;
  }
  #saasbox_page_content body[data-aos-duration='200'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='200'] {
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
  }
  #saasbox_page_content body[data-aos-delay='200'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='200'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='200'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
  }
  #saasbox_page_content body[data-aos-duration='250'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='250'] {
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
  }
  #saasbox_page_content body[data-aos-delay='250'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='250'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='250'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    -webkit-transition-delay: 250ms;
    transition-delay: 250ms;
  }
  #saasbox_page_content body[data-aos-duration='300'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='300'] {
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
  }
  #saasbox_page_content body[data-aos-delay='300'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='300'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='300'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    -webkit-transition-delay: 300ms;
    transition-delay: 300ms;
  }
  #saasbox_page_content body[data-aos-duration='350'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='350'] {
    -webkit-transition-duration: 350ms;
    transition-duration: 350ms;
  }
  #saasbox_page_content body[data-aos-delay='350'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='350'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='350'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    -webkit-transition-delay: 350ms;
    transition-delay: 350ms;
  }
  #saasbox_page_content body[data-aos-duration='400'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='400'] {
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
  }
  #saasbox_page_content body[data-aos-delay='400'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='400'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='400'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
  }
  #saasbox_page_content body[data-aos-duration='450'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='450'] {
    -webkit-transition-duration: 450ms;
    transition-duration: 450ms;
  }
  #saasbox_page_content body[data-aos-delay='450'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='450'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='450'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    -webkit-transition-delay: 450ms;
    transition-delay: 450ms;
  }
  #saasbox_page_content body[data-aos-duration='500'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='500'] {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
  }
  #saasbox_page_content body[data-aos-delay='500'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='500'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='500'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    -webkit-transition-delay: 500ms;
    transition-delay: 500ms;
  }
  #saasbox_page_content body[data-aos-duration='550'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='550'] {
    -webkit-transition-duration: 550ms;
    transition-duration: 550ms;
  }
  #saasbox_page_content body[data-aos-delay='550'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='550'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='550'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    -webkit-transition-delay: 550ms;
    transition-delay: 550ms;
  }
  #saasbox_page_content body[data-aos-duration='600'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='600'] {
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
  }
  #saasbox_page_content body[data-aos-delay='600'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='600'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='600'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    -webkit-transition-delay: 600ms;
    transition-delay: 600ms;
  }
  #saasbox_page_content body[data-aos-duration='650'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='650'] {
    -webkit-transition-duration: 650ms;
    transition-duration: 650ms;
  }
  #saasbox_page_content body[data-aos-delay='650'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='650'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='650'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    -webkit-transition-delay: 650ms;
    transition-delay: 650ms;
  }
  #saasbox_page_content body[data-aos-duration='700'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='700'] {
    -webkit-transition-duration: 700ms;
    transition-duration: 700ms;
  }
  #saasbox_page_content body[data-aos-delay='700'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='700'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='700'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    -webkit-transition-delay: 700ms;
    transition-delay: 700ms;
  }
  #saasbox_page_content body[data-aos-duration='750'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='750'] {
    -webkit-transition-duration: 750ms;
    transition-duration: 750ms;
  }
  #saasbox_page_content body[data-aos-delay='750'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='750'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='750'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    -webkit-transition-delay: 750ms;
    transition-delay: 750ms;
  }
  #saasbox_page_content body[data-aos-duration='800'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='800'] {
    -webkit-transition-duration: 800ms;
    transition-duration: 800ms;
  }
  #saasbox_page_content body[data-aos-delay='800'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='800'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='800'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    -webkit-transition-delay: 800ms;
    transition-delay: 800ms;
  }
  #saasbox_page_content body[data-aos-duration='850'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='850'] {
    -webkit-transition-duration: 850ms;
    transition-duration: 850ms;
  }
  #saasbox_page_content body[data-aos-delay='850'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='850'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='850'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    -webkit-transition-delay: 850ms;
    transition-delay: 850ms;
  }
  #saasbox_page_content body[data-aos-duration='900'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='900'] {
    -webkit-transition-duration: 900ms;
    transition-duration: 900ms;
  }
  #saasbox_page_content body[data-aos-delay='900'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='900'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='900'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    -webkit-transition-delay: 900ms;
    transition-delay: 900ms;
  }
  #saasbox_page_content body[data-aos-duration='950'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='950'] {
    -webkit-transition-duration: 950ms;
    transition-duration: 950ms;
  }
  #saasbox_page_content body[data-aos-delay='950'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='950'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='950'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    -webkit-transition-delay: 950ms;
    transition-delay: 950ms;
  }
  #saasbox_page_content body[data-aos-duration='1000'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1000'] {
    -webkit-transition-duration: 1000ms;
    transition-duration: 1000ms;
  }
  #saasbox_page_content body[data-aos-delay='1000'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1000'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1000'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    -webkit-transition-delay: 1000ms;
    transition-delay: 1000ms;
  }
  #saasbox_page_content body[data-aos-duration='1050'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1050'] {
    -webkit-transition-duration: 1050ms;
    transition-duration: 1050ms;
  }
  #saasbox_page_content body[data-aos-delay='1050'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1050'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1050'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    -webkit-transition-delay: 1050ms;
    transition-delay: 1050ms;
  }
  #saasbox_page_content body[data-aos-duration='1100'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1100'] {
    -webkit-transition-duration: 1100ms;
    transition-duration: 1100ms;
  }
  #saasbox_page_content body[data-aos-delay='1100'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1100'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1100'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    -webkit-transition-delay: 1100ms;
    transition-delay: 1100ms;
  }
  #saasbox_page_content body[data-aos-duration='1150'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1150'] {
    -webkit-transition-duration: 1150ms;
    transition-duration: 1150ms;
  }
  #saasbox_page_content body[data-aos-delay='1150'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1150'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1150'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    -webkit-transition-delay: 1150ms;
    transition-delay: 1150ms;
  }
  #saasbox_page_content body[data-aos-duration='1200'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1200'] {
    -webkit-transition-duration: 1200ms;
    transition-duration: 1200ms;
  }
  #saasbox_page_content body[data-aos-delay='1200'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1200'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1200'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    -webkit-transition-delay: 1200ms;
    transition-delay: 1200ms;
  }
  #saasbox_page_content body[data-aos-duration='1250'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1250'] {
    -webkit-transition-duration: 1250ms;
    transition-duration: 1250ms;
  }
  #saasbox_page_content body[data-aos-delay='1250'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1250'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1250'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    -webkit-transition-delay: 1250ms;
    transition-delay: 1250ms;
  }
  #saasbox_page_content body[data-aos-duration='1300'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1300'] {
    -webkit-transition-duration: 1300ms;
    transition-duration: 1300ms;
  }
  #saasbox_page_content body[data-aos-delay='1300'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1300'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1300'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    -webkit-transition-delay: 1300ms;
    transition-delay: 1300ms;
  }
  #saasbox_page_content body[data-aos-duration='1350'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1350'] {
    -webkit-transition-duration: 1350ms;
    transition-duration: 1350ms;
  }
  #saasbox_page_content body[data-aos-delay='1350'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1350'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1350'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    -webkit-transition-delay: 1350ms;
    transition-delay: 1350ms;
  }
  #saasbox_page_content body[data-aos-duration='1400'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1400'] {
    -webkit-transition-duration: 1400ms;
    transition-duration: 1400ms;
  }
  #saasbox_page_content body[data-aos-delay='1400'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1400'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1400'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    -webkit-transition-delay: 1400ms;
    transition-delay: 1400ms;
  }
  #saasbox_page_content body[data-aos-duration='1450'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1450'] {
    -webkit-transition-duration: 1450ms;
    transition-duration: 1450ms;
  }
  #saasbox_page_content body[data-aos-delay='1450'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1450'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1450'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    -webkit-transition-delay: 1450ms;
    transition-delay: 1450ms;
  }
  #saasbox_page_content body[data-aos-duration='1500'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1500'] {
    -webkit-transition-duration: 1500ms;
    transition-duration: 1500ms;
  }
  #saasbox_page_content body[data-aos-delay='1500'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1500'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1500'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    -webkit-transition-delay: 1500ms;
    transition-delay: 1500ms;
  }
  #saasbox_page_content body[data-aos-duration='1550'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1550'] {
    -webkit-transition-duration: 1550ms;
    transition-duration: 1550ms;
  }
  #saasbox_page_content body[data-aos-delay='1550'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1550'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1550'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    -webkit-transition-delay: 1550ms;
    transition-delay: 1550ms;
  }
  #saasbox_page_content body[data-aos-duration='1600'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1600'] {
    -webkit-transition-duration: 1600ms;
    transition-duration: 1600ms;
  }
  #saasbox_page_content body[data-aos-delay='1600'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1600'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1600'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    -webkit-transition-delay: 1600ms;
    transition-delay: 1600ms;
  }
  #saasbox_page_content body[data-aos-duration='1650'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1650'] {
    -webkit-transition-duration: 1650ms;
    transition-duration: 1650ms;
  }
  #saasbox_page_content body[data-aos-delay='1650'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1650'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1650'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    -webkit-transition-delay: 1650ms;
    transition-delay: 1650ms;
  }
  #saasbox_page_content body[data-aos-duration='1700'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1700'] {
    -webkit-transition-duration: 1700ms;
    transition-duration: 1700ms;
  }
  #saasbox_page_content body[data-aos-delay='1700'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1700'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1700'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    -webkit-transition-delay: 1700ms;
    transition-delay: 1700ms;
  }
  #saasbox_page_content body[data-aos-duration='1750'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1750'] {
    -webkit-transition-duration: 1750ms;
    transition-duration: 1750ms;
  }
  #saasbox_page_content body[data-aos-delay='1750'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1750'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1750'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    -webkit-transition-delay: 1750ms;
    transition-delay: 1750ms;
  }
  #saasbox_page_content body[data-aos-duration='1800'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1800'] {
    -webkit-transition-duration: 1800ms;
    transition-duration: 1800ms;
  }
  #saasbox_page_content body[data-aos-delay='1800'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1800'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1800'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    -webkit-transition-delay: 1800ms;
    transition-delay: 1800ms;
  }
  #saasbox_page_content body[data-aos-duration='1850'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1850'] {
    -webkit-transition-duration: 1850ms;
    transition-duration: 1850ms;
  }
  #saasbox_page_content body[data-aos-delay='1850'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1850'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1850'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    -webkit-transition-delay: 1850ms;
    transition-delay: 1850ms;
  }
  #saasbox_page_content body[data-aos-duration='1900'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1900'] {
    -webkit-transition-duration: 1900ms;
    transition-duration: 1900ms;
  }
  #saasbox_page_content body[data-aos-delay='1900'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1900'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1900'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    -webkit-transition-delay: 1900ms;
    transition-delay: 1900ms;
  }
  #saasbox_page_content body[data-aos-duration='1950'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='1950'] {
    -webkit-transition-duration: 1950ms;
    transition-duration: 1950ms;
  }
  #saasbox_page_content body[data-aos-delay='1950'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1950'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='1950'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    -webkit-transition-delay: 1950ms;
    transition-delay: 1950ms;
  }
  #saasbox_page_content body[data-aos-duration='2000'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2000'] {
    -webkit-transition-duration: 2000ms;
    transition-duration: 2000ms;
  }
  #saasbox_page_content body[data-aos-delay='2000'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2000'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2000'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    -webkit-transition-delay: 2000ms;
    transition-delay: 2000ms;
  }
  #saasbox_page_content body[data-aos-duration='2050'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2050'] {
    -webkit-transition-duration: 2050ms;
    transition-duration: 2050ms;
  }
  #saasbox_page_content body[data-aos-delay='2050'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2050'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2050'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    -webkit-transition-delay: 2050ms;
    transition-delay: 2050ms;
  }
  #saasbox_page_content body[data-aos-duration='2100'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2100'] {
    -webkit-transition-duration: 2100ms;
    transition-duration: 2100ms;
  }
  #saasbox_page_content body[data-aos-delay='2100'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2100'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2100'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    -webkit-transition-delay: 2100ms;
    transition-delay: 2100ms;
  }
  #saasbox_page_content body[data-aos-duration='2150'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2150'] {
    -webkit-transition-duration: 2150ms;
    transition-duration: 2150ms;
  }
  #saasbox_page_content body[data-aos-delay='2150'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2150'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2150'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    -webkit-transition-delay: 2150ms;
    transition-delay: 2150ms;
  }
  #saasbox_page_content body[data-aos-duration='2200'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2200'] {
    -webkit-transition-duration: 2200ms;
    transition-duration: 2200ms;
  }
  #saasbox_page_content body[data-aos-delay='2200'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2200'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2200'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    -webkit-transition-delay: 2200ms;
    transition-delay: 2200ms;
  }
  #saasbox_page_content body[data-aos-duration='2250'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2250'] {
    -webkit-transition-duration: 2250ms;
    transition-duration: 2250ms;
  }
  #saasbox_page_content body[data-aos-delay='2250'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2250'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2250'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    -webkit-transition-delay: 2250ms;
    transition-delay: 2250ms;
  }
  #saasbox_page_content body[data-aos-duration='2300'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2300'] {
    -webkit-transition-duration: 2300ms;
    transition-duration: 2300ms;
  }
  #saasbox_page_content body[data-aos-delay='2300'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2300'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2300'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    -webkit-transition-delay: 2300ms;
    transition-delay: 2300ms;
  }
  #saasbox_page_content body[data-aos-duration='2350'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2350'] {
    -webkit-transition-duration: 2350ms;
    transition-duration: 2350ms;
  }
  #saasbox_page_content body[data-aos-delay='2350'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2350'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2350'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    -webkit-transition-delay: 2350ms;
    transition-delay: 2350ms;
  }
  #saasbox_page_content body[data-aos-duration='2400'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2400'] {
    -webkit-transition-duration: 2400ms;
    transition-duration: 2400ms;
  }
  #saasbox_page_content body[data-aos-delay='2400'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2400'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2400'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    -webkit-transition-delay: 2400ms;
    transition-delay: 2400ms;
  }
  #saasbox_page_content body[data-aos-duration='2450'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2450'] {
    -webkit-transition-duration: 2450ms;
    transition-duration: 2450ms;
  }
  #saasbox_page_content body[data-aos-delay='2450'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2450'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2450'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    -webkit-transition-delay: 2450ms;
    transition-delay: 2450ms;
  }
  #saasbox_page_content body[data-aos-duration='2500'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2500'] {
    -webkit-transition-duration: 2500ms;
    transition-duration: 2500ms;
  }
  #saasbox_page_content body[data-aos-delay='2500'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2500'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2500'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    -webkit-transition-delay: 2500ms;
    transition-delay: 2500ms;
  }
  #saasbox_page_content body[data-aos-duration='2550'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2550'] {
    -webkit-transition-duration: 2550ms;
    transition-duration: 2550ms;
  }
  #saasbox_page_content body[data-aos-delay='2550'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2550'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2550'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    -webkit-transition-delay: 2550ms;
    transition-delay: 2550ms;
  }
  #saasbox_page_content body[data-aos-duration='2600'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2600'] {
    -webkit-transition-duration: 2600ms;
    transition-duration: 2600ms;
  }
  #saasbox_page_content body[data-aos-delay='2600'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2600'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2600'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    -webkit-transition-delay: 2600ms;
    transition-delay: 2600ms;
  }
  #saasbox_page_content body[data-aos-duration='2650'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2650'] {
    -webkit-transition-duration: 2650ms;
    transition-duration: 2650ms;
  }
  #saasbox_page_content body[data-aos-delay='2650'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2650'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2650'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    -webkit-transition-delay: 2650ms;
    transition-delay: 2650ms;
  }
  #saasbox_page_content body[data-aos-duration='2700'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2700'] {
    -webkit-transition-duration: 2700ms;
    transition-duration: 2700ms;
  }
  #saasbox_page_content body[data-aos-delay='2700'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2700'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2700'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    -webkit-transition-delay: 2700ms;
    transition-delay: 2700ms;
  }
  #saasbox_page_content body[data-aos-duration='2750'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2750'] {
    -webkit-transition-duration: 2750ms;
    transition-duration: 2750ms;
  }
  #saasbox_page_content body[data-aos-delay='2750'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2750'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2750'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    -webkit-transition-delay: 2750ms;
    transition-delay: 2750ms;
  }
  #saasbox_page_content body[data-aos-duration='2800'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2800'] {
    -webkit-transition-duration: 2800ms;
    transition-duration: 2800ms;
  }
  #saasbox_page_content body[data-aos-delay='2800'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2800'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2800'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    -webkit-transition-delay: 2800ms;
    transition-delay: 2800ms;
  }
  #saasbox_page_content body[data-aos-duration='2850'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2850'] {
    -webkit-transition-duration: 2850ms;
    transition-duration: 2850ms;
  }
  #saasbox_page_content body[data-aos-delay='2850'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2850'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2850'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    -webkit-transition-delay: 2850ms;
    transition-delay: 2850ms;
  }
  #saasbox_page_content body[data-aos-duration='2900'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2900'] {
    -webkit-transition-duration: 2900ms;
    transition-duration: 2900ms;
  }
  #saasbox_page_content body[data-aos-delay='2900'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2900'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2900'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    -webkit-transition-delay: 2900ms;
    transition-delay: 2900ms;
  }
  #saasbox_page_content body[data-aos-duration='2950'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='2950'] {
    -webkit-transition-duration: 2950ms;
    transition-duration: 2950ms;
  }
  #saasbox_page_content body[data-aos-delay='2950'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2950'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='2950'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    -webkit-transition-delay: 2950ms;
    transition-delay: 2950ms;
  }
  #saasbox_page_content body[data-aos-duration='3000'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-duration='3000'] {
    -webkit-transition-duration: 3000ms;
    transition-duration: 3000ms;
  }
  #saasbox_page_content body[data-aos-delay='3000'] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='3000'] {
    -webkit-transition-delay: 0;
    transition-delay: 0;
  }
  #saasbox_page_content body[data-aos-delay='3000'] [data-aos].aos-animate,
  #saasbox_page_content [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    -webkit-transition-delay: 3000ms;
    transition-delay: 3000ms;
  }
  #saasbox_page_content body[data-aos-easing="linear"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="linear"] {
    -webkit-transition-timing-function: cubic-bezier(0.25,0.25,0.75,0.75);
    transition-timing-function: cubic-bezier(0.25,0.25,0.75,0.75);
  }
  #saasbox_page_content body[data-aos-easing="ease"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease"] {
    -webkit-transition-timing-function: cubic-bezier(0.25,0.1,0.25,1);
    transition-timing-function: cubic-bezier(0.25,0.1,0.25,1);
  }
  #saasbox_page_content body[data-aos-easing="ease-in"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in"] {
    -webkit-transition-timing-function: cubic-bezier(0.42,0,1,1);
    transition-timing-function: cubic-bezier(0.42,0,1,1);
  }
  #saasbox_page_content body[data-aos-easing="ease-out"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-out"] {
    -webkit-transition-timing-function: cubic-bezier(0,0,0.58,1);
    transition-timing-function: cubic-bezier(0,0,0.58,1);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-out"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-out"] {
    -webkit-transition-timing-function: cubic-bezier(0.42,0,0.58,1);
    transition-timing-function: cubic-bezier(0.42,0,0.58,1);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-back"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-back"] {
    -webkit-transition-timing-function: cubic-bezier(0.6,-0.28,0.735,0.045);
    transition-timing-function: cubic-bezier(0.6,-0.28,0.735,0.045);
  }
  #saasbox_page_content body[data-aos-easing="ease-out-back"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-out-back"] {
    -webkit-transition-timing-function: cubic-bezier(0.175,0.885,0.32,1.275);
    transition-timing-function: cubic-bezier(0.175,0.885,0.32,1.275);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-out-back"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
    -webkit-transition-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
    transition-timing-function: cubic-bezier(0.68,-0.55,0.265,1.55);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-sine"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
    -webkit-transition-timing-function: cubic-bezier(0.47,0,0.745,0.715);
    transition-timing-function: cubic-bezier(0.47,0,0.745,0.715);
  }
  #saasbox_page_content body[data-aos-easing="ease-out-sine"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
    -webkit-transition-timing-function: cubic-bezier(0.39,0.575,0.565,1);
    transition-timing-function: cubic-bezier(0.39,0.575,0.565,1);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-out-sine"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
    -webkit-transition-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
    transition-timing-function: cubic-bezier(0.445,0.05,0.55,0.95);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-quad"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
    -webkit-transition-timing-function: cubic-bezier(0.55,0.085,0.68,0.53);
    transition-timing-function: cubic-bezier(0.55,0.085,0.68,0.53);
  }
  #saasbox_page_content body[data-aos-easing="ease-out-quad"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
    -webkit-transition-timing-function: cubic-bezier(0.25,0.46,0.45,0.94);
    transition-timing-function: cubic-bezier(0.25,0.46,0.45,0.94);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-out-quad"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
    -webkit-transition-timing-function: cubic-bezier(0.455,0.03,0.515,0.955);
    transition-timing-function: cubic-bezier(0.455,0.03,0.515,0.955);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-cubic"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
    -webkit-transition-timing-function: cubic-bezier(0.55,0.085,0.68,0.53);
    transition-timing-function: cubic-bezier(0.55,0.085,0.68,0.53);
  }
  #saasbox_page_content body[data-aos-easing="ease-out-cubic"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
    -webkit-transition-timing-function: cubic-bezier(0.25,0.46,0.45,0.94);
    transition-timing-function: cubic-bezier(0.25,0.46,0.45,0.94);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-out-cubic"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
    -webkit-transition-timing-function: cubic-bezier(0.455,0.03,0.515,0.955);
    transition-timing-function: cubic-bezier(0.455,0.03,0.515,0.955);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-quart"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
    -webkit-transition-timing-function: cubic-bezier(0.55,0.085,0.68,0.53);
    transition-timing-function: cubic-bezier(0.55,0.085,0.68,0.53);
  }
  #saasbox_page_content body[data-aos-easing="ease-out-quart"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
    -webkit-transition-timing-function: cubic-bezier(0.25,0.46,0.45,0.94);
    transition-timing-function: cubic-bezier(0.25,0.46,0.45,0.94);
  }
  #saasbox_page_content body[data-aos-easing="ease-in-out-quart"] [data-aos],
  #saasbox_page_content [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
    -webkit-transition-timing-function: cubic-bezier(0.455,0.03,0.515,0.955);
    transition-timing-function: cubic-bezier(0.455,0.03,0.515,0.955);
  }
  #saasbox_page_content [data-aos^='fade'][data-aos^='fade'] {
    opacity: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }
  #saasbox_page_content [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }
  #saasbox_page_content [data-aos='fade-up'] {
    -webkit-transform: translate3d(0,100px,0);
    transform: translate3d(0,100px,0);
  }
  #saasbox_page_content [data-aos='fade-down'] {
    -webkit-transform: translate3d(0,-100px,0);
    transform: translate3d(0,-100px,0);
  }
  #saasbox_page_content [data-aos='fade-right'] {
    -webkit-transform: translate3d(-100px,0,0);
    transform: translate3d(-100px,0,0);
  }
  #saasbox_page_content [data-aos='fade-left'] {
    -webkit-transform: translate3d(100px,0,0);
    transform: translate3d(100px,0,0);
  }
  #saasbox_page_content [data-aos='fade-up-right'] {
    -webkit-transform: translate3d(-100px,100px,0);
    transform: translate3d(-100px,100px,0);
  }
  #saasbox_page_content [data-aos='fade-up-left'] {
    -webkit-transform: translate3d(100px,100px,0);
    transform: translate3d(100px,100px,0);
  }
  #saasbox_page_content [data-aos='fade-down-right'] {
    -webkit-transform: translate3d(-100px,-100px,0);
    transform: translate3d(-100px,-100px,0);
  }
  #saasbox_page_content [data-aos='fade-down-left'] {
    -webkit-transform: translate3d(100px,-100px,0);
    transform: translate3d(100px,-100px,0);
  }
  #saasbox_page_content [data-aos^='zoom'][data-aos^='zoom'] {
    opacity: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }
  #saasbox_page_content [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0) scale(1);
    transform: translate3d(0,0,0) scale(1);
  }
  #saasbox_page_content [data-aos='zoom-in'] {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
  #saasbox_page_content [data-aos='zoom-in-up'] {
    -webkit-transform: translate3d(0,100px,0) scale(0.6);
    transform: translate3d(0,100px,0) scale(0.6);
  }
  #saasbox_page_content [data-aos='zoom-in-down'] {
    -webkit-transform: translate3d(0,-100px,0) scale(0.6);
    transform: translate3d(0,-100px,0) scale(0.6);
  }
  #saasbox_page_content [data-aos='zoom-in-right'] {
    -webkit-transform: translate3d(-100px,0,0) scale(0.6);
    transform: translate3d(-100px,0,0) scale(0.6);
  }
  #saasbox_page_content [data-aos='zoom-in-left'] {
    -webkit-transform: translate3d(100px,0,0) scale(0.6);
    transform: translate3d(100px,0,0) scale(0.6);
  }
  #saasbox_page_content [data-aos='zoom-out'] {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  #saasbox_page_content [data-aos='zoom-out-up'] {
    -webkit-transform: translate3d(0,100px,0) scale(1.2);
    transform: translate3d(0,100px,0) scale(1.2);
  }
  #saasbox_page_content [data-aos='zoom-out-down'] {
    -webkit-transform: translate3d(0,-100px,0) scale(1.2);
    transform: translate3d(0,-100px,0) scale(1.2);
  }
  #saasbox_page_content [data-aos='zoom-out-right'] {
    -webkit-transform: translate3d(-100px,0,0) scale(1.2);
    transform: translate3d(-100px,0,0) scale(1.2);
  }
  #saasbox_page_content [data-aos='zoom-out-left'] {
    -webkit-transform: translate3d(100px,0,0) scale(1.2);
    transform: translate3d(100px,0,0) scale(1.2);
  }
  #saasbox_page_content [data-aos^='slide'][data-aos^='slide'] {
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  #saasbox_page_content [data-aos^='slide'][data-aos^='slide'].aos-animate {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }
  #saasbox_page_content [data-aos='slide-up'] {
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
  }
  #saasbox_page_content [data-aos='slide-down'] {
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0);
  }
  #saasbox_page_content [data-aos='slide-right'] {
    -webkit-transform: translate3d(-100%,0,0);
    transform: translate3d(-100%,0,0);
  }
  #saasbox_page_content [data-aos='slide-left'] {
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
  }
  #saasbox_page_content [data-aos^='flip'][data-aos^='flip'] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  #saasbox_page_content [data-aos='flip-left'] {
    -webkit-transform: perspective(2500px) rotateY(-100deg);
    transform: perspective(2500px) rotateY(-100deg);
  }
  #saasbox_page_content [data-aos='flip-left'].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }
  #saasbox_page_content [data-aos='flip-right'] {
    -webkit-transform: perspective(2500px) rotateY(100deg);
    transform: perspective(2500px) rotateY(100deg);
  }
  #saasbox_page_content [data-aos='flip-right'].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }
  #saasbox_page_content [data-aos='flip-up'] {
    -webkit-transform: perspective(2500px) rotateX(-100deg);
    transform: perspective(2500px) rotateX(-100deg);
  }
  #saasbox_page_content [data-aos='flip-up'].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
  #saasbox_page_content [data-aos='flip-down'] {
    -webkit-transform: perspective(2500px) rotateX(100deg);
    transform: perspective(2500px) rotateX(100deg);
  }
  #saasbox_page_content [data-aos='flip-down'].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
  #saasbox_page_content .constellation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #saasbox_page_content .countdown {
    text-align: center;
  }
  #saasbox_page_content .countdown .col {
    padding: 0;
  }
  #saasbox_page_content .countdown h5 {
    font-weight: 500;
    font-size: 3rem;
    margin-bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  #saasbox_page_content .countdown small {
    display: block;
    font-size: .8325rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .countdown h5 {
      font-size: 2rem;
    }
    #saasbox_page_content .countdown small {
      font-size: .75rem;
    }
  }
  #saasbox_page_content .countdown-uppercase small {
    text-transform: uppercase;
  }
  #saasbox_page_content .countdown-sm h5 {
    font-size: 2.5rem;
  }
  #saasbox_page_content .countdown-sm small {
    font-size: .75rem;
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .countdown-sm h5 {
      font-size: 28px;
    }
    #saasbox_page_content .countdown-sm small {
      font-size: .625rem;
    }
  }
  #saasbox_page_content .countdown-lg h5 {
    font-size: 3.5rem;
  }
  #saasbox_page_content .countdown-lg small {
    font-size: .875rem;
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .countdown-lg h5 {
      font-size: 2.25rem;
    }
    #saasbox_page_content .countdown-lg small {
      font-size: .75rem;
    }
  }
  #saasbox_page_content .countdown-outline .col {
    border: 1px solid rgba(117,117,117,0.5);
    border-radius: .125rem;
  }
  #saasbox_page_content .countdown-outline h5 {
    border-bottom: 1px solid rgba(117,117,117,0.5);
  }
  #saasbox_page_content .countdown-light .col {
    border-color: rgba(255,255,255,0.5);
  }
  #saasbox_page_content .countdown-light h5,
  #saasbox_page_content .countdown-light small {
    color: rgba(255,255,255,0.85);
    border-color: rgba(255,255,255,0.5);
  }
  #saasbox_page_content [data-parallax],
  #saasbox_page_content [data-video],
  #saasbox_page_content .jarallax {
    position: relative;
    z-index: 0;
  }
  #saasbox_page_content .slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  #saasbox_page_content .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }
  #saasbox_page_content .slick-list:focus {
    outline: 0;
  }
  #saasbox_page_content .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  #saasbox_page_content .slick-slider .slick-track,
  #saasbox_page_content .slick-slider .slick-list {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }
  #saasbox_page_content .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  #saasbox_page_content .slick-track:before,
  #saasbox_page_content .slick-track:after {
    content: "";
    display: table;
  }
  #saasbox_page_content .slick-track:after {
    clear: both;
  }
  #saasbox_page_content .slick-loading .slick-track {
    visibility: hidden;
  }
  #saasbox_page_content .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
  }
  #saasbox_page_content [dir="rtl"] .slick-slide {
    float: right;
  }
  #saasbox_page_content .slick-slide img {
    display: block;
  }
  #saasbox_page_content .slick-slide.slick-loading img {
    display: none;
  }
  #saasbox_page_content .slick-slide.dragging img {
    pointer-events: none;
  }
  #saasbox_page_content .slick-initialized .slick-slide {
    display: block;
  }
  #saasbox_page_content .slick-loading .slick-slide {
    visibility: hidden;
  }
  #saasbox_page_content .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  #saasbox_page_content .slick-arrow.slick-hidden {
    display: none;
  }
  #saasbox_page_content .slick-slide {
    outline: 0;
  }
  #saasbox_page_content .slick-slide img {
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
  }
  #saasbox_page_content .slider-center-focused .slick-slide {
    opacity: .2;
    -webkit-transition: .5s ease-out;
    transition: .5s ease-out;
  }
  #saasbox_page_content .slider-center-focused .slick-center {
    opacity: 1;
  }
  #saasbox_page_content .slick-prev,
  #saasbox_page_content .slick-next {
    position: absolute;
    display: block;
    height: 50px;
    width: 36px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    padding: 0;
    border: 0;
    outline: 0;
    background-color: rgba(0,0,0,0.2);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
    z-index: 1;
  }
  #saasbox_page_content .slick-prev:hover,
  #saasbox_page_content .slick-next:hover {
    outline: 0;
    background-color: rgba(0,0,0,0.3);
  }
  #saasbox_page_content .slick-prev:hover::before,
  #saasbox_page_content .slick-next:hover::before {
    border-right-color: #fff;
    border-bottom-color: #fff;
  }
  #saasbox_page_content .slick-prev.slick-disabled::before,
  #saasbox_page_content .slick-next.slick-disabled::before {
    opacity: .5;
  }
  #saasbox_page_content .slick-prev::before,
  #saasbox_page_content .slick-next::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-right: 1px solid rgba(255,255,255,0.7);
    border-bottom: 1px solid rgba(255,255,255,0.7);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .slick-prev {
    left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  #saasbox_page_content .slick-prev::before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    margin-right: -2px;
  }
  #saasbox_page_content .slick-next {
    right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  #saasbox_page_content .slick-next::before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-left: -2px;
  }
  #saasbox_page_content .slider-arrows-circle-light .slick-prev,
  #saasbox_page_content .slider-arrows-circle-light .slick-next {
    width: 40px;
    height: 40px;
    background-color: rgba(255,255,255,0.95);
    border-radius: 10rem;
    border: 1px solid #eaeff4;
    opacity: .7;
  }
  #saasbox_page_content .slider-arrows-circle-light .slick-prev::before,
  #saasbox_page_content .slider-arrows-circle-light .slick-next::before {
    border-right-color: rgba(0,0,0,0.3);
    border-bottom-color: rgba(0,0,0,0.3);
  }
  #saasbox_page_content .slider-arrows-circle-light .slick-prev:hover,
  #saasbox_page_content .slider-arrows-circle-light .slick-next:hover {
    opacity: 1;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.08);
    box-shadow: 0 0 15px rgba(0,0,0,0.08);
  }
  #saasbox_page_content .slider-arrows-circle-light .slick-prev:hover::before,
  #saasbox_page_content .slider-arrows-circle-light .slick-next:hover::before {
    border-right-color: rgba(0,0,0,0.5);
    border-bottom-color: rgba(0,0,0,0.5);
  }
  #saasbox_page_content .slider-arrows-circle-light .slick-prev {
    left: 1rem;
  }
  #saasbox_page_content .slider-arrows-circle-light .slick-next {
    right: 1rem;
  }
  #saasbox_page_content .slider-arrows-circle-dark .slick-prev,
  #saasbox_page_content .slider-arrows-circle-dark .slick-next {
    width: 40px;
    height: 40px;
    background-color: rgba(0,0,0,0.6);
    border-radius: 10rem;
    opacity: .7;
  }
  #saasbox_page_content .slider-arrows-circle-dark .slick-prev::before,
  #saasbox_page_content .slider-arrows-circle-dark .slick-next::before {
    border-right-color: rgba(255,255,255,0.6);
    border-bottom-color: rgba(255,255,255,0.6);
  }
  #saasbox_page_content .slider-arrows-circle-dark .slick-prev:hover,
  #saasbox_page_content .slider-arrows-circle-dark .slick-next:hover {
    opacity: 1;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.08);
    box-shadow: 0 0 15px rgba(0,0,0,0.08);
  }
  #saasbox_page_content .slider-arrows-circle-dark .slick-prev:hover::before,
  #saasbox_page_content .slider-arrows-circle-dark .slick-next:hover::before {
    border-right-color: rgba(255,255,255,0.9);
    border-bottom-color: rgba(255,255,255,0.9);
  }
  #saasbox_page_content .slider-arrows-circle-dark .slick-prev {
    left: 1rem;
  }
  #saasbox_page_content .slider-arrows-circle-dark .slick-next {
    right: 1rem;
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-prev,
  #saasbox_page_content .slider-arrows-flash-dark .slick-next {
    width: 40px;
    height: 40px;
    background-color: transparent;
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-prev::before,
  #saasbox_page_content .slider-arrows-flash-dark .slick-next::before {
    position: absolute;
    top: 50%;
    width: 11px;
    height: 11px;
    margin-top: -5px;
    border-width: 1px;
    border-right-color: rgba(0,0,0,0.2);
    border-bottom-color: rgba(0,0,0,0.2);
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-prev::after,
  #saasbox_page_content .slider-arrows-flash-dark .slick-next::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 24px;
    height: 1px;
    background-color: rgba(0,0,0,0.2);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-prev:hover::before,
  #saasbox_page_content .slider-arrows-flash-dark .slick-next:hover::before {
    border-right-color: rgba(0,0,0,0.5);
    border-bottom-color: rgba(0,0,0,0.5);
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-prev:hover::after,
  #saasbox_page_content .slider-arrows-flash-dark .slick-next:hover::after {
    background-color: rgba(0,0,0,0.5);
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-prev {
    left: 1rem;
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-prev::before,
  #saasbox_page_content .slider-arrows-flash-dark .slick-prev::after {
    left: 9px;
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-next {
    right: 1rem;
  }
  #saasbox_page_content .slider-arrows-flash-dark .slick-next::before,
  #saasbox_page_content .slider-arrows-flash-dark .slick-next::after {
    right: 9px;
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-prev,
  #saasbox_page_content .slider-arrows-flash-light .slick-next {
    width: 40px;
    height: 40px;
    background-color: transparent;
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-prev::before,
  #saasbox_page_content .slider-arrows-flash-light .slick-next::before {
    position: absolute;
    top: 50%;
    width: 11px;
    height: 11px;
    margin-top: -5px;
    border-width: 1px;
    border-right-color: rgba(255,255,255,0.5);
    border-bottom-color: rgba(255,255,255,0.5);
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-prev::after,
  #saasbox_page_content .slider-arrows-flash-light .slick-next::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 24px;
    height: 1px;
    background-color: rgba(255,255,255,0.5);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-prev:hover::before,
  #saasbox_page_content .slider-arrows-flash-light .slick-next:hover::before {
    border-right-color: rgba(255,255,255,0.9);
    border-bottom-color: rgba(255,255,255,0.9);
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-prev:hover::after,
  #saasbox_page_content .slider-arrows-flash-light .slick-next:hover::after {
    background-color: rgba(255,255,255,0.9);
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-prev {
    left: 1rem;
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-prev::before,
  #saasbox_page_content .slider-arrows-flash-light .slick-prev::after {
    left: 9px;
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-next {
    right: 1rem;
  }
  #saasbox_page_content .slider-arrows-flash-light .slick-next::before,
  #saasbox_page_content .slider-arrows-flash-light .slick-next::after {
    right: 9px;
  }
  #saasbox_page_content .slick-dotted.slick-slider {
    margin-bottom: 35px;
  }
  #saasbox_page_content .slick-dots {
    position: absolute;
    bottom: -35px;
    list-style: none;
    display: -webkit-box;
    display: flex;
    align-content: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  #saasbox_page_content .slick-dots li {
    margin: 0 5px;
    padding: 8px 6px;
    line-height: 1px;
    cursor: pointer;
  }
  #saasbox_page_content .slick-dots li button {
    border: 1px solid #929daf;
    padding: 0;
    background: transparent;
    height: 6px;
    width: 6px;
    border-radius: 10rem;
    font-size: 0;
    cursor: pointer;
    -webkit-transition: .3s ease;
    transition: .3s ease;
  }
  #saasbox_page_content .slick-dots li button:hover {
    outline: 0;
  }
  #saasbox_page_content .slick-dots li.slick-active button {
    background-color: #929daf;
  }
  #saasbox_page_content .slider-dots-inside.slick-slider {
    margin-bottom: 0;
  }
  #saasbox_page_content .slider-dots-inside.slick-slider .slick-dots {
    bottom: 1rem;
    opacity: .8;
  }
  #saasbox_page_content .slider-dots-fill .slick-dots button {
    border: 0;
    background-color: #eaeff4;
  }
  #saasbox_page_content .slider-dots-fill .slick-dots .slick-active button {
    background-color: #929daf;
  }
  #saasbox_page_content .slider-dots-fill-primary .slick-dots button {
    border: 0;
    background-color: #eaeff4;
  }
  #saasbox_page_content .slider-dots-fill-primary .slick-dots .slick-active button {
    background-color: #50a1ff;
  }
  #saasbox_page_content .slider-dots-line .slick-dots li {
    padding-left: 2px;
    padding-right: 2px;
  }
  #saasbox_page_content .slider-dots-line .slick-dots button {
    border: 0;
    width: 24px;
    height: 2px;
    background-color: #eaeff4;
  }
  #saasbox_page_content .slider-dots-line .slick-dots .slick-active button {
    width: 34px;
    background-color: #929daf;
  }
  #saasbox_page_content .slider-dots-white .slick-dots button {
    border-color: rgba(255,255,255,0.7);
    background-color: rgba(255,255,255,0.5);
  }
  #saasbox_page_content .slider-dots-white .slick-dots .slick-active button {
    background-color: rgba(255,255,255,0.95);
  }
  #saasbox_page_content .lity {
    z-index: 9990;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    background: #0b0b0b;
    background: rgba(0,0,0,0.9);
    outline: none !important;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
  }
  #saasbox_page_content .lity.lity-opened {
    opacity: 1;
  }
  #saasbox_page_content .lity.lity-closed {
    opacity: 0;
  }
  #saasbox_page_content .lity * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  #saasbox_page_content .lity-wrap {
    z-index: 9990;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    outline: none !important;
  }
  #saasbox_page_content .lity-wrap:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
  }
  #saasbox_page_content .lity-loader {
    z-index: 9991;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -0.8em;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0;
    -webkit-transition: opacity .3s ease;
    transition: opacity .3s ease;
  }
  #saasbox_page_content .lity-loading .lity-loader {
    opacity: 1;
  }
  #saasbox_page_content .lity-container {
    z-index: 9992;
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    white-space: normal;
    max-width: 100%;
    max-height: 100%;
    outline: none !important;
  }
  #saasbox_page_content .lity-content {
    z-index: 9993;
    width: 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
  }
  #saasbox_page_content .lity-loading .lity-content,
  #saasbox_page_content .lity-closed .lity-content {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  #saasbox_page_content .lity-content:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
  }
  #saasbox_page_content .lity-close {
    z-index: 9994;
    width: 35px;
    height: 35px;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-appearance: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial, Baskerville, monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0,0,0,0.6);
    border: 0;
    background: 0;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .lity-close::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  #saasbox_page_content .lity-close:hover,
  #saasbox_page_content .lity-close:focus,
  #saasbox_page_content .lity-close:active,
  #saasbox_page_content .lity-close:visited {
    text-decoration: none;
    text-align: center;
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 35px;
    font-family: Arial, Baskerville, monospace;
    line-height: 35px;
    text-shadow: 0 1px 2px rgba(0,0,0,0.6);
    border: 0;
    background: 0;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .lity-close:active {
    top: 1px;
  }
  #saasbox_page_content .lity-image img {
    max-width: 100%;
    display: block;
    line-height: 0;
    border: 0;
  }
  #saasbox_page_content .lity-iframe .lity-container,
  #saasbox_page_content .lity-youtube .lity-container,
  #saasbox_page_content .lity-vimeo .lity-container,
  #saasbox_page_content .lity-facebookvideo .lity-container,
  #saasbox_page_content .lity-googlemaps .lity-container {
    width: 100%;
    max-width: 964px;
  }
  #saasbox_page_content .lity-iframe-container {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: auto;
    pointer-events: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-overflow-scrolling: touch;
  }
  #saasbox_page_content .lity-iframe-container iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
    background: #000;
  }
  #saasbox_page_content .lity-hide {
    display: none;
  }
  #saasbox_page_content .lity-close {
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    opacity: .6;
    padding: .5rem 1rem;
    width: auto;
    height: auto;
    -webkit-transition: .3s ease;
    transition: .3s ease;
  }
  #saasbox_page_content .lity-close:hover,
  #saasbox_page_content .lity-close:focus,
  #saasbox_page_content .lity-close:active,
  #saasbox_page_content .lity-close:visited {
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    opacity: 1;
    padding: .5rem 1rem;
  }
  #saasbox_page_content .lity-close:active {
    top: 0;
  }
  #saasbox_page_content .lity-iframe-container iframe {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .pswp {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    touch-action: none;
    z-index: 1500;
    -webkit-text-size-adjust: 100%;
    -webkit-backface-visibility: hidden;
    outline: 0;
  }
  #saasbox_page_content .pswp * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  #saasbox_page_content .pswp img {
    max-width: none;
  }
  #saasbox_page_content .pswp--animate_opacity {
    opacity: .001;
    will-change: opacity;
    -webkit-transition: opacity 333ms cubic-bezier(0.4,0,0.22,1);
    transition: opacity 333ms cubic-bezier(0.4,0,0.22,1);
  }
  #saasbox_page_content .pswp--open {
    display: block;
  }
  #saasbox_page_content .pswp--zoom-allowed .pswp__img {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
  }
  #saasbox_page_content .pswp--zoomed-in .pswp__img {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
  }
  #saasbox_page_content .pswp--dragging .pswp__img {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }
  #saasbox_page_content .pswp__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    will-change: opacity;
  }
  #saasbox_page_content .pswp__scroll-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  #saasbox_page_content .pswp__container,
  #saasbox_page_content .pswp__zoom-wrap {
    touch-action: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  #saasbox_page_content .pswp__container,
  #saasbox_page_content .pswp__img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
  }
  #saasbox_page_content .pswp__zoom-wrap {
    position: absolute;
    width: 100%;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transition: -webkit-transform 333ms cubic-bezier(0.4,0,0.22,1);
    transition: -webkit-transform 333ms cubic-bezier(0.4,0,0.22,1);
    transition: transform 333ms cubic-bezier(0.4,0,0.22,1);
    transition: transform 333ms cubic-bezier(0.4,0,0.22,1), -webkit-transform 333ms cubic-bezier(0.4,0,0.22,1);
  }
  #saasbox_page_content .pswp__bg {
    will-change: opacity;
    -webkit-transition: opacity 333ms cubic-bezier(0.4,0,0.22,1);
    transition: opacity 333ms cubic-bezier(0.4,0,0.22,1);
  }
  #saasbox_page_content .pswp--animated-in .pswp__bg,
  #saasbox_page_content .pswp--animated-in .pswp__zoom-wrap {
    -webkit-transition: none;
    transition: none;
  }
  #saasbox_page_content .pswp__container,
  #saasbox_page_content .pswp__zoom-wrap {
    -webkit-backface-visibility: hidden;
  }
  #saasbox_page_content .pswp__item {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
  #saasbox_page_content .pswp__img {
    position: absolute;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
  }
  #saasbox_page_content .pswp__img--placeholder {
    -webkit-backface-visibility: hidden;
  }
  #saasbox_page_content .pswp__img--placeholder--blank {
    background: #222;
  }
  #saasbox_page_content .pswp--ie .pswp__img {
    width: 100% !important;
    height: auto !important;
    left: 0;
    top: 0;
  }
  #saasbox_page_content .pswp__error-msg {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    margin-top: -8px;
    color: #CCC;
  }
  #saasbox_page_content .pswp__error-msg a {
    color: #CCC;
    text-decoration: underline;
  }
  #saasbox_page_content .pswp__button {
    width: 44px;
    height: 44px;
    position: relative;
    background: 0;
    cursor: pointer;
    overflow: visible;
    -webkit-appearance: none;
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    float: right;
    opacity: .75;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .pswp__button:focus,
  #saasbox_page_content .pswp__button:hover {
    opacity: 1;
  }
  #saasbox_page_content .pswp__button:active {
    outline: 0;
    opacity: .9;
  }
  #saasbox_page_content .pswp__button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  #saasbox_page_content .pswp__ui--over-close .pswp__button--close {
    opacity: 1;
  }
  #saasbox_page_content .pswp__button,
  #saasbox_page_content .pswp__button--arrow--left:before,
  #saasbox_page_content .pswp__button--arrow--right:before {
    background: url("https://t.uncledesk.com/saasbox/resources/png/default-skin__e3f799c6dec9af194c86decdf7392405.png") 0 0 no-repeat;
    -webkit-background-size: 264px 88px;
    background-size: 264px 88px;
    width: 44px;
    height: 44px;
  }
  @media (-webkit-min-device-pixel-ratio: 1.1),(-webkit-min-device-pixel-ratio: 1.09375),(min-resolution: 105dpi),(min-resolution: 1.1dppx) {
    #saasbox_page_content .pswp--svg .pswp__button,
    #saasbox_page_content .pswp--svg .pswp__button--arrow--left:before,
    #saasbox_page_content .pswp--svg .pswp__button--arrow--right:before {
      background-image: url("https://t.uncledesk.com/saasbox/resources/svg/default-skin__b257fa9c5ac8c515ac4d77a667ce2943.svg");
    }
    #saasbox_page_content .pswp--svg .pswp__button--arrow--left,
    #saasbox_page_content .pswp--svg .pswp__button--arrow--right {
      background: 0;
    }
  }
  #saasbox_page_content .pswp__button--close {
    background-position: 0 -44px;
  }
  #saasbox_page_content .pswp__button--share {
    background-position: -44px -44px;
  }
  #saasbox_page_content .pswp__button--fs {
    display: none;
  }
  #saasbox_page_content .pswp--supports-fs .pswp__button--fs {
    display: block;
  }
  #saasbox_page_content .pswp--fs .pswp__button--fs {
    background-position: -44px 0;
  }
  #saasbox_page_content .pswp__button--zoom {
    display: none;
    background-position: -88px 0;
  }
  #saasbox_page_content .pswp--zoom-allowed .pswp__button--zoom {
    display: block;
  }
  #saasbox_page_content .pswp--zoomed-in .pswp__button--zoom {
    background-position: -132px 0;
  }
  #saasbox_page_content .pswp--touch .pswp__button--arrow--left,
  #saasbox_page_content .pswp--touch .pswp__button--arrow--right {
    visibility: hidden;
  }
  #saasbox_page_content .pswp__button--arrow--left,
  #saasbox_page_content .pswp__button--arrow--right {
    background: 0;
    top: 50%;
    margin-top: -50px;
    width: 70px;
    height: 100px;
    position: absolute;
  }
  #saasbox_page_content .pswp__button--arrow--left {
    left: 0;
  }
  #saasbox_page_content .pswp__button--arrow--right {
    right: 0;
  }
  #saasbox_page_content .pswp__button--arrow--left:before,
  #saasbox_page_content .pswp__button--arrow--right:before {
    content: '';
    top: 35px;
    background-color: rgba(0,0,0,0.3);
    height: 30px;
    width: 32px;
    position: absolute;
  }
  #saasbox_page_content .pswp__button--arrow--left:before {
    left: 6px;
    background-position: -138px -44px;
  }
  #saasbox_page_content .pswp__button--arrow--right:before {
    right: 6px;
    background-position: -94px -44px;
  }
  #saasbox_page_content .pswp__counter,
  #saasbox_page_content .pswp__share-modal {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  #saasbox_page_content .pswp__share-modal {
    display: block;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 10px;
    position: absolute;
    z-index: 1600;
    opacity: 0;
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out;
    -webkit-backface-visibility: hidden;
    will-change: opacity;
  }
  #saasbox_page_content .pswp__share-modal--hidden {
    display: none;
  }
  #saasbox_page_content .pswp__share-tooltip {
    z-index: 1620;
    position: absolute;
    background: #FFF;
    top: 56px;
    border-radius: 2px;
    display: block;
    width: auto;
    right: 44px;
    -webkit-box-shadow: 0 2px 5px rgba(0,0,0,0.25);
    box-shadow: 0 2px 5px rgba(0,0,0,0.25);
    -webkit-transform: translateY(6px);
    transform: translateY(6px);
    -webkit-transition: -webkit-transform .25s;
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s, -webkit-transform .25s;
    -webkit-backface-visibility: hidden;
    will-change: transform;
  }
  #saasbox_page_content .pswp__share-tooltip a {
    display: block;
    padding: 8px 12px;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
  }
  #saasbox_page_content .pswp__share-tooltip a:hover {
    text-decoration: none;
    color: #000;
  }
  #saasbox_page_content .pswp__share-tooltip a:first-child {
    border-radius: 2px 2px 0 0;
  }
  #saasbox_page_content .pswp__share-tooltip a:last-child {
    border-radius: 0 0 2px 2px;
  }
  #saasbox_page_content .pswp__share-modal--fade-in {
    opacity: 1;
  }
  #saasbox_page_content .pswp__share-modal--fade-in .pswp__share-tooltip {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  #saasbox_page_content .pswp--touch .pswp__share-tooltip a {
    padding: 16px 12px;
  }
  #saasbox_page_content a.pswp__share--facebook:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -12px;
    right: 15px;
    border: 6px solid rgba(0,0,0,0);
    border-bottom-color: #FFF;
    -webkit-pointer-events: none;
    -moz-pointer-events: none;
    pointer-events: none;
  }
  #saasbox_page_content a.pswp__share--facebook:hover {
    background: #3e5c9a;
    color: #FFF;
  }
  #saasbox_page_content a.pswp__share--facebook:hover:before {
    border-bottom-color: #3e5c9a;
  }
  #saasbox_page_content a.pswp__share--twitter:hover {
    background: #55acee;
    color: #FFF;
  }
  #saasbox_page_content a.pswp__share--pinterest:hover {
    background: #CCC;
    color: #ce272d;
  }
  #saasbox_page_content a.pswp__share--download:hover {
    background: #DDD;
  }
  #saasbox_page_content .pswp__counter {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    font-size: 13px;
    line-height: 44px;
    color: #FFF;
    opacity: .75;
    padding: 0 10px;
  }
  #saasbox_page_content .pswp__caption {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 44px;
  }
  #saasbox_page_content .pswp__caption small {
    font-size: 11px;
    color: #BBB;
  }
  #saasbox_page_content .pswp__caption__center {
    text-align: left;
    max-width: 420px;
    margin: 0 auto;
    font-size: 13px;
    padding: 10px;
    line-height: 20px;
    color: #CCC;
  }
  #saasbox_page_content .pswp__caption--empty {
    display: none;
  }
  #saasbox_page_content .pswp__caption--fake {
    visibility: hidden;
  }
  #saasbox_page_content .pswp__preloader {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -22px;
    opacity: 0;
    -webkit-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out;
    will-change: opacity;
    direction: ltr;
  }
  #saasbox_page_content .pswp__preloader__icn {
    width: 20px;
    height: 20px;
    margin: 12px;
  }
  #saasbox_page_content .pswp__preloader--active {
    opacity: 1;
  }
  #saasbox_page_content .pswp__preloader--active .pswp__preloader__icn {
    background: url("https://t.uncledesk.com/saasbox/resources/gif/preloader__e34aafbb485a96eaf2a789b2bf3af6fe.gif") 0 0 no-repeat;
  }
  #saasbox_page_content .pswp--css_animation .pswp__preloader--active {
    opacity: 1;
  }
  #saasbox_page_content .pswp--css_animation .pswp__preloader--active .pswp__preloader__icn {
    -webkit-animation: clockwise 500ms linear infinite;
    animation: clockwise 500ms linear infinite;
  }
  #saasbox_page_content .pswp--css_animation .pswp__preloader--active .pswp__preloader__donut {
    -webkit-animation: donut-rotate 1000ms cubic-bezier(0.4,0,0.22,1) infinite;
    animation: donut-rotate 1000ms cubic-bezier(0.4,0,0.22,1) infinite;
  }
  #saasbox_page_content .pswp--css_animation .pswp__preloader__icn {
    background: 0;
    opacity: .75;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 15px;
    top: 15px;
    margin: 0;
  }
  #saasbox_page_content .pswp--css_animation .pswp__preloader__cut {
    position: relative;
    width: 7px;
    height: 14px;
    overflow: hidden;
  }
  #saasbox_page_content .pswp--css_animation .pswp__preloader__donut {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    border: 2px solid #FFF;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background: 0;
    margin: 0;
  }
  @media screen and (max-width: 1024px) {
    #saasbox_page_content .pswp__preloader {
      position: relative;
      left: auto;
      top: auto;
      margin: 0;
      float: right;
    }
  }
  @-webkit-keyframes clockwise {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    #saasbox_page_content 100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes clockwise {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    #saasbox_page_content 100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes donut-rotate {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    #saasbox_page_content 50% {
      -webkit-transform: rotate(-140deg);
      transform: rotate(-140deg);
    }
    #saasbox_page_content 100% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  }
  @keyframes donut-rotate {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    #saasbox_page_content 50% {
      -webkit-transform: rotate(-140deg);
      transform: rotate(-140deg);
    }
    #saasbox_page_content 100% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  }
  #saasbox_page_content .pswp__ui {
    -webkit-font-smoothing: auto;
    visibility: visible;
    opacity: 1;
    z-index: 1550;
  }
  #saasbox_page_content .pswp__top-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    width: 100%;
  }
  #saasbox_page_content .pswp__caption,
  #saasbox_page_content .pswp__top-bar,
  #saasbox_page_content .pswp--has_mouse .pswp__button--arrow--left,
  #saasbox_page_content .pswp--has_mouse .pswp__button--arrow--right {
    -webkit-backface-visibility: hidden;
    will-change: opacity;
    -webkit-transition: opacity 333ms cubic-bezier(0.4,0,0.22,1);
    transition: opacity 333ms cubic-bezier(0.4,0,0.22,1);
  }
  #saasbox_page_content .pswp--has_mouse .pswp__button--arrow--left,
  #saasbox_page_content .pswp--has_mouse .pswp__button--arrow--right {
    visibility: visible;
  }
  #saasbox_page_content .pswp__top-bar,
  #saasbox_page_content .pswp__caption {
    background-color: rgba(0,0,0,0.5);
  }
  #saasbox_page_content .pswp__ui--fit .pswp__top-bar,
  #saasbox_page_content .pswp__ui--fit .pswp__caption {
    background-color: rgba(0,0,0,0.3);
  }
  #saasbox_page_content .pswp__ui--idle .pswp__top-bar {
    opacity: 0;
  }
  #saasbox_page_content .pswp__ui--idle .pswp__button--arrow--left,
  #saasbox_page_content .pswp__ui--idle .pswp__button--arrow--right {
    opacity: 0;
  }
  #saasbox_page_content .pswp__ui--hidden .pswp__top-bar,
  #saasbox_page_content .pswp__ui--hidden .pswp__caption,
  #saasbox_page_content .pswp__ui--hidden .pswp__button--arrow--left,
  #saasbox_page_content .pswp__ui--hidden .pswp__button--arrow--right {
    opacity: .001;
  }
  #saasbox_page_content .pswp__ui--one-slide .pswp__button--arrow--left,
  #saasbox_page_content .pswp__ui--one-slide .pswp__button--arrow--right,
  #saasbox_page_content .pswp__ui--one-slide .pswp__counter {
    display: none;
  }
  #saasbox_page_content .pswp__element--disabled {
    display: none !important;
  }
  #saasbox_page_content .pswp--minimal--dark .pswp__top-bar {
    background: 0;
  }
  #saasbox_page_content .grecaptcha-badge {
    display: none !important;
  }
  #saasbox_page_content .typed-cursor {
    font-weight: 200;
    opacity: 1;
    -webkit-animation: blink .7s infinite;
    animation: blink .7s infinite;
  }
  #saasbox_page_content [data-type].text-primary+.typed-cursor {
    color: #50a1ff;
  }
  #saasbox_page_content [data-type].text-secondary+.typed-cursor {
    color: #e9ecf0;
  }
  #saasbox_page_content [data-type].text-success+.typed-cursor {
    color: #3cd458;
  }
  #saasbox_page_content [data-type].text-info+.typed-cursor {
    color: #926dde;
  }
  #saasbox_page_content [data-type].text-warning+.typed-cursor {
    color: #ffba00;
  }
  #saasbox_page_content [data-type].text-danger+.typed-cursor {
    color: #ff4954;
  }
  #saasbox_page_content [data-type].text-light+.typed-cursor {
    color: #f8f9fa;
  }
  #saasbox_page_content [data-type].text-dark+.typed-cursor {
    color: #191919;
  }
  @-webkit-keyframes blink {
    #saasbox_page_content 0% {
      opacity: 1;
    }
    #saasbox_page_content 50% {
      opacity: 0;
    }
    #saasbox_page_content 100% {
      opacity: 1;
    }
  }
  @keyframes blink {
    #saasbox_page_content 0% {
      opacity: 1;
    }
    #saasbox_page_content 50% {
      opacity: 0;
    }
    #saasbox_page_content 100% {
      opacity: 1;
    }
  }
  #saasbox_page_content p:empty {
    display: none;
  }
  #saasbox_page_content a {
    color: #50a1ff;
    -webkit-transition: .2s linear;
    transition: .2s linear;
  }
  #saasbox_page_content a:hover,
  #saasbox_page_content a:focus {
    color: #50a1ff;
    text-decoration: none;
    outline: 0;
  }
  #saasbox_page_content a.anchor::before {
    content: '#';
    vertical-align: text-bottom;
  }
  #saasbox_page_content .link-color-default a {
    color: #757575;
  }
  #saasbox_page_content .link-color-default a:hover,
  #saasbox_page_content .link-color-default a:focus {
    color: #50a1ff;
  }
  #saasbox_page_content b,
  #saasbox_page_content strong {
    font-weight: 600;
  }
  #saasbox_page_content del {
    opacity: .6;
  }
  #saasbox_page_content .semibold {
    font-weight: 400;
  }
  #saasbox_page_content .lead,
  #saasbox_page_content .pricing-4 .plan-price p {
    font-size: 1.125rem;
  }
  #saasbox_page_content .label {
    display: block;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  #saasbox_page_content h1,
  #saasbox_page_content h2,
  #saasbox_page_content h3,
  #saasbox_page_content h4,
  #saasbox_page_content .woocommerce h2,
  #saasbox_page_content h5,
  #saasbox_page_content h6,
  #saasbox_page_content .h1,
  #saasbox_page_content .h2,
  #saasbox_page_content .h3,
  #saasbox_page_content .h4,
  #saasbox_page_content .h5,
  #saasbox_page_content .h6 {
    color: #323d47;
    letter-spacing: .5px;
  }
  #saasbox_page_content h1 small,
  #saasbox_page_content h2 small,
  #saasbox_page_content h3 small,
  #saasbox_page_content h4 small,
  #saasbox_page_content .woocommerce h2 small,
  #saasbox_page_content h5 small,
  #saasbox_page_content h6 small,
  #saasbox_page_content .h1 small,
  #saasbox_page_content .h2 small,
  #saasbox_page_content .h3 small,
  #saasbox_page_content .h4 small,
  #saasbox_page_content .h5 small,
  #saasbox_page_content .h6 small {
    font-size: 65%;
    color: #929daf;
  }
  #saasbox_page_content h1 strong,
  #saasbox_page_content h1 b,
  #saasbox_page_content h2 strong,
  #saasbox_page_content h2 b,
  #saasbox_page_content h3 strong,
  #saasbox_page_content h3 b,
  #saasbox_page_content h4 strong,
  #saasbox_page_content .woocommerce h2 strong,
  #saasbox_page_content h4 b,
  #saasbox_page_content .woocommerce h2 b,
  #saasbox_page_content h5 strong,
  #saasbox_page_content h5 b,
  #saasbox_page_content h6 strong,
  #saasbox_page_content h6 b,
  #saasbox_page_content .h1 strong,
  #saasbox_page_content .h1 b,
  #saasbox_page_content .h2 strong,
  #saasbox_page_content .h2 b,
  #saasbox_page_content .h3 strong,
  #saasbox_page_content .h3 b,
  #saasbox_page_content .h4 strong,
  #saasbox_page_content .h4 b,
  #saasbox_page_content .h5 strong,
  #saasbox_page_content .h5 b,
  #saasbox_page_content .h6 strong,
  #saasbox_page_content .h6 b {
    font-weight: 500;
  }
  #saasbox_page_content h1 a,
  #saasbox_page_content h2 a,
  #saasbox_page_content h3 a,
  #saasbox_page_content h4 a,
  #saasbox_page_content .woocommerce h2 a,
  #saasbox_page_content h5 a,
  #saasbox_page_content h6 a,
  #saasbox_page_content .h1 a,
  #saasbox_page_content .h2 a,
  #saasbox_page_content .h3 a,
  #saasbox_page_content .h4 a,
  #saasbox_page_content .h5 a,
  #saasbox_page_content .h6 a {
    color: #323d47;
  }
  #saasbox_page_content h1 a:hover,
  #saasbox_page_content h2 a:hover,
  #saasbox_page_content h3 a:hover,
  #saasbox_page_content h4 a:hover,
  #saasbox_page_content .woocommerce h2 a:hover,
  #saasbox_page_content h5 a:hover,
  #saasbox_page_content h6 a:hover,
  #saasbox_page_content .h1 a:hover,
  #saasbox_page_content .h2 a:hover,
  #saasbox_page_content .h3 a:hover,
  #saasbox_page_content .h4 a:hover,
  #saasbox_page_content .h5 a:hover,
  #saasbox_page_content .h6 a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content h1 .anchor,
  #saasbox_page_content h2 .anchor,
  #saasbox_page_content h3 .anchor,
  #saasbox_page_content h4 .anchor,
  #saasbox_page_content .woocommerce h2 .anchor,
  #saasbox_page_content h5 .anchor,
  #saasbox_page_content h6 .anchor,
  #saasbox_page_content .h1 .anchor,
  #saasbox_page_content .h2 .anchor,
  #saasbox_page_content .h3 .anchor,
  #saasbox_page_content .h4 .anchor,
  #saasbox_page_content .h5 .anchor,
  #saasbox_page_content .h6 .anchor {
    position: absolute;
    right: 100%;
    opacity: 0;
    color: #50a1ff;
  }
  #saasbox_page_content h1.anchor-visible .anchor,
  #saasbox_page_content h1:hover .anchor,
  #saasbox_page_content h2.anchor-visible .anchor,
  #saasbox_page_content h2:hover .anchor,
  #saasbox_page_content h3.anchor-visible .anchor,
  #saasbox_page_content h3:hover .anchor,
  #saasbox_page_content h4.anchor-visible .anchor,
  #saasbox_page_content .woocommerce h2.anchor-visible .anchor,
  #saasbox_page_content h4:hover .anchor,
  #saasbox_page_content .woocommerce h2:hover .anchor,
  #saasbox_page_content h5.anchor-visible .anchor,
  #saasbox_page_content h5:hover .anchor,
  #saasbox_page_content h6.anchor-visible .anchor,
  #saasbox_page_content h6:hover .anchor,
  #saasbox_page_content .h1.anchor-visible .anchor,
  #saasbox_page_content .h1:hover .anchor,
  #saasbox_page_content .h2.anchor-visible .anchor,
  #saasbox_page_content .h2:hover .anchor,
  #saasbox_page_content .h3.anchor-visible .anchor,
  #saasbox_page_content .h3:hover .anchor,
  #saasbox_page_content .h4.anchor-visible .anchor,
  #saasbox_page_content .h4:hover .anchor,
  #saasbox_page_content .h5.anchor-visible .anchor,
  #saasbox_page_content .h5:hover .anchor,
  #saasbox_page_content .h6.anchor-visible .anchor,
  #saasbox_page_content .h6:hover .anchor {
    opacity: .5;
  }
  #saasbox_page_content h1.anchor-visible .anchor:hover,
  #saasbox_page_content h1:hover .anchor:hover,
  #saasbox_page_content h2.anchor-visible .anchor:hover,
  #saasbox_page_content h2:hover .anchor:hover,
  #saasbox_page_content h3.anchor-visible .anchor:hover,
  #saasbox_page_content h3:hover .anchor:hover,
  #saasbox_page_content h4.anchor-visible .anchor:hover,
  #saasbox_page_content .woocommerce h2.anchor-visible .anchor:hover,
  #saasbox_page_content h4:hover .anchor:hover,
  #saasbox_page_content .woocommerce h2:hover .anchor:hover,
  #saasbox_page_content h5.anchor-visible .anchor:hover,
  #saasbox_page_content h5:hover .anchor:hover,
  #saasbox_page_content h6.anchor-visible .anchor:hover,
  #saasbox_page_content h6:hover .anchor:hover,
  #saasbox_page_content .h1.anchor-visible .anchor:hover,
  #saasbox_page_content .h1:hover .anchor:hover,
  #saasbox_page_content .h2.anchor-visible .anchor:hover,
  #saasbox_page_content .h2:hover .anchor:hover,
  #saasbox_page_content .h3.anchor-visible .anchor:hover,
  #saasbox_page_content .h3:hover .anchor:hover,
  #saasbox_page_content .h4.anchor-visible .anchor:hover,
  #saasbox_page_content .h4:hover .anchor:hover,
  #saasbox_page_content .h5.anchor-visible .anchor:hover,
  #saasbox_page_content .h5:hover .anchor:hover,
  #saasbox_page_content .h6.anchor-visible .anchor:hover,
  #saasbox_page_content .h6:hover .anchor:hover {
    opacity: .8;
  }
  #saasbox_page_content h1,
  #saasbox_page_content .h1 {
    font-weight: 400;
  }
  #saasbox_page_content h2,
  #saasbox_page_content .h2 {
    font-weight: 400;
  }
  #saasbox_page_content h3,
  #saasbox_page_content .h3 {
    font-weight: 400;
  }
  #saasbox_page_content h4,
  #saasbox_page_content .woocommerce h2,
  #saasbox_page_content .h4 {
    font-weight: 400;
  }
  #saasbox_page_content h5,
  #saasbox_page_content .h5 {
    font-weight: 400;
  }
  #saasbox_page_content h6,
  #saasbox_page_content .h6 {
    font-weight: 500;
  }
  #saasbox_page_content h6,
  #saasbox_page_content .h6 {
    letter-spacing: .75px;
  }
  #saasbox_page_content h6.bold,
  #saasbox_page_content .h6.bold {
    text-transform: uppercase;
    font-weight: 700;
    font-size: .75rem;
    letter-spacing: .5px;
  }
  #saasbox_page_content h6.bold::before,
  #saasbox_page_content .h6.bold::before {
    content: '\2014 \00A0';
  }
  #saasbox_page_content .heading-alt {
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0;
  }
  #saasbox_page_content .blockquote {
    text-align: center;
    margin-bottom: 2rem;
  }
  #saasbox_page_content .blockquote .quote-sign::before {
    content: 'â€œ';
    display: block;
    font-size: 9rem;
    line-height: .2;
    font-weight: 600;
    padding-top: 4rem;
    opacity: .1;
  }
  #saasbox_page_content .blockquote .quote-sign-end::before {
    content: 'â€';
  }
  #saasbox_page_content .blockquote p::before,
  #saasbox_page_content .blockquote p::after {
    font-size: 1.5rem;
    line-height: 1;
    opacity: .5;
  }
  #saasbox_page_content .blockquote p::before {
    content: 'â€œ';
    margin-left: -13px;
    padding-right: 4px;
  }
  #saasbox_page_content .blockquote p::after {
    content: 'â€';
    margin-right: -13px;
    padding-left: 4px;
  }
  #saasbox_page_content .blockquote footer {
    color: #757575;
    font-size: .875rem;
    margin-top: 1.5rem;
  }
  #saasbox_page_content .blockquote footer::before {
    content: '\2014';
    margin-right: .5rem;
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .blockquote {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 2rem;
    }
    #saasbox_page_content .blockquote .quote-sign::before {
      font-size: 5rem;
    }
  }
  #saasbox_page_content .text-quoted::before,
  #saasbox_page_content .text-quoted::after {
    opacity: .7;
  }
  #saasbox_page_content .text-quoted::before {
    content: 'â€œ';
    padding-right: 2px;
  }
  #saasbox_page_content .text-quoted::after {
    content: 'â€';
    padding-left: 2px;
  }
  #saasbox_page_content hr {
    border-top-color: rgba(117,117,117,0.09);
    margin: 2rem auto;
  }
  #saasbox_page_content .hr-dash {
    border-top-style: dashed;
  }
  #saasbox_page_content .hr-dot {
    border-top-style: dotted;
  }
  #saasbox_page_content .hr-light {
    border-top-color: rgba(117,117,117,0.05);
  }
  #saasbox_page_content .mark-underline {
    padding-bottom: 2px;
    border-bottom: 1px dashed white;
  }
  #saasbox_page_content .divider {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    flex: 0;
    color: #929daf;
    font-size: .8375rem;
    letter-spacing: .5px;
    margin: 2rem auto;
    width: 100%;
  }
  #saasbox_page_content .divider::before,
  #saasbox_page_content .divider::after {
    content: '';
    -webkit-box-flex: 1;
    flex-grow: 1;
    border-top: 1px solid #eaeff4;
  }
  #saasbox_page_content .divider::before {
    margin-right: 1rem;
  }
  #saasbox_page_content .divider::after {
    margin-left: 1rem;
  }
  #saasbox_page_content ::-moz-selection {
    background: #74b4ff;
    color: #fff;
  }
  #saasbox_page_content ::selection {
    background: #74b4ff;
    color: #fff;
  }
  #saasbox_page_content ::-moz-selection {
    background: #74b4ff;
    color: #fff;
  }
  @media (max-width: 767.98px) {
    html {
      font-size: .87891rem;
    }
  }
  #saasbox_page_content img {
    max-width: 100%;
    height: auto;
  }
  #saasbox_page_content .img-thumbnail {
    padding: .25rem;
    border-color: #f4f7f9;
    border-radius: 3px;
  }
  #saasbox_page_content .img-outside-right {
    overflow: hidden;
  }
  #saasbox_page_content .img-outside-right img {
    width: 100%;
    -webkit-transform: translateX(15%);
    transform: translateX(15%);
  }
  #saasbox_page_content .avatar {
    border-radius: 10rem;
    width: 48px;
    height: 48px;
  }
  #saasbox_page_content .avatar-xxs {
    width: 22px;
    height: 22px;
  }
  #saasbox_page_content .avatar-xs {
    width: 34px;
    height: 34px;
  }
  #saasbox_page_content .avatar-sm {
    width: 40px;
    height: 40px;
  }
  #saasbox_page_content .avatar-lg {
    width: 56px;
    height: 56px;
  }
  #saasbox_page_content .avatar-xl {
    width: 64px;
    height: 64px;
  }
  #saasbox_page_content .img-fadein {
    opacity: .75;
    -webkit-transition: .5s;
    transition: .5s;
  }
  #saasbox_page_content .img-fadein:hover {
    opacity: 1;
  }
  #saasbox_page_content .table {
    border: 1px solid #f1f2f3;
  }
  #saasbox_page_content .table th {
    border-top: 0;
    font-weight: 400;
  }
  #saasbox_page_content .table thead th {
    border-bottom: 1px solid #eaeff4;
  }
  #saasbox_page_content .table-hover tbody tr {
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
  }
  #saasbox_page_content .table-sm th,
  #saasbox_page_content .table-sm td {
    padding: .5rem;
  }
  #saasbox_page_content .table-lg th,
  #saasbox_page_content .table-lg td {
    padding: 1rem;
  }
  #saasbox_page_content .media-list .media {
    border-bottom: 1px solid #f1f2f3;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  #saasbox_page_content .media-list .media:last-child {
    border-bottom: 0;
  }
  #saasbox_page_content video {
    max-width: 100%;
  }
  #saasbox_page_content .video-wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  #saasbox_page_content .video-wrapper::before {
    display: block;
    content: "";
    z-index: 0;
  }
  #saasbox_page_content .video-wrapper.ratio-21x9::before {
    padding-top: 42.85714%;
  }
  #saasbox_page_content .video-wrapper.ratio-16x9::before {
    padding-top: 56.25%;
  }
  #saasbox_page_content .video-wrapper.ratio-4x3::before {
    padding-top: 75%;
  }
  #saasbox_page_content .video-wrapper.ratio-1x1::before {
    padding-top: 100%;
  }
  #saasbox_page_content .video-wrapper .poster {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: inherit;
    z-index: 1;
    -webkit-transition: .3s;
    transition: .3s;
  }
  #saasbox_page_content .video-wrapper .btn,
  #saasbox_page_content .video-wrapper .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .video-wrapper .comment-reply-link,
  #saasbox_page_content .video-wrapper .comment-form .submit,
  #saasbox_page_content .comment-form .video-wrapper .submit,
  #saasbox_page_content .video-wrapper .woocommerce a.button,
  #saasbox_page_content .woocommerce .video-wrapper a.button,
  #saasbox_page_content .video-wrapper .woocommerce input.button,
  #saasbox_page_content .woocommerce .video-wrapper input.button,
  #saasbox_page_content .video-wrapper .woocommerce button.button,
  #saasbox_page_content .woocommerce .video-wrapper button.button,
  #saasbox_page_content .video-wrapper .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .video-wrapper a.checkout-button,
  #saasbox_page_content .video-wrapper .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .video-wrapper input#submit,
  #saasbox_page_content .video-wrapper .wpcf7-submit {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 2;
    -webkit-transition: .3s;
    transition: .3s;
  }
  #saasbox_page_content .video-wrapper .btn:hover,
  #saasbox_page_content .video-wrapper .comment-list .comment-reply-link:hover,
  #saasbox_page_content .comment-list .video-wrapper .comment-reply-link:hover,
  #saasbox_page_content .video-wrapper .comment-form .submit:hover,
  #saasbox_page_content .comment-form .video-wrapper .submit:hover,
  #saasbox_page_content .video-wrapper .woocommerce a.button:hover,
  #saasbox_page_content .woocommerce .video-wrapper a.button:hover,
  #saasbox_page_content .video-wrapper .woocommerce input.button:hover,
  #saasbox_page_content .woocommerce .video-wrapper input.button:hover,
  #saasbox_page_content .video-wrapper .woocommerce button.button:hover,
  #saasbox_page_content .woocommerce .video-wrapper button.button:hover,
  #saasbox_page_content .video-wrapper .woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .video-wrapper a.checkout-button:hover,
  #saasbox_page_content .video-wrapper .woocommerce #respond input#submit:hover,
  #saasbox_page_content .woocommerce #respond .video-wrapper input#submit:hover,
  #saasbox_page_content .video-wrapper .wpcf7-submit:hover {
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
  }
  #saasbox_page_content .video-wrapper iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: inherit;
  }
  #saasbox_page_content .video-wrapper.reveal .poster,
  #saasbox_page_content .video-wrapper.reveal .btn,
  #saasbox_page_content .video-wrapper.reveal .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .video-wrapper.reveal .comment-reply-link,
  #saasbox_page_content .video-wrapper.reveal .comment-form .submit,
  #saasbox_page_content .comment-form .video-wrapper.reveal .submit,
  #saasbox_page_content .video-wrapper.reveal .woocommerce a.button,
  #saasbox_page_content .woocommerce .video-wrapper.reveal a.button,
  #saasbox_page_content .video-wrapper.reveal .woocommerce input.button,
  #saasbox_page_content .woocommerce .video-wrapper.reveal input.button,
  #saasbox_page_content .video-wrapper.reveal .woocommerce button.button,
  #saasbox_page_content .woocommerce .video-wrapper.reveal button.button,
  #saasbox_page_content .video-wrapper.reveal .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .video-wrapper.reveal a.checkout-button,
  #saasbox_page_content .video-wrapper.reveal .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .video-wrapper.reveal input#submit,
  #saasbox_page_content .video-wrapper.reveal .wpcf7-submit {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .8s linear;
    transition: .8s linear;
  }
  #saasbox_page_content .video-btn-wrapper {
    position: relative;
    text-align: center;
  }
  #saasbox_page_content .video-btn-wrapper .btn,
  #saasbox_page_content .video-btn-wrapper .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .video-btn-wrapper .comment-reply-link,
  #saasbox_page_content .video-btn-wrapper .comment-form .submit,
  #saasbox_page_content .comment-form .video-btn-wrapper .submit,
  #saasbox_page_content .video-btn-wrapper .woocommerce a.button,
  #saasbox_page_content .woocommerce .video-btn-wrapper a.button,
  #saasbox_page_content .video-btn-wrapper .woocommerce input.button,
  #saasbox_page_content .woocommerce .video-btn-wrapper input.button,
  #saasbox_page_content .video-btn-wrapper .woocommerce button.button,
  #saasbox_page_content .woocommerce .video-btn-wrapper button.button,
  #saasbox_page_content .video-btn-wrapper .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .video-btn-wrapper a.checkout-button,
  #saasbox_page_content .video-btn-wrapper .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .video-btn-wrapper input#submit,
  #saasbox_page_content .video-btn-wrapper .wpcf7-submit {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transition: .3s;
    transition: .3s;
  }
  #saasbox_page_content .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -100;
  }
  #saasbox_page_content .card {
    border: 0;
    -webkit-transition: .5s;
    transition: .5s;
  }
  #saasbox_page_content .card.bg-img .card-body {
    position: relative;
  }
  #saasbox_page_content .card.text-white a {
    color: rgba(255,255,255,0.8);
  }
  #saasbox_page_content .card>.alert,
  #saasbox_page_content .card .card-body .alert {
    border-radius: 0;
    margin-bottom: 0;
  }
  #saasbox_page_content .card>.nav-tabs,
  #saasbox_page_content .card .card-body .nav-tabs {
    margin-bottom: 0;
  }
  #saasbox_page_content .card>.table {
    margin-bottom: 0;
  }
  #saasbox_page_content .card>.table tr td:first-child,
  #saasbox_page_content .card>.table tr th:first-child {
    padding-left: 20px;
  }
  #saasbox_page_content .card>.table tr td:last-child,
  #saasbox_page_content .card>.table tr th:last-child {
    padding-right: 20px;
  }
  #saasbox_page_content .card .card-hover-show {
    opacity: 0;
    -webkit-transition: .3s linear;
    transition: .3s linear;
  }
  #saasbox_page_content .card:hover .card-hover-show {
    opacity: 1;
  }
  #saasbox_page_content .card-title {
    color: inherit;
  }
  #saasbox_page_content .card-footer {
    background-color: #fff;
    border-top: 1px solid #f1f2f3;
  }
  #saasbox_page_content .card-body-bottom {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    position: absolute;
    bottom: 0;
  }
  #saasbox_page_content .card-shadow {
    -webkit-box-shadow: 0 1px 25px rgba(0,0,0,0.05);
    box-shadow: 0 1px 25px rgba(0,0,0,0.05);
  }
  #saasbox_page_content .card-hover-shadow:hover {
    -webkit-box-shadow: 0 1px 35px rgba(0,0,0,0.07);
    box-shadow: 0 1px 35px rgba(0,0,0,0.07);
  }
  #saasbox_page_content .card-img-top {
    position: relative;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }
  #saasbox_page_content .card-img-top img {
    border-radius: inherit;
  }
  #saasbox_page_content .card-img-top .badges {
    position: absolute;
    top: 0;
    padding: 1rem 1.25rem;
  }
  #saasbox_page_content .card-img-top .badges-right {
    right: 0;
  }
  #saasbox_page_content .card-img-bottom {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }
  #saasbox_page_content .card-hover {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
  }
  #saasbox_page_content .card-hover.bg-img {
    position: absolute;
  }
  #saasbox_page_content .card-hover[data-animation] {
    -webkit-transition: .4s ease-out;
    transition: .4s ease-out;
  }
  #saasbox_page_content .card-hover[data-animation="zoom"] {
    -webkit-transform: scale(0,0);
    transform: scale(0,0);
  }
  #saasbox_page_content .card-hover[data-animation="slide-up"] {
    top: 100%;
  }
  #saasbox_page_content .card-hover[data-animation="slide-down"] {
    top: -100%;
  }
  #saasbox_page_content .card-hover[data-animation="slide-left"] {
    left: 100%;
  }
  #saasbox_page_content .card-hover[data-animation="slide-right"] {
    left: -100%;
  }
  #saasbox_page_content .card:hover .card-hover {
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
  }
  #saasbox_page_content .card:hover .card-hover[data-animation="zoom"] {
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
  }
  #saasbox_page_content .card-hover .card-body {
    position: relative;
  }
  #saasbox_page_content .card-columns .card {
    margin-bottom: 1.5rem;
  }
  @media (max-width: 1199.98px) {
    #saasbox_page_content .card-columns {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .card-columns {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }
  }
  #saasbox_page_content pre {
    background-color: #fcfdfd;
    padding: 12px;
    border: 1px solid #f1f2f3;
    border-left: 3px solid #50a1ff;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.02);
    box-shadow: 0 0 15px rgba(0,0,0,0.02);
  }
  #saasbox_page_content .pre-scrollable {
    max-height: 350px;
    overflow-y: auto;
  }
  #saasbox_page_content .fa {
    font-weight: inherit;
  }
  #saasbox_page_content .iconbox {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background-color: #f5f6f7;
    color: #999;
    border-radius: 10rem;
  }
  #saasbox_page_content .iconbox.iconbox-xs {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: .6875rem;
  }
  #saasbox_page_content .iconbox.iconbox-sm {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: .75rem;
  }
  #saasbox_page_content .iconbox.iconbox-lg,
  #saasbox_page_content .step-icon .iconbox {
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 1.25rem;
  }
  #saasbox_page_content .iconbox.iconbox-xl {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 1.5rem;
  }
  #saasbox_page_content .iconbox.iconbox-xxl {
    width: 96px;
    height: 96px;
    line-height: 96px;
    font-size: 2rem;
  }
  #saasbox_page_content .iconbox-sq {
    border-radius: 0;
  }
  #saasbox_page_content .iconbox-outline {
    border: 1px solid #f1f2f3;
    background-color: transparent;
  }
  #saasbox_page_content .accordion .card {
    background-color: #fff;
    border: 1px solid #f1f2f3 !important;
    margin-bottom: 16px;
  }
  #saasbox_page_content .accordion .card:last-child {
    margin-bottom: 0;
  }
  #saasbox_page_content .accordion .card-title {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f9fafb;
  }
  #saasbox_page_content .accordion .card-title a {
    display: block;
    padding: 16px 33px;
    color: #323d47;
  }
  #saasbox_page_content .accordion .card-title a::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-left: 1px solid #757575;
    border-top: 1px solid #757575;
    margin-right: 20px;
    margin-left: -33px;
    margin-bottom: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: .2s linear;
    transition: .2s linear;
  }
  #saasbox_page_content .accordion .card-title a.collapsed::before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  #saasbox_page_content .accordion-connected {
    border: 1px solid #f1f2f3;
  }
  #saasbox_page_content .accordion-connected .card {
    margin-bottom: 0;
    border: 0 !important;
    border-bottom: 1px solid #f1f2f3 !important;
  }
  #saasbox_page_content .accordion-connected .card:last-child {
    border-bottom: 0 !important;
  }
  #saasbox_page_content .accordion-connected .card-title {
    border-bottom: 0;
  }
  #saasbox_page_content .accordion-connected .card-title a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #saasbox_page_content .accordion-light .card-title {
    background-color: #fff;
  }
  #saasbox_page_content .accordion-arrow-right .card-title a {
    padding-left: 0;
    padding-right: 0;
  }
  #saasbox_page_content .accordion-arrow-right .card-title a::before {
    float: right;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }
  #saasbox_page_content .alert {
    border-width: 0;
    border-left-width: 4px;
    border-radius: 0;
  }
  #saasbox_page_content .badge,
  #saasbox_page_content .tagcloud .tag-cloud-link {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  #saasbox_page_content .badge:empty,
  #saasbox_page_content .tagcloud .tag-cloud-link:empty {
    display: inline-block;
  }
  #saasbox_page_content .badge-pale.badge-primary {
    color: #4387d6;
    background-color: #eef4fc;
  }
  #saasbox_page_content .badge-pale.badge-secondary,
  #saasbox_page_content .tagcloud .badge-pale.tag-cloud-link {
    background-color: #f0f4f7;
  }
  #saasbox_page_content .badge-pale.badge-success {
    color: #32b24a;
    background-color: #eefcf0;
  }
  #saasbox_page_content .badge-pale.badge-info {
    color: #7b5cba;
    background-color: #f2eefc;
  }
  #saasbox_page_content .badge-pale.badge-warning {
    color: #d69c00;
    background-color: #fcf8ee;
  }
  #saasbox_page_content .badge-pale.badge-danger {
    color: #d63d47;
    background-color: #fceeef;
  }
  #saasbox_page_content .badge-pale.badge-light {
    background-color: #fff;
  }
  #saasbox_page_content .badge-pale.badge-dark {
    color: #151515;
    background-color: #e5e5e5;
  }
  #saasbox_page_content .badge-glass.badge-primary {
    background-color: rgba(80,161,255,0.55);
    color: #fff;
  }
  #saasbox_page_content .badge-glass.badge-secondary,
  #saasbox_page_content .tagcloud .badge-glass.tag-cloud-link {
    background-color: rgba(233,236,240,0.55);
    color: #fff;
  }
  #saasbox_page_content .badge-glass.badge-success {
    background-color: rgba(60,212,88,0.55);
    color: #fff;
  }
  #saasbox_page_content .badge-glass.badge-info {
    background-color: rgba(146,109,222,0.55);
    color: #fff;
  }
  #saasbox_page_content .badge-glass.badge-warning {
    background-color: rgba(255,186,0,0.55);
    color: #fff;
  }
  #saasbox_page_content .badge-glass.badge-danger {
    background-color: rgba(255,73,84,0.55);
    color: #fff;
  }
  #saasbox_page_content .badge-glass.badge-light {
    background-color: rgba(248,249,250,0.55);
    color: #fff;
  }
  #saasbox_page_content .badge-glass.badge-dark {
    background-color: rgba(25,25,25,0.55);
    color: #fff;
  }
  #saasbox_page_content .badge-dot {
    width: 6px;
    height: 6px;
    padding: 0;
    border-radius: 100%;
    vertical-align: middle;
  }
  #saasbox_page_content .badge-ring {
    position: relative;
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 100%;
    vertical-align: middle;
  }
  #saasbox_page_content .badge-ring::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s;
    transition: .3s;
  }
  #saasbox_page_content .badge-ring.fill::after {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  #saasbox_page_content .badge-number {
    position: relative;
    top: -10px;
    margin-left: -0.5rem;
    font-size: .75rem;
    padding: 2px 4px;
    border-radius: 10rem;
    min-width: 1rem;
    min-height: 1rem;
  }
  #saasbox_page_content .breadcrumb {
    margin-bottom: 0;
  }
  #saasbox_page_content .breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
  }
  #saasbox_page_content .breadcrumb-item+.breadcrumb-item::before {
    padding: 0;
    margin: 0 .75rem;
    width: 4px;
    height: 4px;
    border-right: 1px solid #bfc5ca;
    border-top: 1px solid #bfc5ca;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #saasbox_page_content .breadcrumb-item a {
    color: #999;
  }
  #saasbox_page_content .breadcrumb-item a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content .btn,
  #saasbox_page_content .comment-list .comment-reply-link,
  #saasbox_page_content .comment-form .submit,
  #saasbox_page_content .woocommerce a.button,
  #saasbox_page_content .woocommerce input.button,
  #saasbox_page_content .woocommerce button.button,
  #saasbox_page_content .woocommerce button.button.alt,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce #respond input#submit,
  #saasbox_page_content .wpcf7-submit {
    font-size: 11px;
    padding: 8px 26px 6px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    border-radius: 2px;
    outline: 0;
    -webkit-transition: .15s linear;
    transition: .15s linear;
  }
  #saasbox_page_content button:focus {
    outline: 0;
  }
  #saasbox_page_content .btn-link:hover,
  #saasbox_page_content .btn-link:focus {
    -webkit-text-decoration: normal;
    text-decoration: normal;
  }
  #saasbox_page_content .btn-outline-secondary {
    color: #929daf;
  }
  #saasbox_page_content .btn-white {
    color: #757575;
    background-color: #fff;
    border-color: #fff;
  }
  #saasbox_page_content .btn-white:hover {
    color: #757575;
    background-color: whitesmoke;
    border-color: whitesmoke;
    -webkit-box-shadow: 0 1px 10px rgba(255,255,255,0.4);
    box-shadow: 0 1px 10px rgba(255,255,255,0.4);
  }
  #saasbox_page_content .btn-white:focus,
  #saasbox_page_content .btn-white.focus {
    color: #757575;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,255,255,0.5);
    box-shadow: 0 0 0 0rem rgba(255,255,255,0.5);
  }
  #saasbox_page_content .btn-white.disabled,
  #saasbox_page_content .btn-white:disabled {
    background-color: #fff;
    border-color: #fff;
  }
  #saasbox_page_content .btn-white:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-white:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-white.dropdown-toggle {
    color: #757575;
    background-color: #ebebeb;
    border-color: #ebebeb;
    -webkit-box-shadow: 0 0 0 0rem rgba(255,255,255,0.5);
    box-shadow: 0 0 0 0rem rgba(255,255,255,0.5);
  }
  #saasbox_page_content .btn-white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #323d47;
  }
  #saasbox_page_content .btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
  }
  #saasbox_page_content .btn-facebook:hover {
    color: #fff;
    background-color: #355089;
    border-color: #355089;
    -webkit-box-shadow: 0 1px 10px rgba(59,89,152,0.4);
    box-shadow: 0 1px 10px rgba(59,89,152,0.4);
  }
  #saasbox_page_content .btn-facebook:focus,
  #saasbox_page_content .btn-facebook.focus {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(59,89,152,0.5);
    box-shadow: 0 0 0 0rem rgba(59,89,152,0.5);
  }
  #saasbox_page_content .btn-facebook.disabled,
  #saasbox_page_content .btn-facebook:disabled {
    background-color: #3b5998;
    border-color: #3b5998;
  }
  #saasbox_page_content .btn-facebook:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-facebook:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #30487b;
    border-color: #30487b;
    -webkit-box-shadow: 0 0 0 0rem rgba(59,89,152,0.5);
    box-shadow: 0 0 0 0rem rgba(59,89,152,0.5);
  }
  #saasbox_page_content .btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
  }
  #saasbox_page_content .btn-google:hover {
    color: #fff;
    background-color: #da3b28;
    border-color: #da3b28;
    -webkit-box-shadow: 0 1px 10px rgba(221,75,57,0.4);
    box-shadow: 0 1px 10px rgba(221,75,57,0.4);
  }
  #saasbox_page_content .btn-google:focus,
  #saasbox_page_content .btn-google.focus {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(221,75,57,0.5);
    box-shadow: 0 0 0 0rem rgba(221,75,57,0.5);
  }
  #saasbox_page_content .btn-google.disabled,
  #saasbox_page_content .btn-google:disabled {
    background-color: #dd4b39;
    border-color: #dd4b39;
  }
  #saasbox_page_content .btn-google:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-google:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-google.dropdown-toggle {
    color: #fff;
    background-color: #ca3523;
    border-color: #ca3523;
    -webkit-box-shadow: 0 0 0 0rem rgba(221,75,57,0.5);
    box-shadow: 0 0 0 0rem rgba(221,75,57,0.5);
  }
  #saasbox_page_content .btn-twitter {
    color: #fff;
    background-color: #00aced;
    border-color: #00aced;
  }
  #saasbox_page_content .btn-twitter:hover {
    color: #fff;
    background-color: #009dd9;
    border-color: #009dd9;
    -webkit-box-shadow: 0 1px 10px rgba(0,172,237,0.4);
    box-shadow: 0 1px 10px rgba(0,172,237,0.4);
  }
  #saasbox_page_content .btn-twitter:focus,
  #saasbox_page_content .btn-twitter.focus {
    color: #fff;
    -webkit-box-shadow: 0 0 0 0rem rgba(0,172,237,0.5);
    box-shadow: 0 0 0 0rem rgba(0,172,237,0.5);
  }
  #saasbox_page_content .btn-twitter.disabled,
  #saasbox_page_content .btn-twitter:disabled {
    background-color: #00aced;
    border-color: #00aced;
  }
  #saasbox_page_content .btn-twitter:not([disabled]):not(.disabled):active,
  #saasbox_page_content .btn-twitter:not([disabled]):not(.disabled).active,
  #saasbox_page_content .show>.btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #008ec4;
    border-color: #008ec4;
    -webkit-box-shadow: 0 0 0 0rem rgba(0,172,237,0.5);
    box-shadow: 0 0 0 0rem rgba(0,172,237,0.5);
  }
  #saasbox_page_content .btn-xs {
    padding: 4px 12px 3px;
    font-size: 10px;
  }
  #saasbox_page_content .btn-sm,
  #saasbox_page_content .btn-group-sm>.btn,
  #saasbox_page_content .comment-form .btn-group-sm>.submit,
  #saasbox_page_content .woocommerce .btn-group-sm>a.button,
  #saasbox_page_content .woocommerce .btn-group-sm>input.button,
  #saasbox_page_content .woocommerce .btn-group-sm>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-sm>input#submit,
  #saasbox_page_content .btn-group-sm>.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn,
  #saasbox_page_content .comment-list .input-group-sm>.input-group-prepend>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-prepend>.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>a.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>input.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-prepend>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-prepend>input#submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn,
  #saasbox_page_content .comment-list .input-group-sm>.input-group-append>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-append>.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>a.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>input.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-append>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-append>input#submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.wpcf7-submit,
  #saasbox_page_content .comment-list .comment-reply-link {
    padding: 8px 20px 6px;
    font-size: 11px;
  }
  #saasbox_page_content .btn-lg,
  #saasbox_page_content .btn-group-lg>.btn,
  #saasbox_page_content .comment-list .btn-group-lg>.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-lg>.submit,
  #saasbox_page_content .woocommerce .btn-group-lg>a.button,
  #saasbox_page_content .woocommerce .btn-group-lg>input.button,
  #saasbox_page_content .woocommerce .btn-group-lg>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-lg>input#submit,
  #saasbox_page_content .btn-group-lg>.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-prepend>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-prepend>.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>a.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>input.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-prepend>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-prepend>input#submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-append>.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-append>.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>a.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>input.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>button.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-append>a.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-append>input#submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.wpcf7-submit {
    padding: 7px 32px 6px;
    font-size: 12px;
  }
  #saasbox_page_content .btn-xl {
    padding: 10px 38px 10px;
    font-size: 13px;
  }
  #saasbox_page_content .btn-round {
    border-radius: 10rem;
  }
  #saasbox_page_content .btn-circle {
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 10rem;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 1rem;
  }
  #saasbox_page_content .btn-circle .fa-play {
    margin-left: 4px;
    font-size: .875rem;
  }
  #saasbox_page_content .btn-circle.btn-xs {
    width: 40px;
    height: 40px;
    font-size: .875rem;
  }
  #saasbox_page_content .btn-circle.btn-sm,
  #saasbox_page_content .btn-group-sm>.btn-circle.btn,
  #saasbox_page_content .comment-form .btn-group-sm>.btn-circle.submit,
  #saasbox_page_content .woocommerce .btn-group-sm>a.btn-circle.button,
  #saasbox_page_content .woocommerce .btn-group-sm>input.btn-circle.button,
  #saasbox_page_content .woocommerce .btn-group-sm>button.btn-circle.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.btn-circle.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-sm>input.btn-circle#submit,
  #saasbox_page_content .btn-group-sm>.btn-circle.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn-circle.btn,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-prepend>.btn-circle.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>a.btn-circle.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>input.btn-circle.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>button.btn-circle.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-prepend>a.btn-circle.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-prepend>input.btn-circle#submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn-circle.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn-circle.btn,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-append>.btn-circle.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>a.btn-circle.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>input.btn-circle.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>button.btn-circle.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-append>a.btn-circle.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-append>input.btn-circle#submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn-circle.wpcf7-submit,
  #saasbox_page_content .comment-list .btn-circle.comment-reply-link {
    width: 48px;
    height: 48px;
    font-size: .9325rem;
  }
  #saasbox_page_content .btn-circle.btn-lg,
  #saasbox_page_content .btn-group-lg>.btn-circle.btn,
  #saasbox_page_content .comment-list .btn-group-lg>.btn-circle.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-lg>.btn-circle.submit,
  #saasbox_page_content .woocommerce .btn-group-lg>a.btn-circle.button,
  #saasbox_page_content .woocommerce .btn-group-lg>input.btn-circle.button,
  #saasbox_page_content .woocommerce .btn-group-lg>button.btn-circle.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.btn-circle.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-lg>input.btn-circle#submit,
  #saasbox_page_content .btn-group-lg>.btn-circle.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn-circle.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-prepend>.btn-circle.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-prepend>.btn-circle.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>a.btn-circle.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>input.btn-circle.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>button.btn-circle.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-prepend>a.btn-circle.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-prepend>input.btn-circle#submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn-circle.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn-circle.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-append>.btn-circle.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-append>.btn-circle.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>a.btn-circle.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>input.btn-circle.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>button.btn-circle.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-append>a.btn-circle.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-append>input.btn-circle#submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn-circle.wpcf7-submit {
    width: 64px;
    height: 64px;
    font-size: 1.25rem;
  }
  #saasbox_page_content .btn-circle.btn-xl {
    width: 72px;
    height: 72px;
    font-size: 1.5rem;
  }
  #saasbox_page_content .btn-glass.btn-primary,
  #saasbox_page_content .comment-form .btn-glass.submit,
  #saasbox_page_content .btn-glass.wpcf7-submit {
    background-color: rgba(80,161,255,0.6);
    border-color: transparent;
    color: #fff;
  }
  #saasbox_page_content .btn-glass.btn-primary:hover,
  #saasbox_page_content .comment-form .btn-glass.submit:hover,
  #saasbox_page_content .btn-glass.wpcf7-submit:hover {
    background-color: rgba(80,161,255,0.9);
  }
  #saasbox_page_content .btn-glass.btn-secondary,
  #saasbox_page_content .comment-list .btn-glass.comment-reply-link,
  #saasbox_page_content .woocommerce a.btn-glass.button,
  #saasbox_page_content .woocommerce input.btn-glass.button,
  #saasbox_page_content .woocommerce button.btn-glass.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.btn-glass.checkout-button,
  #saasbox_page_content .woocommerce #respond input.btn-glass#submit {
    background-color: rgba(233,236,240,0.6);
    border-color: transparent;
    color: #fff;
  }
  #saasbox_page_content .btn-glass.btn-secondary:hover,
  #saasbox_page_content .comment-list .btn-glass.comment-reply-link:hover,
  #saasbox_page_content .woocommerce a.btn-glass.button:hover,
  #saasbox_page_content .woocommerce input.btn-glass.button:hover,
  #saasbox_page_content .woocommerce button.btn-glass.button:hover,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.btn-glass.checkout-button:hover,
  #saasbox_page_content .woocommerce #respond input.btn-glass#submit:hover {
    background-color: rgba(233,236,240,0.9);
  }
  #saasbox_page_content .btn-glass.btn-success {
    background-color: rgba(60,212,88,0.6);
    border-color: transparent;
    color: #fff;
  }
  #saasbox_page_content .btn-glass.btn-success:hover {
    background-color: rgba(60,212,88,0.9);
  }
  #saasbox_page_content .btn-glass.btn-info {
    background-color: rgba(146,109,222,0.6);
    border-color: transparent;
    color: #fff;
  }
  #saasbox_page_content .btn-glass.btn-info:hover {
    background-color: rgba(146,109,222,0.9);
  }
  #saasbox_page_content .btn-glass.btn-warning {
    background-color: rgba(255,186,0,0.6);
    border-color: transparent;
    color: #fff;
  }
  #saasbox_page_content .btn-glass.btn-warning:hover {
    background-color: rgba(255,186,0,0.9);
  }
  #saasbox_page_content .btn-glass.btn-danger {
    background-color: rgba(255,73,84,0.6);
    border-color: transparent;
    color: #fff;
  }
  #saasbox_page_content .btn-glass.btn-danger:hover {
    background-color: rgba(255,73,84,0.9);
  }
  #saasbox_page_content .btn-glass.btn-light {
    background-color: rgba(248,249,250,0.6);
    border-color: transparent;
    color: #fff;
  }
  #saasbox_page_content .btn-glass.btn-light:hover {
    background-color: rgba(248,249,250,0.9);
  }
  #saasbox_page_content .btn-glass.btn-dark {
    background-color: rgba(25,25,25,0.6);
    border-color: transparent;
    color: #fff;
  }
  #saasbox_page_content .btn-glass.btn-dark:hover {
    background-color: rgba(25,25,25,0.9);
  }
  #saasbox_page_content .btn-label {
    position: relative;
    padding-left: 52px;
    overflow: hidden;
  }
  #saasbox_page_content .btn-label label {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 36px;
    line-height: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
    margin-bottom: 0;
  }
  #saasbox_page_content .btn-label.btn-xl {
    padding-left: 62px;
  }
  #saasbox_page_content .btn-label.btn-xl label {
    width: 46px;
  }
  #saasbox_page_content .btn-label.btn-lg,
  #saasbox_page_content .btn-group-lg>.btn-label.btn,
  #saasbox_page_content .comment-list .btn-group-lg>.btn-label.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-lg>.btn-label.submit,
  #saasbox_page_content .woocommerce .btn-group-lg>a.btn-label.button,
  #saasbox_page_content .woocommerce .btn-group-lg>input.btn-label.button,
  #saasbox_page_content .woocommerce .btn-group-lg>button.btn-label.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.btn-label.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-lg>input.btn-label#submit,
  #saasbox_page_content .btn-group-lg>.btn-label.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn-label.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-prepend>.btn-label.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-prepend>.btn-label.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>a.btn-label.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>input.btn-label.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>button.btn-label.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-prepend>a.btn-label.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-prepend>input.btn-label#submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn-label.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn-label.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-append>.btn-label.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-append>.btn-label.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>a.btn-label.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>input.btn-label.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>button.btn-label.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-append>a.btn-label.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-append>input.btn-label#submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn-label.wpcf7-submit {
    padding-left: 56px;
  }
  #saasbox_page_content .btn-label.btn-lg label,
  #saasbox_page_content .btn-group-lg>.btn-label.btn label,
  #saasbox_page_content .comment-list .btn-group-lg>.btn-label.comment-reply-link label,
  #saasbox_page_content .comment-form .btn-group-lg>.btn-label.submit label,
  #saasbox_page_content .woocommerce .btn-group-lg>a.btn-label.button label,
  #saasbox_page_content .woocommerce .btn-group-lg>input.btn-label.button label,
  #saasbox_page_content .woocommerce .btn-group-lg>button.btn-label.button label,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.btn-label.checkout-button label,
  #saasbox_page_content .woocommerce #respond .btn-group-lg>input.btn-label#submit label,
  #saasbox_page_content .btn-group-lg>.btn-label.wpcf7-submit label,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn-label.btn label,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-prepend>.btn-label.comment-reply-link label,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-prepend>.btn-label.submit label,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>a.btn-label.button label,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>input.btn-label.button label,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>button.btn-label.button label,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-prepend>a.btn-label.checkout-button label,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-prepend>input.btn-label#submit label,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn-label.wpcf7-submit label,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn-label.btn label,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-append>.btn-label.comment-reply-link label,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-append>.btn-label.submit label,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>a.btn-label.button label,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>input.btn-label.button label,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>button.btn-label.button label,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-append>a.btn-label.checkout-button label,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-append>input.btn-label#submit label,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn-label.wpcf7-submit label {
    width: 40px;
  }
  #saasbox_page_content .btn-label.btn-sm,
  #saasbox_page_content .btn-group-sm>.btn-label.btn,
  #saasbox_page_content .comment-form .btn-group-sm>.btn-label.submit,
  #saasbox_page_content .woocommerce .btn-group-sm>a.btn-label.button,
  #saasbox_page_content .woocommerce .btn-group-sm>input.btn-label.button,
  #saasbox_page_content .woocommerce .btn-group-sm>button.btn-label.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.btn-label.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-sm>input.btn-label#submit,
  #saasbox_page_content .btn-group-sm>.btn-label.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn-label.btn,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-prepend>.btn-label.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>a.btn-label.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>input.btn-label.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>button.btn-label.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-prepend>a.btn-label.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-prepend>input.btn-label#submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn-label.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn-label.btn,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-append>.btn-label.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>a.btn-label.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>input.btn-label.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>button.btn-label.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-append>a.btn-label.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-append>input.btn-label#submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn-label.wpcf7-submit,
  #saasbox_page_content .comment-list .btn-label.comment-reply-link {
    padding-left: 48px;
  }
  #saasbox_page_content .btn-label.btn-sm label,
  #saasbox_page_content .btn-group-sm>.btn-label.btn label,
  #saasbox_page_content .comment-form .btn-group-sm>.btn-label.submit label,
  #saasbox_page_content .woocommerce .btn-group-sm>a.btn-label.button label,
  #saasbox_page_content .woocommerce .btn-group-sm>input.btn-label.button label,
  #saasbox_page_content .woocommerce .btn-group-sm>button.btn-label.button label,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.btn-label.checkout-button label,
  #saasbox_page_content .woocommerce #respond .btn-group-sm>input.btn-label#submit label,
  #saasbox_page_content .btn-group-sm>.btn-label.wpcf7-submit label,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn-label.btn label,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-prepend>.btn-label.submit label,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>a.btn-label.button label,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>input.btn-label.button label,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>button.btn-label.button label,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-prepend>a.btn-label.checkout-button label,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-prepend>input.btn-label#submit label,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn-label.wpcf7-submit label,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn-label.btn label,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-append>.btn-label.submit label,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>a.btn-label.button label,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>input.btn-label.button label,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>button.btn-label.button label,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-append>a.btn-label.checkout-button label,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-append>input.btn-label#submit label,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn-label.wpcf7-submit label,
  #saasbox_page_content .comment-list .btn-label.comment-reply-link label {
    width: 32px;
  }
  #saasbox_page_content .btn-label.btn-xs {
    padding-left: 43px;
  }
  #saasbox_page_content .btn-label.btn-xs label {
    width: 27px;
  }
  #saasbox_page_content .btn-square {
    padding-left: 5px;
    padding-right: 5px;
    width: 36px;
    height: 36px;
  }
  #saasbox_page_content .btn-square.btn-xl {
    width: 46px;
    height: 46px;
  }
  #saasbox_page_content .btn-square.btn-lg,
  #saasbox_page_content .btn-group-lg>.btn-square.btn,
  #saasbox_page_content .comment-list .btn-group-lg>.btn-square.comment-reply-link,
  #saasbox_page_content .comment-form .btn-group-lg>.btn-square.submit,
  #saasbox_page_content .woocommerce .btn-group-lg>a.btn-square.button,
  #saasbox_page_content .woocommerce .btn-group-lg>input.btn-square.button,
  #saasbox_page_content .woocommerce .btn-group-lg>button.btn-square.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-lg>a.btn-square.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-lg>input.btn-square#submit,
  #saasbox_page_content .btn-group-lg>.btn-square.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn-square.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-prepend>.btn-square.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-prepend>.btn-square.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>a.btn-square.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>input.btn-square.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-prepend>button.btn-square.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-prepend>a.btn-square.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-prepend>input.btn-square#submit,
  #saasbox_page_content .input-group-lg>.input-group-prepend>.btn-square.wpcf7-submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn-square.btn,
  #saasbox_page_content .comment-list .input-group-lg>.input-group-append>.btn-square.comment-reply-link,
  #saasbox_page_content .comment-form .input-group-lg>.input-group-append>.btn-square.submit,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>a.btn-square.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>input.btn-square.button,
  #saasbox_page_content .woocommerce .input-group-lg>.input-group-append>button.btn-square.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-lg>.input-group-append>a.btn-square.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-lg>.input-group-append>input.btn-square#submit,
  #saasbox_page_content .input-group-lg>.input-group-append>.btn-square.wpcf7-submit {
    width: 40px;
    height: 40px;
  }
  #saasbox_page_content .btn-square.btn-sm,
  #saasbox_page_content .btn-group-sm>.btn-square.btn,
  #saasbox_page_content .comment-form .btn-group-sm>.btn-square.submit,
  #saasbox_page_content .woocommerce .btn-group-sm>a.btn-square.button,
  #saasbox_page_content .woocommerce .btn-group-sm>input.btn-square.button,
  #saasbox_page_content .woocommerce .btn-group-sm>button.btn-square.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .btn-group-sm>a.btn-square.checkout-button,
  #saasbox_page_content .woocommerce #respond .btn-group-sm>input.btn-square#submit,
  #saasbox_page_content .btn-group-sm>.btn-square.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn-square.btn,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-prepend>.btn-square.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>a.btn-square.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>input.btn-square.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-prepend>button.btn-square.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-prepend>a.btn-square.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-prepend>input.btn-square#submit,
  #saasbox_page_content .input-group-sm>.input-group-prepend>.btn-square.wpcf7-submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn-square.btn,
  #saasbox_page_content .comment-form .input-group-sm>.input-group-append>.btn-square.submit,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>a.btn-square.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>input.btn-square.button,
  #saasbox_page_content .woocommerce .input-group-sm>.input-group-append>button.btn-square.button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-sm>.input-group-append>a.btn-square.checkout-button,
  #saasbox_page_content .woocommerce #respond .input-group-sm>.input-group-append>input.btn-square#submit,
  #saasbox_page_content .input-group-sm>.input-group-append>.btn-square.wpcf7-submit,
  #saasbox_page_content .comment-list .btn-square.comment-reply-link {
    width: 32px;
    height: 32px;
  }
  #saasbox_page_content .btn-square.btn-xs {
    width: 27px;
    height: 27px;
  }
  #saasbox_page_content .dropdown-toggle {
    cursor: pointer;
  }
  #saasbox_page_content .dropdown-toggle::after,
  #saasbox_page_content .dropup .dropdown-toggle::after {
    content: '';
    width: 4px;
    height: 4px;
    vertical-align: initial;
    margin-left: .5rem;
    vertical-align: middle;
    border: 0;
    border-right: 1px solid;
    border-bottom: 1px solid;
  }
  #saasbox_page_content .dropdown-toggle::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #saasbox_page_content .dropup .dropdown-toggle::after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  #saasbox_page_content .dropdown-toggle.no-caret::after {
    display: none;
  }
  #saasbox_page_content .dropdown-menu {
    padding: 10px 16px;
    font-size: 14px;
    margin-top: 5px;
    border: 0;
    -webkit-box-shadow: 0 2px 25px rgba(0,0,0,0.07);
    box-shadow: 0 2px 25px rgba(0,0,0,0.07);
  }
  #saasbox_page_content .dropdown-menu *:last-child {
    margin-bottom: 0;
  }
  #saasbox_page_content .dropdown-item {
    padding-left: 0;
    padding-right: 0;
    color: #999;
    background-color: transparent;
    opacity: .8;
  }
  #saasbox_page_content .dropdown-item:hover {
    color: #757575;
    opacity: 1;
  }
  #saasbox_page_content .dropdown-item:active,
  #saasbox_page_content .dropdown-item:hover,
  #saasbox_page_content .dropdown-item:focus {
    background-color: transparent;
  }
  #saasbox_page_content .dropdown-header {
    padding-left: 0;
    padding-right: 0;
    letter-spacing: 1px;
    font-size: .8325rem;
    font-weight: 500;
    color: #757575;
    text-transform: uppercase;
  }
  #saasbox_page_content .dropdown-divider {
    background-color: #f1f2f3;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  #saasbox_page_content .open-on-hover:hover .dropdown-menu,
  #saasbox_page_content .open-on-hover:focus .dropdown-menu,
  #saasbox_page_content .open-on-hover.show .dropdown-menu {
    display: block;
    margin-top: 0;
  }
  #saasbox_page_content .gallery {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  #saasbox_page_content .gallery-item {
    position: relative;
    flex-basis: 50%;
    padding: .25rem;
    display: block;
  }
  #saasbox_page_content .gallery-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  #saasbox_page_content .gallery-item-overlay {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    top: .25rem;
    left: .25rem;
    right: .25rem;
    bottom: .25rem;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    font-size: 1.75rem;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .gallery-item-overlay:hover {
    background-color: rgba(0,0,0,0.45);
  }
  #saasbox_page_content .gallery.items-connected .gallery-item {
    padding: 0;
  }
  #saasbox_page_content .gallery.items-connected .gallery-item-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #saasbox_page_content .gallery-extra-items {
    display: none;
  }
  #saasbox_page_content .gallery-3-type1 .gallery-item {
    flex-basis: 33.33%;
  }
  #saasbox_page_content .gallery-3-type2 .gallery-item {
    flex-basis: 100%;
  }
  #saasbox_page_content .gallery-3-type2 .gallery-item:nth-child(n+2) {
    flex-basis: 50%;
  }
  #saasbox_page_content .gallery-3-type3 .gallery-item {
    flex-basis: 50%;
  }
  #saasbox_page_content .gallery-3-type3 .gallery-item:nth-child(n+3) {
    flex-basis: 100%;
  }
  #saasbox_page_content .gallery-3-type4>.gallery-item {
    flex-basis: 60%;
  }
  #saasbox_page_content .gallery-3-type4 .gallery-item-group {
    flex-basis: 40%;
  }
  #saasbox_page_content .gallery-3-type5>.gallery-item {
    flex-basis: 60%;
  }
  #saasbox_page_content .gallery-3-type5 .gallery-item-group {
    flex-basis: 40%;
  }
  #saasbox_page_content .gallery-4-type1 .gallery-item {
    flex-basis: 25%;
  }
  #saasbox_page_content .gallery-4-type2 .gallery-item {
    flex-basis: 100%;
  }
  #saasbox_page_content .gallery-4-type2 .gallery-item:nth-child(n+2) {
    flex-basis: 33.33%;
  }
  #saasbox_page_content .gallery-4-type3 .gallery-item {
    flex-basis: 33.33%;
  }
  #saasbox_page_content .gallery-4-type3 .gallery-item:nth-child(n+4) {
    flex-basis: 100%;
  }
  #saasbox_page_content .gallery-4-type4>.gallery-item {
    flex-basis: 70%;
  }
  #saasbox_page_content .gallery-4-type4 .gallery-item-group {
    flex-basis: 30%;
  }
  #saasbox_page_content .gallery-4-type5>.gallery-item {
    flex-basis: 70%;
  }
  #saasbox_page_content .gallery-4-type5 .gallery-item-group {
    flex-basis: 30%;
  }
  #saasbox_page_content .modal .close {
    font-weight: 100;
  }
  #saasbox_page_content .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
    display: -webkit-box;
    display: flex;
    height: 100%;
  }
  #saasbox_page_content .modal-content {
    margin-top: auto;
    margin-bottom: auto;
    border: 0;
  }
  #saasbox_page_content .modal-header,
  #saasbox_page_content .modal-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #saasbox_page_content .modal-body>.close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .5rem .75rem;
    z-index: 1;
  }
  #saasbox_page_content .modal.fade:not(.modal-top):not(.modal-bottom) .modal-dialog {
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
  }
  #saasbox_page_content .modal-top .modal-content {
    margin-top: 3rem;
  }
  #saasbox_page_content .modal-bottom.modal.fade .modal-dialog {
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    overflow: hidden;
  }
  #saasbox_page_content .modal-bottom.modal.fade .modal-dialog .modal-content {
    margin-bottom: 0;
    -webkit-transform: translate(0,100%);
    transform: translate(0,100%);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .modal-bottom.modal.show .modal-dialog .modal-content {
    margin-bottom: 3rem;
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .modal-long {
    height: auto;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  #saasbox_page_content .nav.flex-column .nav-link,
  #saasbox_page_content .nav.flex-column .menu-item a,
  #saasbox_page_content .menu-item .nav.flex-column a {
    padding: 2px 0;
  }
  #saasbox_page_content .nav.flex-column .nav-link:first-child,
  #saasbox_page_content .nav.flex-column .menu-item a:first-child,
  #saasbox_page_content .menu-item .nav.flex-column a:first-child {
    padding-top: 0;
  }
  #saasbox_page_content .nav-link,
  #saasbox_page_content .menu-item a {
    color: #757575;
    font-weight: 400;
  }
  #saasbox_page_content .nav-link.active,
  #saasbox_page_content .menu-item a.active,
  #saasbox_page_content .nav-item.show .nav-link,
  #saasbox_page_content .show.menu-item .nav-link,
  #saasbox_page_content .nav-item.show .menu-item a,
  #saasbox_page_content .menu-item .nav-item.show a,
  #saasbox_page_content .show.menu-item a {
    color: #50a1ff;
  }
  #saasbox_page_content .nav-divider {
    display: block;
    margin: .5rem 1.125rem;
    height: 1px;
    background-color: rgba(127,127,127,0.1);
  }
  #saasbox_page_content .nav-uppercase>.nav-link,
  #saasbox_page_content .menu-item .nav-uppercase>a,
  #saasbox_page_content .nav-uppercase>.nav-item>.nav-link,
  #saasbox_page_content .nav-uppercase>.menu-item>.nav-link,
  #saasbox_page_content .menu-item .nav-uppercase>.nav-item>a,
  #saasbox_page_content .nav-uppercase>.menu-item>a {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .85em;
    word-spacing: 2px;
  }
  #saasbox_page_content .nav-bold .nav-item,
  #saasbox_page_content .nav-bold .menu-item {
    font-weight: 600;
  }
  #saasbox_page_content .nav-bold .nav-link,
  #saasbox_page_content .nav-bold .menu-item a,
  #saasbox_page_content .menu-item .nav-bold a {
    font-weight: 600;
    letter-spacing: 1px;
    font-size: .85em;
    word-spacing: 2px;
  }
  #saasbox_page_content .nav-bolder .nav-item,
  #saasbox_page_content .nav-bolder .menu-item {
    font-weight: 700;
  }
  #saasbox_page_content .nav-bolder .nav-link,
  #saasbox_page_content .nav-bolder .menu-item a,
  #saasbox_page_content .menu-item .nav-bolder a {
    font-weight: 700;
    letter-spacing: 1px;
    font-size: .8em;
    word-spacing: 2px;
  }
  #saasbox_page_content .nav-lead .nav-link,
  #saasbox_page_content .nav-lead .menu-item a,
  #saasbox_page_content .menu-item .nav-lead a {
    font-size: 1rem;
  }
  #saasbox_page_content .nav-pills .nav-link,
  #saasbox_page_content .nav-pills .menu-item a,
  #saasbox_page_content .menu-item .nav-pills a,
  #saasbox_page_content .nav-pills.flex-column .nav-link,
  #saasbox_page_content .nav-pills.flex-column .menu-item a,
  #saasbox_page_content .menu-item .nav-pills.flex-column a {
    padding: 3px 1rem;
  }
  #saasbox_page_content .nav-slash .nav-item,
  #saasbox_page_content .nav-slash .menu-item {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
  }
  #saasbox_page_content .nav-slash .nav-item::after,
  #saasbox_page_content .nav-slash .menu-item::after {
    content: '/';
    font-weight: 400;
    opacity: .6;
  }
  #saasbox_page_content .nav-slash .nav-item:last-child::after,
  #saasbox_page_content .nav-slash .menu-item:last-child::after {
    display: none;
  }
  #saasbox_page_content .nav-dot .nav-item,
  #saasbox_page_content .nav-dot .menu-item {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
  }
  #saasbox_page_content .nav-dot .nav-item::after,
  #saasbox_page_content .nav-dot .menu-item::after {
    content: "\2022";
    font-weight: 100;
    opacity: .75;
  }
  #saasbox_page_content .nav-dot .nav-item:last-child::after,
  #saasbox_page_content .nav-dot .menu-item:last-child::after {
    display: none;
  }
  #saasbox_page_content .nav-iconic .nav-link,
  #saasbox_page_content .nav-iconic .menu-item a,
  #saasbox_page_content .menu-item .nav-iconic a {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }
  #saasbox_page_content .nav-iconic .nav-link i,
  #saasbox_page_content .nav-iconic .menu-item a i,
  #saasbox_page_content .menu-item .nav-iconic a i {
    margin-bottom: 1rem;
  }
  #saasbox_page_content .nav-sidebar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    font-weight: 400;
  }
  #saasbox_page_content .nav-sidebar .nav-item,
  #saasbox_page_content .nav-sidebar .menu-item {
    margin: 4px 0;
    padding-right: 5px;
    overflow: hidden;
    flex-shrink: 0;
  }
  #saasbox_page_content .nav-sidebar .nav-item>.nav-link,
  #saasbox_page_content .nav-sidebar .menu-item>.nav-link,
  #saasbox_page_content .nav-sidebar .menu-item .nav-item>a,
  #saasbox_page_content .menu-item .nav-sidebar .nav-item>a,
  #saasbox_page_content .nav-sidebar .menu-item>a {
    font-weight: 500;
  }
  #saasbox_page_content .nav-sidebar .nav-link,
  #saasbox_page_content .nav-sidebar .menu-item a,
  #saasbox_page_content .menu-item .nav-sidebar a {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 3px 0;
    line-height: 1.5;
  }
  #saasbox_page_content .nav-sidebar .nav-angle {
    font-family: themify;
    font-style: normal;
    font-size: 8px;
    display: inline-block;
    line-height: 24px;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    color: #757575;
  }
  #saasbox_page_content .nav-sidebar .nav-angle::before {
    content: "\e649";
  }
  #saasbox_page_content .nav-sidebar .nav-angle.rotate {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  #saasbox_page_content .nav-sidebar .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    font-size: .875rem;
    margin-left: 1rem;
    margin-top: .5rem;
    flex-wrap: nowrap;
    display: none;
  }
  #saasbox_page_content .nav-sidebar .nav .nav {
    display: -webkit-box;
    display: flex;
    font-size: 95%;
    margin-left: .75rem;
    margin-top: 0;
  }
  #saasbox_page_content .nav-sidebar .nav .nav .nav-item,
  #saasbox_page_content .nav-sidebar .nav .nav .menu-item {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  #saasbox_page_content .nav-sidebar .nav .nav-link,
  #saasbox_page_content .nav-sidebar .nav .menu-item a,
  #saasbox_page_content .menu-item .nav-sidebar .nav a {
    flex-shrink: 0;
  }
  #saasbox_page_content .nav-sidebar .show>.nav-link .nav-angle,
  #saasbox_page_content .nav-sidebar .menu-item .show>a .nav-angle,
  #saasbox_page_content .menu-item .nav-sidebar .show>a .nav-angle {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  #saasbox_page_content .nav-sidebar .show>.nav {
    display: block;
  }
  #saasbox_page_content .nav-sidebar .nav-link.active,
  #saasbox_page_content .nav-sidebar .menu-item a.active,
  #saasbox_page_content .menu-item .nav-sidebar a.active {
    color: #50a1ff;
  }
  #saasbox_page_content .nav-sidebar .nav-link.active+.nav,
  #saasbox_page_content .nav-sidebar .menu-item a.active+.nav,
  #saasbox_page_content .menu-item .nav-sidebar a.active+.nav {
    display: block;
  }
  #saasbox_page_content .nav-sidebar .nav-item>.nav-link.active,
  #saasbox_page_content .nav-sidebar .menu-item>.nav-link.active,
  #saasbox_page_content .nav-sidebar .menu-item .nav-item>a.active,
  #saasbox_page_content .menu-item .nav-sidebar .nav-item>a.active,
  #saasbox_page_content .nav-sidebar .menu-item>a.active {
    color: #757575;
    -webkit-transition: none;
    transition: none;
  }
  #saasbox_page_content .nav-sidebar a {
    color: rgba(0,0,0,0.6);
  }
  #saasbox_page_content .nav-sidebar a:hover {
    color: rgba(0,0,0,0.8);
  }
  #saasbox_page_content .nav-sidebar .nav-header {
    color: #323d47;
    font-weight: 500;
    letter-spacing: .5px;
    margin-bottom: .5rem;
  }
  #saasbox_page_content .nav-sidebar-hero>.nav-item,
  #saasbox_page_content .nav-sidebar-hero>.menu-item {
    border-bottom: 1px dashed #eaeff4;
    padding-top: 2px;
    padding-bottom: 6px;
  }
  #saasbox_page_content .nav-sidebar-hero>.nav-item:last-child,
  #saasbox_page_content .nav-sidebar-hero>.menu-item:last-child {
    border-bottom: 0;
  }
  #saasbox_page_content .nav-sidebar-hero>.nav-item>.nav-link,
  #saasbox_page_content .nav-sidebar-hero>.menu-item>.nav-link,
  #saasbox_page_content .menu-item .nav-sidebar-hero>.nav-item>a,
  #saasbox_page_content .nav-sidebar-hero>.menu-item>a {
    font-size: 1.125rem;
    font-weight: 400;
    padding: 8px 0;
  }
  #saasbox_page_content .nav-sidebar-hero .nav-item>.nav-link,
  #saasbox_page_content .nav-sidebar-hero .menu-item>.nav-link,
  #saasbox_page_content .nav-sidebar-hero .menu-item .nav-item>a,
  #saasbox_page_content .menu-item .nav-sidebar-hero .nav-item>a,
  #saasbox_page_content .nav-sidebar-hero .menu-item>a {
    font-weight: 400;
  }
  #saasbox_page_content .nav-sidebar-hero .nav {
    font-size: 1rem;
  }
  #saasbox_page_content .nav-sidebar-hero .nav-link.active,
  #saasbox_page_content .nav-sidebar-hero .menu-item a.active,
  #saasbox_page_content .menu-item .nav-sidebar-hero a.active {
    font-weight: 500;
  }
  #saasbox_page_content .nav-sidebar-pill .nav-item>.nav-link.active,
  #saasbox_page_content .nav-sidebar-pill .menu-item>.nav-link.active,
  #saasbox_page_content .nav-sidebar-pill .menu-item .nav-item>a.active,
  #saasbox_page_content .menu-item .nav-sidebar-pill .nav-item>a.active,
  #saasbox_page_content .nav-sidebar-pill .menu-item>a.active {
    color: #50a1ff;
  }
  #saasbox_page_content .nav-sidebar-pill .nav-item>.nav-link.active>.nav-angle,
  #saasbox_page_content .nav-sidebar-pill .menu-item>.nav-link.active>.nav-angle,
  #saasbox_page_content .nav-sidebar-pill .menu-item .nav-item>a.active>.nav-angle,
  #saasbox_page_content .menu-item .nav-sidebar-pill .nav-item>a.active>.nav-angle,
  #saasbox_page_content .nav-sidebar-pill .menu-item>a.active>.nav-angle {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  #saasbox_page_content .nav-sidebar-pill .nav {
    margin-left: .5rem;
  }
  #saasbox_page_content .nav-sidebar-pill .nav .nav-link,
  #saasbox_page_content .nav-sidebar-pill .nav .menu-item a,
  #saasbox_page_content .menu-item .nav-sidebar-pill .nav a {
    display: table;
    border-radius: 10rem;
    padding: 6px 16px;
  }
  #saasbox_page_content .nav-sidebar-pill .nav .nav-link.active,
  #saasbox_page_content .nav-sidebar-pill .nav .menu-item a.active,
  #saasbox_page_content .menu-item .nav-sidebar-pill .nav a.active {
    background-color: rgba(80,161,255,0.1);
  }
  #saasbox_page_content .nav-page {
    display: block;
    position: fixed;
    top: 50%;
    right: 20px;
    width: 34px;
    border-radius: 10rem;
    padding: 7px 0;
    text-align: center;
    background-color: #191919;
    opacity: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: .5s ease-out;
    transition: .5s ease-out;
  }
  #saasbox_page_content .body-scrolled .nav-page {
    opacity: .5;
  }
  #saasbox_page_content .nav-page:hover {
    opacity: .65;
  }
  #saasbox_page_content .nav-page.nav-page-left {
    right: auto;
    left: 20px;
  }
  #saasbox_page_content .nav-page .nav-item,
  #saasbox_page_content .nav-page .menu-item {
    position: relative;
    display: block;
    padding: 0;
  }
  #saasbox_page_content .nav-page .nav-link,
  #saasbox_page_content .nav-page .menu-item a,
  #saasbox_page_content .menu-item .nav-page a {
    display: inline-block;
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 4px;
    background-color: #fff;
    opacity: .5;
  }
  #saasbox_page_content .nav-page .nav-link:hover,
  #saasbox_page_content .nav-page .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-page a:hover,
  #saasbox_page_content .nav-page .nav-link.active,
  #saasbox_page_content .nav-page .menu-item a.active,
  #saasbox_page_content .menu-item .nav-page a.active {
    opacity: 1;
  }
  #saasbox_page_content .nav-page .nav-link::before,
  #saasbox_page_content .nav-page .menu-item a::before,
  #saasbox_page_content .menu-item .nav-page a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  #saasbox_page_content .nav-dark .nav-link,
  #saasbox_page_content .nav-dark .menu-item a,
  #saasbox_page_content .menu-item .nav-dark a {
    color: rgba(117,117,117,0.65);
  }
  #saasbox_page_content .nav-dark .nav-link:hover,
  #saasbox_page_content .nav-dark .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-dark a:hover,
  #saasbox_page_content .nav-dark .nav-link.active,
  #saasbox_page_content .nav-dark .menu-item a.active,
  #saasbox_page_content .menu-item .nav-dark a.active,
  #saasbox_page_content .nav-dark .nav-item.show .nav-link,
  #saasbox_page_content .nav-dark .show.menu-item .nav-link,
  #saasbox_page_content .nav-dark .nav-item.show .menu-item a,
  #saasbox_page_content .menu-item .nav-dark .nav-item.show a,
  #saasbox_page_content .nav-dark .show.menu-item a {
    color: #323d47;
  }
  #saasbox_page_content .nav-dark .nav-link.disabled,
  #saasbox_page_content .nav-dark .menu-item a.disabled,
  #saasbox_page_content .menu-item .nav-dark a.disabled {
    color: #a5b3c7;
  }
  #saasbox_page_content .nav-light .nav-link,
  #saasbox_page_content .nav-light .menu-item a,
  #saasbox_page_content .menu-item .nav-light a {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .nav-light .nav-link:hover,
  #saasbox_page_content .nav-light .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-light a:hover,
  #saasbox_page_content .nav-light .nav-link.active,
  #saasbox_page_content .nav-light .menu-item a.active,
  #saasbox_page_content .menu-item .nav-light a.active,
  #saasbox_page_content .nav-light .nav-item.show .nav-link,
  #saasbox_page_content .nav-light .show.menu-item .nav-link,
  #saasbox_page_content .nav-light .nav-item.show .menu-item a,
  #saasbox_page_content .menu-item .nav-light .nav-item.show a,
  #saasbox_page_content .nav-light .show.menu-item a {
    color: #fff;
  }
  #saasbox_page_content .nav-light .nav-link.disabled,
  #saasbox_page_content .nav-light .menu-item a.disabled,
  #saasbox_page_content .menu-item .nav-light a.disabled {
    color: rgba(255,255,255,0.5);
  }
  #saasbox_page_content .nav-light.nav-pills .nav-link.active,
  #saasbox_page_content .nav-light.nav-pills .menu-item a.active,
  #saasbox_page_content .menu-item .nav-light.nav-pills a.active,
  #saasbox_page_content .nav-light.nav-pills .show>.nav-link,
  #saasbox_page_content .nav-light.nav-pills .menu-item .show>a,
  #saasbox_page_content .menu-item .nav-light.nav-pills .show>a {
    background-color: rgba(255,255,255,0.25);
  }
  #saasbox_page_content .nav-trim .nav-link:first-child,
  #saasbox_page_content .nav-trim .menu-item a:first-child,
  #saasbox_page_content .menu-item .nav-trim a:first-child {
    padding-left: 0;
  }
  #saasbox_page_content .nav-trim .nav-link:last-child,
  #saasbox_page_content .nav-trim .menu-item a:last-child,
  #saasbox_page_content .menu-item .nav-trim a:last-child {
    padding-right: 0;
  }
  #saasbox_page_content .nav-transform-none .nav-link,
  #saasbox_page_content .nav-transform-none .menu-item a,
  #saasbox_page_content .menu-item .nav-transform-none a {
    text-transform: none !important;
  }
  #saasbox_page_content [data-overlay],
  #saasbox_page_content [data-overlay-light] {
    position: relative;
  }
  #saasbox_page_content [data-overlay]::before,
  #saasbox_page_content [data-overlay-light]::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #191919;
    border-radius: inherit;
  }
  #saasbox_page_content [data-overlay-light]::before {
    background: #fff;
  }
  #saasbox_page_content [data-overlay-primary]::before {
    background: #50a1ff;
  }
  #saasbox_page_content [data-overlay="1"]::before {
    opacity: .1;
  }
  #saasbox_page_content [data-overlay="2"]::before {
    opacity: .2;
  }
  #saasbox_page_content [data-overlay="3"]::before {
    opacity: .3;
  }
  #saasbox_page_content [data-overlay="4"]::before {
    opacity: .4;
  }
  #saasbox_page_content [data-overlay="5"]::before {
    opacity: .5;
  }
  #saasbox_page_content [data-overlay="6"]::before {
    opacity: .6;
  }
  #saasbox_page_content [data-overlay="7"]::before {
    opacity: .7;
  }
  #saasbox_page_content [data-overlay="8"]::before {
    opacity: .8;
  }
  #saasbox_page_content [data-overlay="9"]::before {
    opacity: .9;
  }
  #saasbox_page_content [data-overlay-light="1"]::before {
    opacity: .1;
  }
  #saasbox_page_content [data-overlay-light="2"]::before {
    opacity: .2;
  }
  #saasbox_page_content [data-overlay-light="3"]::before {
    opacity: .3;
  }
  #saasbox_page_content [data-overlay-light="4"]::before {
    opacity: .4;
  }
  #saasbox_page_content [data-overlay-light="5"]::before {
    opacity: .5;
  }
  #saasbox_page_content [data-overlay-light="6"]::before {
    opacity: .6;
  }
  #saasbox_page_content [data-overlay-light="7"]::before {
    opacity: .7;
  }
  #saasbox_page_content [data-overlay-light="8"]::before {
    opacity: .8;
  }
  #saasbox_page_content [data-overlay-light="9"]::before {
    opacity: .9;
  }
  #saasbox_page_content [data-scrim-top],
  #saasbox_page_content [data-scrim-bottom] {
    position: relative;
  }
  #saasbox_page_content [data-scrim-top]::before,
  #saasbox_page_content [data-scrim-bottom]::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 20%;
    background: -webkit-gradient(linear,left top,left bottom,from(#191919),to(rgba(0,0,0,0)));
    background: -webkit-linear-gradient(top,#191919 0,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom,#191919 0,rgba(0,0,0,0) 100%);
    z-index: 0;
    border-radius: inherit;
  }
  #saasbox_page_content [data-scrim-bottom]::before {
    top: 20%;
    bottom: 0;
    background: -webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,0)),to(#191919));
    background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0,#191919 100%);
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,#191919 100%);
  }
  #saasbox_page_content [data-scrim-top="1"]::before,
  #saasbox_page_content [data-scrim-bottom="1"]::before {
    opacity: .1;
  }
  #saasbox_page_content [data-scrim-top="2"]::before,
  #saasbox_page_content [data-scrim-bottom="2"]::before {
    opacity: .2;
  }
  #saasbox_page_content [data-scrim-top="3"]::before,
  #saasbox_page_content [data-scrim-bottom="3"]::before {
    opacity: .3;
  }
  #saasbox_page_content [data-scrim-top="4"]::before,
  #saasbox_page_content [data-scrim-bottom="4"]::before {
    opacity: .4;
  }
  #saasbox_page_content [data-scrim-top="5"]::before,
  #saasbox_page_content [data-scrim-bottom="5"]::before {
    opacity: .5;
  }
  #saasbox_page_content [data-scrim-top="6"]::before,
  #saasbox_page_content [data-scrim-bottom="6"]::before {
    opacity: .6;
  }
  #saasbox_page_content [data-scrim-top="7"]::before,
  #saasbox_page_content [data-scrim-bottom="7"]::before {
    opacity: .7;
  }
  #saasbox_page_content [data-scrim-top="8"]::before,
  #saasbox_page_content [data-scrim-bottom="8"]::before {
    opacity: .8;
  }
  #saasbox_page_content [data-scrim-top="9"]::before,
  #saasbox_page_content [data-scrim-bottom="9"]::before {
    opacity: .9;
  }
  #saasbox_page_content [data-scrim-top="0"]::before,
  #saasbox_page_content [data-scrim-bottom="0"]::before {
    opacity: 0;
  }
  #saasbox_page_content .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    border-radius: inherit;
  }
  #saasbox_page_content .page-link {
    margin: 0 3px;
    padding: 0;
    text-align: center;
    min-width: 32px;
  }
  #saasbox_page_content .page-link,
  #saasbox_page_content .page-item:last-child .page-link,
  #saasbox_page_content .page-item:first-child .page-link {
    border-radius: 2px;
  }
  #saasbox_page_content .page-item.disabled {
    opacity: .6;
  }
  #saasbox_page_content .popover {
    -webkit-box-shadow: 0 1px 15px rgba(0,0,0,0.05);
    box-shadow: 0 1px 15px rgba(0,0,0,0.05);
  }
  #saasbox_page_content .timeline {
    position: relative;
    list-style: none;
    padding: 1rem 0;
    margin: 0;
  }
  #saasbox_page_content .timeline::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    margin-left: -1px;
    background-color: #50a1ff;
  }
  #saasbox_page_content .timeline-item {
    position: relative;
    width: 50%;
    padding: 1rem 0;
    padding-right: 2.5rem;
    text-align: right;
  }
  #saasbox_page_content .timeline-item::before {
    content: '';
    position: absolute;
    right: -8px;
    top: 1.35rem;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #50a1ff;
    background-color: #fff;
  }
  #saasbox_page_content .timeline-item:nth-child(even) {
    margin-left: 50%;
    padding-left: 2.5rem;
    padding-right: 0;
    text-align: left;
  }
  #saasbox_page_content .timeline-item:nth-child(even)::before {
    right: auto;
    left: -8px;
  }
  #saasbox_page_content .timeline-horizontal {
    display: -webkit-box;
    display: flex;
  }
  #saasbox_page_content .timeline-horizontal::before {
    left: 0;
    bottom: auto;
    right: 0;
    width: 100%;
    height: 2px;
  }
  #saasbox_page_content .timeline-horizontal .timeline-item {
    padding: 1.5rem 1rem;
    text-align: left;
  }
  #saasbox_page_content .timeline-horizontal .timeline-item::before {
    top: -1.5rem;
    left: .75rem;
  }
  #saasbox_page_content .timeline-horizontal .timeline-item:nth-child(even) {
    margin-left: 0;
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .timeline::before {
      left: 8px;
    }
    #saasbox_page_content .timeline-item {
      width: 100%;
      text-align: left;
      padding-left: 2.5rem;
      padding-right: 0;
    }
    #saasbox_page_content .timeline-item::before {
      top: 1.25rem;
      left: 1px;
    }
    #saasbox_page_content .timeline-item:nth-child(even) {
      margin-left: 0rem;
    }
    #saasbox_page_content .timeline-item:nth-child(even)::before {
      left: 1px;
    }
    #saasbox_page_content .timeline-horizontal {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
    }
    #saasbox_page_content .timeline-horizontal::before {
      right: auto;
      bottom: 0;
      width: 2px;
      height: 100%;
    }
    #saasbox_page_content .timeline-horizontal .timeline-item {
      padding: 1rem 0;
      padding-left: 2.5rem;
    }
    #saasbox_page_content .timeline-horizontal .timeline-item::before {
      top: 1.25rem;
      left: 1px;
    }
  }
  #saasbox_page_content .step {
    list-style: none;
    margin: 0;
  }
  #saasbox_page_content .step-item {
    display: -webkit-box;
    display: flex;
    padding: 1rem 0;
  }
  #saasbox_page_content .step-icon {
    position: relative;
    width: 7rem;
    flex-shrink: 0;
    text-align: center;
  }
  #saasbox_page_content .step-icon .iconbox {
    color: #bfc5ca;
    background-color: #eaeff4;
    font-size: 1.5rem;
  }
  #saasbox_page_content .step-icon::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 48px;
    bottom: -2rem;
    margin-left: -1px;
    border-left: 2px dashed #eaeff4;
  }
  #saasbox_page_content .step-content {
    margin-left: 1rem;
  }
  #saasbox_page_content .step .step-item:last-child .step-icon::before {
    bottom: 1rem;
  }
  #saasbox_page_content .scroll-down-1 span {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 5px;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: scrollDown1 2s infinite;
    animation: scrollDown1 2s infinite;
  }
  @-webkit-keyframes scrollDown1 {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(-45deg) translate(0,0);
      transform: rotate(-45deg) translate(0,0);
    }
    #saasbox_page_content 20% {
      -webkit-transform: rotate(-45deg) translate(-16px,16px);
      transform: rotate(-45deg) translate(-16px,16px);
    }
    #saasbox_page_content 40% {
      -webkit-transform: rotate(-45deg) translate(0,0);
      transform: rotate(-45deg) translate(0,0);
    }
  }
  @keyframes scrollDown1 {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(-45deg) translate(0,0);
      transform: rotate(-45deg) translate(0,0);
    }
    #saasbox_page_content 20% {
      -webkit-transform: rotate(-45deg) translate(-16px,16px);
      transform: rotate(-45deg) translate(-16px,16px);
    }
    #saasbox_page_content 40% {
      -webkit-transform: rotate(-45deg) translate(0,0);
      transform: rotate(-45deg) translate(0,0);
    }
  }
  #saasbox_page_content .scroll-down-2 span {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 5px;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: scrollDown2 2s infinite;
    animation: scrollDown2 2s infinite;
  }
  @-webkit-keyframes scrollDown2 {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(-45deg) translate(0,0);
      transform: rotate(-45deg) translate(0,0);
      opacity: 0;
    }
    #saasbox_page_content 50% {
      opacity: 1;
    }
    #saasbox_page_content 100% {
      -webkit-transform: rotate(-45deg) translate(-20px,20px);
      transform: rotate(-45deg) translate(-20px,20px);
      opacity: 0;
    }
  }
  @keyframes scrollDown2 {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(-45deg) translate(0,0);
      transform: rotate(-45deg) translate(0,0);
      opacity: 0;
    }
    #saasbox_page_content 50% {
      opacity: 1;
    }
    #saasbox_page_content 100% {
      -webkit-transform: rotate(-45deg) translate(-20px,20px);
      transform: rotate(-45deg) translate(-20px,20px);
      opacity: 0;
    }
  }
  #saasbox_page_content .scroll-down-3 {
    position: relative;
  }
  #saasbox_page_content .scroll-down-3 span {
    position: absolute;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 5px;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: scrollDown3 2s infinite;
    animation: scrollDown3 2s infinite;
    opacity: 0;
  }
  #saasbox_page_content .scroll-down-3 span:nth-of-type(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  #saasbox_page_content .scroll-down-3 span:nth-of-type(2) {
    top: 16px;
    -webkit-animation-delay: .15s;
    animation-delay: .15s;
  }
  #saasbox_page_content .scroll-down-3 span:nth-of-type(3) {
    top: 32px;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
  }
  @-webkit-keyframes scrollDown3 {
    #saasbox_page_content 0% {
      opacity: 0;
    }
    #saasbox_page_content 50% {
      opacity: 1;
    }
    #saasbox_page_content 100% {
      opacity: 0;
    }
  }
  @keyframes scrollDown3 {
    #saasbox_page_content 0% {
      opacity: 0;
    }
    #saasbox_page_content 50% {
      opacity: 1;
    }
    #saasbox_page_content 100% {
      opacity: 0;
    }
  }
  #saasbox_page_content .scroll-down-4 span {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 50px;
    border: 1px solid #000;
    border-radius: 50px;
    opacity: .5;
  }
  #saasbox_page_content .scroll-down-4 span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #000;
    border-radius: 100%;
  }
  #saasbox_page_content .scroll-down-5 span {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 50px;
    border: 1px solid #000;
    border-radius: 50px;
    opacity: .5;
  }
  #saasbox_page_content .scroll-down-5 span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #000;
    border-radius: 100%;
    -webkit-animation: scrollDown5 2s infinite;
    animation: scrollDown5 2s infinite;
  }
  @-webkit-keyframes scrollDown5 {
    #saasbox_page_content 0% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
      opacity: 0;
    }
    #saasbox_page_content 40% {
      opacity: 1;
    }
    #saasbox_page_content 80% {
      -webkit-transform: translate(0,20px);
      transform: translate(0,20px);
      opacity: 0;
    }
    #saasbox_page_content 100% {
      opacity: 0;
    }
  }
  @keyframes scrollDown5 {
    #saasbox_page_content 0% {
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
      opacity: 0;
    }
    #saasbox_page_content 40% {
      opacity: 1;
    }
    #saasbox_page_content 80% {
      -webkit-transform: translate(0,20px);
      transform: translate(0,20px);
      opacity: 0;
    }
    #saasbox_page_content 100% {
      opacity: 0;
    }
  }
  #saasbox_page_content .scroll-down-white span {
    border-color: #fff;
  }
  #saasbox_page_content .scroll-down-white span::before {
    background-color: #fff;
  }
  #saasbox_page_content .scroll-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    font-weight: 300;
    opacity: 0;
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.1);
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    z-index: 1000;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }
  #saasbox_page_content .header-scrolled .scroll-top {
    opacity: .6;
  }
  #saasbox_page_content .scroll-top:hover {
    opacity: 1;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
  }
  #saasbox_page_content .scroll-top .fa {
    font-size: 24px;
    margin-bottom: 4px;
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .scroll-top {
      right: 15px;
      bottom: 5px;
      width: 34px;
      height: 34px;
      line-height: 34px;
    }
    #saasbox_page_content .scroll-top .fa {
      font-size: 15px;
    }
  }
  #saasbox_page_content .social a {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-right: 4px;
    margin-bottom: 4px;
    text-align: center;
    color: inherit;
    font-size: 1rem;
    border-radius: 3px;
    opacity: .8;
    -webkit-transition: .4s ease-out;
    transition: .4s ease-out;
  }
  #saasbox_page_content .social a:hover {
    opacity: 1;
  }
  #saasbox_page_content .social a:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .social a.social-facebook:hover {
    color: #3b5998;
  }
  #saasbox_page_content .social a.social-google:hover {
    color: #dd4b39;
  }
  #saasbox_page_content .social a.social-gplus:hover {
    color: #dd4b39;
  }
  #saasbox_page_content .social a.social-twitter:hover {
    color: #00aced;
  }
  #saasbox_page_content .social a.social-linkedin:hover {
    color: #007bb6;
  }
  #saasbox_page_content .social a.social-pinterest:hover {
    color: #cb2027;
  }
  #saasbox_page_content .social a.social-git:hover {
    color: #666;
  }
  #saasbox_page_content .social a.social-tumblr:hover {
    color: #32506d;
  }
  #saasbox_page_content .social a.social-vimeo:hover {
    color: #aad450;
  }
  #saasbox_page_content .social a.social-youtube:hover {
    color: #b00;
  }
  #saasbox_page_content .social a.social-flickr:hover {
    color: #ff0084;
  }
  #saasbox_page_content .social a.social-reddit:hover {
    color: #ff4500;
  }
  #saasbox_page_content .social a.social-dribbble:hover {
    color: #ea4c89;
  }
  #saasbox_page_content .social a.social-skype:hover {
    color: #00aff0;
  }
  #saasbox_page_content .social a.social-instagram:hover {
    color: #517fa4;
  }
  #saasbox_page_content .social a.social-lastfm:hover {
    color: #c3000d;
  }
  #saasbox_page_content .social a.social-soundcloud:hover {
    color: #f80;
  }
  #saasbox_page_content .social a.social-behance:hover {
    color: #1769ff;
  }
  #saasbox_page_content .social a.social-envato:hover {
    color: #82b541;
  }
  #saasbox_page_content .social a.social-medium:hover {
    color: #00ab6c;
  }
  #saasbox_page_content .social a.social-spotify:hover {
    color: #1db954;
  }
  #saasbox_page_content .social a.social-quora:hover {
    color: #a82400;
  }
  #saasbox_page_content .social a.social-xing:hover {
    color: #026466;
  }
  #saasbox_page_content .social a.social-snapchat:hover {
    color: #fffc00;
  }
  #saasbox_page_content .social a.social-telegram:hover {
    color: #08c;
  }
  #saasbox_page_content .social a.social-whatsapp:hover {
    color: #075e54;
  }
  #saasbox_page_content .social a.social-rss:hover {
    color: #f26522;
  }
  #saasbox_page_content .social-vertical {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  #saasbox_page_content .social-sm a {
    width: 28px;
    height: 28px;
    line-height: 28px;
    margin-right: 2px;
    margin-bottom: 2px;
    font-size: .8125rem;
  }
  #saasbox_page_content .social-lg a {
    width: 38px;
    height: 38px;
    line-height: 36px;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 1.25rem;
  }
  #saasbox_page_content .social-inline a {
    width: auto;
    height: auto;
    margin-right: 1rem;
  }
  #saasbox_page_content .social-brand a.social-facebook {
    color: #3b5998;
  }
  #saasbox_page_content .social-brand a.social-google {
    color: #dd4b39;
  }
  #saasbox_page_content .social-brand a.social-gplus {
    color: #dd4b39;
  }
  #saasbox_page_content .social-brand a.social-twitter {
    color: #00aced;
  }
  #saasbox_page_content .social-brand a.social-linkedin {
    color: #007bb6;
  }
  #saasbox_page_content .social-brand a.social-pinterest {
    color: #cb2027;
  }
  #saasbox_page_content .social-brand a.social-git {
    color: #666;
  }
  #saasbox_page_content .social-brand a.social-tumblr {
    color: #32506d;
  }
  #saasbox_page_content .social-brand a.social-vimeo {
    color: #aad450;
  }
  #saasbox_page_content .social-brand a.social-youtube {
    color: #b00;
  }
  #saasbox_page_content .social-brand a.social-flickr {
    color: #ff0084;
  }
  #saasbox_page_content .social-brand a.social-reddit {
    color: #ff4500;
  }
  #saasbox_page_content .social-brand a.social-dribbble {
    color: #ea4c89;
  }
  #saasbox_page_content .social-brand a.social-skype {
    color: #00aff0;
  }
  #saasbox_page_content .social-brand a.social-instagram {
    color: #517fa4;
  }
  #saasbox_page_content .social-brand a.social-lastfm {
    color: #c3000d;
  }
  #saasbox_page_content .social-brand a.social-soundcloud {
    color: #f80;
  }
  #saasbox_page_content .social-brand a.social-behance {
    color: #1769ff;
  }
  #saasbox_page_content .social-brand a.social-envato {
    color: #82b541;
  }
  #saasbox_page_content .social-brand a.social-medium {
    color: #00ab6c;
  }
  #saasbox_page_content .social-brand a.social-spotify {
    color: #1db954;
  }
  #saasbox_page_content .social-brand a.social-quora {
    color: #a82400;
  }
  #saasbox_page_content .social-brand a.social-xing {
    color: #026466;
  }
  #saasbox_page_content .social-brand a.social-snapchat {
    color: #fffc00;
  }
  #saasbox_page_content .social-brand a.social-telegram {
    color: #08c;
  }
  #saasbox_page_content .social-brand a.social-whatsapp {
    color: #075e54;
  }
  #saasbox_page_content .social-brand a.social-rss {
    color: #f26522;
  }
  #saasbox_page_content .social-gray a {
    color: #929daf;
  }
  #saasbox_page_content .social-gray a:hover {
    color: #757575 !important;
  }
  #saasbox_page_content .social-light a {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .social-light a:hover {
    color: #fff !important;
  }
  #saasbox_page_content .social-bg-brand a {
    color: #fff;
    opacity: 1;
  }
  #saasbox_page_content .social-bg-brand a:hover {
    color: #fff !important;
  }
  #saasbox_page_content .social-bg-brand .social-facebook {
    background-color: #3b5998;
  }
  #saasbox_page_content .social-bg-brand .social-google {
    background-color: #dd4b39;
  }
  #saasbox_page_content .social-bg-brand .social-gplus {
    background-color: #dd4b39;
  }
  #saasbox_page_content .social-bg-brand .social-twitter {
    background-color: #00aced;
  }
  #saasbox_page_content .social-bg-brand .social-linkedin {
    background-color: #007bb6;
  }
  #saasbox_page_content .social-bg-brand .social-pinterest {
    background-color: #cb2027;
  }
  #saasbox_page_content .social-bg-brand .social-git {
    background-color: #666;
  }
  #saasbox_page_content .social-bg-brand .social-tumblr {
    background-color: #32506d;
  }
  #saasbox_page_content .social-bg-brand .social-vimeo {
    background-color: #aad450;
  }
  #saasbox_page_content .social-bg-brand .social-youtube {
    background-color: #b00;
  }
  #saasbox_page_content .social-bg-brand .social-flickr {
    background-color: #ff0084;
  }
  #saasbox_page_content .social-bg-brand .social-reddit {
    background-color: #ff4500;
  }
  #saasbox_page_content .social-bg-brand .social-dribbble {
    background-color: #ea4c89;
  }
  #saasbox_page_content .social-bg-brand .social-skype {
    background-color: #00aff0;
  }
  #saasbox_page_content .social-bg-brand .social-instagram {
    background-color: #517fa4;
  }
  #saasbox_page_content .social-bg-brand .social-lastfm {
    background-color: #c3000d;
  }
  #saasbox_page_content .social-bg-brand .social-soundcloud {
    background-color: #f80;
  }
  #saasbox_page_content .social-bg-brand .social-behance {
    background-color: #1769ff;
  }
  #saasbox_page_content .social-bg-brand .social-envato {
    background-color: #82b541;
  }
  #saasbox_page_content .social-bg-brand .social-medium {
    background-color: #00ab6c;
  }
  #saasbox_page_content .social-bg-brand .social-spotify {
    background-color: #1db954;
  }
  #saasbox_page_content .social-bg-brand .social-quora {
    background-color: #a82400;
  }
  #saasbox_page_content .social-bg-brand .social-xing {
    background-color: #026466;
  }
  #saasbox_page_content .social-bg-brand .social-snapchat {
    background-color: #fffc00;
  }
  #saasbox_page_content .social-bg-brand .social-telegram {
    background-color: #08c;
  }
  #saasbox_page_content .social-bg-brand .social-whatsapp {
    background-color: #075e54;
  }
  #saasbox_page_content .social-bg-brand .social-rss {
    background-color: #f26522;
  }
  #saasbox_page_content .social-bg-pale-brand a {
    opacity: 1;
  }
  #saasbox_page_content .social-bg-pale-brand .social-facebook {
    color: #3b5998;
    background-color: #e7ebf3;
  }
  #saasbox_page_content .social-bg-pale-brand .social-facebook:hover {
    color: #3b5998 !important;
    background-color: #d8deea !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-google {
    color: #dd4b39;
    background-color: #fbe9e7;
  }
  #saasbox_page_content .social-bg-pale-brand .social-google:hover {
    color: #dd4b39 !important;
    background-color: #f8dbd7 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-gplus {
    color: #dd4b39;
    background-color: #fbe9e7;
  }
  #saasbox_page_content .social-bg-pale-brand .social-gplus:hover {
    color: #dd4b39 !important;
    background-color: #f8dbd7 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-twitter {
    color: #00aced;
    background-color: #e0f5fd;
  }
  #saasbox_page_content .social-bg-pale-brand .social-twitter:hover {
    color: #00aced !important;
    background-color: #cceefb !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-linkedin {
    color: #007bb6;
    background-color: #e0eff6;
  }
  #saasbox_page_content .social-bg-pale-brand .social-linkedin:hover {
    color: #007bb6 !important;
    background-color: #cce5f0 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-pinterest {
    color: #cb2027;
    background-color: #f9e4e5;
  }
  #saasbox_page_content .social-bg-pale-brand .social-pinterest:hover {
    color: #cb2027 !important;
    background-color: #f5d2d4 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-git {
    color: #666;
    background-color: #ededed;
  }
  #saasbox_page_content .social-bg-pale-brand .social-git:hover {
    color: #666 !important;
    background-color: #e0e0e0 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-tumblr {
    color: #32506d;
    background-color: #e6eaed;
  }
  #saasbox_page_content .social-bg-pale-brand .social-tumblr:hover {
    color: #32506d !important;
    background-color: #d6dce2 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-vimeo {
    color: #aad450;
    background-color: #f5faea;
  }
  #saasbox_page_content .social-bg-pale-brand .social-vimeo:hover {
    color: #aad450 !important;
    background-color: #eef6dc !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-youtube {
    color: #b00;
    background-color: #f7e0e0;
  }
  #saasbox_page_content .social-bg-pale-brand .social-youtube:hover {
    color: #b00 !important;
    background-color: #f1cccc !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-flickr {
    color: #ff0084;
    background-color: #ffe0f0;
  }
  #saasbox_page_content .social-bg-pale-brand .social-flickr:hover {
    color: #ff0084 !important;
    background-color: #ffcce6 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-reddit {
    color: #ff4500;
    background-color: #ffe9e0;
  }
  #saasbox_page_content .social-bg-pale-brand .social-reddit:hover {
    color: #ff4500 !important;
    background-color: #ffdacc !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-dribbble {
    color: #ea4c89;
    background-color: #fceaf1;
  }
  #saasbox_page_content .social-bg-pale-brand .social-dribbble:hover {
    color: #ea4c89 !important;
    background-color: #fbdbe7 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-skype {
    color: #00aff0;
    background-color: #e0f5fd;
  }
  #saasbox_page_content .social-bg-pale-brand .social-skype:hover {
    color: #00aff0 !important;
    background-color: #cceffc !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-instagram {
    color: #517fa4;
    background-color: #eaf0f4;
  }
  #saasbox_page_content .social-bg-pale-brand .social-instagram:hover {
    color: #517fa4 !important;
    background-color: #dce5ed !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-lastfm {
    color: #c3000d;
    background-color: #f8e0e2;
  }
  #saasbox_page_content .social-bg-pale-brand .social-lastfm:hover {
    color: #c3000d !important;
    background-color: #f3cccf !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-soundcloud {
    color: #f80;
    background-color: #fff1e0;
  }
  #saasbox_page_content .social-bg-pale-brand .social-soundcloud:hover {
    color: #f80 !important;
    background-color: #ffe7cc !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-behance {
    color: #1769ff;
    background-color: #e3edff;
  }
  #saasbox_page_content .social-bg-pale-brand .social-behance:hover {
    color: #1769ff !important;
    background-color: #d1e1ff !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-envato {
    color: #82b541;
    background-color: #f0f6e8;
  }
  #saasbox_page_content .social-bg-pale-brand .social-envato:hover {
    color: #82b541 !important;
    background-color: #e6f0d9 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-medium {
    color: #00ab6c;
    background-color: #e0f5ed;
  }
  #saasbox_page_content .social-bg-pale-brand .social-medium:hover {
    color: #00ab6c !important;
    background-color: #cceee2 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-spotify {
    color: #1db954;
    background-color: #e4f7ea;
  }
  #saasbox_page_content .social-bg-pale-brand .social-spotify:hover {
    color: #1db954 !important;
    background-color: #d2f1dd !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-quora {
    color: #a82400;
    background-color: #f5e5e0;
  }
  #saasbox_page_content .social-bg-pale-brand .social-quora:hover {
    color: #a82400 !important;
    background-color: #eed3cc !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-xing {
    color: #026466;
    background-color: #e1eced;
  }
  #saasbox_page_content .social-bg-pale-brand .social-xing:hover {
    color: #026466 !important;
    background-color: #cce0e0 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-snapchat {
    color: #fffc00;
    background-color: lightyellow;
  }
  #saasbox_page_content .social-bg-pale-brand .social-snapchat:hover {
    color: #fffc00 !important;
    background-color: #fffecc !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-telegram {
    color: #08c;
    background-color: #e0f1f9;
  }
  #saasbox_page_content .social-bg-pale-brand .social-telegram:hover {
    color: #08c !important;
    background-color: #cce7f5 !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-whatsapp {
    color: #075e54;
    background-color: #e1ecea;
  }
  #saasbox_page_content .social-bg-pale-brand .social-whatsapp:hover {
    color: #075e54 !important;
    background-color: #cddfdd !important;
  }
  #saasbox_page_content .social-bg-pale-brand .social-rss {
    color: #f26522;
    background-color: #fdede4;
  }
  #saasbox_page_content .social-bg-pale-brand .social-rss:hover {
    color: #f26522 !important;
    background-color: #fce0d3 !important;
  }
  #saasbox_page_content .social-bg-gray a {
    color: #929daf;
    border: 0;
    background-color: #fafbfb;
  }
  #saasbox_page_content .social-bg-light a {
    border: 0;
    background-color: #fff;
  }
  #saasbox_page_content .social-bg-dark a {
    border: 0;
    color: rgba(255,255,255,0.6);
    background-color: rgba(255,255,255,0.1);
  }
  #saasbox_page_content .social-bg-dark a:hover {
    color: rgba(255,255,255,0.8) !important;
    background-color: rgba(255,255,255,0.15) !important;
  }
  #saasbox_page_content .social-hover-primary a:hover {
    color: #50a1ff !important;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-facebook:hover {
    background-color: #3b5998;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-google:hover {
    background-color: #dd4b39;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-gplus:hover {
    background-color: #dd4b39;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-twitter:hover {
    background-color: #00aced;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-linkedin:hover {
    background-color: #007bb6;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-pinterest:hover {
    background-color: #cb2027;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-git:hover {
    background-color: #666;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-tumblr:hover {
    background-color: #32506d;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-vimeo:hover {
    background-color: #aad450;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-youtube:hover {
    background-color: #b00;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-flickr:hover {
    background-color: #ff0084;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-reddit:hover {
    background-color: #ff4500;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-dribbble:hover {
    background-color: #ea4c89;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-skype:hover {
    background-color: #00aff0;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-instagram:hover {
    background-color: #517fa4;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-lastfm:hover {
    background-color: #c3000d;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-soundcloud:hover {
    background-color: #f80;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-behance:hover {
    background-color: #1769ff;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-envato:hover {
    background-color: #82b541;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-medium:hover {
    background-color: #00ab6c;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-spotify:hover {
    background-color: #1db954;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-quora:hover {
    background-color: #a82400;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-xing:hover {
    background-color: #026466;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-snapchat:hover {
    background-color: #fffc00;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-telegram:hover {
    background-color: #08c;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-whatsapp:hover {
    background-color: #075e54;
    color: #fff;
  }
  #saasbox_page_content .social-hover-bg-brand a.social-rss:hover {
    background-color: #f26522;
    color: #fff;
  }
  #saasbox_page_content .social-rounded a {
    border-radius: 50%;
    border: 1px solid #f1f2f3;
  }
  #saasbox_page_content .social-rounded.social-gray a {
    border-color: #f5f6f7;
  }
  #saasbox_page_content .social-rounded.social-light a {
    border-color: rgba(255,255,255,0.5);
    font-size: .8125rem;
  }
  #saasbox_page_content .social-rounded.social-light.social-sm a {
    font-size: .6875rem;
  }
  #saasbox_page_content .social-rounded.social-light.social-lg a {
    font-size: .9375rem;
  }
  #saasbox_page_content .social-bordered a {
    border: 1px solid #eaeff4;
  }
  #saasbox_page_content .social-bordered.social-gray a {
    border-color: #f5f6f7;
  }
  #saasbox_page_content .social-bordered.social-light a {
    border-color: rgba(255,255,255,0.6);
  }
  #saasbox_page_content .social-cycling a:hover i {
    -webkit-animation: cycle .4s forwards;
    animation: cycle .4s forwards;
  }
  @-webkit-keyframes cycle {
    #saasbox_page_content 49% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    #saasbox_page_content 50% {
      opacity: 0;
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
    #saasbox_page_content 51% {
      opacity: 1;
    }
  }
  @keyframes cycle {
    #saasbox_page_content 49% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    #saasbox_page_content 50% {
      opacity: 0;
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
    }
    #saasbox_page_content 51% {
      opacity: 1;
    }
  }
  #saasbox_page_content .nav-tabs .nav-link,
  #saasbox_page_content .nav-tabs .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs a {
    border-top-width: 2px;
  }
  #saasbox_page_content .nav-tabs-minimal {
    border-bottom: 1px solid #f1f2f3;
  }
  #saasbox_page_content .nav-tabs-minimal .nav-link,
  #saasbox_page_content .nav-tabs-minimal .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-minimal a {
    border-bottom: 2px solid transparent;
  }
  #saasbox_page_content .nav-tabs-minimal .nav-link.active,
  #saasbox_page_content .nav-tabs-minimal .menu-item a.active,
  #saasbox_page_content .menu-item .nav-tabs-minimal a.active,
  #saasbox_page_content .nav-tabs-minimal .nav-item.show .nav-link,
  #saasbox_page_content .nav-tabs-minimal .show.menu-item .nav-link,
  #saasbox_page_content .nav-tabs-minimal .nav-item.show .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-minimal .nav-item.show a,
  #saasbox_page_content .nav-tabs-minimal .show.menu-item a {
    border-bottom-color: #50a1ff;
  }
  #saasbox_page_content .nav-tabs-outline {
    -webkit-box-pack: center;
    justify-content: center;
  }
  #saasbox_page_content .nav-tabs-outline .nav-link,
  #saasbox_page_content .nav-tabs-outline .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline a {
    padding: .25rem 1.25rem;
    border: 1px solid #191919;
    color: #191919;
    text-transform: uppercase;
    font-size: .8325rem;
    letter-spacing: 1px;
  }
  #saasbox_page_content .nav-tabs-outline .nav-link:hover,
  #saasbox_page_content .nav-tabs-outline .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-tabs-outline a:hover,
  #saasbox_page_content .nav-tabs-outline .nav-link.active,
  #saasbox_page_content .nav-tabs-outline .menu-item a.active,
  #saasbox_page_content .menu-item .nav-tabs-outline a.active {
    color: #fff;
    background-color: #191919;
  }
  #saasbox_page_content .nav-tabs-outline .nav-link.disabled,
  #saasbox_page_content .nav-tabs-outline .menu-item a.disabled,
  #saasbox_page_content .menu-item .nav-tabs-outline a.disabled {
    opacity: .5;
    cursor: default;
  }
  #saasbox_page_content .nav-tabs-outline .nav-link.disabled:hover,
  #saasbox_page_content .nav-tabs-outline .menu-item a.disabled:hover,
  #saasbox_page_content .menu-item .nav-tabs-outline a.disabled:hover {
    color: #191919;
    background-color: transparent;
  }
  #saasbox_page_content .nav-tabs-outline .nav-item,
  #saasbox_page_content .nav-tabs-outline .menu-item {
    min-width: 120px;
    text-align: center;
  }
  #saasbox_page_content .nav-tabs-outline .nav-item+.nav-item .nav-link,
  #saasbox_page_content .nav-tabs-outline .menu-item+.nav-item .nav-link,
  #saasbox_page_content .nav-tabs-outline .nav-item+.menu-item .nav-link,
  #saasbox_page_content .nav-tabs-outline .menu-item+.menu-item .nav-link,
  #saasbox_page_content .nav-tabs-outline .nav-item+.nav-item .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline .nav-item+.nav-item a,
  #saasbox_page_content .nav-tabs-outline .menu-item+.nav-item .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline .menu-item+.nav-item a,
  #saasbox_page_content .nav-tabs-outline .nav-item+.menu-item a,
  #saasbox_page_content .nav-tabs-outline .menu-item+.menu-item .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline .menu-item+.menu-item a {
    border-left-width: 0;
  }
  #saasbox_page_content .nav-tabs-outline .nav-item:first-child .nav-link,
  #saasbox_page_content .nav-tabs-outline .menu-item:first-child .nav-link,
  #saasbox_page_content .nav-tabs-outline .nav-item:first-child .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline .nav-item:first-child a,
  #saasbox_page_content .nav-tabs-outline .menu-item:first-child a {
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 10rem;
  }
  #saasbox_page_content .nav-tabs-outline .nav-item:last-child .nav-link,
  #saasbox_page_content .nav-tabs-outline .menu-item:last-child .nav-link,
  #saasbox_page_content .nav-tabs-outline .nav-item:last-child .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline .nav-item:last-child a,
  #saasbox_page_content .nav-tabs-outline .menu-item:last-child a {
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
  }
  #saasbox_page_content .nav-tabs-outline.nav-separated .nav-item,
  #saasbox_page_content .nav-tabs-outline.nav-separated .menu-item {
    min-width: 150px;
    padding: .25rem .5rem;
  }
  #saasbox_page_content .nav-tabs-outline.nav-separated .nav-item+.nav-item .nav-link,
  #saasbox_page_content .nav-tabs-outline.nav-separated .menu-item+.nav-item .nav-link,
  #saasbox_page_content .nav-tabs-outline.nav-separated .nav-item+.menu-item .nav-link,
  #saasbox_page_content .nav-tabs-outline.nav-separated .menu-item+.menu-item .nav-link,
  #saasbox_page_content .nav-tabs-outline.nav-separated .nav-item+.nav-item .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline.nav-separated .nav-item+.nav-item a,
  #saasbox_page_content .nav-tabs-outline.nav-separated .menu-item+.nav-item .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline.nav-separated .menu-item+.nav-item a,
  #saasbox_page_content .nav-tabs-outline.nav-separated .nav-item+.menu-item a,
  #saasbox_page_content .nav-tabs-outline.nav-separated .menu-item+.menu-item .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline.nav-separated .menu-item+.menu-item a {
    border-left-width: 1px;
  }
  #saasbox_page_content .nav-tabs-outline.nav-separated .nav-link,
  #saasbox_page_content .nav-tabs-outline.nav-separated .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-outline.nav-separated a {
    border-radius: 10rem;
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .nav-tabs-outline .nav-item,
    #saasbox_page_content .nav-tabs-outline .menu-item {
      min-width: 150px;
      padding: .25rem .5rem;
    }
    #saasbox_page_content .nav-tabs-outline .nav-item+.nav-item .nav-link,
    #saasbox_page_content .nav-tabs-outline .menu-item+.nav-item .nav-link,
    #saasbox_page_content .nav-tabs-outline .nav-item+.menu-item .nav-link,
    #saasbox_page_content .nav-tabs-outline .menu-item+.menu-item .nav-link,
    #saasbox_page_content .nav-tabs-outline .nav-item+.nav-item .menu-item a,
    #saasbox_page_content .menu-item .nav-tabs-outline .nav-item+.nav-item a,
    #saasbox_page_content .nav-tabs-outline .menu-item+.nav-item .menu-item a,
    #saasbox_page_content .menu-item .nav-tabs-outline .menu-item+.nav-item a,
    #saasbox_page_content .nav-tabs-outline .nav-item+.menu-item a,
    #saasbox_page_content .nav-tabs-outline .menu-item+.menu-item .menu-item a,
    #saasbox_page_content .menu-item .nav-tabs-outline .menu-item+.menu-item a {
      border-left-width: 1px;
    }
    #saasbox_page_content .nav-tabs-outline .nav-link,
    #saasbox_page_content .nav-tabs-outline .menu-item a,
    #saasbox_page_content .menu-item .nav-tabs-outline a {
      border-radius: 10rem;
    }
  }
  #saasbox_page_content .nav-tabs-border {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  #saasbox_page_content .nav-tabs-border .nav-link,
  #saasbox_page_content .nav-tabs-border .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-border a {
    border: 1px solid transparent;
    padding: 1.25rem 1.5rem;
    border-radius: 5px;
    opacity: .75;
  }
  #saasbox_page_content .nav-tabs-border .nav-link:hover,
  #saasbox_page_content .nav-tabs-border .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-tabs-border a:hover {
    opacity: 1;
    color: #757575;
  }
  #saasbox_page_content .nav-tabs-border .nav-link.active,
  #saasbox_page_content .nav-tabs-border .menu-item a.active,
  #saasbox_page_content .menu-item .nav-tabs-border a.active,
  #saasbox_page_content .nav-tabs-border .nav-item.show .nav-link,
  #saasbox_page_content .nav-tabs-border .show.menu-item .nav-link,
  #saasbox_page_content .nav-tabs-border .nav-item.show .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-border .nav-item.show a,
  #saasbox_page_content .nav-tabs-border .show.menu-item a {
    opacity: 1;
    color: #757575;
    border-color: #f1f2f3;
  }
  #saasbox_page_content .nav-tabs-border .nav-link.disabled,
  #saasbox_page_content .nav-tabs-border .menu-item a.disabled,
  #saasbox_page_content .menu-item .nav-tabs-border a.disabled {
    opacity: .6;
  }
  #saasbox_page_content .nav-tabs-gray {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  #saasbox_page_content .nav-tabs-gray .nav-link,
  #saasbox_page_content .nav-tabs-gray .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-gray a {
    padding: 1.5rem 1.75rem;
    border-radius: 5px;
    opacity: .75;
  }
  #saasbox_page_content .nav-tabs-gray .nav-link:hover,
  #saasbox_page_content .nav-tabs-gray .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-tabs-gray a:hover {
    opacity: 1;
    color: #757575;
  }
  #saasbox_page_content .nav-tabs-gray .nav-link.active,
  #saasbox_page_content .nav-tabs-gray .menu-item a.active,
  #saasbox_page_content .menu-item .nav-tabs-gray a.active,
  #saasbox_page_content .nav-tabs-gray .nav-item.show .nav-link,
  #saasbox_page_content .nav-tabs-gray .show.menu-item .nav-link,
  #saasbox_page_content .nav-tabs-gray .nav-item.show .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-gray .nav-item.show a,
  #saasbox_page_content .nav-tabs-gray .show.menu-item a {
    opacity: 1;
    color: #757575;
    background-color: #f9fafb;
  }
  #saasbox_page_content .nav-tabs-gray .nav-link.disabled,
  #saasbox_page_content .nav-tabs-gray .menu-item a.disabled,
  #saasbox_page_content .menu-item .nav-tabs-gray a.disabled {
    opacity: .6;
  }
  #saasbox_page_content .nav-tabs-shadow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  #saasbox_page_content .nav-tabs-shadow .nav-link,
  #saasbox_page_content .nav-tabs-shadow .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-shadow a {
    padding: 1.5rem 1.75rem;
    border-radius: 5px;
    opacity: .75;
  }
  #saasbox_page_content .nav-tabs-shadow .nav-link:hover,
  #saasbox_page_content .nav-tabs-shadow .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-tabs-shadow a:hover {
    opacity: 1;
    color: #757575;
  }
  #saasbox_page_content .nav-tabs-shadow .nav-link.active,
  #saasbox_page_content .nav-tabs-shadow .menu-item a.active,
  #saasbox_page_content .menu-item .nav-tabs-shadow a.active,
  #saasbox_page_content .nav-tabs-shadow .nav-item.show .nav-link,
  #saasbox_page_content .nav-tabs-shadow .show.menu-item .nav-link,
  #saasbox_page_content .nav-tabs-shadow .nav-item.show .menu-item a,
  #saasbox_page_content .menu-item .nav-tabs-shadow .nav-item.show a,
  #saasbox_page_content .nav-tabs-shadow .show.menu-item a {
    opacity: 1;
    color: #757575;
    background-color: #fff;
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.05);
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
  }
  #saasbox_page_content .nav-tabs-shadow .nav-link.disabled,
  #saasbox_page_content .nav-tabs-shadow .menu-item a.disabled,
  #saasbox_page_content .menu-item .nav-tabs-shadow a.disabled {
    opacity: .6;
  }
  #saasbox_page_content .rating {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  #saasbox_page_content .rating label {
    color: #ffba00;
    cursor: default;
  }
  #saasbox_page_content .rating label::before {
    margin-right: 5px;
    display: inline-block;
  }
  #saasbox_page_content .rating label.active {
    color: #ffba00;
  }
  #saasbox_page_content .rating label.empty {
    color: #ecf0f0;
  }
  #saasbox_page_content .rating-xs label::before {
    margin-right: 1px;
    font-size: 11px;
  }
  #saasbox_page_content .rating-sm label::before {
    margin-right: 2px;
    font-size: 13px;
  }
  #saasbox_page_content .rating-lg label::before {
    font-size: 18px;
  }
  #saasbox_page_content .custom-control {
    padding-left: 0;
    min-height: auto;
  }
  #saasbox_page_content .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  #saasbox_page_content .custom-control-input:disabled ~ .custom-control-label {
    opacity: .7;
  }
  #saasbox_page_content .custom-control-input:disabled ~ .custom-control-label::before,
  #saasbox_page_content .custom-control-input:disabled ~ .custom-control-label::after {
    opacity: .7;
  }
  #saasbox_page_content .custom-control-label {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 300;
  }
  #saasbox_page_content .custom-control-label::before {
    position: static;
    top: auto;
    border: 1px solid #eaeff4;
    margin-right: .5rem;
  }
  #saasbox_page_content .custom-control-label::after {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
  }
  #saasbox_page_content .custom-radio {
    cursor: pointer;
  }
  #saasbox_page_content .custom-radio .custom-control-label::after {
    top: 8px;
    left: .3125rem;
    border-radius: 50%;
    width: .5rem;
    height: .5rem;
    background-color: #50a1ff;
  }
  #saasbox_page_content .custom-checkbox {
    cursor: pointer;
  }
  #saasbox_page_content .custom-checkbox .custom-control-label::after {
    top: 50%;
    left: 6px;
    margin-top: -6px;
    width: 5px;
    height: 10px;
    border: solid #50a1ff;
    border-width: 0 1px 1px 0;
    -webkit-transform: scale(0) rotate(35deg);
    transform: scale(0) rotate(35deg);
  }
  #saasbox_page_content .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: scale(1) rotate(35deg);
    transform: scale(1) rotate(35deg);
  }
  #saasbox_page_content .switch {
    position: relative;
    min-height: 1.9rem;
  }
  #saasbox_page_content .switch-inline {
    display: -webkit-inline-box;
    display: inline-flex;
    margin-right: 1rem;
  }
  #saasbox_page_content .switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  #saasbox_page_content .switch-input:checked ~ .switch-label::before {
    border-color: #cee5ff;
  }
  #saasbox_page_content .switch-input:checked ~ .switch-label::after {
    background: #50a1ff;
    left: 24px;
  }
  #saasbox_page_content .switch-input:disabled ~ .switch-label {
    opacity: .5;
  }
  #saasbox_page_content .switch-label {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 300;
    margin-bottom: 0;
  }
  #saasbox_page_content .switch-label::before {
    content: '';
    width: 40px;
    height: 20px;
    background: transparent;
    border: 2px solid #f1f2f3;
    border-radius: 20px;
    margin-right: .5rem;
    -webkit-transition: .3s;
    transition: .3s;
  }
  #saasbox_page_content .switch-label::after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 4px;
    width: 12px;
    height: 12px;
    cursor: inherit;
    background: #e8e8e8;
    border-radius: 50%;
    -webkit-transition: .3s;
    transition: .3s;
  }
  #saasbox_page_content .switch-stacked .switch {
    display: inline;
  }
  #saasbox_page_content .switch-stacked .switch::after {
    display: block;
    margin-bottom: .25rem;
    content: "";
  }
  #saasbox_page_content .form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .wpcf7-text,
  #saasbox_page_content .wpcf7-date,
  #saasbox_page_content .wpcf7-quiz,
  #saasbox_page_content .wpcf7-number,
  #saasbox_page_content .wpcf7-select,
  #saasbox_page_content .wpcf7-textarea {
    font-weight: 300;
  }
  #saasbox_page_content .form-control option,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text option,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text option,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text option,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field option,
  #saasbox_page_content .wpcf7-text option,
  #saasbox_page_content .wpcf7-date option,
  #saasbox_page_content .wpcf7-quiz option,
  #saasbox_page_content .wpcf7-number option,
  #saasbox_page_content .wpcf7-select option,
  #saasbox_page_content .wpcf7-textarea option {
    font-weight: 300;
  }
  #saasbox_page_content label {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: .5px;
    margin-bottom: 4px;
  }
  #saasbox_page_content label.require::after {
    content: '*';
    color: #ff4954;
    font-weight: 500;
    margin-left: 8px;
  }
  #saasbox_page_content .form-row .btn-block {
    height: 100%;
  }
  #saasbox_page_content select:invalid {
    color: red;
  }
  #saasbox_page_content .file-group {
    position: relative;
    overflow: hidden;
  }
  #saasbox_page_content .file-group input[type="file"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    width: 20px;
  }
  #saasbox_page_content .file-group .file-value {
    background-color: #fff;
  }
  #saasbox_page_content .file-group-inline {
    display: inline-block;
  }
  #saasbox_page_content .custom-file input {
    cursor: pointer;
  }
  #saasbox_page_content .custom-file-label {
    font-size: .9375rem;
    color: #c9ccce;
    font-weight: 300;
  }
  #saasbox_page_content .custom-file-label::after {
    cursor: pointer;
    font-weight: 400;
  }
  #saasbox_page_content [data-form="mailer"] .d-on-success {
    display: none;
  }
  #saasbox_page_content .input-group {
    border: 1px solid #eaeff4;
    border-radius: 2px;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
  #saasbox_page_content .input-group.focus {
    border-color: #f1f2f3;
    -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.045);
    box-shadow: 0 0 40px rgba(0,0,0,0.045);
  }
  #saasbox_page_content .input-group .form-control,
  #saasbox_page_content .input-group #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group .input-text,
  #saasbox_page_content .input-group .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group .input-text,
  #saasbox_page_content .input-group .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group .input-text,
  #saasbox_page_content .input-group .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group .select2-search__field,
  #saasbox_page_content .input-group .wpcf7-text,
  #saasbox_page_content .input-group .wpcf7-date,
  #saasbox_page_content .input-group .wpcf7-quiz,
  #saasbox_page_content .input-group .wpcf7-number,
  #saasbox_page_content .input-group .wpcf7-select,
  #saasbox_page_content .input-group .wpcf7-textarea {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
  }
  #saasbox_page_content .input-group-prepend,
  #saasbox_page_content .input-group-append {
    margin-left: 0;
    margin-right: 0;
  }
  #saasbox_page_content .input-group-prepend .btn,
  #saasbox_page_content .input-group-prepend .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .comment-reply-link,
  #saasbox_page_content .input-group-prepend .comment-form .submit,
  #saasbox_page_content .comment-form .input-group-prepend .submit,
  #saasbox_page_content .input-group-prepend .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-group-prepend a.button,
  #saasbox_page_content .input-group-prepend .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-group-prepend input.button,
  #saasbox_page_content .input-group-prepend .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-group-prepend button.button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit,
  #saasbox_page_content .input-group-prepend .wpcf7-submit,
  #saasbox_page_content .input-group-append .btn,
  #saasbox_page_content .input-group-append .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .comment-reply-link,
  #saasbox_page_content .input-group-append .comment-form .submit,
  #saasbox_page_content .comment-form .input-group-append .submit,
  #saasbox_page_content .input-group-append .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-group-append a.button,
  #saasbox_page_content .input-group-append .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-group-append input.button,
  #saasbox_page_content .input-group-append .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-group-append button.button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit,
  #saasbox_page_content .input-group-append .wpcf7-submit {
    height: calc(102%);
    margin: -1px;
  }
  #saasbox_page_content .input-group-text {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    border: 0;
    color: #c9ccce;
    font-weight: 300;
    border-radius: 0;
    height: 100%;
  }
  #saasbox_page_content .input-group-text .custom-control {
    margin-bottom: 0;
  }
  #saasbox_page_content .input-group-text+.form-control,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group-text+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group-text+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group-text+.input-text,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group-text+.select2-search__field,
  #saasbox_page_content .input-group-text+.wpcf7-text,
  #saasbox_page_content .input-group-text+.wpcf7-date,
  #saasbox_page_content .input-group-text+.wpcf7-quiz,
  #saasbox_page_content .input-group-text+.wpcf7-number,
  #saasbox_page_content .input-group-text+.wpcf7-select,
  #saasbox_page_content .input-group-text+.wpcf7-textarea {
    padding-left: 0;
  }
  #saasbox_page_content .input-group .form-control:not(:last-child),
  #saasbox_page_content .input-group #add_payment_method table.cart td.actions .coupon .input-text:not(:last-child),
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group .input-text:not(:last-child),
  #saasbox_page_content .input-group .woocommerce-cart table.cart td.actions .coupon .input-text:not(:last-child),
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group .input-text:not(:last-child),
  #saasbox_page_content .input-group .woocommerce-checkout table.cart td.actions .coupon .input-text:not(:last-child),
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group .input-text:not(:last-child),
  #saasbox_page_content .input-group .select2-container--default .select2-search--dropdown .select2-search__field:not(:last-child),
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group .select2-search__field:not(:last-child),
  #saasbox_page_content .input-group .wpcf7-text:not(:last-child),
  #saasbox_page_content .input-group .wpcf7-date:not(:last-child),
  #saasbox_page_content .input-group .wpcf7-quiz:not(:last-child),
  #saasbox_page_content .input-group .wpcf7-number:not(:last-child),
  #saasbox_page_content .input-group .wpcf7-select:not(:last-child),
  #saasbox_page_content .input-group .wpcf7-textarea:not(:last-child),
  #saasbox_page_content .input-group-text:not(:last-child) {
    border-right: 0;
  }
  #saasbox_page_content .input-group .form-control:not(:first-child),
  #saasbox_page_content .input-group #add_payment_method table.cart td.actions .coupon .input-text:not(:first-child),
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-group .input-text:not(:first-child),
  #saasbox_page_content .input-group .woocommerce-cart table.cart td.actions .coupon .input-text:not(:first-child),
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-group .input-text:not(:first-child),
  #saasbox_page_content .input-group .woocommerce-checkout table.cart td.actions .coupon .input-text:not(:first-child),
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-group .input-text:not(:first-child),
  #saasbox_page_content .input-group .select2-container--default .select2-search--dropdown .select2-search__field:not(:first-child),
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-group .select2-search__field:not(:first-child),
  #saasbox_page_content .input-group .wpcf7-text:not(:first-child),
  #saasbox_page_content .input-group .wpcf7-date:not(:first-child),
  #saasbox_page_content .input-group .wpcf7-quiz:not(:first-child),
  #saasbox_page_content .input-group .wpcf7-number:not(:first-child),
  #saasbox_page_content .input-group .wpcf7-select:not(:first-child),
  #saasbox_page_content .input-group .wpcf7-textarea:not(:first-child),
  #saasbox_page_content .input-group-text:not(:first-child) {
    border-left: 0;
  }
  #saasbox_page_content .input-group-prepend .btn,
  #saasbox_page_content .input-group-prepend .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-group-prepend .comment-reply-link,
  #saasbox_page_content .input-group-prepend .comment-form .submit,
  #saasbox_page_content .comment-form .input-group-prepend .submit,
  #saasbox_page_content .input-group-prepend .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-group-prepend a.button,
  #saasbox_page_content .input-group-prepend .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-group-prepend input.button,
  #saasbox_page_content .input-group-prepend .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-group-prepend button.button,
  #saasbox_page_content .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-prepend a.checkout-button,
  #saasbox_page_content .input-group-prepend .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-prepend input#submit,
  #saasbox_page_content .input-group-prepend .wpcf7-submit {
    margin-right: 0;
  }
  #saasbox_page_content .input-group-append .btn,
  #saasbox_page_content .input-group-append .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-group-append .comment-reply-link,
  #saasbox_page_content .input-group-append .comment-form .submit,
  #saasbox_page_content .comment-form .input-group-append .submit,
  #saasbox_page_content .input-group-append .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-group-append a.button,
  #saasbox_page_content .input-group-append .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-group-append input.button,
  #saasbox_page_content .input-group-append .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-group-append button.button,
  #saasbox_page_content .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-group-append a.checkout-button,
  #saasbox_page_content .input-group-append .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-group-append input#submit,
  #saasbox_page_content .input-group-append .wpcf7-submit {
    margin-left: 0;
  }
  #saasbox_page_content .input-group-prepend .input-group-text:last-child {
    padding-right: 0;
  }
  #saasbox_page_content .was-validated .input-group:valid,
  #saasbox_page_content .input-group.is-valid {
    border-color: #3cd458;
  }
  #saasbox_page_content .was-validated .input-group:valid .valid-feedback,
  #saasbox_page_content .was-validated .input-group:valid .valid-tooltip,
  #saasbox_page_content .was-validated .input-group:valid ~ .valid-feedback,
  #saasbox_page_content .was-validated .input-group:valid ~ .valid-tooltip,
  #saasbox_page_content .input-group.is-valid .valid-feedback,
  #saasbox_page_content .input-group.is-valid .valid-tooltip,
  #saasbox_page_content .input-group.is-valid ~ .valid-feedback,
  #saasbox_page_content .input-group.is-valid ~ .valid-tooltip {
    display: block;
  }
  #saasbox_page_content .was-validated .input-group:invalid,
  #saasbox_page_content .input-group.is-invalid {
    border-color: #ff4954;
  }
  #saasbox_page_content .was-validated .input-group:invalid .invalid-feedback,
  #saasbox_page_content .was-validated .input-group:invalid .invalid-tooltip,
  #saasbox_page_content .was-validated .input-group:invalid ~ .invalid-feedback,
  #saasbox_page_content .was-validated .input-group:invalid ~ .invalid-tooltip,
  #saasbox_page_content .input-group.is-invalid .invalid-feedback,
  #saasbox_page_content .input-group.is-invalid .invalid-tooltip,
  #saasbox_page_content .input-group.is-invalid ~ .invalid-feedback,
  #saasbox_page_content .input-group.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  #saasbox_page_content .input-border .form-control:focus,
  #saasbox_page_content .input-border #add_payment_method table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-border .input-text:focus,
  #saasbox_page_content .input-border .woocommerce-cart table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-border .input-text:focus,
  #saasbox_page_content .input-border .woocommerce-checkout table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-border .input-text:focus,
  #saasbox_page_content .input-border .select2-container--default .select2-search--dropdown .select2-search__field:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-border .select2-search__field:focus,
  #saasbox_page_content .input-border .wpcf7-text:focus,
  #saasbox_page_content .input-border .wpcf7-date:focus,
  #saasbox_page_content .input-border .wpcf7-quiz:focus,
  #saasbox_page_content .input-border .wpcf7-number:focus,
  #saasbox_page_content .input-border .wpcf7-select:focus,
  #saasbox_page_content .input-border .wpcf7-textarea:focus,
  #saasbox_page_content .input-border .input-group.focus {
    border-color: #96c7ff;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .input-line .form-control,
  #saasbox_page_content .input-line #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-line .input-text,
  #saasbox_page_content .input-line .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-line .input-text,
  #saasbox_page_content .input-line .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-line .input-text,
  #saasbox_page_content .input-line .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-line .select2-search__field,
  #saasbox_page_content .input-line .wpcf7-text,
  #saasbox_page_content .input-line .wpcf7-date,
  #saasbox_page_content .input-line .wpcf7-quiz,
  #saasbox_page_content .input-line .wpcf7-number,
  #saasbox_page_content .input-line .wpcf7-select,
  #saasbox_page_content .input-line .wpcf7-textarea {
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    -webkit-background-size: 0 1px, 100% 1px;
    background-size: 0 1px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#96c7ff),to(#96c7ff)), -webkit-gradient(linear,left top,left bottom,from(#eaeff4),to(#eaeff4));
    background-image: -webkit-linear-gradient(#96c7ff,#96c7ff), -webkit-linear-gradient(#eaeff4,#eaeff4);
    background-image: linear-gradient(#96c7ff,#96c7ff), linear-gradient(#eaeff4,#eaeff4);
    -webkit-transition: background .3s;
    transition: background .3s;
  }
  #saasbox_page_content .input-line .form-control:focus,
  #saasbox_page_content .input-line #add_payment_method table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-line .input-text:focus,
  #saasbox_page_content .input-line .woocommerce-cart table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-line .input-text:focus,
  #saasbox_page_content .input-line .woocommerce-checkout table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-line .input-text:focus,
  #saasbox_page_content .input-line .select2-container--default .select2-search--dropdown .select2-search__field:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-line .select2-search__field:focus,
  #saasbox_page_content .input-line .wpcf7-text:focus,
  #saasbox_page_content .input-line .wpcf7-date:focus,
  #saasbox_page_content .input-line .wpcf7-quiz:focus,
  #saasbox_page_content .input-line .wpcf7-number:focus,
  #saasbox_page_content .input-line .wpcf7-select:focus,
  #saasbox_page_content .input-line .wpcf7-textarea:focus,
  #saasbox_page_content .input-line .form-control.focus,
  #saasbox_page_content .input-line #add_payment_method table.cart td.actions .coupon .focus.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-line .focus.input-text,
  #saasbox_page_content .input-line .woocommerce-cart table.cart td.actions .coupon .focus.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-line .focus.input-text,
  #saasbox_page_content .input-line .woocommerce-checkout table.cart td.actions .coupon .focus.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-line .focus.input-text,
  #saasbox_page_content .input-line .select2-container--default .select2-search--dropdown .focus.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-line .focus.select2-search__field,
  #saasbox_page_content .input-line .focus.wpcf7-text,
  #saasbox_page_content .input-line .focus.wpcf7-date,
  #saasbox_page_content .input-line .focus.wpcf7-quiz,
  #saasbox_page_content .input-line .focus.wpcf7-number,
  #saasbox_page_content .input-line .focus.wpcf7-select,
  #saasbox_page_content .input-line .focus.wpcf7-textarea {
    -webkit-background-size: 100% 1px, 100% 1px;
    background-size: 100% 1px, 100% 1px;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .input-line .form-control:disabled,
  #saasbox_page_content .input-line #add_payment_method table.cart td.actions .coupon .input-text:disabled,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-line .input-text:disabled,
  #saasbox_page_content .input-line .woocommerce-cart table.cart td.actions .coupon .input-text:disabled,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-line .input-text:disabled,
  #saasbox_page_content .input-line .woocommerce-checkout table.cart td.actions .coupon .input-text:disabled,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-line .input-text:disabled,
  #saasbox_page_content .input-line .select2-container--default .select2-search--dropdown .select2-search__field:disabled,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-line .select2-search__field:disabled,
  #saasbox_page_content .input-line .wpcf7-text:disabled,
  #saasbox_page_content .input-line .wpcf7-date:disabled,
  #saasbox_page_content .input-line .wpcf7-quiz:disabled,
  #saasbox_page_content .input-line .wpcf7-number:disabled,
  #saasbox_page_content .input-line .wpcf7-select:disabled,
  #saasbox_page_content .input-line .wpcf7-textarea:disabled,
  #saasbox_page_content .input-line .form-control[readonly],
  #saasbox_page_content .input-line #add_payment_method table.cart td.actions .coupon .input-text[readonly],
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-line .input-text[readonly],
  #saasbox_page_content .input-line .woocommerce-cart table.cart td.actions .coupon .input-text[readonly],
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-line .input-text[readonly],
  #saasbox_page_content .input-line .woocommerce-checkout table.cart td.actions .coupon .input-text[readonly],
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-line .input-text[readonly],
  #saasbox_page_content .input-line .select2-container--default .select2-search--dropdown .select2-search__field[readonly],
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-line .select2-search__field[readonly],
  #saasbox_page_content .input-line .wpcf7-text[readonly],
  #saasbox_page_content .input-line .wpcf7-date[readonly],
  #saasbox_page_content .input-line .wpcf7-quiz[readonly],
  #saasbox_page_content .input-line .wpcf7-number[readonly],
  #saasbox_page_content .input-line .wpcf7-select[readonly],
  #saasbox_page_content .input-line .wpcf7-textarea[readonly] {
    background-color: #fff;
    opacity: .7;
  }
  #saasbox_page_content .input-line .form-control.is-valid,
  #saasbox_page_content .input-line #add_payment_method table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-line .is-valid.input-text,
  #saasbox_page_content .input-line .woocommerce-cart table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-line .is-valid.input-text,
  #saasbox_page_content .input-line .woocommerce-checkout table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-line .is-valid.input-text,
  #saasbox_page_content .input-line .select2-container--default .select2-search--dropdown .is-valid.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-line .is-valid.select2-search__field,
  #saasbox_page_content .input-line .is-valid.wpcf7-text,
  #saasbox_page_content .input-line .is-valid.wpcf7-date,
  #saasbox_page_content .input-line .is-valid.wpcf7-quiz,
  #saasbox_page_content .input-line .is-valid.wpcf7-number,
  #saasbox_page_content .input-line .is-valid.wpcf7-select,
  #saasbox_page_content .input-line .is-valid.wpcf7-textarea {
    background-image: -webkit-gradient(linear,left top,left bottom,from(#96c7ff),to(#96c7ff)), -webkit-gradient(linear,left top,left bottom,from(#3cd458),to(#3cd458));
    background-image: -webkit-linear-gradient(#96c7ff,#96c7ff), -webkit-linear-gradient(#3cd458,#3cd458);
    background-image: linear-gradient(#96c7ff,#96c7ff), linear-gradient(#3cd458,#3cd458);
  }
  #saasbox_page_content .input-line .form-control.is-invalid,
  #saasbox_page_content .input-line #add_payment_method table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-line .is-invalid.input-text,
  #saasbox_page_content .input-line .woocommerce-cart table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-line .is-invalid.input-text,
  #saasbox_page_content .input-line .woocommerce-checkout table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-line .is-invalid.input-text,
  #saasbox_page_content .input-line .select2-container--default .select2-search--dropdown .is-invalid.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-line .is-invalid.select2-search__field,
  #saasbox_page_content .input-line .is-invalid.wpcf7-text,
  #saasbox_page_content .input-line .is-invalid.wpcf7-date,
  #saasbox_page_content .input-line .is-invalid.wpcf7-quiz,
  #saasbox_page_content .input-line .is-invalid.wpcf7-number,
  #saasbox_page_content .input-line .is-invalid.wpcf7-select,
  #saasbox_page_content .input-line .is-invalid.wpcf7-textarea {
    background-image: -webkit-gradient(linear,left top,left bottom,from(#96c7ff),to(#96c7ff)), -webkit-gradient(linear,left top,left bottom,from(#ff4954),to(#ff4954));
    background-image: -webkit-linear-gradient(#96c7ff,#96c7ff), -webkit-linear-gradient(#ff4954,#ff4954);
    background-image: linear-gradient(#96c7ff,#96c7ff), linear-gradient(#ff4954,#ff4954);
  }
  #saasbox_page_content .input-line .input-group {
    border: 0;
  }
  #saasbox_page_content .input-line .input-group.focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .input-line .input-group-prepend .btn,
  #saasbox_page_content .input-line .input-group-prepend .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-line .input-group-prepend .comment-reply-link,
  #saasbox_page_content .input-line .input-group-prepend .comment-form .submit,
  #saasbox_page_content .comment-form .input-line .input-group-prepend .submit,
  #saasbox_page_content .input-line .input-group-prepend .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-line .input-group-prepend a.button,
  #saasbox_page_content .input-line .input-group-prepend .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-line .input-group-prepend input.button,
  #saasbox_page_content .input-line .input-group-prepend .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-line .input-group-prepend button.button,
  #saasbox_page_content .input-line .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-line .input-group-prepend a.checkout-button,
  #saasbox_page_content .input-line .input-group-prepend .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-line .input-group-prepend input#submit,
  #saasbox_page_content .input-line .input-group-prepend .wpcf7-submit,
  #saasbox_page_content .input-line .input-group-append .btn,
  #saasbox_page_content .input-line .input-group-append .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-line .input-group-append .comment-reply-link,
  #saasbox_page_content .input-line .input-group-append .comment-form .submit,
  #saasbox_page_content .comment-form .input-line .input-group-append .submit,
  #saasbox_page_content .input-line .input-group-append .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-line .input-group-append a.button,
  #saasbox_page_content .input-line .input-group-append .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-line .input-group-append input.button,
  #saasbox_page_content .input-line .input-group-append .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-line .input-group-append button.button,
  #saasbox_page_content .input-line .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-line .input-group-append a.checkout-button,
  #saasbox_page_content .input-line .input-group-append .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-line .input-group-append input#submit,
  #saasbox_page_content .input-line .input-group-append .wpcf7-submit {
    height: 100%;
    margin: 0;
  }
  #saasbox_page_content .input-line .input-group-text {
    background-color: transparent;
  }
  #saasbox_page_content .input-line .input-group-prepend .input-group-text:last-child {
    padding-right: .75rem;
  }
  #saasbox_page_content .input-round .form-control,
  #saasbox_page_content .input-round #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-round .input-text,
  #saasbox_page_content .input-round .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-round .input-text,
  #saasbox_page_content .input-round .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-round .input-text,
  #saasbox_page_content .input-round .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-round .select2-search__field,
  #saasbox_page_content .input-round .wpcf7-text,
  #saasbox_page_content .input-round .wpcf7-date,
  #saasbox_page_content .input-round .wpcf7-quiz,
  #saasbox_page_content .input-round .wpcf7-number,
  #saasbox_page_content .input-round .wpcf7-select,
  #saasbox_page_content .input-round .wpcf7-textarea,
  #saasbox_page_content .input-round .input-group,
  #saasbox_page_content .input-round .input-group-text,
  #saasbox_page_content .input-round .input-group .btn,
  #saasbox_page_content .input-round .input-group .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-round .input-group .comment-reply-link,
  #saasbox_page_content .input-round .input-group .comment-form .submit,
  #saasbox_page_content .comment-form .input-round .input-group .submit,
  #saasbox_page_content .input-round .input-group .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-round .input-group a.button,
  #saasbox_page_content .input-round .input-group .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-round .input-group input.button,
  #saasbox_page_content .input-round .input-group .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-round .input-group button.button,
  #saasbox_page_content .input-round .input-group .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-round .input-group a.checkout-button,
  #saasbox_page_content .input-round .input-group .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-round .input-group input#submit,
  #saasbox_page_content .input-round .input-group .wpcf7-submit {
    border-radius: 10rem;
  }
  #saasbox_page_content .input-round .form-control,
  #saasbox_page_content .input-round #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-round .input-text,
  #saasbox_page_content .input-round .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-round .input-text,
  #saasbox_page_content .input-round .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-round .input-text,
  #saasbox_page_content .input-round .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-round .select2-search__field,
  #saasbox_page_content .input-round .wpcf7-text,
  #saasbox_page_content .input-round .wpcf7-date,
  #saasbox_page_content .input-round .wpcf7-quiz,
  #saasbox_page_content .input-round .wpcf7-number,
  #saasbox_page_content .input-round .wpcf7-select,
  #saasbox_page_content .input-round .wpcf7-textarea {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #saasbox_page_content .input-round textarea.form-control,
  #saasbox_page_content .input-round #add_payment_method table.cart td.actions .coupon textarea.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-round textarea.input-text,
  #saasbox_page_content .input-round .woocommerce-cart table.cart td.actions .coupon textarea.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-round textarea.input-text,
  #saasbox_page_content .input-round .woocommerce-checkout table.cart td.actions .coupon textarea.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-round textarea.input-text,
  #saasbox_page_content .input-round .select2-container--default .select2-search--dropdown textarea.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-round textarea.select2-search__field,
  #saasbox_page_content .input-round textarea.wpcf7-text,
  #saasbox_page_content .input-round textarea.wpcf7-date,
  #saasbox_page_content .input-round textarea.wpcf7-quiz,
  #saasbox_page_content .input-round textarea.wpcf7-number,
  #saasbox_page_content .input-round textarea.wpcf7-select,
  #saasbox_page_content .input-round textarea.wpcf7-textarea {
    border-radius: 2px;
  }
  #saasbox_page_content .input-round .input-group-text {
    padding-left: 1rem;
    padding-right: 1rem;
    opacity: .8;
  }
  #saasbox_page_content .input-round .input-group-text+.form-control,
  #saasbox_page_content .input-round #add_payment_method table.cart td.actions .coupon .input-group-text+.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-round .input-group-text+.input-text,
  #saasbox_page_content .input-round .woocommerce-cart table.cart td.actions .coupon .input-group-text+.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-round .input-group-text+.input-text,
  #saasbox_page_content .input-round .woocommerce-checkout table.cart td.actions .coupon .input-group-text+.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-round .input-group-text+.input-text,
  #saasbox_page_content .input-round .select2-container--default .select2-search--dropdown .input-group-text+.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-round .input-group-text+.select2-search__field,
  #saasbox_page_content .input-round .input-group-text+.wpcf7-text,
  #saasbox_page_content .input-round .input-group-text+.wpcf7-date,
  #saasbox_page_content .input-round .input-group-text+.wpcf7-quiz,
  #saasbox_page_content .input-round .input-group-text+.wpcf7-number,
  #saasbox_page_content .input-round .input-group-text+.wpcf7-select,
  #saasbox_page_content .input-round .input-group-text+.wpcf7-textarea {
    padding-left: 0;
  }
  #saasbox_page_content .input-round .form-control-lg,
  #saasbox_page_content .input-round .input-group-lg .form-control,
  #saasbox_page_content .input-round .input-group-lg #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-round .input-group-lg .input-text,
  #saasbox_page_content .input-round .input-group-lg .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-round .input-group-lg .input-text,
  #saasbox_page_content .input-round .input-group-lg .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-round .input-group-lg .input-text,
  #saasbox_page_content .input-round .input-group-lg .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-round .input-group-lg .select2-search__field,
  #saasbox_page_content .input-round .input-group-lg .wpcf7-text,
  #saasbox_page_content .input-round .input-group-lg .wpcf7-date,
  #saasbox_page_content .input-round .input-group-lg .wpcf7-quiz,
  #saasbox_page_content .input-round .input-group-lg .wpcf7-number,
  #saasbox_page_content .input-round .input-group-lg .wpcf7-select,
  #saasbox_page_content .input-round .input-group-lg .wpcf7-textarea {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  #saasbox_page_content .input-glass .form-control,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .input-text,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .input-text,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .input-text,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .select2-search__field,
  #saasbox_page_content .input-glass .wpcf7-text,
  #saasbox_page_content .input-glass .wpcf7-date,
  #saasbox_page_content .input-glass .wpcf7-quiz,
  #saasbox_page_content .input-glass .wpcf7-number,
  #saasbox_page_content .input-glass .wpcf7-select,
  #saasbox_page_content .input-glass .wpcf7-textarea {
    border: 0;
    background-color: rgba(255,255,255,0.2);
    font-weight: 400;
    color: rgba(255,255,255,0.8);
  }
  #saasbox_page_content .input-glass .form-control.is-valid,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .is-valid.input-text,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .is-valid.input-text,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .is-valid.input-text,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .is-valid.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .is-valid.select2-search__field,
  #saasbox_page_content .input-glass .is-valid.wpcf7-text,
  #saasbox_page_content .input-glass .is-valid.wpcf7-date,
  #saasbox_page_content .input-glass .is-valid.wpcf7-quiz,
  #saasbox_page_content .input-glass .is-valid.wpcf7-number,
  #saasbox_page_content .input-glass .is-valid.wpcf7-select,
  #saasbox_page_content .input-glass .is-valid.wpcf7-textarea {
    background-color: rgba(60,212,88,0.2);
  }
  #saasbox_page_content .input-glass .form-control.is-invalid,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .is-invalid.input-text,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .is-invalid.input-text,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .is-invalid.input-text,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .is-invalid.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .is-invalid.select2-search__field,
  #saasbox_page_content .input-glass .is-invalid.wpcf7-text,
  #saasbox_page_content .input-glass .is-invalid.wpcf7-date,
  #saasbox_page_content .input-glass .is-invalid.wpcf7-quiz,
  #saasbox_page_content .input-glass .is-invalid.wpcf7-number,
  #saasbox_page_content .input-glass .is-invalid.wpcf7-select,
  #saasbox_page_content .input-glass .is-invalid.wpcf7-textarea {
    background-color: rgba(255,73,84,0.2);
  }
  #saasbox_page_content .input-glass .form-control::-webkit-input-placeholder,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .input-text::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .input-text::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .input-text::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .select2-search__field::-webkit-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .select2-search__field::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-text::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-date::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-quiz::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-number::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-select::-webkit-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-textarea::-webkit-input-placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-glass .form-control::-moz-placeholder,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .input-text::-moz-placeholder,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .input-text::-moz-placeholder,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .input-text::-moz-placeholder,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .select2-search__field::-moz-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .select2-search__field::-moz-placeholder,
  #saasbox_page_content .input-glass .wpcf7-text::-moz-placeholder,
  #saasbox_page_content .input-glass .wpcf7-date::-moz-placeholder,
  #saasbox_page_content .input-glass .wpcf7-quiz::-moz-placeholder,
  #saasbox_page_content .input-glass .wpcf7-number::-moz-placeholder,
  #saasbox_page_content .input-glass .wpcf7-select::-moz-placeholder,
  #saasbox_page_content .input-glass .wpcf7-textarea::-moz-placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-glass .form-control:-ms-input-placeholder,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .input-text:-ms-input-placeholder,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .input-text:-ms-input-placeholder,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .input-text:-ms-input-placeholder,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .select2-search__field:-ms-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .select2-search__field:-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-text:-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-date:-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-quiz:-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-number:-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-select:-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-textarea:-ms-input-placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-glass .form-control::-ms-input-placeholder,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .input-text::-ms-input-placeholder,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .input-text::-ms-input-placeholder,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .input-text::-ms-input-placeholder,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .select2-search__field::-ms-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .select2-search__field::-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-text::-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-date::-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-quiz::-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-number::-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-select::-ms-input-placeholder,
  #saasbox_page_content .input-glass .wpcf7-textarea::-ms-input-placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-glass .form-control::placeholder,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .input-text::placeholder,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .input-text::placeholder,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .input-text::placeholder,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .select2-search__field::placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .select2-search__field::placeholder,
  #saasbox_page_content .input-glass .wpcf7-text::placeholder,
  #saasbox_page_content .input-glass .wpcf7-date::placeholder,
  #saasbox_page_content .input-glass .wpcf7-quiz::placeholder,
  #saasbox_page_content .input-glass .wpcf7-number::placeholder,
  #saasbox_page_content .input-glass .wpcf7-select::placeholder,
  #saasbox_page_content .input-glass .wpcf7-textarea::placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-glass .form-control:focus,
  #saasbox_page_content .input-glass #add_payment_method table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass .input-text:focus,
  #saasbox_page_content .input-glass .woocommerce-cart table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass .input-text:focus,
  #saasbox_page_content .input-glass .woocommerce-checkout table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass .input-text:focus,
  #saasbox_page_content .input-glass .select2-container--default .select2-search--dropdown .select2-search__field:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass .select2-search__field:focus,
  #saasbox_page_content .input-glass .wpcf7-text:focus,
  #saasbox_page_content .input-glass .wpcf7-date:focus,
  #saasbox_page_content .input-glass .wpcf7-quiz:focus,
  #saasbox_page_content .input-glass .wpcf7-number:focus,
  #saasbox_page_content .input-glass .wpcf7-select:focus,
  #saasbox_page_content .input-glass .wpcf7-textarea:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
  }
  #saasbox_page_content .input-glass select option {
    color: #323d47;
  }
  #saasbox_page_content .input-glass label {
    opacity: .8;
  }
  #saasbox_page_content .input-glass .input-group {
    border: 0;
  }
  #saasbox_page_content .input-glass .input-group-prepend .btn,
  #saasbox_page_content .input-glass .input-group-prepend .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-glass .input-group-prepend .comment-reply-link,
  #saasbox_page_content .input-glass .input-group-prepend .comment-form .submit,
  #saasbox_page_content .comment-form .input-glass .input-group-prepend .submit,
  #saasbox_page_content .input-glass .input-group-prepend .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-glass .input-group-prepend a.button,
  #saasbox_page_content .input-glass .input-group-prepend .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-glass .input-group-prepend input.button,
  #saasbox_page_content .input-glass .input-group-prepend .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-glass .input-group-prepend button.button,
  #saasbox_page_content .input-glass .input-group-prepend .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-glass .input-group-prepend a.checkout-button,
  #saasbox_page_content .input-glass .input-group-prepend .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-glass .input-group-prepend input#submit,
  #saasbox_page_content .input-glass .input-group-prepend .wpcf7-submit,
  #saasbox_page_content .input-glass .input-group-append .btn,
  #saasbox_page_content .input-glass .input-group-append .comment-list .comment-reply-link,
  #saasbox_page_content .comment-list .input-glass .input-group-append .comment-reply-link,
  #saasbox_page_content .input-glass .input-group-append .comment-form .submit,
  #saasbox_page_content .comment-form .input-glass .input-group-append .submit,
  #saasbox_page_content .input-glass .input-group-append .woocommerce a.button,
  #saasbox_page_content .woocommerce .input-glass .input-group-append a.button,
  #saasbox_page_content .input-glass .input-group-append .woocommerce input.button,
  #saasbox_page_content .woocommerce .input-glass .input-group-append input.button,
  #saasbox_page_content .input-glass .input-group-append .woocommerce button.button,
  #saasbox_page_content .woocommerce .input-glass .input-group-append button.button,
  #saasbox_page_content .input-glass .input-group-append .woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout .input-glass .input-group-append a.checkout-button,
  #saasbox_page_content .input-glass .input-group-append .woocommerce #respond input#submit,
  #saasbox_page_content .woocommerce #respond .input-glass .input-group-append input#submit,
  #saasbox_page_content .input-glass .input-group-append .wpcf7-submit {
    height: 100%;
    margin: 0;
  }
  #saasbox_page_content .input-glass .input-group-text {
    background-color: rgba(255,255,255,0.2);
    border: 0;
    color: rgba(255,255,255,0.8);
  }
  #saasbox_page_content .input-glass.input-round .input-group {
    background-color: rgba(255,255,255,0.2);
  }
  #saasbox_page_content .input-glass.input-round .input-group .form-control,
  #saasbox_page_content .input-glass.input-round .input-group #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-glass.input-round .input-group .input-text,
  #saasbox_page_content .input-glass.input-round .input-group .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-glass.input-round .input-group .input-text,
  #saasbox_page_content .input-glass.input-round .input-group .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-glass.input-round .input-group .input-text,
  #saasbox_page_content .input-glass.input-round .input-group .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-glass.input-round .input-group .select2-search__field,
  #saasbox_page_content .input-glass.input-round .input-group .wpcf7-text,
  #saasbox_page_content .input-glass.input-round .input-group .wpcf7-date,
  #saasbox_page_content .input-glass.input-round .input-group .wpcf7-quiz,
  #saasbox_page_content .input-glass.input-round .input-group .wpcf7-number,
  #saasbox_page_content .input-glass.input-round .input-group .wpcf7-select,
  #saasbox_page_content .input-glass.input-round .input-group .wpcf7-textarea,
  #saasbox_page_content .input-glass.input-round .input-group .input-group-text {
    background-color: transparent;
  }
  #saasbox_page_content .input-transparent .form-control,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .input-text,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .input-text,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .input-text,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .select2-search__field,
  #saasbox_page_content .input-transparent .wpcf7-text,
  #saasbox_page_content .input-transparent .wpcf7-date,
  #saasbox_page_content .input-transparent .wpcf7-quiz,
  #saasbox_page_content .input-transparent .wpcf7-number,
  #saasbox_page_content .input-transparent .wpcf7-select,
  #saasbox_page_content .input-transparent .wpcf7-textarea {
    border-color: rgba(255,255,255,0.3);
    background-color: transparent;
    color: rgba(255,255,255,0.8);
  }
  #saasbox_page_content .input-transparent .form-control.is-valid,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .is-valid.input-text,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .is-valid.input-text,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .is-valid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .is-valid.input-text,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .is-valid.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .is-valid.select2-search__field,
  #saasbox_page_content .input-transparent .is-valid.wpcf7-text,
  #saasbox_page_content .input-transparent .is-valid.wpcf7-date,
  #saasbox_page_content .input-transparent .is-valid.wpcf7-quiz,
  #saasbox_page_content .input-transparent .is-valid.wpcf7-number,
  #saasbox_page_content .input-transparent .is-valid.wpcf7-select,
  #saasbox_page_content .input-transparent .is-valid.wpcf7-textarea {
    border-color: rgba(60,212,88,0.4);
  }
  #saasbox_page_content .input-transparent .form-control.is-invalid,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .is-invalid.input-text,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .is-invalid.input-text,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .is-invalid.input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .is-invalid.input-text,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .is-invalid.select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .is-invalid.select2-search__field,
  #saasbox_page_content .input-transparent .is-invalid.wpcf7-text,
  #saasbox_page_content .input-transparent .is-invalid.wpcf7-date,
  #saasbox_page_content .input-transparent .is-invalid.wpcf7-quiz,
  #saasbox_page_content .input-transparent .is-invalid.wpcf7-number,
  #saasbox_page_content .input-transparent .is-invalid.wpcf7-select,
  #saasbox_page_content .input-transparent .is-invalid.wpcf7-textarea {
    border-color: rgba(255,73,84,0.4);
  }
  #saasbox_page_content .input-transparent .form-control::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .input-text::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .input-text::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .input-text::-webkit-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .input-text::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .select2-search__field::-webkit-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .select2-search__field::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-text::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-date::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-quiz::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-number::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-select::-webkit-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-textarea::-webkit-input-placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-transparent .form-control::-moz-placeholder,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .input-text::-moz-placeholder,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .input-text::-moz-placeholder,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .input-text::-moz-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .input-text::-moz-placeholder,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .select2-search__field::-moz-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .select2-search__field::-moz-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-text::-moz-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-date::-moz-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-quiz::-moz-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-number::-moz-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-select::-moz-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-textarea::-moz-placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-transparent .form-control:-ms-input-placeholder,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .input-text:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .input-text:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .input-text:-ms-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .input-text:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .select2-search__field:-ms-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .select2-search__field:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-text:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-date:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-quiz:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-number:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-select:-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-textarea:-ms-input-placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-transparent .form-control::-ms-input-placeholder,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .input-text::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .input-text::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .input-text::-ms-input-placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .input-text::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .select2-search__field::-ms-input-placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .select2-search__field::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-text::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-date::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-quiz::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-number::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-select::-ms-input-placeholder,
  #saasbox_page_content .input-transparent .wpcf7-textarea::-ms-input-placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-transparent .form-control::placeholder,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .input-text::placeholder,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .input-text::placeholder,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .input-text::placeholder,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .input-text::placeholder,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .select2-search__field::placeholder,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .select2-search__field::placeholder,
  #saasbox_page_content .input-transparent .wpcf7-text::placeholder,
  #saasbox_page_content .input-transparent .wpcf7-date::placeholder,
  #saasbox_page_content .input-transparent .wpcf7-quiz::placeholder,
  #saasbox_page_content .input-transparent .wpcf7-number::placeholder,
  #saasbox_page_content .input-transparent .wpcf7-select::placeholder,
  #saasbox_page_content .input-transparent .wpcf7-textarea::placeholder {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .input-transparent .form-control:focus,
  #saasbox_page_content .input-transparent #add_payment_method table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-transparent .input-text:focus,
  #saasbox_page_content .input-transparent .woocommerce-cart table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-transparent .input-text:focus,
  #saasbox_page_content .input-transparent .woocommerce-checkout table.cart td.actions .coupon .input-text:focus,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-transparent .input-text:focus,
  #saasbox_page_content .input-transparent .select2-container--default .select2-search--dropdown .select2-search__field:focus,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .input-transparent .select2-search__field:focus,
  #saasbox_page_content .input-transparent .wpcf7-text:focus,
  #saasbox_page_content .input-transparent .wpcf7-date:focus,
  #saasbox_page_content .input-transparent .wpcf7-quiz:focus,
  #saasbox_page_content .input-transparent .wpcf7-number:focus,
  #saasbox_page_content .input-transparent .wpcf7-select:focus,
  #saasbox_page_content .input-transparent .wpcf7-textarea:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: rgba(255,255,255,0.5);
    color: #fff;
  }
  #saasbox_page_content .input-transparent select option {
    color: #323d47;
  }
  #saasbox_page_content .input-transparent label {
    opacity: .8;
  }
  #saasbox_page_content .input-transparent .input-group {
    border-color: rgba(255,255,255,0.3);
  }
  #saasbox_page_content .input-transparent .input-group.focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: rgba(255,255,255,0.5);
    color: #fff;
  }
  #saasbox_page_content .input-transparent .input-group .btn-outline-light {
    border-color: rgba(255,255,255,0.3);
  }
  #saasbox_page_content .input-transparent .input-group .input-group-prepend .btn-outline-light {
    border-top-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
  }
  #saasbox_page_content .input-transparent .input-group .input-group-append .btn-outline-light {
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
  }
  #saasbox_page_content .input-transparent .input-group-text {
    background-color: transparent;
    border-color: rgba(255,255,255,0.3);
    color: rgba(255,255,255,0.75);
  }
  #saasbox_page_content .feature-1 .feature-icon {
    font-size: 3rem;
    line-height: 1;
    margin-bottom: 1.75rem;
    color: #929daf;
  }
  #saasbox_page_content .feature-2 .feature-icon {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 1.5rem;
    color: #929daf;
  }
  #saasbox_page_content .feature-2 h5,
  #saasbox_page_content .feature-2 h6 {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
  }
  #saasbox_page_content .partner {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  }
  #saasbox_page_content .partner img {
    margin: 16px 24px;
    max-width: 100%;
  }
  #saasbox_page_content .partner [class*="col-"] img {
    margin: 0;
  }
  #saasbox_page_content .partner-sm img {
    height: 20px;
  }
  #saasbox_page_content .portfolio-1 {
    position: relative;
    overflow: hidden;
    display: block;
  }
  #saasbox_page_content .portfolio-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #50a1ff;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 1;
  }
  #saasbox_page_content .portfolio-1::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #fff;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 2;
  }
  #saasbox_page_content .portfolio-1:hover::before {
    opacity: .8;
  }
  #saasbox_page_content .portfolio-1:hover::after {
    opacity: .8;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
  #saasbox_page_content .portfolio-1:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  #saasbox_page_content .portfolio-1:hover .portfolio-detail {
    opacity: 1;
  }
  #saasbox_page_content .portfolio-1 img {
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #saasbox_page_content .portfolio-1 .portfolio-detail {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    color: #fff;
    z-index: 3;
  }
  #saasbox_page_content .portfolio-1 h5 {
    color: #fff;
    margin-bottom: 0;
  }
  #saasbox_page_content .portfolio-1 p {
    font-size: 13px;
  }
  #saasbox_page_content .project-detail {
    list-style: none;
    padding-left: 0;
  }
  #saasbox_page_content .project-detail li:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  #saasbox_page_content .project-detail strong {
    display: block;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #323d47;
  }
  #saasbox_page_content .press-kit {
    position: relative;
    overflow: hidden;
    display: block;
    min-height: 160px;
    text-align: center;
    border: 1px solid #f1f2f3;
    border-radius: 3px;
  }
  #saasbox_page_content .press-kit::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #191919;
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    z-index: 1;
  }
  #saasbox_page_content .press-kit:hover::before {
    opacity: .5;
  }
  #saasbox_page_content .press-kit:hover .asset-details {
    opacity: 1;
  }
  #saasbox_page_content .press-kit img {
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #saasbox_page_content .press-kit .asset-details {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    -webkit-transition: all .3s;
    transition: all .3s;
    color: #fff;
    z-index: 3;
  }
  #saasbox_page_content .press-kit h5 {
    color: #fff;
    margin-bottom: 0;
  }
  #saasbox_page_content .press-kit p {
    font-size: 13px;
  }
  #saasbox_page_content .price-unit {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-top;
    margin-right: -10px;
    margin-top: 16px;
  }
  #saasbox_page_content .plan-period {
    display: inline-block;
    font-size: 12px;
    vertical-align: text-bottom;
    margin-left: -10px;
    margin-bottom: 14px;
    color: #929daf;
  }
  #saasbox_page_content .pricing-1 {
    background-color: #fff;
    border: 1px solid #f1f2f3;
    border-radius: 5px;
    text-align: center;
    -webkit-transition: .5s;
    transition: .5s;
  }
  #saasbox_page_content .pricing-1.popular,
  #saasbox_page_content .pricing-1:hover {
    -webkit-box-shadow: 0 0 25px rgba(0,0,0,0.07);
    box-shadow: 0 0 25px rgba(0,0,0,0.07);
  }
  #saasbox_page_content .pricing-1 .plan-name {
    padding: 16px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    border-bottom: 1px solid #f1f2f3;
    background-color: #fcfdfe;
  }
  #saasbox_page_content .pricing-1 .price {
    font-size: 60px;
    font-weight: 600;
  }
  #saasbox_page_content .pricing-2 {
    text-align: center;
  }
  #saasbox_page_content .pricing-2 .price {
    font-size: 5rem;
    font-weight: 200;
    opacity: .9;
  }
  #saasbox_page_content .pricing-2 .plan-name {
    text-transform: uppercase;
    font-weight: 500;
    font-size: .8325rem;
    opacity: .9;
  }
  #saasbox_page_content .pricing-2 .plan-description {
    opacity: .9;
  }
  #saasbox_page_content .pricing-3 {
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
  }
  #saasbox_page_content .pricing-3.popular {
    background-color: #fafbfb;
    position: relative;
  }
  #saasbox_page_content .pricing-3 .popular-tag {
    position: absolute;
    top: 0;
    left: 40px;
    background-color: #3cd458;
    color: #fff;
    padding: 1px .75rem;
    padding-right: 1.5rem;
    text-transform: uppercase;
    font-size: .75rem;
    letter-spacing: 1.5px;
    font-weight: 400;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  #saasbox_page_content .pricing-3 .price {
    font-size: 3.75rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  #saasbox_page_content .pricing-3 .plan-name {
    text-transform: uppercase;
    font-weight: 600;
    font-size: .8325rem;
    letter-spacing: 1px;
    opacity: .9;
  }
  #saasbox_page_content .pricing-3 ul {
    list-style: none;
    padding-left: 0;
  }
  #saasbox_page_content .pricing-4 {
    -webkit-box-align: center;
    align-items: center;
    color: #757575;
    border: 1px solid #f1f2f3;
  }
  #saasbox_page_content .pricing-4.popular,
  #saasbox_page_content .pricing-4:hover,
  #saasbox_page_content .pricing-4:focus {
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.06);
    box-shadow: 0 0 15px rgba(0,0,0,0.06);
    color: #757575;
  }
  #saasbox_page_content .pricing-4 .plan-description {
    background-color: #fafbfb;
    padding: 2rem;
  }
  #saasbox_page_content .pricing-4 .plan-description h5 {
    font-weight: 500;
  }
  #saasbox_page_content .pricing-4 .plan-price {
    text-align: center;
  }
  #saasbox_page_content .pricing-4 .plan-price h3 {
    font-weight: 300;
    font-size: 2.25rem;
    padding-top: 1rem;
  }
  #saasbox_page_content .pricing-4 .plan-price h3 small {
    font-weight: 300;
    font-size: .8325rem;
  }
  #saasbox_page_content .product-1 {
    position: relative;
    display: block;
    border: 1px solid #f1f2f3;
    border-radius: 3px;
    padding: 1rem 1.25rem;
    background-color: #fcfdfe;
    color: #757575;
  }
  #saasbox_page_content .product-1:hover {
    background-color: #f9fafb;
    color: #757575;
  }
  #saasbox_page_content .product-1:hover img {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
  #saasbox_page_content .product-1 img {
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  #saasbox_page_content .product-1 img+.product-detail {
    margin-top: 1rem;
  }
  #saasbox_page_content .product-1 .product-detail {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  #saasbox_page_content .product-1 .product-detail h5,
  #saasbox_page_content .product-1 .product-detail h6 {
    margin-bottom: .25rem;
  }
  #saasbox_page_content .product-1 .product-detail p {
    font-size: .875rem;
    color: #929daf;
    line-height: 1.4;
  }
  #saasbox_page_content .product-1 .product-detail .product-price {
    font-weight: 100;
    font-size: 1.5rem;
    letter-spacing: 1px;
    padding-left: 1rem;
    white-space: nowrap;
  }
  #saasbox_page_content .product-1 .product-detail .product-price .unit {
    font-size: .75rem;
    vertical-align: text-top;
  }
  #saasbox_page_content .product-1 .product-detail .product-price s {
    color: #d3d3d3;
    margin-right: .5rem;
    font-size: 65%;
    vertical-align: middle;
  }
  #saasbox_page_content .product-1 .badge-pos-left,
  #saasbox_page_content .product-1 .badge-pos-right {
    position: absolute;
    padding-top: 3px;
    padding-bottom: 4px;
    font-size: 11px;
    top: -0.5rem;
    z-index: 1;
  }
  #saasbox_page_content .product-1 .badge-pos-left {
    left: -0.5rem;
  }
  #saasbox_page_content .product-1 .badge-pos-right {
    right: -0.5rem;
  }
  #saasbox_page_content .product-2 .product-media {
    position: relative;
    display: block;
    border-radius: .35rem;
    margin-bottom: 1rem;
  }
  #saasbox_page_content .product-2 .product-media,
  #saasbox_page_content .product-2 .product-media:hover {
    background-color: #f9fafb;
  }
  #saasbox_page_content .product-2 .product-detail {
    text-align: center;
  }
  #saasbox_page_content .product-2 .product-detail .product-price s {
    color: #d3d3d3;
    margin-right: .5rem;
  }
  #saasbox_page_content .product-2 .badge-pos-left,
  #saasbox_page_content .product-2 .badge-pos-right {
    position: absolute;
    padding-top: 3px;
    padding-bottom: 4px;
    font-size: 11px;
    top: .5rem;
    z-index: 1;
  }
  #saasbox_page_content .product-2 .badge-pos-left {
    left: .5rem;
  }
  #saasbox_page_content .product-2 .badge-pos-right {
    right: .5rem;
  }
  #saasbox_page_content .product-3 {
    position: relative;
    background-color: #fff;
    border-radius: .25rem;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .product-3 .product-media {
    border-radius: inherit;
  }
  #saasbox_page_content .product-3 .product-detail {
    padding: 1rem;
    text-align: center;
  }
  #saasbox_page_content .product-3 .product-detail .product-price s {
    color: #d3d3d3;
    margin-right: .5rem;
  }
  #saasbox_page_content .product-3 .badge-pos-left,
  #saasbox_page_content .product-3 .badge-pos-right {
    position: absolute;
    padding-top: 3px;
    padding-bottom: 4px;
    font-size: 11px;
    top: -0.5rem;
    z-index: 1;
  }
  #saasbox_page_content .product-3 .badge-pos-left {
    left: -0.5rem;
  }
  #saasbox_page_content .product-3 .badge-pos-right {
    right: -0.5rem;
  }
  #saasbox_page_content .table-cart {
    border: 1px solid #eaeff4;
    border-top: 0;
  }
  #saasbox_page_content .table-cart td {
    vertical-align: middle;
    border-top-color: #eaeff4;
    padding-left: 12px;
    padding-right: 12px;
  }
  #saasbox_page_content .table-cart tr td:first-child {
    padding-left: 30px;
  }
  #saasbox_page_content .table-cart tr td:last-child {
    padding-right: 30px;
    text-align: right;
  }
  #saasbox_page_content .table-cart h5 {
    font-size: 1rem;
    margin-bottom: 0;
  }
  #saasbox_page_content .table-cart p {
    font-size: 13px;
    color: #929daf;
    line-height: 1.4;
    margin-bottom: 0;
  }
  #saasbox_page_content .table-cart img {
    max-height: 80px;
  }
  #saasbox_page_content .table-cart label {
    font-size: 12px;
    display: inline-block;
    padding-right: 8px;
  }
  #saasbox_page_content .table-cart input {
    max-width: 60px;
    display: inline-block;
  }
  #saasbox_page_content .table-cart .item-remove {
    color: #929daf;
  }
  #saasbox_page_content .table-cart .item-remove:hover {
    color: #ff4954;
  }
  #saasbox_page_content .table-cart .price {
    font-weight: 100;
  }
  #saasbox_page_content .cart-price {
    border: 1px solid #eaeff4;
    border-radius: 3px;
    margin-bottom: 1rem;
    padding: 20px;
    background-color: #fcfdfe;
  }
  #saasbox_page_content .cart-price .flexbox p {
    margin-bottom: 4px;
  }
  #saasbox_page_content .cart-price .flexbox strong {
    font-weight: 400;
    font-size: .9em;
  }
  #saasbox_page_content .team-1 {
    text-align: center;
  }
  #saasbox_page_content .team-1 img {
    border-radius: .25rem;
    margin-bottom: 2rem;
  }
  #saasbox_page_content .team-1 h5,
  #saasbox_page_content .team-1 h6 {
    letter-spacing: 1px;
  }
  #saasbox_page_content .team-1 h5 small,
  #saasbox_page_content .team-1 h6 small {
    text-transform: uppercase;
    display: block;
    padding-top: .75rem;
  }
  #saasbox_page_content .team-1 small {
    display: block;
    letter-spacing: 1px;
    margin-bottom: 1rem;
    color: #929daf;
  }
  #saasbox_page_content .team-1 p {
    opacity: .9;
  }
  #saasbox_page_content .team-2 {
    text-align: center;
  }
  #saasbox_page_content .team-2 img {
    border-radius: 10rem;
    margin-bottom: 2rem;
  }
  #saasbox_page_content .team-2 small {
    display: block;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }
  #saasbox_page_content .team-3 {
    display: -webkit-box;
    display: flex;
  }
  #saasbox_page_content .team-3 img {
    border-radius: .25rem;
    max-width: 128px;
  }
  #saasbox_page_content .team-3 .team-img {
    flex-shrink: 0;
  }
  #saasbox_page_content .team-3 .team-body {
    padding-left: 2rem;
  }
  #saasbox_page_content .team-3 small {
    display: block;
    letter-spacing: 1px;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  #saasbox_page_content .team-3 p {
    opacity: .9;
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .team-3 img {
      max-width: 56px;
    }
  }
  body {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-height: 100vh;
  }
  #saasbox_page_content .container-xs,
  #saasbox_page_content .container-sm {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }
  #saasbox_page_content .container-xs {
    max-width: 640px;
  }
  #saasbox_page_content .container-sm {
    max-width: 780px;
  }
  #saasbox_page_content .main-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    position: relative;
  }
  #saasbox_page_content .main-container.container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  #saasbox_page_content .main-content {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  #saasbox_page_content .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    cursor: pointer;
  }
  #saasbox_page_content .layout-centered .main-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
  }
  #saasbox_page_content .layout-centered .main-content .section {
    width: 100%;
  }
  #saasbox_page_content .footer {
    position: relative;
    padding: 1.25rem 0;
    border-top: 1px solid #f1f2f3;
  }
  #saasbox_page_content .footer>.container,
  #saasbox_page_content .woocommerce-page .footer>.woocommerce,
  #saasbox_page_content .footer>.container-fluid,
  #saasbox_page_content .footer>.container-wide {
    position: relative;
    height: 100%;
  }
  #saasbox_page_content .row.no-gap {
    margin-left: 0;
    margin-right: 0;
  }
  #saasbox_page_content .row.no-gap>.col,
  #saasbox_page_content .row.no-gap>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  #saasbox_page_content .row.gap-1 {
    margin-left: -4px;
    margin-right: -4px;
  }
  #saasbox_page_content .row.gap-1>.col,
  #saasbox_page_content .row.gap-1>[class*="col-"] {
    padding-left: 4px;
    padding-right: 4px;
  }
  #saasbox_page_content .row.gap-2 {
    margin-left: -8px;
    margin-right: -8px;
  }
  #saasbox_page_content .row.gap-2>.col,
  #saasbox_page_content .row.gap-2>[class*="col-"] {
    padding-left: 8px;
    padding-right: 8px;
  }
  #saasbox_page_content .row.gap-3 {
    margin-left: -15px;
    margin-right: -15px;
  }
  #saasbox_page_content .row.gap-3>.col,
  #saasbox_page_content .row.gap-3>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
  #saasbox_page_content .row.gap-4 {
    margin-left: -15px;
    margin-right: -15px;
  }
  #saasbox_page_content .row.gap-4>.col,
  #saasbox_page_content .row.gap-4>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
  #saasbox_page_content .row.gap-5 {
    margin-left: -15px;
    margin-right: -15px;
  }
  #saasbox_page_content .row.gap-5>.col,
  #saasbox_page_content .row.gap-5>[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
  #saasbox_page_content .row.gap-y {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  #saasbox_page_content .row.gap-y>.col,
  #saasbox_page_content .row.gap-y>[class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  #saasbox_page_content .row.gap-y.gap-1 {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  #saasbox_page_content .row.gap-y.gap-1>.col,
  #saasbox_page_content .row.gap-y.gap-1>[class*="col-"] {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #saasbox_page_content .row.gap-y.gap-2 {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  #saasbox_page_content .row.gap-y.gap-2>.col,
  #saasbox_page_content .row.gap-y.gap-2>[class*="col-"] {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #saasbox_page_content .row.gap-y.gap-3 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  #saasbox_page_content .row.gap-y.gap-3>.col,
  #saasbox_page_content .row.gap-y.gap-3>[class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  #saasbox_page_content .row.gap-y.gap-4 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  #saasbox_page_content .row.gap-y.gap-4>.col,
  #saasbox_page_content .row.gap-y.gap-4>[class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  #saasbox_page_content .row.gap-y.gap-5 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  #saasbox_page_content .row.gap-y.gap-5>.col,
  #saasbox_page_content .row.gap-y.gap-5>[class*="col-"] {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  #saasbox_page_content .flexbox {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  #saasbox_page_content .flexbox>* {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
  }
  #saasbox_page_content .flexbox>*:first-child {
    margin-left: 0;
  }
  #saasbox_page_content .flexbox>*:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .flexbox.no-gap>* {
    margin-left: 0;
    margin-right: 0;
  }
  #saasbox_page_content ul.flexbox {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  #saasbox_page_content .flexbox-vertical {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  #saasbox_page_content .flexbox-vertical.no-gap>* {
    margin-top: 0;
    margin-bottom: 0;
  }
  #saasbox_page_content .flex-grow-all>*,
  #saasbox_page_content .flex-cols-wide>*,
  #saasbox_page_content .flex-col-wide,
  #saasbox_page_content .flex-grow {
    -webkit-box-flex: 1;
    flex: 1;
  }
  #saasbox_page_content .flex-grow-1 {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  #saasbox_page_content .flex-grow-2 {
    -webkit-box-flex: 2;
    flex-grow: 2;
  }
  #saasbox_page_content .flex-grow-3 {
    -webkit-box-flex: 3;
    flex-grow: 3;
  }
  #saasbox_page_content .flex-grow-4 {
    -webkit-box-flex: 4;
    flex-grow: 4;
  }
  #saasbox_page_content .flex-grow-5 {
    -webkit-box-flex: 5;
    flex-grow: 5;
  }
  #saasbox_page_content .flex-grow-6 {
    -webkit-box-flex: 6;
    flex-grow: 6;
  }
  #saasbox_page_content .flex-grow-7 {
    -webkit-box-flex: 7;
    flex-grow: 7;
  }
  #saasbox_page_content .flex-grow-8 {
    -webkit-box-flex: 8;
    flex-grow: 8;
  }
  #saasbox_page_content .flex-grow-9 {
    -webkit-box-flex: 9;
    flex-grow: 9;
  }
  #saasbox_page_content .flex-grow-0 {
    -webkit-box-flex: 0;
    flex-grow: 0;
  }
  #saasbox_page_content .masonry-grid {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
  #saasbox_page_content .masonry-grid.no-gap {
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }
  #saasbox_page_content .masonry-grid.no-gap .masonry-item {
    padding-bottom: 0;
  }
  #saasbox_page_content .masonry-grid.gap-1 {
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    column-gap: 8px;
  }
  #saasbox_page_content .masonry-grid.gap-1 .masonry-item {
    padding-bottom: 8px;
  }
  #saasbox_page_content .masonry-grid.gap-2 {
    -webkit-column-gap: 16px;
    -moz-column-gap: 16px;
    column-gap: 16px;
  }
  #saasbox_page_content .masonry-grid.gap-2 .masonry-item {
    padding-bottom: 16px;
  }
  #saasbox_page_content .masonry-grid.gap-3 {
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
  }
  #saasbox_page_content .masonry-grid.gap-3 .masonry-item {
    padding-bottom: 30px;
  }
  #saasbox_page_content .masonry-grid.gap-4 {
    -webkit-column-gap: 48px;
    -moz-column-gap: 48px;
    column-gap: 48px;
  }
  #saasbox_page_content .masonry-grid.gap-4 .masonry-item {
    padding-bottom: 48px;
  }
  #saasbox_page_content .masonry-grid.gap-5 {
    -webkit-column-gap: 64px;
    -moz-column-gap: 64px;
    column-gap: 64px;
  }
  #saasbox_page_content .masonry-grid.gap-5 .masonry-item {
    padding-bottom: 64px;
  }
  #saasbox_page_content .masonry-cols-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  #saasbox_page_content .masonry-cols-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  #saasbox_page_content .masonry-cols-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  #saasbox_page_content .masonry-cols-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  #saasbox_page_content .masonry-item {
    display: block;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    padding-bottom: 30px;
  }
  #saasbox_page_content .topbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f1f2f3;
    height: 40px;
    z-index: 1001;
    display: none;
  }
  #saasbox_page_content .topbar>.container,
  #saasbox_page_content .woocommerce-page .topbar>.woocommerce,
  #saasbox_page_content .topbar>.container-fluid {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    height: inherit;
  }
  #saasbox_page_content .topbar.text-white {
    border-bottom-color: rgba(255,255,255,0.05);
  }
  #saasbox_page_content .topbar-divider {
    display: inline-block;
    height: 12px;
    width: 1px;
    background-color: rgba(127,127,127,0.15);
    margin: 0 1rem;
    margin-bottom: 2px;
    vertical-align: middle;
  }
  @media (min-width: 576px) {
    #saasbox_page_content body:not(.body-scrolled) .topbar.d-sm-flex ~ .navbar {
      top: 40px;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content body:not(.body-scrolled) .topbar.d-md-flex ~ .navbar {
      top: 40px;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content body:not(.body-scrolled) .topbar.d-lg-flex ~ .navbar {
      top: 40px;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content body:not(.body-scrolled) .topbar.d-xl-flex ~ .navbar {
      top: 40px;
    }
  }
  #saasbox_page_content body:not(.body-scrolled) .topbar.d-flex ~ .navbar {
    top: 40px;
  }
  #saasbox_page_content .navbar {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 1000;
    -webkit-transition: background-color .3s ease-out, -webkit-transform .3s ease-out;
    transition: background-color .3s ease-out, -webkit-transform .3s ease-out;
    transition: transform .3s ease-out, background-color .3s ease-out;
    transition: transform .3s ease-out, background-color .3s ease-out, -webkit-transform .3s ease-out;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 56px;
  }
  #saasbox_page_content .modal-open .navbar {
    right: 16px;
  }
  #saasbox_page_content .navbar .row {
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  #saasbox_page_content .navbar-scrolled .navbar {
    top: 0;
  }
  #saasbox_page_content .navbar-offset-0 {
    top: 0;
  }
  #saasbox_page_content .navbar-left {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-shrink: 0;
  }
  #saasbox_page_content [data-navbar="static"] {
    position: static;
    border-bottom: 1px solid #eaeff4;
    top: auto;
  }
  #saasbox_page_content [data-navbar="fixed"] {
    position: fixed;
  }
  #saasbox_page_content .navbar-scrolled [data-navbar="fixed"] {
    background-color: rgba(255,255,255,0.99);
    -webkit-box-shadow: 0 1px 9px rgba(0,0,0,0.05);
    box-shadow: 0 1px 9px rgba(0,0,0,0.05);
  }
  #saasbox_page_content .navbar-scrolled [data-navbar="fixed"] .d-stick-none {
    display: none !important;
  }
  #saasbox_page_content .navbar-scrolled [data-navbar="fixed"] .d-stick-block {
    display: block !important;
  }
  #saasbox_page_content .navbar-scrolled [data-navbar="fixed"] .d-stick-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  #saasbox_page_content .navbar-scrolled [data-navbar="fixed"] .d-stick-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
  #saasbox_page_content .navbar-scrolled [data-navbar="fixed"] .d-stick-inline-block {
    display: inline-block !important;
  }
  #saasbox_page_content .body-scrolled [data-navbar="sticky"] {
    -webkit-transform: translate(0,-100%);
    transform: translate(0,-100%);
  }
  #saasbox_page_content .navbar-scrolled [data-navbar="sticky"] {
    position: fixed;
  }
  #saasbox_page_content .header-scrolled [data-navbar="sticky"] {
    top: 0;
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    background-color: rgba(255,255,255,0.99);
    -webkit-box-shadow: 0 1px 9px rgba(0,0,0,0.05);
    box-shadow: 0 1px 9px rgba(0,0,0,0.05);
  }
  #saasbox_page_content .header-scrolled [data-navbar="sticky"] .d-stick-none {
    display: none !important;
  }
  #saasbox_page_content .header-scrolled [data-navbar="sticky"] .d-stick-block {
    display: block !important;
  }
  #saasbox_page_content .header-scrolled [data-navbar="sticky"] .d-stick-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  #saasbox_page_content .header-scrolled [data-navbar="sticky"] .d-stick-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
  #saasbox_page_content .header-scrolled [data-navbar="sticky"] .d-stick-inline-block {
    display: inline-block !important;
  }
  #saasbox_page_content .body-scrolled [data-navbar="smart"] {
    -webkit-transform: translate(0,-100%);
    transform: translate(0,-100%);
  }
  #saasbox_page_content .navbar-scrolled [data-navbar="smart"] {
    position: fixed;
  }
  #saasbox_page_content [data-navbar="smart"].stick {
    top: 0;
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
    -webkit-box-shadow: 0 1px 9px rgba(0,0,0,0.05);
    box-shadow: 0 1px 9px rgba(0,0,0,0.05);
    background-color: rgba(255,255,255,0.99);
  }
  #saasbox_page_content [data-navbar="smart"].stick .d-stick-none {
    display: none !important;
  }
  #saasbox_page_content [data-navbar="smart"].stick .d-stick-block {
    display: block !important;
  }
  #saasbox_page_content [data-navbar="smart"].stick .d-stick-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  #saasbox_page_content [data-navbar="smart"].stick .d-stick-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
  #saasbox_page_content [data-navbar="smart"].stick .d-stick-inline-block {
    display: inline-block !important;
  }
  #saasbox_page_content .navbar-brand {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
  }
  #saasbox_page_content .navbar-brand img {
    vertical-align: baseline;
    max-height: 50px;
  }
  #saasbox_page_content .navbar-divider {
    display: inline-block;
    height: 16px;
    width: 1px;
    background-color: #fff;
    margin: 0 1rem;
    opacity: .15;
  }
  #saasbox_page_content .navbar-toggler {
    margin-right: 1rem;
    border: 0;
    cursor: pointer;
    font-size: 1.5rem;
  }
  #saasbox_page_content .nav-navbar {
    position: relative;
  }
  #saasbox_page_content .nav-navbar .nav-item,
  #saasbox_page_content .nav-navbar .menu-item {
    position: relative;
  }
  #saasbox_page_content .nav-navbar>.nav-item>.nav-link,
  #saasbox_page_content .nav-navbar>.menu-item>.nav-link,
  #saasbox_page_content .menu-item .nav-navbar>.nav-item>a,
  #saasbox_page_content .nav-navbar>.menu-item>a,
  #saasbox_page_content .nav-navbar>.nav-link,
  #saasbox_page_content .menu-item .nav-navbar>a {
    line-height: 56px;
    padding-top: 0;
    padding-bottom: 0;
  }
  #saasbox_page_content .nav-navbar .nav-link,
  #saasbox_page_content .nav-navbar .menu-item a,
  #saasbox_page_content .menu-item .nav-navbar a {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .87em;
    word-spacing: 2px;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  #saasbox_page_content .nav-navbar .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-bottom: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
  }
  #saasbox_page_content .nav-navbar .nav-item:hover>.nav,
  #saasbox_page_content .nav-navbar .menu-item:hover>.nav {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
  }
  #saasbox_page_content .nav-navbar .nav-item.nav-mega:hover .nav,
  #saasbox_page_content .nav-navbar .nav-mega.menu-item:hover .nav {
    opacity: 1;
    visibility: visible;
  }
  #saasbox_page_content .nav-navbar.nav-text-normal .nav-link,
  #saasbox_page_content .nav-navbar.nav-text-normal .menu-item a,
  #saasbox_page_content .menu-item .nav-navbar.nav-text-normal a {
    text-transform: none;
  }
  #saasbox_page_content .nav-navbar .nav {
    position: absolute;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-width: 11rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,0.04);
    box-shadow: 0 3px 9px rgba(0,0,0,0.04);
    border-radius: 3px;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(0,-8px);
    transform: translate(0,-8px);
    z-index: 1;
  }
  #saasbox_page_content .nav-navbar .nav.align-right {
    right: 0;
  }
  #saasbox_page_content .nav-navbar .nav.columns-2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    min-width: 22rem;
  }
  #saasbox_page_content .nav-navbar .nav.columns-2>.nav-link,
  #saasbox_page_content .nav-navbar .menu-item .nav.columns-2>a,
  #saasbox_page_content .menu-item .nav-navbar .nav.columns-2>a,
  #saasbox_page_content .nav-navbar .nav.columns-2>.nav-item,
  #saasbox_page_content .nav-navbar .nav.columns-2>.menu-item {
    flex-basis: 50%;
  }
  #saasbox_page_content .nav-navbar .nav.columns-3 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    min-width: 33rem;
  }
  #saasbox_page_content .nav-navbar .nav.columns-3>.nav-link,
  #saasbox_page_content .nav-navbar .menu-item .nav.columns-3>a,
  #saasbox_page_content .menu-item .nav-navbar .nav.columns-3>a,
  #saasbox_page_content .nav-navbar .nav.columns-3>.nav-item,
  #saasbox_page_content .nav-navbar .nav.columns-3>.menu-item {
    flex-basis: 33.3333%;
  }
  #saasbox_page_content .nav-navbar .nav .nav-link,
  #saasbox_page_content .nav-navbar .nav .menu-item a,
  #saasbox_page_content .menu-item .nav-navbar .nav a {
    font-weight: 400;
    text-transform: none;
    padding-top: .25rem;
    padding-bottom: .25rem;
    letter-spacing: .5px;
    color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .nav-navbar .nav .arrow {
    position: absolute;
    right: 1.25rem;
    top: 50%;
    margin-top: -2px;
    font-style: normal;
    border: 0;
    opacity: .7;
    width: 5px;
    height: 5px;
    border-top: 1px solid rgba(117,117,117,0.8);
    border-right: 1px solid rgba(117,117,117,0.8);
    border-top-width: 1px !important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #saasbox_page_content .nav-navbar .nav .nav-item.show>.nav-link,
  #saasbox_page_content .nav-navbar .nav .show.menu-item>.nav-link,
  #saasbox_page_content .nav-navbar .nav .menu-item .nav-item.show>a,
  #saasbox_page_content .menu-item .nav-navbar .nav .nav-item.show>a,
  #saasbox_page_content .nav-navbar .nav .show.menu-item>a,
  #saasbox_page_content .nav-navbar .nav .nav-item:hover>.nav-link,
  #saasbox_page_content .nav-navbar .nav .menu-item:hover>.nav-link,
  #saasbox_page_content .nav-navbar .nav .menu-item .nav-item:hover>a,
  #saasbox_page_content .menu-item .nav-navbar .nav .nav-item:hover>a,
  #saasbox_page_content .nav-navbar .nav .menu-item:hover>a,
  #saasbox_page_content .nav-navbar .nav .nav-link.show,
  #saasbox_page_content .nav-navbar .nav .menu-item a.show,
  #saasbox_page_content .menu-item .nav-navbar .nav a.show,
  #saasbox_page_content .nav-navbar .nav .nav-link:hover,
  #saasbox_page_content .nav-navbar .nav .menu-item a:hover,
  #saasbox_page_content .menu-item .nav-navbar .nav a:hover {
    color: #555;
  }
  #saasbox_page_content .nav-navbar .nav .nav-item.show>.nav-link .arrow,
  #saasbox_page_content .nav-navbar .nav .show.menu-item>.nav-link .arrow,
  #saasbox_page_content .nav-navbar .nav .menu-item .nav-item.show>a .arrow,
  #saasbox_page_content .menu-item .nav-navbar .nav .nav-item.show>a .arrow,
  #saasbox_page_content .nav-navbar .nav .show.menu-item>a .arrow,
  #saasbox_page_content .nav-navbar .nav .nav-item:hover>.nav-link .arrow,
  #saasbox_page_content .nav-navbar .nav .menu-item:hover>.nav-link .arrow,
  #saasbox_page_content .nav-navbar .nav .menu-item .nav-item:hover>a .arrow,
  #saasbox_page_content .menu-item .nav-navbar .nav .nav-item:hover>a .arrow,
  #saasbox_page_content .nav-navbar .nav .menu-item:hover>a .arrow,
  #saasbox_page_content .nav-navbar .nav .nav-link.show .arrow,
  #saasbox_page_content .nav-navbar .nav .menu-item a.show .arrow,
  #saasbox_page_content .menu-item .nav-navbar .nav a.show .arrow,
  #saasbox_page_content .nav-navbar .nav .nav-link:hover .arrow,
  #saasbox_page_content .nav-navbar .nav .menu-item a:hover .arrow,
  #saasbox_page_content .menu-item .nav-navbar .nav a:hover .arrow {
    border-top-color: #555;
    border-right-color: #555;
  }
  #saasbox_page_content .nav-navbar .nav .nav-item.active>.nav-link,
  #saasbox_page_content .nav-navbar .nav .active.menu-item>.nav-link,
  #saasbox_page_content .nav-navbar .nav .menu-item .nav-item.active>a,
  #saasbox_page_content .menu-item .nav-navbar .nav .nav-item.active>a,
  #saasbox_page_content .nav-navbar .nav .active.menu-item>a,
  #saasbox_page_content .nav-navbar .nav .nav-link.active,
  #saasbox_page_content .nav-navbar .nav .menu-item a.active,
  #saasbox_page_content .menu-item .nav-navbar .nav a.active {
    font-weight: 600;
    color: #323d47;
  }
  #saasbox_page_content .nav-navbar .nav .nav-item.active>.nav-link .arrow,
  #saasbox_page_content .nav-navbar .nav .active.menu-item>.nav-link .arrow,
  #saasbox_page_content .nav-navbar .nav .menu-item .nav-item.active>a .arrow,
  #saasbox_page_content .menu-item .nav-navbar .nav .nav-item.active>a .arrow,
  #saasbox_page_content .nav-navbar .nav .active.menu-item>a .arrow,
  #saasbox_page_content .nav-navbar .nav .nav-link.active .arrow,
  #saasbox_page_content .nav-navbar .nav .menu-item a.active .arrow,
  #saasbox_page_content .menu-item .nav-navbar .nav a.active .arrow {
    border-top-color: #323d47;
    border-right-color: #323d47;
  }
  #saasbox_page_content .nav-navbar .nav .nav {
    top: 0;
    left: 99%;
    -webkit-transform: translate(-8px,0);
    transform: translate(-8px,0);
  }
  #saasbox_page_content .nav-navbar .nav.align-right .nav {
    left: auto;
    right: 99%;
    -webkit-transform: translate(8px,0);
    transform: translate(8px,0);
  }
  #saasbox_page_content .nav-navbar .nav-mega {
    position: static;
  }
  #saasbox_page_content .nav-navbar .nav-mega .nav {
    left: 0;
    min-width: 100%;
  }
  #saasbox_page_content .nav-navbar .nav-mega .nav .nav {
    position: static;
    background-color: transparent;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }
  #saasbox_page_content .nav-navbar .nav-mega .row {
    -webkit-box-align: start;
    align-items: flex-start;
  }
  #saasbox_page_content .navbar-mobile {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
  }
  #saasbox_page_content .backdrop-navbar {
    background-color: rgba(255,255,255,0.95);
    z-index: 1001;
  }
  #saasbox_page_content .navbar-open {
    overflow: hidden;
  }
  #saasbox_page_content .navbar-open .navbar {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .navbar-open .navbar .logo-dark {
    display: inline-block;
  }
  #saasbox_page_content .navbar-open .navbar .logo-light {
    display: none;
  }
  #saasbox_page_content .navbar-open .navbar .navbar-toggler {
    color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-open .navbar .navbar-toggler:hover {
    color: #555;
  }
  #saasbox_page_content .navbar-open .navbar .navbar-divider {
    background-color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item>.nav-link,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item>.nav-link,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item>a,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item>a,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item>a,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-link,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>a,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>a {
    color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item>a>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>a>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>a>.arrow {
    border-top: 3px solid rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item.show>.nav-link,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.show.menu-item>.nav-link,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.show.menu-item>a,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item:hover>.nav-link,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item:hover>.nav-link,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item:hover>a,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-link.show,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>a.show,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>a.show,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-link:hover,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>a:hover,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>a:hover {
    color: #555;
  }
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item.show>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.show.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.show.menu-item>a>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item:hover>a>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-link.show>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>a.show>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>a.show>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-link:hover>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>a:hover>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>a:hover>.arrow {
    border-top-color: inherit;
  }
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item.active>.nav-link,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.active.menu-item>.nav-link,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.active.menu-item>a,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item>.nav-link.active,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item>.nav-link.active,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item>a.active,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-link.active,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>a.active,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>a.active {
    color: #323d47;
  }
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item.active>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.active.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.active.menu-item>a>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.menu-item>a.active>.arrow,
  #saasbox_page_content .navbar-open .navbar .nav-navbar>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-open .navbar .menu-item .nav-navbar>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-open .navbar .nav-navbar>a.active>.arrow {
    border-top-color: inherit;
  }
  #saasbox_page_content .navbar-open .navbar-mobile {
    left: 0 !important;
    -webkit-box-shadow: 2px 0 9px rgba(0,0,0,0.05);
    box-shadow: 2px 0 9px rgba(0,0,0,0.05);
  }
  #saasbox_page_content .navbar-open .nav-navbar>.nav-item>.nav,
  #saasbox_page_content .navbar-open .nav-navbar>.menu-item>.nav {
    display: block !important;
  }
  @media (max-width: 575.98px) {
    #saasbox_page_content .navbar-expand-sm .navbar-mobile {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-align: start;
      align-items: flex-start;
      width: 300px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: -300px;
      bottom: 0;
      padding: 20px;
      background-color: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-transition: left .3s ease;
      transition: left .3s ease;
      z-index: 1002;
    }
    #saasbox_page_content .navbar-expand-sm .navbar-mobile>* {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    #saasbox_page_content .navbar-expand-sm .navbar-divider {
      display: block;
      height: 1px;
      width: 100%;
      margin: 1rem 0;
      padding: 0;
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline)>.nav-item>.nav-link,
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline)>.menu-item>.nav-link,
    #saasbox_page_content .navbar-expand-sm .menu-item .nav-navbar:not(.nav-inline)>.nav-item>a,
    #saasbox_page_content .menu-item .navbar-expand-sm .nav-navbar:not(.nav-inline)>.nav-item>a,
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline)>.menu-item>a,
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline)>.nav-link,
    #saasbox_page_content .navbar-expand-sm .menu-item .nav-navbar:not(.nav-inline)>a,
    #saasbox_page_content .menu-item .navbar-expand-sm .nav-navbar:not(.nav-inline)>a {
      min-height: inherit;
      line-height: inherit;
      padding-top: .75rem;
      padding-bottom: .75rem;
      font-size: .95rem;
      text-transform: none;
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline) .arrow {
      float: right;
      margin-top: 12px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline) .nav-divider {
      display: none;
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline)>.nav-item>.nav,
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline)>.menu-item>.nav {
      display: none;
      border-left: 1px dashed rgba(0,0,0,0.08);
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline)>.nav-item>.nav::before,
    #saasbox_page_content .navbar-expand-sm .nav-navbar:not(.nav-inline)>.menu-item>.nav::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 9px;
      left: -4px;
      bottom: 15px;
      border-left: 1px dashed rgba(0,0,0,0.08);
      display: none;
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav {
      position: static;
      visibility: visible;
      opacity: 1;
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-left: 1.25rem;
      padding-top: 0;
      padding-bottom: 0;
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav.columns-2,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav.columns-3 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      min-width: 100%;
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav.columns-2>.nav-link,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .menu-item .nav.columns-2>a,
    #saasbox_page_content .menu-item .navbar-expand-sm .nav-navbar .nav.columns-2>a,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav.columns-2>.nav-item,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav.columns-2>.menu-item,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav.columns-3>.nav-link,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .menu-item .nav.columns-3>a,
    #saasbox_page_content .menu-item .navbar-expand-sm .nav-navbar .nav.columns-3>a,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav.columns-3>.nav-item,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav.columns-3>.menu-item {
      flex-basis: 100%;
    }
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav .nav-link,
    #saasbox_page_content .navbar-expand-sm .nav-navbar .nav .menu-item a,
    #saasbox_page_content .menu-item .navbar-expand-sm .nav-navbar .nav a {
      padding-top: .375rem;
      padding-bottom: .375rem;
      font-size: .875rem;
    }
    #saasbox_page_content .navbar-expand-sm .d-mobile-none {
      display: none !important;
    }
    #saasbox_page_content .navbar-expand-sm .d-mobile-block {
      display: block !important;
    }
    #saasbox_page_content .navbar-expand-sm .d-mobile-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .navbar-expand-sm .d-mobile-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
    #saasbox_page_content .navbar-expand-sm .d-mobile-inline-block {
      display: inline-block !important;
    }
  }
  @media (min-width: 576px) {
    #saasbox_page_content .navbar-expand-sm .backdrop-navbar {
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .navbar-expand-md .navbar-mobile {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-align: start;
      align-items: flex-start;
      width: 300px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: -300px;
      bottom: 0;
      padding: 20px;
      background-color: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-transition: left .3s ease;
      transition: left .3s ease;
      z-index: 1002;
    }
    #saasbox_page_content .navbar-expand-md .navbar-mobile>* {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    #saasbox_page_content .navbar-expand-md .navbar-divider {
      display: block;
      height: 1px;
      width: 100%;
      margin: 1rem 0;
      padding: 0;
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline)>.nav-item>.nav-link,
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline)>.menu-item>.nav-link,
    #saasbox_page_content .navbar-expand-md .menu-item .nav-navbar:not(.nav-inline)>.nav-item>a,
    #saasbox_page_content .menu-item .navbar-expand-md .nav-navbar:not(.nav-inline)>.nav-item>a,
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline)>.menu-item>a,
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline)>.nav-link,
    #saasbox_page_content .navbar-expand-md .menu-item .nav-navbar:not(.nav-inline)>a,
    #saasbox_page_content .menu-item .navbar-expand-md .nav-navbar:not(.nav-inline)>a {
      min-height: inherit;
      line-height: inherit;
      padding-top: .75rem;
      padding-bottom: .75rem;
      font-size: .95rem;
      text-transform: none;
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline) .arrow {
      float: right;
      margin-top: 12px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline) .nav-divider {
      display: none;
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline)>.nav-item>.nav,
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline)>.menu-item>.nav {
      display: none;
      border-left: 1px dashed rgba(0,0,0,0.08);
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline)>.nav-item>.nav::before,
    #saasbox_page_content .navbar-expand-md .nav-navbar:not(.nav-inline)>.menu-item>.nav::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 9px;
      left: -4px;
      bottom: 15px;
      border-left: 1px dashed rgba(0,0,0,0.08);
      display: none;
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav {
      position: static;
      visibility: visible;
      opacity: 1;
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-left: 1.25rem;
      padding-top: 0;
      padding-bottom: 0;
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav.columns-2,
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav.columns-3 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      min-width: 100%;
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav.columns-2>.nav-link,
    #saasbox_page_content .navbar-expand-md .nav-navbar .menu-item .nav.columns-2>a,
    #saasbox_page_content .menu-item .navbar-expand-md .nav-navbar .nav.columns-2>a,
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav.columns-2>.nav-item,
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav.columns-2>.menu-item,
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav.columns-3>.nav-link,
    #saasbox_page_content .navbar-expand-md .nav-navbar .menu-item .nav.columns-3>a,
    #saasbox_page_content .menu-item .navbar-expand-md .nav-navbar .nav.columns-3>a,
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav.columns-3>.nav-item,
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav.columns-3>.menu-item {
      flex-basis: 100%;
    }
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav .nav-link,
    #saasbox_page_content .navbar-expand-md .nav-navbar .nav .menu-item a,
    #saasbox_page_content .menu-item .navbar-expand-md .nav-navbar .nav a {
      padding-top: .375rem;
      padding-bottom: .375rem;
      font-size: .875rem;
    }
    #saasbox_page_content .navbar-expand-md .d-mobile-none {
      display: none !important;
    }
    #saasbox_page_content .navbar-expand-md .d-mobile-block {
      display: block !important;
    }
    #saasbox_page_content .navbar-expand-md .d-mobile-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .navbar-expand-md .d-mobile-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
    #saasbox_page_content .navbar-expand-md .d-mobile-inline-block {
      display: inline-block !important;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .navbar-expand-md .backdrop-navbar {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .navbar-expand-lg .navbar-mobile {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-align: start;
      align-items: flex-start;
      width: 300px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: -300px;
      bottom: 0;
      padding: 20px;
      background-color: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-transition: left .3s ease;
      transition: left .3s ease;
      z-index: 1002;
    }
    #saasbox_page_content .navbar-expand-lg .navbar-mobile>* {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    #saasbox_page_content .navbar-expand-lg .navbar-divider {
      display: block;
      height: 1px;
      width: 100%;
      margin: 1rem 0;
      padding: 0;
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline)>.nav-item>.nav-link,
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline)>.menu-item>.nav-link,
    #saasbox_page_content .navbar-expand-lg .menu-item .nav-navbar:not(.nav-inline)>.nav-item>a,
    #saasbox_page_content .menu-item .navbar-expand-lg .nav-navbar:not(.nav-inline)>.nav-item>a,
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline)>.menu-item>a,
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline)>.nav-link,
    #saasbox_page_content .navbar-expand-lg .menu-item .nav-navbar:not(.nav-inline)>a,
    #saasbox_page_content .menu-item .navbar-expand-lg .nav-navbar:not(.nav-inline)>a {
      min-height: inherit;
      line-height: inherit;
      padding-top: .75rem;
      padding-bottom: .75rem;
      font-size: .95rem;
      text-transform: none;
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline) .arrow {
      float: right;
      margin-top: 12px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline) .nav-divider {
      display: none;
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline)>.nav-item>.nav,
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline)>.menu-item>.nav {
      display: none;
      border-left: 1px dashed rgba(0,0,0,0.08);
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline)>.nav-item>.nav::before,
    #saasbox_page_content .navbar-expand-lg .nav-navbar:not(.nav-inline)>.menu-item>.nav::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 9px;
      left: -4px;
      bottom: 15px;
      border-left: 1px dashed rgba(0,0,0,0.08);
      display: none;
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav {
      position: static;
      visibility: visible;
      opacity: 1;
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-left: 1.25rem;
      padding-top: 0;
      padding-bottom: 0;
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav.columns-2,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav.columns-3 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      min-width: 100%;
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav.columns-2>.nav-link,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .menu-item .nav.columns-2>a,
    #saasbox_page_content .menu-item .navbar-expand-lg .nav-navbar .nav.columns-2>a,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav.columns-2>.nav-item,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav.columns-2>.menu-item,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav.columns-3>.nav-link,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .menu-item .nav.columns-3>a,
    #saasbox_page_content .menu-item .navbar-expand-lg .nav-navbar .nav.columns-3>a,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav.columns-3>.nav-item,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav.columns-3>.menu-item {
      flex-basis: 100%;
    }
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav .nav-link,
    #saasbox_page_content .navbar-expand-lg .nav-navbar .nav .menu-item a,
    #saasbox_page_content .menu-item .navbar-expand-lg .nav-navbar .nav a {
      padding-top: .375rem;
      padding-bottom: .375rem;
      font-size: .875rem;
    }
    #saasbox_page_content .navbar-expand-lg .d-mobile-none {
      display: none !important;
    }
    #saasbox_page_content .navbar-expand-lg .d-mobile-block {
      display: block !important;
    }
    #saasbox_page_content .navbar-expand-lg .d-mobile-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .navbar-expand-lg .d-mobile-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
    #saasbox_page_content .navbar-expand-lg .d-mobile-inline-block {
      display: inline-block !important;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .navbar-expand-lg .backdrop-navbar {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    #saasbox_page_content .navbar-expand-xl .navbar-mobile {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-align: start;
      align-items: flex-start;
      width: 300px;
      height: 100vh;
      position: fixed;
      top: 0;
      left: -300px;
      bottom: 0;
      padding: 20px;
      background-color: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-transition: left .3s ease;
      transition: left .3s ease;
      z-index: 1002;
    }
    #saasbox_page_content .navbar-expand-xl .navbar-mobile>* {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    #saasbox_page_content .navbar-expand-xl .navbar-divider {
      display: block;
      height: 1px;
      width: 100%;
      margin: 1rem 0;
      padding: 0;
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline)>.nav-item>.nav-link,
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline)>.menu-item>.nav-link,
    #saasbox_page_content .navbar-expand-xl .menu-item .nav-navbar:not(.nav-inline)>.nav-item>a,
    #saasbox_page_content .menu-item .navbar-expand-xl .nav-navbar:not(.nav-inline)>.nav-item>a,
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline)>.menu-item>a,
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline)>.nav-link,
    #saasbox_page_content .navbar-expand-xl .menu-item .nav-navbar:not(.nav-inline)>a,
    #saasbox_page_content .menu-item .navbar-expand-xl .nav-navbar:not(.nav-inline)>a {
      min-height: inherit;
      line-height: inherit;
      padding-top: .75rem;
      padding-bottom: .75rem;
      font-size: .95rem;
      text-transform: none;
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline) .arrow {
      float: right;
      margin-top: 12px;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline) .nav-divider {
      display: none;
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline)>.nav-item>.nav,
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline)>.menu-item>.nav {
      display: none;
      border-left: 1px dashed rgba(0,0,0,0.08);
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline)>.nav-item>.nav::before,
    #saasbox_page_content .navbar-expand-xl .nav-navbar:not(.nav-inline)>.menu-item>.nav::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 9px;
      left: -4px;
      bottom: 15px;
      border-left: 1px dashed rgba(0,0,0,0.08);
      display: none;
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav {
      position: static;
      visibility: visible;
      opacity: 1;
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-left: 1.25rem;
      padding-top: 0;
      padding-bottom: 0;
      -webkit-transform: translate(0,0);
      transform: translate(0,0);
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav.columns-2,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav.columns-3 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      min-width: 100%;
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav.columns-2>.nav-link,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .menu-item .nav.columns-2>a,
    #saasbox_page_content .menu-item .navbar-expand-xl .nav-navbar .nav.columns-2>a,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav.columns-2>.nav-item,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav.columns-2>.menu-item,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav.columns-3>.nav-link,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .menu-item .nav.columns-3>a,
    #saasbox_page_content .menu-item .navbar-expand-xl .nav-navbar .nav.columns-3>a,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav.columns-3>.nav-item,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav.columns-3>.menu-item {
      flex-basis: 100%;
    }
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav .nav-link,
    #saasbox_page_content .navbar-expand-xl .nav-navbar .nav .menu-item a,
    #saasbox_page_content .menu-item .navbar-expand-xl .nav-navbar .nav a {
      padding-top: .375rem;
      padding-bottom: .375rem;
      font-size: .875rem;
    }
    #saasbox_page_content .navbar-expand-xl .d-mobile-none {
      display: none !important;
    }
    #saasbox_page_content .navbar-expand-xl .d-mobile-block {
      display: block !important;
    }
    #saasbox_page_content .navbar-expand-xl .d-mobile-flex {
      display: -webkit-box !important;
      display: flex !important;
    }
    #saasbox_page_content .navbar-expand-xl .d-mobile-inline-flex {
      display: -webkit-inline-box !important;
      display: inline-flex !important;
    }
    #saasbox_page_content .navbar-expand-xl .d-mobile-inline-block {
      display: inline-block !important;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .navbar-expand-xl .backdrop-navbar {
      display: none;
    }
  }
  #saasbox_page_content .navbar-expand .navbar-mobile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: start;
    align-items: flex-start;
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -300px;
    bottom: 0;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transition: left .3s ease;
    transition: left .3s ease;
    z-index: 1002;
  }
  #saasbox_page_content .navbar-expand .navbar-mobile>* {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #saasbox_page_content .navbar-expand .navbar-divider {
    display: block;
    height: 1px;
    width: 100%;
    margin: 1rem 0;
    padding: 0;
  }
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline)>.nav-item>.nav-link,
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline)>.menu-item>.nav-link,
  #saasbox_page_content .navbar-expand .menu-item .nav-navbar:not(.nav-inline)>.nav-item>a,
  #saasbox_page_content .menu-item .navbar-expand .nav-navbar:not(.nav-inline)>.nav-item>a,
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline)>.menu-item>a,
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline)>.nav-link,
  #saasbox_page_content .navbar-expand .menu-item .nav-navbar:not(.nav-inline)>a,
  #saasbox_page_content .menu-item .navbar-expand .nav-navbar:not(.nav-inline)>a {
    min-height: inherit;
    line-height: inherit;
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: .95rem;
    text-transform: none;
  }
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline) .arrow {
    float: right;
    margin-top: 12px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline) .nav-divider {
    display: none;
  }
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline)>.nav-item>.nav,
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline)>.menu-item>.nav {
    display: none;
    border-left: 1px dashed rgba(0,0,0,0.08);
  }
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline)>.nav-item>.nav::before,
  #saasbox_page_content .navbar-expand .nav-navbar:not(.nav-inline)>.menu-item>.nav::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 9px;
    left: -4px;
    bottom: 15px;
    border-left: 1px dashed rgba(0,0,0,0.08);
    display: none;
  }
  #saasbox_page_content .navbar-expand .nav-navbar .nav {
    position: static;
    visibility: visible;
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: 1.25rem;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
  }
  #saasbox_page_content .navbar-expand .nav-navbar .nav.columns-2,
  #saasbox_page_content .navbar-expand .nav-navbar .nav.columns-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-width: 100%;
  }
  #saasbox_page_content .navbar-expand .nav-navbar .nav.columns-2>.nav-link,
  #saasbox_page_content .navbar-expand .nav-navbar .menu-item .nav.columns-2>a,
  #saasbox_page_content .menu-item .navbar-expand .nav-navbar .nav.columns-2>a,
  #saasbox_page_content .navbar-expand .nav-navbar .nav.columns-2>.nav-item,
  #saasbox_page_content .navbar-expand .nav-navbar .nav.columns-2>.menu-item,
  #saasbox_page_content .navbar-expand .nav-navbar .nav.columns-3>.nav-link,
  #saasbox_page_content .navbar-expand .nav-navbar .menu-item .nav.columns-3>a,
  #saasbox_page_content .menu-item .navbar-expand .nav-navbar .nav.columns-3>a,
  #saasbox_page_content .navbar-expand .nav-navbar .nav.columns-3>.nav-item,
  #saasbox_page_content .navbar-expand .nav-navbar .nav.columns-3>.menu-item {
    flex-basis: 100%;
  }
  #saasbox_page_content .navbar-expand .nav-navbar .nav .nav-link,
  #saasbox_page_content .navbar-expand .nav-navbar .nav .menu-item a,
  #saasbox_page_content .menu-item .navbar-expand .nav-navbar .nav a {
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: .875rem;
  }
  #saasbox_page_content .navbar-expand .d-mobile-none {
    display: none !important;
  }
  #saasbox_page_content .navbar-expand .d-mobile-block {
    display: block !important;
  }
  #saasbox_page_content .navbar-expand .d-mobile-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  #saasbox_page_content .navbar-expand .d-mobile-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
  #saasbox_page_content .navbar-expand .d-mobile-inline-block {
    display: inline-block !important;
  }
  #saasbox_page_content .navbar-expand .backdrop-navbar {
    display: none;
  }
  #saasbox_page_content .navbar-light .logo-dark,
  #saasbox_page_content .navbar-stick-light.stick .logo-dark {
    display: none;
  }
  #saasbox_page_content .navbar-light .logo-light,
  #saasbox_page_content .navbar-stick-light.stick .logo-light {
    display: inline-block;
  }
  #saasbox_page_content .navbar-light .navbar-toggler,
  #saasbox_page_content .navbar-stick-light.stick .navbar-toggler {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .navbar-light .navbar-toggler:hover,
  #saasbox_page_content .navbar-stick-light.stick .navbar-toggler:hover {
    color: #fff;
  }
  #saasbox_page_content .navbar-light .navbar-divider,
  #saasbox_page_content .navbar-stick-light.stick .navbar-divider {
    background-color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item>.nav-link,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item>.nav-link,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item>a,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item>a,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item>a,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-link,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>a,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item>a,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>a,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>a {
    color: rgba(255,255,255,0.65);
  }
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item>a>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>a>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>a>.arrow {
    border-top: 3px solid rgba(255,255,255,0.65);
  }
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item.show>.nav-link,
  #saasbox_page_content .navbar-light .nav-navbar>.show.menu-item>.nav-link,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .navbar-light .nav-navbar>.show.menu-item>a,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item:hover>.nav-link,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item:hover>.nav-link,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item:hover>a,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-link.show,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>a.show,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>a.show,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-link:hover,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>a:hover,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>a:hover,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item.show>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.show.menu-item>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.show.menu-item>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item:hover>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item:hover>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item:hover>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-link.show,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>a.show,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>a.show,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-link:hover,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>a:hover,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>a:hover {
    color: #fff;
  }
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item.show>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.show.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.show.menu-item>a>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item:hover>a>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-link.show>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>a.show>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>a.show>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-link:hover>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>a:hover>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>a:hover>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item.show>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.show.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.show.menu-item>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item:hover>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-link.show>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>a.show>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>a.show>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-link:hover>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>a:hover>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>a:hover>.arrow {
    border-top-color: inherit;
  }
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item.active>.nav-link,
  #saasbox_page_content .navbar-light .nav-navbar>.active.menu-item>.nav-link,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .navbar-light .nav-navbar>.active.menu-item>a,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item>.nav-link.active,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item>.nav-link.active,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item>a.active,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-link.active,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>a.active,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>a.active,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item.active>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.active.menu-item>.nav-link,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.active.menu-item>a,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item>.nav-link.active,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item>.nav-link.active,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item>a.active,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-link.active,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>a.active,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>a.active {
    color: #fff;
  }
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item.active>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.active.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.active.menu-item>a>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.menu-item>a.active>.arrow,
  #saasbox_page_content .navbar-light .nav-navbar>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-light .menu-item .nav-navbar>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-light .nav-navbar>a.active>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item.active>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.active.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.active.menu-item>a>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.menu-item>a.active>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .nav-navbar>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-stick-light.stick .menu-item .nav-navbar>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-light.stick .nav-navbar>a.active>.arrow {
    border-top-color: inherit;
  }
  #saasbox_page_content .navbar-dark .logo-dark,
  #saasbox_page_content .navbar-stick-dark.stick .logo-dark {
    display: inline-block;
  }
  #saasbox_page_content .navbar-dark .logo-light,
  #saasbox_page_content .navbar-stick-dark.stick .logo-light {
    display: none;
  }
  #saasbox_page_content .navbar-dark .navbar-toggler,
  #saasbox_page_content .navbar-stick-dark.stick .navbar-toggler {
    color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-dark .navbar-toggler:hover,
  #saasbox_page_content .navbar-stick-dark.stick .navbar-toggler:hover {
    color: #555;
  }
  #saasbox_page_content .navbar-dark .navbar-divider,
  #saasbox_page_content .navbar-stick-dark.stick .navbar-divider {
    background-color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item>.nav-link,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item>.nav-link,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item>a,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item>a,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item>a,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-link,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>a,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item>a,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>a,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>a {
    color: rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item>a>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>a>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>a>.arrow {
    border-top: 3px solid rgba(117,117,117,0.8);
  }
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item.show>.nav-link,
  #saasbox_page_content .navbar-dark .nav-navbar>.show.menu-item>.nav-link,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .navbar-dark .nav-navbar>.show.menu-item>a,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item:hover>.nav-link,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item:hover>.nav-link,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item:hover>a,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-link.show,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>a.show,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>a.show,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-link:hover,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>a:hover,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>a:hover,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item.show>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.show.menu-item>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item.show>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.show.menu-item>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item:hover>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item:hover>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item:hover>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item:hover>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-link.show,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>a.show,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>a.show,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-link:hover,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>a:hover,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>a:hover {
    color: #555;
  }
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item.show>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.show.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.show.menu-item>a>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item:hover>a>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-link.show>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>a.show>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>a.show>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-link:hover>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>a:hover>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>a:hover>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item.show>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.show.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item.show>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.show.menu-item>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item:hover>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item:hover>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item:hover>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-link.show>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>a.show>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>a.show>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-link:hover>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>a:hover>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>a:hover>.arrow {
    border-top-color: inherit;
  }
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item.active>.nav-link,
  #saasbox_page_content .navbar-dark .nav-navbar>.active.menu-item>.nav-link,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .navbar-dark .nav-navbar>.active.menu-item>a,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item>.nav-link.active,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item>.nav-link.active,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item>a.active,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-link.active,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>a.active,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>a.active,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item.active>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.active.menu-item>.nav-link,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item.active>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.active.menu-item>a,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item>.nav-link.active,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item>.nav-link.active,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item>a.active,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item>a.active,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-link.active,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>a.active,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>a.active {
    color: #323d47;
  }
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item.active>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.active.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.active.menu-item>a>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.menu-item>a.active>.arrow,
  #saasbox_page_content .navbar-dark .nav-navbar>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-dark .menu-item .nav-navbar>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-dark .nav-navbar>a.active>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item.active>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.active.menu-item>.nav-link>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item.active>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.active.menu-item>a>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>.nav-item>a.active>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.menu-item>a.active>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .nav-navbar>.nav-link.active>.arrow,
  #saasbox_page_content .navbar-stick-dark.stick .menu-item .nav-navbar>a.active>.arrow,
  #saasbox_page_content .menu-item .navbar-stick-dark.stick .nav-navbar>a.active>.arrow {
    border-top-color: inherit;
  }
  #saasbox_page_content .offcanvas {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    background-color: #fff;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.05);
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
    z-index: 1010;
    visibility: hidden;
  }
  #saasbox_page_content .offcanvas>.container,
  #saasbox_page_content .woocommerce-page .offcanvas>.woocommerce,
  #saasbox_page_content .offcanvas>.container-fluid {
    position: relative;
    height: 100%;
  }
  #saasbox_page_content .offcanvas[data-animation] {
    -webkit-transition: .4s ease-out;
    transition: .4s ease-out;
  }
  #saasbox_page_content .offcanvas[data-animation="fade"] {
    opacity: 0;
  }
  #saasbox_page_content .offcanvas[data-animation="slide-up"] {
    opacity: 1;
    -webkit-transform: translate(0,100%);
    transform: translate(0,100%);
  }
  #saasbox_page_content .offcanvas[data-animation="slide-down"] {
    -webkit-transform: translate(0,-100%);
    transform: translate(0,-100%);
  }
  #saasbox_page_content .offcanvas[data-animation="slide-left"] {
    left: auto;
    right: 0;
    -webkit-transform: translate(100%,0);
    transform: translate(100%,0);
  }
  #saasbox_page_content .offcanvas[data-animation="slide-right"] {
    -webkit-transform: translate(-100%,0);
    transform: translate(-100%,0);
  }
  #saasbox_page_content .offcanvas.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
  }
  #saasbox_page_content .offcanvas .close {
    float: none;
    padding: .75rem 1rem;
    font-weight: 100;
    font-size: 2.5rem;
    -webkit-transition: color .3s ease-out;
    transition: color .3s ease-out;
  }
  #saasbox_page_content .offcanvas .close:not(.position-static) {
    position: absolute;
    top: 0;
    right: 0;
  }
  #saasbox_page_content .offcanvas.bg-img {
    position: fixed;
  }
  #saasbox_page_content .backdrop-offcanvas {
    z-index: 1009;
  }
  #saasbox_page_content .popup {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    padding: 1.5rem;
    background-color: #fff;
    border: 1px solid #f1f2f3;
    border-radius: .25rem;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.05);
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
    -webkit-transition: .4s ease-out;
    transition: .4s ease-out;
    z-index: 1010;
    visibility: hidden;
    opacity: 0;
  }
  #saasbox_page_content .popup.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(0,0) !important;
    transform: translate(0,0) !important;
  }
  #saasbox_page_content .popup.show[data-position$="-center"] {
    -webkit-transform: translate(-50%,0) !important;
    transform: translate(-50%,0) !important;
  }
  #saasbox_page_content .popup[data-position="top-left"] {
    right: auto;
    bottom: auto;
    top: 2rem;
    left: 2rem;
  }
  #saasbox_page_content .popup[data-position="top-right"] {
    bottom: auto;
    top: 2rem;
  }
  #saasbox_page_content .popup[data-position="bottom-left"] {
    right: auto;
    left: 2rem;
  }
  #saasbox_page_content .popup[data-position="top-center"] {
    right: auto;
    bottom: auto;
    top: 2rem;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
  #saasbox_page_content .popup[data-position="bottom-center"] {
    right: auto;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
  #saasbox_page_content .popup[data-animation="slide-up"] {
    -webkit-transform: translate(0,100%);
    transform: translate(0,100%);
  }
  #saasbox_page_content .popup[data-animation="slide-up"][data-position$="-center"] {
    -webkit-transform: translate(-50%,100%);
    transform: translate(-50%,100%);
  }
  #saasbox_page_content .popup[data-animation="slide-down"] {
    -webkit-transform: translate(0,-100%);
    transform: translate(0,-100%);
  }
  #saasbox_page_content .popup[data-animation="slide-down"][data-position$="-center"] {
    -webkit-transform: translate(-50%,-100%);
    transform: translate(-50%,-100%);
  }
  #saasbox_page_content .popup[data-animation="slide-left"] {
    -webkit-transform: translate(100%,0);
    transform: translate(100%,0);
  }
  #saasbox_page_content .popup[data-animation="slide-right"] {
    -webkit-transform: translate(-100%,0);
    transform: translate(-100%,0);
  }
  #saasbox_page_content .popup .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1rem;
    font-weight: 100;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .header {
    position: relative;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 146px;
    padding-bottom: 90px;
  }
  #saasbox_page_content .header>.container,
  #saasbox_page_content .woocommerce-page .header>.woocommerce,
  #saasbox_page_content .header>.container-fluid {
    position: relative;
    height: 100%;
  }
  #saasbox_page_content .header.h-fullscreen {
    padding-top: 90px;
  }
  #saasbox_page_content .section {
    position: relative;
    border-bottom: 0;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  #saasbox_page_content .section>.container,
  #saasbox_page_content .woocommerce-page .section>.woocommerce,
  #saasbox_page_content .section>.container-fluid,
  #saasbox_page_content .section>.container-wide {
    position: relative;
    height: 100%;
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .section {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
  #saasbox_page_content .section-header {
    text-align: center;
    max-width: 70%;
    margin: 0 auto 70px;
  }
  #saasbox_page_content .section-header small {
    display: inline-block;
    font-size: .6875rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .75px;
    margin-bottom: 1.5rem;
    word-spacing: 2px;
    color: rgba(153,153,153,0.6);
  }
  #saasbox_page_content .section-header hr {
    width: 50px;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
  #saasbox_page_content .section-header p {
    font-size: 1.0625rem;
  }
  #saasbox_page_content .section-header p.lead,
  #saasbox_page_content .section-header .pricing-4 .plan-price p,
  #saasbox_page_content .pricing-4 .plan-price .section-header p {
    font-size: 1.125rem;
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .section-header {
      max-width: 90%;
    }
  }
  #saasbox_page_content .section-video {
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
  }
  #saasbox_page_content .section-video>.container,
  #saasbox_page_content .woocommerce-page .section-video>.woocommerce,
  #saasbox_page_content .section-video>.container-fluid,
  #saasbox_page_content .section-video>.container-wide {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  #saasbox_page_content .section-dialog {
    background-color: #fff;
    padding: 1rem;
    border-radius: 4px;
  }
  @media (min-width: 768px) {
    #saasbox_page_content .section-dialog {
      padding: 20px 24px;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .section-dialog {
      padding: 30px 32px;
    }
  }
  #saasbox_page_content .sidebar {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  #saasbox_page_content .sidebar hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border-top-style: solid;
  }
  #saasbox_page_content .sidebar .nav-sidebar {
    height: 100%;
  }
  @media (max-width: 991.98px) {
    #saasbox_page_content .sidebar {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
  #saasbox_page_content .sidebar-fixed {
    position: fixed;
    top: 0;
    left: -240px;
    bottom: 0;
    width: 240px;
    background-color: #fff;
    border-right: 1px solid #f1f2f3;
    z-index: 1000;
    -webkit-transition: left .3s ease-out;
    transition: left .3s ease-out;
  }
  #saasbox_page_content .sidebar-open .sidebar-fixed {
    left: 0;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.05);
    box-shadow: 0 0 15px rgba(0,0,0,0.05);
  }
  #saasbox_page_content .sidebar-sticky.stick:not(.is-mobile-wide) {
    position: fixed;
    top: 0;
  }
  #saasbox_page_content .sidebar-sticky.is-mobile-wide {
    padding-bottom: 0 !important;
  }
  #saasbox_page_content .sidebar-header {
    text-align: center;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #f1f2f3;
  }
  #saasbox_page_content .sidebar-title,
  #saasbox_page_content .widget-title {
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: .85em;
    margin-bottom: 1rem;
  }
  #saasbox_page_content .sidebar-body {
    position: relative;
    padding-right: 20px;
  }
  #saasbox_page_content .sidebar-footer {
    text-align: center;
    padding: 20px 0;
    margin-top: 30px;
    border-top: 1px solid #f1f2f3;
  }
  #saasbox_page_content .sidebar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    cursor: pointer;
    background: 0;
    border: 0;
    border-radius: .25rem;
    margin-right: .5rem;
    margin-bottom: -1px;
  }
  #saasbox_page_content .backdrop-sidebar {
    z-index: 999;
  }
  @media (min-width: 576px) {
    #saasbox_page_content .sidebar-fixed.sidebar-expand-sm {
      position: fixed;
      left: 0;
      background-color: #f5f6f7;
      -webkit-box-shadow: 3px 0 5px rgba(0,0,0,0.015);
      box-shadow: 3px 0 5px rgba(0,0,0,0.015);
    }
    #saasbox_page_content .sidebar-fixed.sidebar-expand-sm ~ .main-content {
      margin-left: 240px;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content .sidebar-fixed.sidebar-expand-md {
      position: fixed;
      left: 0;
      background-color: #f5f6f7;
      -webkit-box-shadow: 3px 0 5px rgba(0,0,0,0.015);
      box-shadow: 3px 0 5px rgba(0,0,0,0.015);
    }
    #saasbox_page_content .sidebar-fixed.sidebar-expand-md ~ .main-content {
      margin-left: 240px;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content .sidebar-fixed.sidebar-expand-lg {
      position: fixed;
      left: 0;
      background-color: #f5f6f7;
      -webkit-box-shadow: 3px 0 5px rgba(0,0,0,0.015);
      box-shadow: 3px 0 5px rgba(0,0,0,0.015);
    }
    #saasbox_page_content .sidebar-fixed.sidebar-expand-lg ~ .main-content {
      margin-left: 240px;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content .sidebar-fixed.sidebar-expand-xl {
      position: fixed;
      left: 0;
      background-color: #f5f6f7;
      -webkit-box-shadow: 3px 0 5px rgba(0,0,0,0.015);
      box-shadow: 3px 0 5px rgba(0,0,0,0.015);
    }
    #saasbox_page_content .sidebar-fixed.sidebar-expand-xl ~ .main-content {
      margin-left: 240px;
    }
  }
  #saasbox_page_content .sidebar-fixed.sidebar-expand {
    position: fixed;
    left: 0;
    background-color: #f5f6f7;
    -webkit-box-shadow: 3px 0 5px rgba(0,0,0,0.015);
    box-shadow: 3px 0 5px rgba(0,0,0,0.015);
  }
  #saasbox_page_content .sidebar-fixed.sidebar-expand ~ .main-content {
    margin-left: 240px;
  }
  #saasbox_page_content [data-sticky="true"].stick:not(.navbar) {
    position: fixed;
    top: 0;
  }
  #saasbox_page_content .bt-1,
  #saasbox_page_content .border-top-1 {
    border-top: 1px solid #f1f2f3;
  }
  #saasbox_page_content .bl-1,
  #saasbox_page_content .border-left-1 {
    border-left: 1px solid #f1f2f3;
  }
  #saasbox_page_content .br-1,
  #saasbox_page_content .border-right-1 {
    border-right: 1px solid #f1f2f3;
  }
  #saasbox_page_content .bb-1,
  #saasbox_page_content .border-bottom-1 {
    border-bottom: 1px solid #f1f2f3;
  }
  #saasbox_page_content .bt-2,
  #saasbox_page_content .border-top-2 {
    border-top: 2px solid #f1f2f3;
  }
  #saasbox_page_content .bl-2,
  #saasbox_page_content .border-left-2 {
    border-left: 2px solid #f1f2f3;
  }
  #saasbox_page_content .br-2,
  #saasbox_page_content .border-right-2 {
    border-right: 2px solid #f1f2f3;
  }
  #saasbox_page_content .bb-2,
  #saasbox_page_content .border-bottom-2 {
    border-bottom: 2px solid #f1f2f3;
  }
  #saasbox_page_content .bt-3,
  #saasbox_page_content .border-top-3 {
    border-top: 3px solid #f1f2f3;
  }
  #saasbox_page_content .bl-3,
  #saasbox_page_content .border-left-3 {
    border-left: 3px solid #f1f2f3;
  }
  #saasbox_page_content .br-3,
  #saasbox_page_content .border-right-3 {
    border-right: 3px solid #f1f2f3;
  }
  #saasbox_page_content .bb-3,
  #saasbox_page_content .border-bottom-3 {
    border-bottom: 3px solid #f1f2f3;
  }
  #saasbox_page_content .bt-0,
  #saasbox_page_content .border-top-0 {
    border-top-width: 0;
  }
  #saasbox_page_content .bl-0,
  #saasbox_page_content .border-left-0 {
    border-left-width: 0;
  }
  #saasbox_page_content .br-0,
  #saasbox_page_content .border-right-0 {
    border-right-width: 0;
  }
  #saasbox_page_content .bb-0,
  #saasbox_page_content .border-bottom-0 {
    border-bottom-width: 0;
  }
  #saasbox_page_content .b-1 {
    border: 1px solid #f1f2f3;
  }
  #saasbox_page_content .b-2 {
    border: 2px solid #f1f2f3;
  }
  #saasbox_page_content .b-3 {
    border: 3px solid #f1f2f3;
  }
  #saasbox_page_content .b-0 {
    border-width: 0;
  }
  #saasbox_page_content .rounded-md {
    border-radius: .5rem !important;
  }
  #saasbox_page_content .rounded-lg {
    border-radius: .75rem !important;
  }
  #saasbox_page_content .rounded-xl {
    border-radius: 1rem !important;
  }
  #saasbox_page_content .border-glass,
  #saasbox_page_content .border-glass-1 {
    border-color: rgba(255,255,255,0.35) !important;
  }
  #saasbox_page_content .border-glass-2 {
    border-color: rgba(255,255,255,0.25) !important;
  }
  #saasbox_page_content .border-glass-3 {
    border-color: rgba(255,255,255,0.15) !important;
  }
  #saasbox_page_content .bg-gray {
    background-color: #fafbfb;
  }
  #saasbox_page_content .bg-pale-primary {
    background-color: #eef4fc !important;
  }
  #saasbox_page_content .bg-pale-secondary {
    background-color: #f0f4f7 !important;
  }
  #saasbox_page_content .bg-pale-success {
    background-color: #eefcf0 !important;
  }
  #saasbox_page_content .bg-pale-danger {
    background-color: #fceeef !important;
  }
  #saasbox_page_content .bg-pale-warning {
    background-color: #fcf8ee !important;
  }
  #saasbox_page_content .bg-pale-info {
    background-color: #f2eefc !important;
  }
  #saasbox_page_content .bg-pale-light {
    background-color: #fff !important;
  }
  #saasbox_page_content .bg-pale-dark {
    background-color: #e5e5e5 !important;
  }
  #saasbox_page_content .bg-gradient-primary {
    background-image: -webkit-linear-gradient(45deg,#50a1ff 0,#88bfff 100%) !important;
    background-image: linear-gradient(45deg,#50a1ff 0,#88bfff 100%) !important;
  }
  #saasbox_page_content .bg-gradient-secondary {
    background-image: -webkit-linear-gradient(45deg,#e9ecf0 0,#f0f2f5 100%) !important;
    background-image: linear-gradient(45deg,#e9ecf0 0,#f0f2f5 100%) !important;
  }
  #saasbox_page_content .bg-gradient-success {
    background-image: -webkit-linear-gradient(45deg,#3cd458 0,#7ae28d 100%) !important;
    background-image: linear-gradient(45deg,#3cd458 0,#7ae28d 100%) !important;
  }
  #saasbox_page_content .bg-gradient-danger {
    background-image: -webkit-linear-gradient(45deg,#ff4954 0,#ff838b 100%) !important;
    background-image: linear-gradient(45deg,#ff4954 0,#ff838b 100%) !important;
  }
  #saasbox_page_content .bg-gradient-warning {
    background-image: -webkit-linear-gradient(45deg,#ffba00 0,#ffd052 100%) !important;
    background-image: linear-gradient(45deg,#ffba00 0,#ffd052 100%) !important;
  }
  #saasbox_page_content .bg-gradient-info {
    background-image: -webkit-linear-gradient(45deg,#926dde 0,#b59ce9 100%) !important;
    background-image: linear-gradient(45deg,#926dde 0,#b59ce9 100%) !important;
  }
  #saasbox_page_content .bg-gradient-dark {
    background-image: -webkit-linear-gradient(45deg,#191919 0,#636363 100%) !important;
    background-image: linear-gradient(45deg,#191919 0,#636363 100%) !important;
  }
  #saasbox_page_content .text-default {
    color: #757575 !important;
  }
  #saasbox_page_content a.text-default:hover,
  #saasbox_page_content a.text-default:focus {
    color: #4f4f4f !important;
  }
  #saasbox_page_content .text-light {
    color: #999 !important;
  }
  #saasbox_page_content a.text-light:hover,
  #saasbox_page_content a.text-light:focus {
    color: #737373 !important;
  }
  #saasbox_page_content .text-lighter {
    color: #bfc5ca !important;
  }
  #saasbox_page_content a.text-lighter:hover,
  #saasbox_page_content a.text-lighter:focus {
    color: #959fa7 !important;
  }
  #saasbox_page_content .text-lightest {
    color: #d3d3d3 !important;
  }
  #saasbox_page_content a.text-lightest:hover,
  #saasbox_page_content a.text-lightest:focus {
    color: #adadad !important;
  }
  #saasbox_page_content .text-inherit {
    color: inherit;
  }
  #saasbox_page_content .link-muted a {
    color: #999;
  }
  #saasbox_page_content .bg-img {
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
  }
  #saasbox_page_content .bg-fixed {
    background-attachment: fixed;
  }
  @media (max-width: 640px) {
    #saasbox_page_content .bg-fixed {
      background-attachment: initial;
    }
  }
  #saasbox_page_content .bg-repeat {
    background-repeat: repeat;
    -webkit-background-size: auto auto;
    background-size: auto;
  }
  #saasbox_page_content .bg-img-left {
    background-position: left center;
  }
  #saasbox_page_content .bg-img-right {
    background-position: right center;
  }
  #saasbox_page_content .bg-img-top {
    background-position: center top;
  }
  #saasbox_page_content .bg-img-bottom {
    background-position: center bottom;
  }
  #saasbox_page_content .bg-size-contain {
    -webkit-background-size: contain;
    background-size: contain;
  }
  @media (max-width: 767px) {
    #saasbox_page_content .bg-img-right {
      background-position: left center;
    }
    #saasbox_page_content .bg-size-contain {
      background-image: none !important;
    }
  }
  #saasbox_page_content .opacity-0 {
    opacity: 0;
  }
  #saasbox_page_content .opacity-5 {
    opacity: .05 !important;
  }
  #saasbox_page_content .opacity-10 {
    opacity: .1 !important;
  }
  #saasbox_page_content .opacity-15 {
    opacity: .15 !important;
  }
  #saasbox_page_content .opacity-20 {
    opacity: .2 !important;
  }
  #saasbox_page_content .opacity-25 {
    opacity: .25 !important;
  }
  #saasbox_page_content .opacity-30 {
    opacity: .3 !important;
  }
  #saasbox_page_content .opacity-35 {
    opacity: .35 !important;
  }
  #saasbox_page_content .opacity-40 {
    opacity: .4 !important;
  }
  #saasbox_page_content .opacity-45 {
    opacity: .45 !important;
  }
  #saasbox_page_content .opacity-50 {
    opacity: .5 !important;
  }
  #saasbox_page_content .opacity-55 {
    opacity: .55 !important;
  }
  #saasbox_page_content .opacity-60 {
    opacity: .6 !important;
  }
  #saasbox_page_content .opacity-65 {
    opacity: .65 !important;
  }
  #saasbox_page_content .opacity-70 {
    opacity: .7 !important;
  }
  #saasbox_page_content .opacity-75 {
    opacity: .75 !important;
  }
  #saasbox_page_content .opacity-80 {
    opacity: .8 !important;
  }
  #saasbox_page_content .opacity-85 {
    opacity: .85 !important;
  }
  #saasbox_page_content .opacity-90 {
    opacity: .9 !important;
  }
  #saasbox_page_content .opacity-95 {
    opacity: .95 !important;
  }
  #saasbox_page_content .opacity-100 {
    opacity: 1 !important;
  }
  #saasbox_page_content .shadow-0,
  #saasbox_page_content .hover-shadow-0:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .shadow-1,
  #saasbox_page_content .product-3,
  #saasbox_page_content .hover-shadow-1:hover {
    -webkit-box-shadow: 0 0 4px rgba(0,0,0,0.06);
    box-shadow: 0 0 4px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .shadow-2,
  #saasbox_page_content .portfolio-2 img,
  #saasbox_page_content .hover-shadow-2:hover {
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.06);
    box-shadow: 0 0 8px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .shadow-3,
  #saasbox_page_content .hover-shadow-3:hover {
    -webkit-box-shadow: 0 0 12px rgba(0,0,0,0.06);
    box-shadow: 0 0 12px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .shadow-4,
  #saasbox_page_content .hover-shadow-4:hover {
    -webkit-box-shadow: 0 0 18px rgba(0,0,0,0.06);
    box-shadow: 0 0 18px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .shadow-5,
  #saasbox_page_content .hover-shadow-5:hover,
  #saasbox_page_content .portfolio-2 img:hover {
    -webkit-box-shadow: 0 0 26px rgba(0,0,0,0.06);
    box-shadow: 0 0 26px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .shadow-6,
  #saasbox_page_content .hover-shadow-6:hover,
  #saasbox_page_content .product-3:hover {
    -webkit-box-shadow: 0 0 36px rgba(0,0,0,0.06);
    box-shadow: 0 0 36px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .shadow-7,
  #saasbox_page_content .hover-shadow-7:hover {
    -webkit-box-shadow: 0 0 48px rgba(0,0,0,0.06);
    box-shadow: 0 0 48px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .shadow-8,
  #saasbox_page_content .hover-shadow-8:hover {
    -webkit-box-shadow: 0 0 60px rgba(0,0,0,0.06);
    box-shadow: 0 0 60px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .shadow-9,
  #saasbox_page_content .hover-shadow-9:hover {
    -webkit-box-shadow: 0 0 74px rgba(0,0,0,0.06);
    box-shadow: 0 0 74px rgba(0,0,0,0.06);
  }
  #saasbox_page_content .overflow-hidden {
    overflow: hidden;
  }
  #saasbox_page_content .transition {
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .cursor-pointer {
    cursor: pointer;
  }
  #saasbox_page_content .hover-move-up {
    display: block;
    -webkit-transition: .2s ease-out;
    transition: .2s ease-out;
  }
  #saasbox_page_content .hover-move-up:hover {
    -webkit-transform: translateY(-0.5rem);
    transform: translateY(-0.5rem);
  }
  #saasbox_page_content .fit-cover {
    object-fit: cover;
  }
  #saasbox_page_content .fill-parent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  #saasbox_page_content .text-hue-rotate {
    color: #f35626;
    background-image: -webkit-linear-gradient(92deg,#f35626,#feab3a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: hue-rotate 60s infinite linear;
  }
  #saasbox_page_content .bg-hue-rotate {
    -webkit-animation: hue-rotate 30s linear infinite;
  }
  @-webkit-keyframes hue {
    #saasbox_page_content from {
      -webkit-filter: hue-rotate(0deg);
    }
    #saasbox_page_content to {
      -webkit-filter: hue-rotate(-360deg);
    }
  }
  #saasbox_page_content .flex-grow-0 {
    -webkit-box-flex: 0;
    flex-grow: 0;
  }
  #saasbox_page_content .w-125 {
    width: 125px !important;
  }
  #saasbox_page_content .h-125 {
    height: 125px !important;
  }
  #saasbox_page_content .mw-125 {
    min-width: 125px !important;
  }
  #saasbox_page_content .mh-125 {
    min-height: 125px !important;
  }
  #saasbox_page_content .w-150 {
    width: 150px !important;
  }
  #saasbox_page_content .h-150 {
    height: 150px !important;
  }
  #saasbox_page_content .mw-150 {
    min-width: 150px !important;
  }
  #saasbox_page_content .mh-150 {
    min-height: 150px !important;
  }
  #saasbox_page_content .w-175 {
    width: 175px !important;
  }
  #saasbox_page_content .h-175 {
    height: 175px !important;
  }
  #saasbox_page_content .mw-175 {
    min-width: 175px !important;
  }
  #saasbox_page_content .mh-175 {
    min-height: 175px !important;
  }
  #saasbox_page_content .w-200 {
    width: 200px !important;
  }
  #saasbox_page_content .h-200 {
    height: 200px !important;
  }
  #saasbox_page_content .mw-200 {
    min-width: 200px !important;
  }
  #saasbox_page_content .mh-200 {
    min-height: 200px !important;
  }
  #saasbox_page_content .w-250 {
    width: 250px !important;
  }
  #saasbox_page_content .h-250 {
    height: 250px !important;
  }
  #saasbox_page_content .mw-250 {
    min-width: 250px !important;
  }
  #saasbox_page_content .mh-250 {
    min-height: 250px !important;
  }
  #saasbox_page_content .w-300 {
    width: 300px !important;
  }
  #saasbox_page_content .h-300 {
    height: 300px !important;
  }
  #saasbox_page_content .mw-300 {
    min-width: 300px !important;
  }
  #saasbox_page_content .mh-300 {
    min-height: 300px !important;
  }
  #saasbox_page_content .w-350 {
    width: 350px !important;
  }
  #saasbox_page_content .h-350 {
    height: 350px !important;
  }
  #saasbox_page_content .mw-350 {
    min-width: 350px !important;
  }
  #saasbox_page_content .mh-350 {
    min-height: 350px !important;
  }
  #saasbox_page_content .w-400 {
    width: 400px !important;
  }
  #saasbox_page_content .h-400 {
    height: 400px !important;
  }
  #saasbox_page_content .mw-400 {
    min-width: 400px !important;
  }
  #saasbox_page_content .mh-400 {
    min-height: 400px !important;
  }
  #saasbox_page_content .w-500 {
    width: 500px !important;
  }
  #saasbox_page_content .h-500 {
    height: 500px !important;
  }
  #saasbox_page_content .mw-500 {
    min-width: 500px !important;
  }
  #saasbox_page_content .mh-500 {
    min-height: 500px !important;
  }
  #saasbox_page_content .w-600 {
    width: 600px !important;
  }
  #saasbox_page_content .h-600 {
    height: 600px !important;
  }
  #saasbox_page_content .mw-600 {
    min-width: 600px !important;
  }
  #saasbox_page_content .mh-600 {
    min-height: 600px !important;
  }
  #saasbox_page_content .w-700 {
    width: 700px !important;
  }
  #saasbox_page_content .h-700 {
    height: 700px !important;
  }
  #saasbox_page_content .mw-700 {
    min-width: 700px !important;
  }
  #saasbox_page_content .mh-700 {
    min-height: 700px !important;
  }
  #saasbox_page_content .w-800 {
    width: 800px !important;
  }
  #saasbox_page_content .h-800 {
    height: 800px !important;
  }
  #saasbox_page_content .mw-800 {
    min-width: 800px !important;
  }
  #saasbox_page_content .mh-800 {
    min-height: 800px !important;
  }
  #saasbox_page_content .w-900 {
    width: 900px !important;
  }
  #saasbox_page_content .h-900 {
    height: 900px !important;
  }
  #saasbox_page_content .mw-900 {
    min-width: 900px !important;
  }
  #saasbox_page_content .mh-900 {
    min-height: 900px !important;
  }
  #saasbox_page_content .w-5px {
    width: 5px !important;
  }
  #saasbox_page_content .h-5px {
    height: 5px !important;
  }
  #saasbox_page_content .w-10px {
    width: 10px !important;
  }
  #saasbox_page_content .h-10px {
    height: 10px !important;
  }
  #saasbox_page_content .w-15px {
    width: 15px !important;
  }
  #saasbox_page_content .h-15px {
    height: 15px !important;
  }
  #saasbox_page_content .w-20px {
    width: 20px !important;
  }
  #saasbox_page_content .h-20px {
    height: 20px !important;
  }
  #saasbox_page_content .w-25px {
    width: 25px !important;
  }
  #saasbox_page_content .h-25px {
    height: 25px !important;
  }
  #saasbox_page_content .w-30px {
    width: 30px !important;
  }
  #saasbox_page_content .h-30px {
    height: 30px !important;
  }
  #saasbox_page_content .w-35px {
    width: 35px !important;
  }
  #saasbox_page_content .h-35px {
    height: 35px !important;
  }
  #saasbox_page_content .w-40px {
    width: 40px !important;
  }
  #saasbox_page_content .h-40px {
    height: 40px !important;
  }
  #saasbox_page_content .w-45px {
    width: 45px !important;
  }
  #saasbox_page_content .h-45px {
    height: 45px !important;
  }
  #saasbox_page_content .w-50px {
    width: 50px !important;
  }
  #saasbox_page_content .h-50px {
    height: 50px !important;
  }
  #saasbox_page_content .w-55px {
    width: 55px !important;
  }
  #saasbox_page_content .h-55px {
    height: 55px !important;
  }
  #saasbox_page_content .w-60px {
    width: 60px !important;
  }
  #saasbox_page_content .h-60px {
    height: 60px !important;
  }
  #saasbox_page_content .w-65px {
    width: 65px !important;
  }
  #saasbox_page_content .h-65px {
    height: 65px !important;
  }
  #saasbox_page_content .w-70px {
    width: 70px !important;
  }
  #saasbox_page_content .h-70px {
    height: 70px !important;
  }
  #saasbox_page_content .w-75px {
    width: 75px !important;
  }
  #saasbox_page_content .h-75px {
    height: 75px !important;
  }
  #saasbox_page_content .w-80px {
    width: 80px !important;
  }
  #saasbox_page_content .h-80px {
    height: 80px !important;
  }
  #saasbox_page_content .w-85px {
    width: 85px !important;
  }
  #saasbox_page_content .h-85px {
    height: 85px !important;
  }
  #saasbox_page_content .w-90px {
    width: 90px !important;
  }
  #saasbox_page_content .h-90px {
    height: 90px !important;
  }
  #saasbox_page_content .w-95px {
    width: 95px !important;
  }
  #saasbox_page_content .h-95px {
    height: 95px !important;
  }
  #saasbox_page_content .w-100px {
    width: 100px !important;
  }
  #saasbox_page_content .h-100px {
    height: 100px !important;
  }
  #saasbox_page_content .h-auto {
    height: auto;
  }
  #saasbox_page_content .w-auto {
    width: auto;
  }
  #saasbox_page_content .w-fullscreen,
  #saasbox_page_content .w-100vw {
    width: 100vw !important;
  }
  #saasbox_page_content .h-fullscreen,
  #saasbox_page_content .h-100vh {
    height: 100vh !important;
  }
  #saasbox_page_content .mw-fullscreen,
  #saasbox_page_content .mw-100vw {
    min-width: 100vw !important;
  }
  #saasbox_page_content .mh-fullscreen,
  #saasbox_page_content .mh-100vh {
    min-height: 100vh !important;
  }
  @media (max-height: 640px) {
    #saasbox_page_content .h-fullscreen {
      height: auto !important;
    }
  }
  #saasbox_page_content .gap-x-1>* {
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
  }
  #saasbox_page_content .gap-x-1>*:first-child {
    margin-left: 0;
  }
  #saasbox_page_content .gap-x-1>*:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .gap-x-2>* {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
  }
  #saasbox_page_content .gap-x-2>*:first-child {
    margin-left: 0;
  }
  #saasbox_page_content .gap-x-2>*:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .gap-x>*,
  #saasbox_page_content .gap-x-3>* {
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
  }
  #saasbox_page_content .gap-x>*:first-child,
  #saasbox_page_content .gap-x-3>*:first-child {
    margin-left: 0;
  }
  #saasbox_page_content .gap-x>*:last-child,
  #saasbox_page_content .gap-x-3>*:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .gap-x-4>* {
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
  }
  #saasbox_page_content .gap-x-4>*:first-child {
    margin-left: 0;
  }
  #saasbox_page_content .gap-x-4>*:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .gap-x-5>* {
    display: inline-block;
    margin-left: 16px;
    margin-right: 16px;
  }
  #saasbox_page_content .gap-x-5>*:first-child {
    margin-left: 0;
  }
  #saasbox_page_content .gap-x-5>*:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .gap-x-6>* {
    display: inline-block;
    margin-left: 24px;
    margin-right: 24px;
  }
  #saasbox_page_content .gap-x-6>*:first-child {
    margin-left: 0;
  }
  #saasbox_page_content .gap-x-6>*:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .gap-x-7>* {
    display: inline-block;
    margin-left: 32px;
    margin-right: 32px;
  }
  #saasbox_page_content .gap-x-7>*:first-child {
    margin-left: 0;
  }
  #saasbox_page_content .gap-x-7>*:last-child {
    margin-right: 0;
  }
  #saasbox_page_content .gap-xy-1 {
    margin: -2px;
  }
  #saasbox_page_content .gap-xy-1>* {
    display: inline-block;
    margin: 2px;
  }
  #saasbox_page_content .gap-xy-2 {
    margin: -4px;
  }
  #saasbox_page_content .gap-xy-2>* {
    display: inline-block;
    margin: 4px;
  }
  #saasbox_page_content .gap-xy,
  #saasbox_page_content .gap-xy-3 {
    margin: -8px;
  }
  #saasbox_page_content .gap-xy>*,
  #saasbox_page_content .gap-xy-3>* {
    display: inline-block;
    margin: 8px;
  }
  #saasbox_page_content .gap-xy-4 {
    margin: -12px;
  }
  #saasbox_page_content .gap-xy-4>* {
    display: inline-block;
    margin: 12px;
  }
  #saasbox_page_content .gap-xy-5 {
    margin: -16px;
  }
  #saasbox_page_content .gap-xy-5>* {
    display: inline-block;
    margin: 16px;
  }
  #saasbox_page_content .gap-xy-6 {
    margin: -24px;
  }
  #saasbox_page_content .gap-xy-6>* {
    display: inline-block;
    margin: 24px;
  }
  #saasbox_page_content .gap-xy-7 {
    margin: -32px;
  }
  #saasbox_page_content .gap-xy-7>* {
    display: inline-block;
    margin: 32px;
  }
  #saasbox_page_content .font-body {
    font-family: "Open Sans", sans-serif;
  }
  #saasbox_page_content .font-title {
    font-family: Dosis, sans-serif;
  }
  #saasbox_page_content .fs-6,
  #saasbox_page_content .font-size-6 {
    font-size: 6px !important;
  }
  #saasbox_page_content .fs-7,
  #saasbox_page_content .font-size-7 {
    font-size: 7px !important;
  }
  #saasbox_page_content .fs-8,
  #saasbox_page_content .font-size-8 {
    font-size: 8px !important;
  }
  #saasbox_page_content .fs-9,
  #saasbox_page_content .font-size-9 {
    font-size: 9px !important;
  }
  #saasbox_page_content .fs-10,
  #saasbox_page_content .font-size-10 {
    font-size: 10px !important;
  }
  #saasbox_page_content .fs-11,
  #saasbox_page_content .font-size-11 {
    font-size: 11px !important;
  }
  #saasbox_page_content .fs-12,
  #saasbox_page_content .font-size-12 {
    font-size: 12px !important;
  }
  #saasbox_page_content .fs-13,
  #saasbox_page_content .font-size-13 {
    font-size: 13px !important;
  }
  #saasbox_page_content .fs-14,
  #saasbox_page_content .font-size-14 {
    font-size: 14px !important;
  }
  #saasbox_page_content .fs-15,
  #saasbox_page_content .font-size-15 {
    font-size: 15px !important;
  }
  #saasbox_page_content .fs-16,
  #saasbox_page_content .font-size-16 {
    font-size: 16px !important;
  }
  #saasbox_page_content .fs-17,
  #saasbox_page_content .font-size-17 {
    font-size: 17px !important;
  }
  #saasbox_page_content .fs-18,
  #saasbox_page_content .font-size-18 {
    font-size: 18px !important;
  }
  #saasbox_page_content .fs-19,
  #saasbox_page_content .font-size-19 {
    font-size: 19px !important;
  }
  #saasbox_page_content .fs-20,
  #saasbox_page_content .font-size-20 {
    font-size: 20px !important;
  }
  #saasbox_page_content .lead-1 {
    font-size: 1.05469rem !important;
  }
  #saasbox_page_content .lead-2 {
    font-size: 1.17188rem !important;
  }
  #saasbox_page_content .lead-3 {
    font-size: 1.40625rem !important;
  }
  #saasbox_page_content .lead-4 {
    font-size: 1.64063rem !important;
  }
  #saasbox_page_content .lead-5 {
    font-size: 1.875rem !important;
  }
  #saasbox_page_content .lead-6 {
    font-size: 2.34375rem !important;
  }
  #saasbox_page_content .lead-7 {
    font-size: 3.04688rem !important;
  }
  #saasbox_page_content .lead-8 {
    font-size: 3.75rem !important;
  }
  #saasbox_page_content .lead-9 {
    font-size: 4.6875rem !important;
  }
  #saasbox_page_content .small-1 {
    font-size: .87891rem !important;
  }
  #saasbox_page_content .small-2 {
    font-size: .82031rem !important;
  }
  #saasbox_page_content .small-3 {
    font-size: .76172rem !important;
  }
  #saasbox_page_content .small-4 {
    font-size: .70313rem !important;
  }
  #saasbox_page_content .small-5 {
    font-size: .64453rem !important;
  }
  #saasbox_page_content .small-6 {
    font-size: .58594rem !important;
  }
  #saasbox_page_content .small-7 {
    font-size: .52734rem !important;
  }
  #saasbox_page_content .small-8 {
    font-size: .46875rem !important;
  }
  #saasbox_page_content .small-9 {
    font-size: .41016rem !important;
  }
  #saasbox_page_content .fw-100,
  #saasbox_page_content .font-weight-100 {
    font-weight: 100 !important;
  }
  #saasbox_page_content .fw-200,
  #saasbox_page_content .font-weight-200 {
    font-weight: 200 !important;
  }
  #saasbox_page_content .fw-300,
  #saasbox_page_content .font-weight-300 {
    font-weight: 300 !important;
  }
  #saasbox_page_content .fw-400,
  #saasbox_page_content .font-weight-400 {
    font-weight: 400 !important;
  }
  #saasbox_page_content .fw-500,
  #saasbox_page_content .font-weight-500 {
    font-weight: 500 !important;
  }
  #saasbox_page_content .fw-600,
  #saasbox_page_content .font-weight-600 {
    font-weight: 600 !important;
  }
  #saasbox_page_content .fw-700,
  #saasbox_page_content .font-weight-700 {
    font-weight: 700 !important;
  }
  #saasbox_page_content .fw-800,
  #saasbox_page_content .font-weight-800 {
    font-weight: 800 !important;
  }
  #saasbox_page_content .fw-900,
  #saasbox_page_content .font-weight-900 {
    font-weight: 900 !important;
  }
  #saasbox_page_content .lh-1,
  #saasbox_page_content .line-height-1 {
    line-height: 1;
  }
  #saasbox_page_content .lh-2,
  #saasbox_page_content .line-height-2 {
    line-height: 1.3;
  }
  #saasbox_page_content .lh-3,
  #saasbox_page_content .line-height-3 {
    line-height: 1.45;
  }
  #saasbox_page_content .lh-4,
  #saasbox_page_content .line-height-4 {
    line-height: 1.6;
  }
  #saasbox_page_content .lh-5,
  #saasbox_page_content .line-height-5 {
    line-height: 1.75;
  }
  #saasbox_page_content .lh-6,
  #saasbox_page_content .line-height-6 {
    line-height: 1.9;
  }
  #saasbox_page_content .lh-7,
  #saasbox_page_content .line-height-7 {
    line-height: 2.05;
  }
  #saasbox_page_content .lh-8,
  #saasbox_page_content .line-height-8 {
    line-height: 2.2;
  }
  #saasbox_page_content .lh-9,
  #saasbox_page_content .line-height-9 {
    line-height: 2.35;
  }
  #saasbox_page_content .ls-0,
  #saasbox_page_content .letter-spacing-0 {
    letter-spacing: 0;
  }
  #saasbox_page_content .ls-1,
  #saasbox_page_content .letter-spacing-1 {
    letter-spacing: 1px;
  }
  #saasbox_page_content .ls-2,
  #saasbox_page_content .letter-spacing-2 {
    letter-spacing: 2px;
  }
  #saasbox_page_content .ls-3,
  #saasbox_page_content .letter-spacing-3 {
    letter-spacing: 3px;
  }
  #saasbox_page_content .ls-4,
  #saasbox_page_content .letter-spacing-4 {
    letter-spacing: 4px;
  }
  #saasbox_page_content .ls-5,
  #saasbox_page_content .letter-spacing-5 {
    letter-spacing: 5px;
  }
  #saasbox_page_content .text-truncate {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #saasbox_page_content .card-outline {
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.3);
  }
  #saasbox_page_content .card-hover-inverse:hover {
    background-color: #191919;
    border-color: #191919;
  }
  #saasbox_page_content .text-white {
    color: rgba(255,255,255,0.85) !important;
  }
  #saasbox_page_content .text-white h1,
  #saasbox_page_content .text-white h2,
  #saasbox_page_content .text-white h3,
  #saasbox_page_content .text-white h4,
  #saasbox_page_content .text-white .woocommerce h2,
  #saasbox_page_content .woocommerce .text-white h2,
  #saasbox_page_content .text-white h5,
  #saasbox_page_content .text-white h6,
  #saasbox_page_content .text-white .h1,
  #saasbox_page_content .text-white .h2,
  #saasbox_page_content .text-white .h3,
  #saasbox_page_content .text-white .h4,
  #saasbox_page_content .text-white .h5,
  #saasbox_page_content .text-white .h6 {
    color: #fff;
  }
  #saasbox_page_content .text-white a:not(.btn):not(.wpcf7-submit):not(.dropdown-item) {
    color: rgba(255,255,255,0.8);
  }
  #saasbox_page_content .text-white hr {
    border-top-color: rgba(255,255,255,0.15);
  }
  #saasbox_page_content .text-white p {
    color: rgba(255,255,255,0.85);
  }
  #saasbox_page_content .text-white code {
    background-color: rgba(255,255,255,0.1);
    color: #eee;
  }
  #saasbox_page_content .text-white small,
  #saasbox_page_content .text-white .small {
    color: rgba(255,255,255,0.8);
  }
  #saasbox_page_content .text-white .divider {
    color: rgba(255,255,255,0.5);
  }
  #saasbox_page_content .text-white .divider::before,
  #saasbox_page_content .text-white .divider::after {
    border-top-color: rgba(255,255,255,0.15);
  }
  #saasbox_page_content .text-white .section-header small {
    color: rgba(255,255,255,0.6);
  }
  #saasbox_page_content .text-white .rating label.empty {
    color: rgba(255,255,255,0.3);
  }
  #saasbox_page_content .text-white .card-inverse {
    background-color: rgba(255,255,255,0.1);
  }
  #saasbox_page_content .text-white .btn-outline-light {
    color: rgba(255,255,255,0.6);
    border-color: rgba(255,255,255,0.3);
  }
  #saasbox_page_content .text-white .btn-outline-light:hover {
    color: #757575;
  }
  #saasbox_page_content .text-white .close {
    color: rgba(255,255,255,0.85);
  }
  #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="sticky"],
  #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="smart"],
  #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="fixed"],
  #saasbox_page_content .admin-bar:not(.body-scrolled) .searchbox {
    top: 32px;
  }
  #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="sticky"],
  #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="fixed"],
  #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="smart"] {
    top: 32px;
  }
  @media screen and (max-width: 782px) {
    #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="sticky"],
    #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="smart"],
    #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="fixed"],
    #saasbox_page_content .admin-bar:not(.body-scrolled) .searchbox {
      top: 46px;
    }
    #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="sticky"],
    #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="fixed"],
    #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="smart"] {
      top: 46px;
    }
    #saasbox_page_content .admin-bar .navbar-expand-xs .nav-navbar,
    #saasbox_page_content .admin-bar .navbar-expand-sm .nav-navbar,
    #saasbox_page_content .admin-bar .navbar-expand-md .nav-navbar {
      top: 46px;
    }
  }
  @media screen and (max-width: 599px) {
    #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="sticky"],
    #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="fixed"],
    #saasbox_page_content .admin-bar:not(.body-scrolled) .navbar[data-navbar="smart"] {
      top: 46px;
    }
    #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="sticky"],
    #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="fixed"],
    #saasbox_page_content .body-scrolled.admin-bar .navbar[data-navbar="smart"] {
      top: 0 !important;
    }
    #saasbox_page_content .body-scrolled.admin-bar .navbar-expand-xs .nav-navbar,
    #saasbox_page_content .body-scrolled.admin-bar .navbar-expand-sm .nav-navbar,
    #saasbox_page_content .body-scrolled.admin-bar .navbar-expand-md .nav-navbar {
      top: 0 !important;
    }
  }
  #saasbox_page_content .alignnone {
    margin: 5px 20px 20px 0;
  }
  #saasbox_page_content .aligncenter,
  #saasbox_page_content div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
  }
  #saasbox_page_content .alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }
  #saasbox_page_content .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }
  #saasbox_page_content a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }
  #saasbox_page_content a img.alignnone {
    margin: 5px 20px 20px 0;
  }
  #saasbox_page_content a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }
  #saasbox_page_content a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  #saasbox_page_content .gallery-caption,
  #saasbox_page_content .wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%;
    padding: 5px 3px 10px;
    text-align: center;
  }
  #saasbox_page_content .wp-caption.alignnone {
    margin: 5px 20px 20px 0;
  }
  #saasbox_page_content .wp-caption.alignleft {
    margin: 5px 20px 20px 0;
  }
  #saasbox_page_content .wp-caption.alignright {
    margin: 5px 0 20px 20px;
  }
  #saasbox_page_content .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }
  #saasbox_page_content .wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }
  #saasbox_page_content .screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  #saasbox_page_content .screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 2px 2px rgba(0,0,0,0.6);
    box-shadow: 0 0 2px 2px rgba(0,0,0,0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: .875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
  #saasbox_page_content .bypostauthor {
    font-size: 1rem;
  }
  #saasbox_page_content .nav-navbar .nav .nav-link.menu-item-has-children,
  #saasbox_page_content .nav-navbar .nav .menu-item a.menu-item-has-children,
  #saasbox_page_content .menu-item .nav-navbar .nav a.menu-item-has-children {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  #saasbox_page_content .nav-navbar .nav .nav-link.menu-item-has-children .fa-caret-right,
  #saasbox_page_content .nav-navbar .nav .menu-item a.menu-item-has-children .fa-caret-right,
  #saasbox_page_content .menu-item .nav-navbar .nav a.menu-item-has-children .fa-caret-right {
    line-height: 1.9;
  }
  #saasbox_page_content .navbar .navbar_link_icons {
    display: -webkit-inline-box;
    display: inline-flex;
  }
  #saasbox_page_content .navbar .navbar_link_icons+.navbar_buttons {
    margin-left: .5rem;
  }
  #saasbox_page_content .comment-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 40px;
  }
  #saasbox_page_content .comment-list>.comment:not(:last-child) {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f1f2f3;
  }
  #saasbox_page_content .comment-list .children {
    list-style: none;
  }
  #saasbox_page_content .comment-list .children .comment {
    margin-top: 30px;
  }
  #saasbox_page_content .comment-list .comment-body {
    position: relative;
  }
  #saasbox_page_content .comment-list .avatar {
    margin-right: 16px;
  }
  #saasbox_page_content .comment-list .comment-content {
    padding-left: 66px;
  }
  #saasbox_page_content .comment-list .reply {
    position: absolute;
    top: 10px;
    right: 0;
  }
  #saasbox_page_content .comment-list .comment-reply-link {
    background-color: transparent;
    opacity: .7;
  }
  #saasbox_page_content .comment-list .comment-reply-link:hover {
    opacity: 1;
  }
  #saasbox_page_content .comment-list .says,
  #saasbox_page_content .comment-list .reply .icon,
  #saasbox_page_content .comment-list .comment-metadata {
    display: none;
  }
  #saasbox_page_content .comment-form input {
    width: 50%;
  }
  @media (max-width: 767.98px) {
    #saasbox_page_content .comment-form input {
      width: 100%;
    }
  }
  #saasbox_page_content .comment-form .logged-in-as {
    font-size: 13px;
  }
  #saasbox_page_content .comment-reply-title {
    display: none;
  }
  #saasbox_page_content #disqus_thread {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    max-width: 100%;
    margin-bottom: 30px;
  }
  @media (min-width: 576px) {
    #saasbox_page_content #disqus_thread {
      width: 540px;
    }
  }
  @media (min-width: 768px) {
    #saasbox_page_content #disqus_thread {
      width: 720px;
    }
  }
  @media (min-width: 992px) {
    #saasbox_page_content #disqus_thread {
      width: 640px;
    }
  }
  @media (min-width: 1200px) {
    #saasbox_page_content #disqus_thread {
      width: 730px;
    }
  }
  #saasbox_page_content .has-primary-background-color {
    background-color: #50a1ff;
  }
  #saasbox_page_content .has-primary-color {
    color: #50a1ff;
  }
  #saasbox_page_content .has-secondary-background-color {
    background-color: #e9ecf0;
  }
  #saasbox_page_content .has-secondary-color {
    color: #e9ecf0;
  }
  #saasbox_page_content .has-danger-background-color {
    background-color: #ff4954;
  }
  #saasbox_page_content .has-danger-color {
    color: #ff4954;
  }
  #saasbox_page_content .has-success-background-color {
    background-color: #3cd458;
  }
  #saasbox_page_content .has-success-color {
    color: #3cd458;
  }
  #saasbox_page_content .has-warning-background-color {
    background-color: #ffba00;
  }
  #saasbox_page_content .has-warning-color {
    color: #ffba00;
  }
  #saasbox_page_content .has-info-background-color {
    background-color: #926dde;
  }
  #saasbox_page_content .has-info-color {
    color: #926dde;
  }
  #saasbox_page_content .has-light-background-color {
    background-color: #fafbfb;
  }
  #saasbox_page_content .has-light-color {
    color: #fafbfb;
  }
  #saasbox_page_content .has-dark-background-color {
    background-color: #191919;
  }
  #saasbox_page_content .has-dark-color {
    color: #191919;
  }
  #saasbox_page_content .has-white-background-color {
    background-color: #fff;
  }
  #saasbox_page_content .has-white-color {
    color: #fff;
  }
  #saasbox_page_content .container>.row .container,
  #saasbox_page_content .woocommerce-page .woocommerce>.row .container,
  #saasbox_page_content .container>.row .woocommerce-page .woocommerce,
  #saasbox_page_content .woocommerce-page .container>.row .woocommerce,
  #saasbox_page_content .woocommerce-page .woocommerce>.row .woocommerce {
    padding-left: 0;
    padding-right: 0;
  }
  #saasbox_page_content .main-content>.header:first-child+.container,
  #saasbox_page_content .woocommerce-page .main-content>.header:first-child+.woocommerce,
  #saasbox_page_content .main-content>.container:first-child,
  #saasbox_page_content .woocommerce-page .main-content>.woocommerce:first-child {
    margin-top: 7rem;
  }
  #saasbox_page_content .main-content>.container:last-child,
  #saasbox_page_content .woocommerce-page .main-content>.woocommerce:last-child {
    margin-bottom: 7rem;
  }
  #saasbox_page_content .post-content [class^="container"]+.section {
    margin-top: 3rem;
  }
  #saasbox_page_content .post-content .section+[class^="container"] {
    margin-top: 3rem;
  }
  #saasbox_page_content .page-links {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
  }
  #saasbox_page_content .page-links .post-page-numbers {
    position: relative;
    display: block;
    line-height: 32px;
    color: #757575;
    background-color: #fff;
    border: 1px solid #eaeff4;
    margin: 0 3px;
    padding: 0;
    text-align: center;
    min-width: 32px;
  }
  #saasbox_page_content .page-links .post-page-numbers.current {
    color: #fff;
    background-color: #50a1ff;
    border-color: #50a1ff;
  }
  #saasbox_page_content .page-links .post-page-numbers:first-child {
    margin-left: .5rem;
  }
  #saasbox_page_content .woocommerce a.added_to_cart,
  #saasbox_page_content #added_to_cart_feedback,
  #saasbox_page_content form.woocommerce-ordering,
  #saasbox_page_content .woocommerce-result-count {
    display: none;
  }
  #saasbox_page_content #add_payment_method table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-cart table.cart td.actions .coupon .input-text,
  #saasbox_page_content .woocommerce-checkout table.cart td.actions .coupon .input-text {
    width: 100px;
  }
  #saasbox_page_content .woocommerce input.button.alt {
    font-weight: 400;
    -webkit-transition: .2s;
    transition: .2s;
  }
  #saasbox_page_content #add_payment_method table.cart input,
  #saasbox_page_content .woocommerce-cart table.cart input,
  #saasbox_page_content .woocommerce-checkout table.cart input {
    line-height: inherit;
  }
  #saasbox_page_content .woocommerce #respond input#submit.disabled:hover,
  #saasbox_page_content .woocommerce #respond input#submit:disabled:hover,
  #saasbox_page_content .woocommerce #respond input#submit:disabled[disabled]:hover,
  #saasbox_page_content .woocommerce a.button.disabled:hover,
  #saasbox_page_content .woocommerce a.button:disabled:hover,
  #saasbox_page_content .woocommerce a.button:disabled[disabled]:hover,
  #saasbox_page_content .woocommerce button.button.disabled:hover,
  #saasbox_page_content .woocommerce button.button:disabled:hover,
  #saasbox_page_content .woocommerce button.button:disabled[disabled]:hover,
  #saasbox_page_content .woocommerce input.button.disabled:hover,
  #saasbox_page_content .woocommerce input.button:disabled:hover,
  #saasbox_page_content .woocommerce input.button:disabled[disabled]:hover {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }
  #saasbox_page_content #coupon_code {
    min-width: 140px;
  }
  #saasbox_page_content .woocommerce input.button.alt {
    border-color: transparent;
  }
  #saasbox_page_content .woocommerce input.button.alt:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #saasbox_page_content .woocommerce-cart table.cart input {
    border: 1px solid #f1f2f3;
  }
  #saasbox_page_content .woocommerce-cart table.cart input:focus {
    outline: 0;
    border-color: #eaeff4;
  }
  #saasbox_page_content .woocommerce form .form-row {
    display: block;
  }
  #saasbox_page_content .woocommerce form .form-row .required {
    color: #ff4954;
  }
  #saasbox_page_content .woocommerce form .form-row .select2-container,
  #saasbox_page_content .woocommerce form .form-row input.input-text,
  #saasbox_page_content .woocommerce form .form-row textarea.input-text,
  #saasbox_page_content .woocommerce form .form-row select,
  #saasbox_page_content .woocommerce .input-text,
  #saasbox_page_content .woocommerce select {
    border-radius: 2px;
    color: #757575;
    padding: 4px 12px;
    font-size: 14px;
    line-height: inherit;
    outline: 0;
    -webkit-transition: .2s linear;
    transition: .2s linear;
    border: 1px solid #eaeff4;
  }
  #saasbox_page_content .woocommerce .input-text.qty {
    min-width: 80px;
    text-align: left;
  }
  #saasbox_page_content .woocommerce form .form-row textarea.input-text {
    height: 120px;
  }
  #saasbox_page_content .woocommerce form .form-row.woocommerce-invalid .select2-container,
  #saasbox_page_content .woocommerce form .form-row.woocommerce-invalid input.input-text,
  #saasbox_page_content .woocommerce form .form-row.woocommerce-invalid select {
    border-color: #ff4954;
  }
  #saasbox_page_content .woocommerce form .form-row.woocommerce-validated .select2-container,
  #saasbox_page_content .woocommerce form .form-row.woocommerce-validated input.input-text,
  #saasbox_page_content .woocommerce form .form-row.woocommerce-validated select {
    border-color: #eaeff4;
  }
  #saasbox_page_content .select2-container--default .select2-selection--single {
    border-color: transparent;
  }
  #saasbox_page_content .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0;
  }
  #saasbox_page_content .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 36px;
  }
  #saasbox_page_content .woocommerce .product_meta {
    display: none;
  }
  #saasbox_page_content .woocommerce .reset_variations {
    margin-left: 1rem;
  }
  #saasbox_page_content .woocommerce .variations_button .button {
    width: 100%;
  }
  #saasbox_page_content .products .product {
    position: relative;
    text-align: center;
  }
  #saasbox_page_content .products .product .onsale {
    border-radius: 3px;
    background-color: #3cd458;
    font-weight: 400;
    min-height: 18px;
    line-height: initial;
  }
  #saasbox_page_content .woocommerce-loop-product__link {
    display: block;
    background-color: #fff;
    border-radius: .25rem;
    -webkit-box-shadow: 0 0 9px rgba(0,0,0,0.06);
    box-shadow: 0 0 9px rgba(0,0,0,0.06);
    border: 1px solid #f1f2f3;
    padding-bottom: 8px;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .woocommerce-loop-product__link img {
    margin-bottom: 8px !important;
  }
  #saasbox_page_content .woocommerce-loop-product__link:hover {
    -webkit-box-shadow: 0 0 36px rgba(0,0,0,0.09);
    box-shadow: 0 0 36px rgba(0,0,0,0.09);
  }
  #saasbox_page_content .woocommerce-loop-product__title {
    font-weight: 1.0625rem;
    font-weight: 600;
  }
  #saasbox_page_content .woocommerce ul.products li.product .price {
    color: #999;
    font-size: .925;
  }
  #saasbox_page_content .woocommerce ul.products li.product .price del {
    display: none;
  }
  #saasbox_page_content .woocommerce ul.products li.product .price ins {
    font-weight: 600;
    text-decoration: none;
  }
  #saasbox_page_content .product .add_to_cart_button.ajax_add_to_cart {
    display: none;
  }
  #saasbox_page_content .woocommerce span.onsale {
    border-radius: 3px;
    background-color: #3cd458;
    font-weight: 400;
    min-height: 18px;
    line-height: initial;
  }
  #saasbox_page_content .woocommerce-tabs {
    margin-top: 4rem;
  }
  #saasbox_page_content .woocommerce div.product p.price,
  #saasbox_page_content .woocommerce div.product span.price {
    color: #757575;
  }
  #saasbox_page_content .woocommerce div.product p.price del+ins,
  #saasbox_page_content .woocommerce div.product span.price del+ins {
    margin-left: 1rem;
  }
  #saasbox_page_content .woocommerce div.product p.price ins,
  #saasbox_page_content .woocommerce div.product span.price ins {
    text-decoration: none;
  }
  #saasbox_page_content .woocommerce .related.products {
    margin-top: 5rem;
  }
  #saasbox_page_content .woocommerce div.product .woocommerce-tabs ul.tabs {
    margin-bottom: 0;
  }
  #saasbox_page_content .woocommerce div.product .woocommerce-tabs ul.tabs li,
  #saasbox_page_content .woocommerce div.product .woocommerce-tabs ul.tabs li::after,
  #saasbox_page_content .woocommerce div.product .woocommerce-tabs ul.tabs li::before,
  #saasbox_page_content .woocommerce div.product .woocommerce-tabs ul.tabs::before {
    border-color: #eaeff4;
  }
  #saasbox_page_content .woocommerce div.product .woocommerce-tabs ul.tabs li a {
    font-weight: 500;
  }
  #saasbox_page_content .woocommerce div.product .woocommerce-tabs .panel {
    padding: 24px;
    border: 1px solid #eaeff4;
    border-top: 0;
  }
  #saasbox_page_content .woocommerce a.remove {
    color: #fff !important;
    background: #ff4954;
    font-weight: 300;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  #saasbox_page_content .woocommerce table.shop_table {
    border-radius: 0;
    border: 0;
  }
  #saasbox_page_content .woocommerce table.shop_table th {
    padding: 12px 16px;
    font-weight: 500;
    background-color: #f9fafb;
    border-bottom: 0;
  }
  #saasbox_page_content .woocommerce table.shop_table td {
    background-color: #fcfdfe;
    padding: 16px 12px;
    border-top-color: #f1f2f3;
  }
  #saasbox_page_content .woocommerce table.shop_table td.actions {
    background-color: transparent;
    padding-top: 26px;
  }
  #saasbox_page_content .woocommerce table.shop_table tbody th {
    font-weight: 600;
  }
  #saasbox_page_content .woocommerce-cart .cart-collaterals .cart_totals tr th,
  #saasbox_page_content .woocommerce-cart .cart-collaterals .cart_totals tr td,
  #saasbox_page_content .woocommerce-cart .cart-collaterals .cart_totals tr th,
  #saasbox_page_content .woocommerce-cart .cart-collaterals .cart_totals tr td {
    border-top: 0;
  }
  #saasbox_page_content .woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
    font-size: 12px;
  }
  #saasbox_page_content .cart_totals {
    margin-top: 30px;
  }
  #saasbox_page_content .cart_totals h2 {
    font-weight: 400;
  }
  #saasbox_page_content .badge-cart-count {
    background: #ff4954;
    position: relative;
    top: -10px;
    font-size: 9px;
  }
  #saasbox_page_content .woocommerce .col2-set .col-1,
  #saasbox_page_content .woocommerce .col2-set .col-2,
  #saasbox_page_content .woocommerce-page .col2-set .col-1,
  #saasbox_page_content .woocommerce-page .col2-set .col-2 {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  #saasbox_page_content #order_review_heading {
    margin-top: 30px;
  }
  #saasbox_page_content .woocommerce table.shop_table {
    border-collapse: collapse;
  }
  #saasbox_page_content .woocommerce table.shop_table tbody th,
  #saasbox_page_content .woocommerce table.shop_table tfoot td,
  #saasbox_page_content .woocommerce table.shop_table tfoot th {
    font-weight: 500;
    border-top: 0;
  }
  #saasbox_page_content .checkout h3 {
    font-weight: 400;
    margin-bottom: 20px;
    font-weight: .8em;
  }
  #saasbox_page_content .woocommerce #payment #place_order,
  #saasbox_page_content .woocommerce-page #payment #place_order {
    background-color: #a46497;
    color: #fff;
  }
  #saasbox_page_content .woocommerce form .form-row .select2-container {
    padding-bottom: 8px !important;
  }
  #saasbox_page_content .select2-dropdown {
    border-color: #eaeff4;
  }
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field {
    height: 32px;
  }
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field,
  #saasbox_page_content .select2-container--default .select2-search--dropdown .select2-search__field:focus {
    background-color: #f2f3f4;
  }
  #saasbox_page_content .select2-selection__rendered:focus {
    outline: none !important;
  }
  #saasbox_page_content .woocommerce nav.woocommerce-pagination {
    margin-top: 40px;
  }
  #saasbox_page_content .woocommerce nav.woocommerce-pagination ul {
    border-color: #eaeff4;
  }
  #saasbox_page_content .woocommerce nav.woocommerce-pagination ul li {
    border-color: #eaeff4;
  }
  #saasbox_page_content .woocommerce nav.woocommerce-pagination ul li a,
  #saasbox_page_content .woocommerce nav.woocommerce-pagination ul li span {
    padding: 8px 10px;
    color: #757575;
  }
  #saasbox_page_content .woocommerce nav.woocommerce-pagination ul li a:hover,
  #saasbox_page_content .woocommerce nav.woocommerce-pagination ul li a:focus {
    background-color: #f9fafb;
  }
  #saasbox_page_content .woocommerce nav.woocommerce-pagination ul li span.current {
    background-color: #f5f6f7;
  }
  #saasbox_page_content .woocommerce .woocommerce-customer-details address {
    background-color: #f8f9fb;
    border-radius: 4px;
    border-width: 1px;
  }
  #saasbox_page_content .woocommerce ul.order_details {
    padding: 16px;
    background: #f8f9fb;
    border: 1px dashed #d3ced2;
  }
  #saasbox_page_content .wpcf7-validation-errors,
  #saasbox_page_content .wpcf7-spam-blocked,
  #saasbox_page_content .wpcf7-mail-sent-ok,
  #saasbox_page_content .wpcf7-mail-sent-ng {
    background-color: #f2dede;
    border: none !important;
    color: #a94442;
    border-radius: 3px;
    padding: 12px !important;
    margin: 0 !important;
  }
  #saasbox_page_content .wpcf7-mail-sent-ok {
    background-color: #f2dede;
    color: #3c763d;
  }
  #saasbox_page_content .wpcf7-not-valid-tip {
    color: #c74644 !important;
  }
  #saasbox_page_content .widget {
    padding: 1.5rem 0;
  }
  #saasbox_page_content .sidebar .widget:first-child {
    padding-top: 0;
  }
  #saasbox_page_content .sidebar .widget:last-child {
    padding-bottom: 0;
  }
  #saasbox_page_content .widget-title {
    font-size: .7em;
    font-weight: 700;
  }
  #saasbox_page_content .widget_categories ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #saasbox_page_content .widget_categories .cat-item {
    font-size: 14px;
    color: #bfc5ca;
  }
  #saasbox_page_content .widget_categories .cat-item a {
    color: #757575;
  }
  #saasbox_page_content .widget_categories .cat-item a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content .widget_pages ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #saasbox_page_content .widget_pages .page_item {
    font-size: 14px;
  }
  #saasbox_page_content .widget_pages .page_item a {
    color: #757575;
  }
  #saasbox_page_content .widget_pages .page_item a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content .widget_text .textwidget {
    font-size: 13px;
  }
  #saasbox_page_content .tagcloud .tag-cloud-link {
    font-size: 12px !important;
    margin-right: 4px;
  }
  #saasbox_page_content .tagcloud .tag-link-count {
    opacity: .6;
    font-size: 11px;
  }
  #saasbox_page_content .widget_archive ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #saasbox_page_content .widget_archive li {
    font-size: 14px;
    color: #bfc5ca;
  }
  #saasbox_page_content .widget_archive li a {
    color: #757575;
  }
  #saasbox_page_content .widget_archive li a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content .widget_recent_entries ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #saasbox_page_content .widget_recent_entries li {
    font-size: 14px;
    color: #bfc5ca;
  }
  #saasbox_page_content .widget_recent_entries li a {
    color: #757575;
  }
  #saasbox_page_content .widget_recent_entries li a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content .widget_recent_entries li .post-date {
    font-size: 12px;
    font-style: italic;
    padding-left: 8px;
  }
  #saasbox_page_content .widget_recent_comments ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #saasbox_page_content .widget_recent_comments li {
    font-size: 14px;
    color: #bfc5ca;
  }
  #saasbox_page_content .widget_recent_comments li a {
    color: #757575;
  }
  #saasbox_page_content .widget_recent_comments li a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content .widget_meta ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #saasbox_page_content .widget_meta li {
    font-size: 14px;
    color: #bfc5ca;
  }
  #saasbox_page_content .widget_meta li a {
    color: #757575;
  }
  #saasbox_page_content .widget_meta li a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content .widget_nav_menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #saasbox_page_content .widget_nav_menu li {
    font-size: 14px;
    color: #bfc5ca;
  }
  #saasbox_page_content .widget_nav_menu li a {
    color: #757575;
    padding: 0;
    font-weight: 300;
  }
  #saasbox_page_content .widget_nav_menu li a:hover {
    color: #50a1ff;
  }
  #saasbox_page_content .widget_calendar table {
    width: 100%;
  }
  #saasbox_page_content .widget_calendar table th {
    font-weight: 600;
  }
  #saasbox_page_content .widget_calendar table caption {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }
  #saasbox_page_content .block-number {
    text-align: center;
    font-weight: bold;
    font-size: .75em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #191919;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
  }
  #saasbox_page_content .block-number:hover {
    opacity: 1;
  }
  #saasbox_page_content .block-number::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 6px;
    margin-bottom: 2px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #191919;
  }
  #saasbox_page_content .block-number a:hover,
  #saasbox_page_content .block-number a:focus {
    color: #191919;
  }
  #saasbox_page_content .sample-blocks img {
    border-radius: .25rem;
  }
  #saasbox_page_content .sample-blocks img.aos-animate {
    opacity: .5 !important;
  }
  #saasbox_page_content .sample-blocks img:hover {
    opacity: 1 !important;
    -webkit-transition: .7s !important;
    transition: .7s !important;
  }
  #saasbox_page_content .sample-blocks a {
    position: absolute;
  }
  #saasbox_page_content .sample-blocks a:nth-child(1) {
    -webkit-animation: wobble 4s infinite;
    animation: wobble 4s infinite;
    right: 15px;
    bottom: -20px;
  }
  #saasbox_page_content .sample-blocks a:nth-child(2) {
    -webkit-animation: wobble 8s infinite;
    animation: wobble 8s infinite;
    right: 320px;
    bottom: 30px;
  }
  #saasbox_page_content .sample-blocks a:nth-child(3) {
    -webkit-animation: wobble 5s infinite;
    animation: wobble 5s infinite;
    right: 0;
    bottom: 190px;
  }
  #saasbox_page_content .sample-blocks a:nth-child(4) {
    -webkit-animation: wobble 6s infinite;
    animation: wobble 6s infinite;
    right: 280px;
    bottom: 180px;
  }
  #saasbox_page_content .sample-blocks a:nth-child(5) {
    -webkit-animation: wobble 9s infinite;
    animation: wobble 9s infinite;
    right: 270px;
    bottom: 320px;
  }
  #saasbox_page_content .sample-blocks a:nth-child(6) {
    -webkit-animation: wobble 7s infinite;
    animation: wobble 7s infinite;
    right: 20px;
    bottom: 370px;
  }
  #saasbox_page_content .sample-blocks a:nth-child(7) {
    -webkit-animation: wobble 5s infinite;
    animation: wobble 5s infinite;
    right: 200px;
    bottom: 470px;
  }
  #saasbox_page_content .sample-blocks a:nth-child(8) {
    -webkit-animation: wobble 8s infinite;
    animation: wobble 8s infinite;
    right: 10px;
    bottom: 470px;
  }
  #saasbox_page_content .sample-blocks a:nth-child(9) {
    -webkit-animation: wobble 9s infinite;
    animation: wobble 9s infinite;
    right: 0;
    bottom: 570px;
  }
  @-webkit-keyframes wobble {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(-1deg) translate(-5px,-5px);
      transform: rotate(-1deg) translate(-5px,-5px);
    }
    #saasbox_page_content 50% {
      -webkit-transform: rotate(1deg) translate(5px,5px);
      transform: rotate(1deg) translate(5px,5px);
    }
    #saasbox_page_content 100% {
      -webkit-transform: rotate(-1deg) translate(-5px,-5px);
      transform: rotate(-1deg) translate(-5px,-5px);
    }
  }
  @keyframes wobble {
    #saasbox_page_content 0% {
      -webkit-transform: rotate(-1deg) translate(-5px,-5px);
      transform: rotate(-1deg) translate(-5px,-5px);
    }
    #saasbox_page_content 50% {
      -webkit-transform: rotate(1deg) translate(5px,5px);
      transform: rotate(1deg) translate(5px,5px);
    }
    #saasbox_page_content 100% {
      -webkit-transform: rotate(-1deg) translate(-5px,-5px);
      transform: rotate(-1deg) translate(-5px,-5px);
    }
  }
  